import { WardCard } from 'components/Card/WardCard';
import { WardOverviewGrid } from './styles';
import type { WardOverviewGridViewProps } from './types';

export const WardOverviewGridView = ({ data }: WardOverviewGridViewProps) => (
  <WardOverviewGrid data-testid="ward-overview-grid">
    {data.map(({ id, name, capacity, statistics, active = true }) => (
      <WardCard
        id={id}
        key={id}
        name={name}
        statistics={statistics}
        capacity={capacity}
        active={active}
      />
    ))}
  </WardOverviewGrid>
);
