import * as R from 'ramda';

import { EMPTY_VALUE } from 'App.constants';
import type { CareSettingPatient } from 'services';
import {
  GenderIdentifiedAsMap,
  PatientWardPreferencesMap,
  OriginMap,
  YesNoMap,
  SpecialtyMap,
} from 'mappings/enums';
import {
  getPatientName,
  getPersonIsolationReason,
} from 'mappings/mapping.utils';
import { getDate } from 'mappings/date.utils';

export const patientMapping = (data: CareSettingPatient) => ({
  patientName: {
    key: 'Patient',
    value: getPatientName(data),
  },
  dateOfBirth: {
    key: 'D.O.B',
    value: getDate(
      data.person.attributes.personalInformation.dateOfBirth,
      'dd/MM/yyyy'
    ),
  },
  gender: {
    key: 'Gender',
    value:
      GenderIdentifiedAsMap[
        data.person.attributes.personalInformation.genderIdentifiedAs
      ],
  },
  wardPref: {
    key: 'Ward pref',
    value:
      PatientWardPreferencesMap[
        data.attributes.patientWardPreference ?? 'NONE'
      ],
  },
  furtherPref: {
    key: 'Further pref',
    value: data.attributes.furtherWardPreferences ?? EMPTY_VALUE,
  },
  decisionToAdmitDate: {
    key: 'DTA date',
    value: data.attributes.client?.decisionToAdmitDate ?? EMPTY_VALUE,
  },
  decisionToAdmitTime: {
    key: 'DTA time',
    value: data.attributes.client?.decisionToAdmitTime ?? EMPTY_VALUE,
  },
  isolationReason: {
    key: 'Isolation',
    value: getPersonIsolationReason(data.attributes),
  },
  origin: {
    key: 'Origin',
    value: OriginMap[data.attributes.origin ?? 'NONE'],
  },
  socialCare: {
    key: 'Social care',
    value: YesNoMap[data.attributes.socialCareYN ?? 'NONE'],
  },
  specialty: {
    key: 'Speciality',
    value: SpecialtyMap[data.attributes.specialty ?? 'NONE'],
  },
  comments: {
    key: 'Comments',
    value: data.attributes.admissionComments ?? EMPTY_VALUE,
  },
});

export const mapPatientObjectKeys = (
  data: CareSettingPatient,
  keys: Array<keyof ReturnType<typeof patientMapping>>
) => Object.values(R.pick(keys, patientMapping(data)));
