import { Notification } from '@bt-healthcare/ui-toolkit';
import type { MatchingPatientAlertProps } from './types';

export const MatchingPatientAlert = ({
  data,
  note = 'There is another patient with the same name in this ward',
}: MatchingPatientAlertProps) => {
  if (!data.hasMatchingPatientName) return null;

  return (
    <Notification type="softWarning" alignSelf="baseline" icon="People">
      Note: {note}
    </Notification>
  );
};
