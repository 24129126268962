import type { NamedSet } from 'zustand/middleware';

import type { WardBed } from 'services';
import type { DictionaryList } from 'types';
import type { AppState } from './types';

export const wardBedSlice = (set: NamedSet<AppState>) => ({
  bed: {} as WardBed,
  bedFromTransfer: [] as DictionaryList<string>,
  bedToTransfer: [] as DictionaryList<string>,
  setBed: (bed: WardBed) => set({ bed }, false, 'wardBed/setBed'),
  setBedFromTransfer: (bedFromTransfer: DictionaryList<string>) =>
    set({ bedFromTransfer }, false, 'wardBed/setBedFromTransfer'),
  setBedToTransfer: (bedToTransfer: DictionaryList<string>) =>
    set({ bedToTransfer }, false, 'wardBed/setBedToTransfer'),
  resetBed: () => set({ bed: {} as WardBed }, false, 'wardBed/resetBed'),
  resetBedTransfer: () =>
    set(
      {
        bedFromTransfer: [] as DictionaryList<string>,
        bedToTransfer: [] as DictionaryList<string>,
      },
      false,
      'wardBed/resetBedTransfer'
    ),
});
