import type { BedAllocationFormData } from 'components/BedAllocation/types';
import type {
  CareSettingPatient,
  WardBed,
  WardBedInput,
  WardPatientInput,
} from 'services';
import { isEmpty } from 'validation/rules';
import { omitGraphQLFields } from 'mappings/mapping.utils';
import {
  getCurrentUTCDateTime,
  getDateAsString,
  getDateTime,
  setFormDatesToUTC,
} from '../date.utils';

export const bedAllocationFormMapping = (
  currentData: WardBed,
  data: BedAllocationFormData
) =>
  ({
    ...currentData,
    attributes: {
      ...currentData.attributes,
      bedStatus: data.bedStatus,
      bedStatusComments: isEmpty(data.bedStatusComments)
        ? null
        : data.bedStatusComments,
      bedStatusDateTime: isEmpty(data.bedStatusDate)
        ? null
        : getDateTime(data.bedStatusDate as Date, data.bedStatusTime),
      client: {
        bedStatusDate: isEmpty(data.bedStatusDate)
          ? null
          : getDateAsString(data.bedStatusDate! as Date),
        bedStatusTime: isEmpty(data.bedStatusTime) ? null : data.bedStatusTime,
      },
      reasonForBedClosure: isEmpty(data.reasonForBedClosure)
        ? currentData.attributes.reasonForBedClosure
        : data.reasonForBedClosure!.value,
      otherReasonForBedClosure: isEmpty(data.otherReasonForBedClosure)
        ? null
        : data.otherReasonForBedClosure,
      bedCleaningMethod: isEmpty(data.bedCleaningMethod)
        ? null
        : data.bedCleaningMethod!.value,
    },
    wardPatient: {
      ...currentData.wardPatient,
      careSettingPatient: data.patient
        ? data.patient
        : currentData.wardPatient?.careSettingPatient,
      attributes: {
        ...currentData.wardPatient?.attributes,
        arrivalExpectedDateTime: isEmpty(data.arrivalDate)
          ? currentData.wardPatient?.attributes.arrivalExpectedDateTime
          : getDateTime(data.arrivalDate as Date, data!.arrivalTime),
        client: {
          arrivalExpectedDate: isEmpty(data.arrivalDate)
            ? null
            : getDateAsString(data!.arrivalDate as Date),
          arrivalExpectedTime: isEmpty(data.arrivalTime)
            ? null
            : data!.arrivalTime,
        },
        outlierYN: isEmpty(data.outlierYN)
          ? currentData.wardPatient?.attributes.outlierYN ?? 'NONE'
          : data.outlierYN,
        porterStatus: isEmpty(data.porterStatus)
          ? currentData.wardPatient?.attributes.porterStatus ?? 'NOT_REQUIRED'
          : data.porterStatus,
      },
    },
  } as WardBed);

export const bedAllocateWardBedSubmissionMap = (
  mappedWardBedData: WardBed,
  wardId: string,
  patient: CareSettingPatient,
  careSettingId: string
) => {
  const wardBedId = mappedWardBedData.id;
  return {
    ...omitGraphQLFields(
      ['audit'],
      setFormDatesToUTC(mappedWardBedData.wardPatient!.attributes, [
        'admissionDateTime',
        'arrivalExpectedDateTime',
      ])
    ),
    wardId,
    wardBedId,
    comments: mappedWardBedData.attributes.bedStatusComments,
    careSettingPatientId: patient.id,
    specialty: patient.attributes.specialty,
    origin: patient.attributes.origin,
    requiresIsolationYN: patient.attributes.requiresIsolationYN ?? 'NONE',
    isolationType: patient.attributes.isolationType ?? 'NONE',
    isolationTypeOther: patient.attributes.isolationTypeOther,
    covid19Status: 'NONE',
    primaryDiagnosis: 'NONE',
    secondaryDiagnosis: 'NONE',
    careSettingId,
    predictedLengthOfStay: 'PT0S',
  } as WardPatientInput;
};

export const bedUpdateWardBedSubmissionMap = (
  mappedWardBedData: WardBed,
  wardId: string,
  careSettingId: string
) => {
  const wardBedId = mappedWardBedData.id;
  const version = mappedWardBedData.attributes.audit?.version;
  const result = {
    ...omitGraphQLFields(
      ['audit', 'wardId', 'res_bedCleaning', 'bedCleaningMethod'],
      setFormDatesToUTC(mappedWardBedData!.attributes, ['bedStatusDateTime'])
    ),
    wardId,
    wardBedId,
    careSettingId,
    version,
    bedCleaningMethod: mappedWardBedData.attributes.bedCleaningMethod,
  } as WardBedInput;

  if (mappedWardBedData.attributes.bedStatusDateTime == null)
    return { ...result, bedStatusDateTime: getCurrentUTCDateTime() };
  return result;
};
