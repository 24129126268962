import type { WardStatisticsView } from 'models';

export const wardStatisticsFilter = (
  data: WardStatisticsView[],
  value: string,
  selectedWardGroupId?: string
) => {
  const regex = new RegExp(value, 'i');

  if (selectedWardGroupId) {
    const selectedWardGroups = data.filter(
      ({ wardGroupId }) => wardGroupId === selectedWardGroupId
    );

    if (value === '') return selectedWardGroups;
    return selectedWardGroups.filter((item) => regex.test(item.name));
  }

  if (data.length === 0 || value === '') return data;
  return data.filter((item) => regex.test(item.name));
};
