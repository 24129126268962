import { Wrapper, MultipleChoiceButton } from '@bt-healthcare/ui-toolkit';

import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import {
  awaitingDischargeAssessOptions,
  waitingForDischargeOptions,
  yesNoOptions,
} from 'components/Form/formOptions';
import { RHFDatePicker } from 'components/Form/RHFDatePicker';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import { getLocalDate, getLocalTime } from 'mappings/date.utils';
import { getPreviousDateTime } from 'pages/BedDischarge/form.utils';
import type { ChangeEvent, SyntheticEvent } from 'react';
import type { DischargeStatusType, YesNoType } from 'services';
import { isYes, matchesOption } from 'validation/rules';
import type { BedDischargeFormFieldsProps } from '../types';

export const DischargeClinicallyReady = ({
  control,
  register,
  previousD2ADateTime,
  dischargeToAssessDateTime,
  setDischargeToAssessDateTime,
  resetField,
  errors,
}: BedDischargeFormFieldsProps) => {
  const handleDischargeToAssess = (event: SyntheticEvent) => {
    const { value } = (event as ChangeEvent<HTMLInputElement>).target;
    const dateTime = getPreviousDateTime(
      value as YesNoType,
      previousD2ADateTime,
      true
    );
    setDischargeToAssessDateTime?.(dateTime);
    resetField!('dischargeAssessmentOutcome', { defaultValue: null });
  };

  return (
    <ConditionalFieldRenderer
      control={control}
      fieldName="dischargeStatus"
      condition={(arg) =>
        matchesOption<DischargeStatusType>(arg, 'CLINICALLY_READY')
      }
    >
      <RHFDatePicker
        control={control}
        fieldName="dischargeExpectedDate"
        timeFieldName="dischargeExpectedTime"
        label="Estimated date and time of discharge"
        minDate={new Date()}
        markAsRequired
        fieldErrorText={errors?.dischargeExpectedDate?.message}
        timeErrorText={errors?.dischargeExpectedTime?.message}
      />
      <RHFDropDown
        label="Waiting for"
        fieldName="waitingReasonsForClinicallyReadyForDischarge"
        control={control}
        options={waitingForDischargeOptions}
        isMulti
      />
      <Wrapper onChange={(event) => handleDischargeToAssess(event)}>
        <MultipleChoiceButton
          label="Is discharge to assess (D2A) required?"
          options={yesNoOptions}
          {...register('requireDischargeToAssess')}
          helperText={
            dischargeToAssessDateTime
              ? `Flagged for D2A: ${getLocalDate(
                  dischargeToAssessDateTime
                )} at ${getLocalTime(dischargeToAssessDateTime)}`
              : undefined
          }
        />
      </Wrapper>
      <ConditionalFieldRenderer
        control={control}
        fieldName="requireDischargeToAssess"
        condition={isYes}
      >
        <RHFDropDown
          label="Awaiting from D2A"
          fieldName="dischargeAssessmentOutcome"
          control={control}
          options={awaitingDischargeAssessOptions}
          markAsRequired
          errorText={
            errors?.dischargeAssessmentOutcome?.message
              ? String(errors.dischargeAssessmentOutcome.message)
              : undefined
          }
        />
      </ConditionalFieldRenderer>
      <RHFDatePicker
        control={control}
        fieldName="clinicallyReadyForDischargeDate"
        timeFieldName="clinicallyReadyForDischargeTime"
        label="Clinically ready for discharge since"
        minDate={new Date()}
        fieldErrorText={errors?.clinicallyReadyForDischargeDate?.message}
        timeErrorText={errors?.clinicallyReadyForDischargeTime?.message}
      />
    </ConditionalFieldRenderer>
  );
};
