import { EMPTY_VALUE } from 'App.constants';
import type {
  ClientWardDischargeAttributes,
  Maybe,
  WardDischargeAttributes,
} from 'services';

const isValid = (
  field: keyof ClientWardDischargeAttributes,
  client?: Maybe<ClientWardDischargeAttributes>
): boolean => client != null && client[field] !== EMPTY_VALUE;

export const getDischargeDateTimeType = (data: WardDischargeAttributes) => {
  if (!data) return null;

  const { dischargeStatus, readyForDischargeDateTime } = data;

  if (dischargeStatus === 'CONFIRMED') return 'readyForDischargeDateTime';
  if (dischargeStatus === 'DELAYED')
    return readyForDischargeDateTime
      ? 'readyForDischargeDateTime'
      : 'dischargeExpectedDateTime';

  return 'dischargeExpectedDateTime';
};

export const getDischargeDateTime = (
  dateTimeType: 'Date' | 'Time',
  data: WardDischargeAttributes
) => {
  if (!data) return EMPTY_VALUE;

  const { dischargeStatus, client } = data;

  if (dischargeStatus === 'CONFIRMED')
    return dateTimeType === 'Date'
      ? client?.readyForDischargeDate
      : client?.readyForDischargeTime;
  if (dischargeStatus === 'DELAYED')
    if (dateTimeType === 'Date') {
      return isValid('readyForDischargeDate', client)
        ? client!.readyForDischargeDate
        : client?.dischargeExpectedDate;
    } else {
      return isValid('readyForDischargeTime', client)
        ? client!.readyForDischargeTime
        : client?.dischargeExpectedTime;
    }
  return dateTimeType === 'Date'
    ? client?.dischargeExpectedDate
    : client?.dischargeExpectedTime;
};
