import type { CareSettingPatientQuery } from 'models';
import type { CareSettingPatient } from 'services';

//* Determines if a matching patient exists from first name and surname and sets a flag */
export const admissionMatchingPatientTransform = (
  patients: CareSettingPatientQuery
) => {
  if (patients == null) return [];

  return patients.map((item) => {
    const { person } = item;
    const { firstName, surname } = person.attributes.personalInformation;
    const { id } = person;

    const duplicatePerson = (patient: CareSettingPatient) =>
      patient.person.attributes.personalInformation.firstName.toLowerCase() ===
        firstName.toLowerCase() &&
      patient.person.attributes.personalInformation.surname.toLowerCase() ===
        surname.toLowerCase() &&
      patient.person.id !== id;

    const hasMatchingPatientName =
      patients!.find((patient) =>
        duplicatePerson(patient as CareSettingPatient)
      ) !== undefined;

    return {
      ...item,
      hasMatchingPatientName,
    };
  });
};
