import { getModulePath } from '@bt-healthcare/ui-toolkit';
import type { NavigateFunction } from 'react-router-dom';

import { ROUTES } from 'App.constants';

export const unauthorisedRedirect = (isAppAuthorised = true) => {
  const rootPath = `${getModulePath('aim', window.location.hostname)}`;
  const redirectPath = !isAppAuthorised
    ? rootPath
    : `${rootPath}/#/unauthorised`;
  window.location.replace(redirectPath);
};

export const mapBackRoute = (
  pathname: string,
  navigate: NavigateFunction,
  wardId?: string
) => {
  switch (pathname) {
    case ROUTES.WARDS:
    case ROUTES.ADMISSIONS_HOME:
    case ROUTES.BED_TRANSFER_HOME:
      return () => navigate(ROUTES.HOME);
    case ROUTES.WARDS_BED_ALLOCATION_MANAGE:
      return () =>
        navigate(ROUTES.WARDS_BED_ALLOCATION_HOME, { state: { wardId } });
    case ROUTES.WARDS_BED_DISCHARGE_MANAGE:
      return () =>
        navigate(ROUTES.WARDS_BED_DISCHARGE_HOME, { state: { wardId } });
    case ROUTES.WARDS_BED_ALLOCATION_HOME:
    case ROUTES.WARDS_BED_DISCHARGE_HOME:
      return () => navigate(ROUTES.WARDS, { state: { wardId } });
    case ROUTES.WARDS_MANAGE:
      return () => navigate(ROUTES.WARDS, { state: { wardId } });
    case ROUTES.WARDS_BED_ALLOCATION_PATIENTS:
      return () => navigate(-1);

    default:
      return undefined;
  }
};
