import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { colors, spacing, Text, Wrapper } from '@bt-healthcare/ui-toolkit';

export const BedTransferFormatOptionLabel = ({
  label,
  patientName,
  gender,
  bedStatus,
}: TypedOptionType<string>) => (
  <Wrapper style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Wrapper>
      <Text
        style={{ paddingRight: spacing.s3 }}
        color={
          bedStatus === 'ALLOCATED'
            ? colors.primaryIndigo.indigo08
            : colors.secondaryBlue.blue06
        }
        fontWeight={500}
      >
        {label}
      </Text>
      {patientName}
    </Wrapper>
    <Wrapper>
      <Text color={colors.grey.grey08}>{gender}</Text>
    </Wrapper>
  </Wrapper>
);
