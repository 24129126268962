import { BedCleaningFormFields } from 'components/BedCleaning/BedCleaningFormFields';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { dischargePathwayReasonOptions } from 'components/Form/formOptions';
import { RHFDatePicker } from 'components/Form/RHFDatePicker';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import type { DischargeStatusType } from 'services';
import { matchesOption } from 'validation/rules';
import type { BedDischargeFormFieldsProps } from '../types';

export const DischargeCompleted = ({
  control,
  register,
  resetField,
  errors,
}: BedDischargeFormFieldsProps) => (
  <ConditionalFieldRenderer
    control={control}
    fieldName="dischargeStatus"
    condition={(arg) => matchesOption<DischargeStatusType>(arg, 'COMPLETED')}
  >
    <RHFDropDown
      label="Discharge pathway or reason"
      fieldName="dischargeAssessmentOutcome"
      control={control}
      options={dischargePathwayReasonOptions}
    />
    <RHFDatePicker
      control={control}
      fieldName="dischargeActualDate"
      timeFieldName="dischargeActualTime"
      label="Date and time of discharge"
      minDate={new Date()}
      fieldErrorText={errors?.dischargeActualDate?.message}
      timeErrorText={errors?.dischargeActualTime?.message}
    />
    <BedCleaningFormFields
      control={control}
      register={register}
      resetField={resetField!}
      errors={errors}
    />
  </ConditionalFieldRenderer>
);
