import { spacing, device, fontSizes, colors } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: ${spacing.s3};

  @media ${device.desktop} {
    h3 {
      font-size: ${fontSizes.h4};
    }
  }
`;

export const StatsContent = styled.div`
  @media ${device.desktop} {
    display: flex;
  }
`;

export const Stat = styled.p`
  flex: 0 0 38%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  line-height: 1.8;
  font-size: ${fontSizes.sm};

  span {
    display: block;
    color: ${colors.grey.grey10};
  }

  @media ${device.desktop} {
    flex-direction: column;

    span {
      font-size: ${fontSizes.h3};
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.appCard.nameColor};
  margin: ${spacing.s4} ${spacing.none};

  @media ${device.desktop} {
    margin: ${spacing.s6} ${spacing.none};
  }
`;

export const DischargeHeadline = styled.p`
  color: ${({ theme }) => theme.headlineColor};
  line-height: 1.8;
  font-size: ${fontSizes.sm};

  @media ${device.desktop} {
    font-size: ${fontSizes.base};
    margin-bottom: ${spacing.s4};
  }
`;
