import {
  Modal,
  ModalFooter,
  Stack,
  SubHeader,
  Button,
} from '@bt-healthcare/ui-toolkit';
import { useNavigate } from 'react-router-dom';
import { Strapline } from '../styles';

import type { MatchingPatientModalProps } from '../types';

export const MatchingPatientModal = ({
  modalOpen,
  setModalOpen,
  redirectPath,
  onCancel,
  wardId,
}: MatchingPatientModalProps) => {
  const navigate = useNavigate();
  const handleClose = () => setModalOpen(false);
  const handleCancel = () => {
    if (redirectPath) navigate(redirectPath, { state: { wardId } });
    if (onCancel) onCancel();
    handleClose();
  };

  return (
    <Modal
      onClose={handleClose}
      isModalOpen={modalOpen}
      id="matching-patient-alert"
      inPortal
    >
      <Stack space="s4" id="confirmation" alignItems="center">
        <SubHeader>Same name patients</SubHeader>
        <Strapline>
          There is another patient in this ward with the same name.
          <br />
          Do you wish to continue?
        </Strapline>
      </Stack>
      <ModalFooter flex>
        <Button aria-label="Cancel modal" id="cancel" onClick={handleCancel}>
          No, cancel
        </Button>
        <Button id="confirm" onClick={handleClose} variant="primaryRed">
          Yes, continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};
