import {
  DateInput,
  Input,
  MultipleChoiceButton,
} from '@bt-healthcare/ui-toolkit';
import { useRef } from 'react';
import { useStore } from 'store';

import { RHFDropDown } from 'components/Form/RHFDropDown';
import {
  originOptions,
  specialtyOptions,
  yesNoOptions,
} from '../Form/formOptions';
import { RHFDatePicker } from '../Form/RHFDatePicker';
import { Isolation } from './FormSections/Isolation';
import { WardPreferences } from './FormSections/WardPreferences';

import type { PatientAdmissionFormFieldsProps } from './types';

export const PatientAdmissionFormFields = ({
  register,
  control,
  resetField,
}: PatientAdmissionFormFieldsProps) => {
  const {
    dayOfBirth = '',
    decisionToAdmitTime = '',
    monthOfBirth = '',
    yearOfBirth = '',
  } = useStore((state) => state.patientAdmissionFormData);
  const dateInputRef = useRef<any>();
  const { ref: dayRef, ...dayRest } = register('dayOfBirth');
  const { ref: monthRef, ...monthRest } = register('monthOfBirth');
  const { ref: yearRef, ...yearRest } = register('yearOfBirth');

  dateInputRef.current = { dayRef, monthRef, yearRef };
  const inputParams = {
    dayInput: {
      id: 'dayOfBirth',
      'data-testid': 'day-of-birth',
      defaultValue: dayOfBirth,
      ...dayRest,
    },
    monthInput: {
      id: 'monthOfBirth',
      'data-testid': 'month-of-birth',
      defaultValue: monthOfBirth,
      ...monthRest,
    },
    yearInput: {
      id: 'yearOfBirth',
      'data-testid': 'year-of-birth',
      defaultValue: yearOfBirth,
      ...yearRest,
    },
  };
  return (
    <>
      <Input
        id="firstName"
        label="First name"
        {...register('firstName')}
        markAsRequired
      />
      <Input
        id="surname"
        label="Surname"
        {...register('surname')}
        markAsRequired
      />
      <WardPreferences control={control} register={register} markAsRequired />
      <DateInput
        inputParams={inputParams}
        ref={dateInputRef}
        id="dateOfBirth"
        label="Date of birth"
        markAsRequired
      />
      <RHFDropDown
        label="Origin"
        fieldName="origin"
        control={control}
        options={originOptions}
        markAsRequired
      />
      <RHFDatePicker
        control={control}
        fieldName="decisionToAdmitDate"
        timeFieldName="decisionToAdmitTime"
        label="Date &amp; time of DTA"
        minDate={new Date()}
        timeFieldValue={decisionToAdmitTime}
        markAsRequired
      />
      <RHFDropDown
        label="Speciality"
        fieldName="specialty"
        control={control}
        options={specialtyOptions}
        markAsRequired
      />
      <Isolation
        control={control}
        register={register}
        resetField={resetField}
        markAsRequired
      />
      <MultipleChoiceButton
        label="Could the patient require a social care package?"
        options={yesNoOptions}
        {...register('socialCareYN')}
      />
    </>
  );
};
