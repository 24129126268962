export const DischargeStatusIcon = () => (
  <svg
    className="icon"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6667 6.77325L17.8934 10.9999L13.6667 15.2266V13.6666H3.00004V8.33325H13.6667V6.77325ZM11 0.333252V5.66658H0.333374V16.3333H11V21.6666L21.6667 10.9999L11 0.333252Z"
      fill="white"
    />
  </svg>
);
