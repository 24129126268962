import { MultipleChoiceButton } from '@bt-healthcare/ui-toolkit';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import {
  ttoOptions,
  pharmacyOptions,
  carePackageOptions,
  porterOptions,
  dischargePathwayOptions,
} from 'components/Form/formOptions';
import { RHFDatePicker } from 'components/Form/RHFDatePicker';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import type { DischargeStatusType } from 'services';
import { matchesEnumValue, matchesOption } from 'validation/rules';
import type { BedDischargeFormFieldsProps } from '../types';

export const DischargeConfirmed = ({
  control,
  register,
  resetField,
  errors,
}: BedDischargeFormFieldsProps) => (
  <ConditionalFieldRenderer
    control={control}
    fieldName="dischargeStatus"
    condition={(arg) => matchesOption<DischargeStatusType>(arg, 'CONFIRMED')}
  >
    <RHFDatePicker
      control={control}
      fieldName="readyForDischargeDate"
      timeFieldName="readyForDischargeTime"
      label="Ready for discharge date and time"
      minDate={new Date()}
      markAsRequired
      fieldErrorText={errors?.readyForDischargeDate?.message}
      timeErrorText={errors?.readyForDischargeTime?.message}
    />
    <RHFDropDown
      label="Discharge pathway"
      fieldName="dischargeAssessmentOutcome"
      control={control}
      options={dischargePathwayOptions}
    />
    <MultipleChoiceButton
      size="lg"
      label="TTO status"
      options={ttoOptions}
      {...register('toTakeOutStatus', {
        onChange: () => resetField!('pharmacy', { defaultValue: '' }),
      })}
    />
    <ConditionalFieldRenderer
      control={control}
      fieldName="toTakeOutStatus"
      condition={(arg) => matchesEnumValue(arg, 'COMPLETE')}
    >
      <MultipleChoiceButton
        label="Pharmacy"
        size="lg"
        options={pharmacyOptions}
        {...register('pharmacy')}
      />
    </ConditionalFieldRenderer>
    <MultipleChoiceButton
      label="Care package"
      size="lg"
      options={carePackageOptions}
      {...register('carePackageStatus')}
    />
    <MultipleChoiceButton
      label="Porter"
      size="lg"
      options={porterOptions}
      {...register('porterStatus')}
    />
  </ConditionalFieldRenderer>
);
