import { MultipleChoiceButton } from '@bt-healthcare/ui-toolkit';
import type { FieldValues, Path } from 'react-hook-form';

import { BedCleaningAlert } from 'components/BedCleaning/BedCleaningAlert';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import {
  bedCleaningStatusOptions,
  bedCleaningMethodOptions,
} from 'components/Form/formOptions';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import { matchesEnumValue } from 'validation/rules';
import type { BedCleaningFormFieldsProps } from './types';

export const BedCleaningFormFields = <T extends FieldValues>({
  control,
  register,
  resetField,
  errors,
  journey = 'discharge',
}: BedCleaningFormFieldsProps<T>) => (
  <>
    <MultipleChoiceButton
      size="lg"
      label="Bed Cleaning"
      markAsRequired={journey === 'discharge'}
      options={bedCleaningStatusOptions}
      {...register('bedCleaningStatus' as Path<T>, {
        onChange: () =>
          resetField('bedCleaningMethod' as Path<T>, { defaultValue: null }),
      })}
    />
    <ConditionalFieldRenderer
      control={control}
      fieldName={'bedCleaningStatus' as Path<T>}
      condition={(arg) => matchesEnumValue(arg, 'REQUIRED')}
    >
      <RHFDropDown
        label="Type of clean"
        fieldName="bedCleaningMethod"
        control={control}
        options={bedCleaningMethodOptions}
        markAsRequired={journey === 'discharge'}
        errorText={
          errors?.bedCleaningMethod?.message
            ? String(errors.bedCleaningMethod.message)
            : undefined
        }
      />
      <BedCleaningAlert journey={journey} />
    </ConditionalFieldRenderer>
  </>
);
