import type { BedDischargeFormData } from 'components/BedDischarge/types';
import type { WardBed } from 'services';
import { convertDateStringForFormInput } from 'mappings/date.utils';
import { getDischargeAssessmentOutcome } from '../form.utils';

export const getConfirmedDefaultValues = (
  defaultFields: Partial<BedDischargeFormData>,
  wardBed: WardBed
): Partial<BedDischargeFormData> => ({
  ...defaultFields,
  readyForDischargeDate: convertDateStringForFormInput(
    wardBed.wardPatient?.wardDischarge?.attributes.client?.readyForDischargeDate
  ),
  readyForDischargeTime:
    wardBed.wardPatient?.wardDischarge?.attributes.client
      ?.readyForDischargeTime ?? '',
  dischargeAssessmentOutcome: getDischargeAssessmentOutcome(
    wardBed,
    'CONFIRMED'
  ),
  toTakeOutStatus:
    wardBed.wardPatient?.wardDischarge?.attributes.toTakeOutStatus ?? '',
  carePackageStatus:
    wardBed.wardPatient?.wardDischarge?.attributes.carePackageStatus ?? '',
  porterStatus:
    wardBed.wardPatient?.wardDischarge?.attributes.porterStatus ?? '',
  pharmacy: wardBed.wardPatient?.wardDischarge?.attributes.pharmacy ?? '',
});
