import type { Maybe, WardBed } from 'services';
import type { ExtendedWardBed } from 'types';

//* Determines if a matching patient exists from first name and surname and sets a flag */
export const wardBedMatchingPatientTransform = (
  wardBeds: Maybe<WardBed[]>
): ExtendedWardBed[] => {
  if (wardBeds == null) return [];

  return wardBeds.map((item) => {
    const person = item.wardPatient?.careSettingPatient?.person;
    let hasMatchingPatientName = false;

    if (person) {
      const { firstName, surname } = person.attributes.personalInformation;
      const { id } = person;

      const duplicatePerson = (wardBed: WardBed) =>
        wardBed.wardPatient?.careSettingPatient?.person.attributes.personalInformation.firstName
          .toLowerCase()
          .trim() === firstName.toLowerCase().trim() &&
        wardBed.wardPatient?.careSettingPatient.person.attributes.personalInformation.surname
          .toLowerCase()
          .trim() === surname.toLowerCase().trim() &&
        wardBed.wardPatient?.careSettingPatient.person.id !== id;

      hasMatchingPatientName =
        wardBeds!.find((wardBed) => duplicatePerson(wardBed)) !== undefined;
    }
    return {
      ...item,
      hasMatchingPatientName,
    };
  });
};
