import { Button, colors, Text } from '@bt-healthcare/ui-toolkit';

import { DataListCard } from 'components/Card/DataListCard/DataListCard';

import {
  FlexContainer,
  IconWrapper,
  ContentWrapper,
  HeadlineWrapper,
  TextWrapper,
  ViewWardContainer,
  CardWrapper,
} from './styles';
import type { StatusFormConfirmationProps } from './types';

export const StatusFormConfirmation = ({
  wardName,
  data,
  handleClick,
  headline = 'Update confirmed',
  strapLine,
  buttonText,
  icon,
}: StatusFormConfirmationProps) => (
  <FlexContainer>
    {icon && <IconWrapper>{icon}</IconWrapper>}

    <ContentWrapper>
      <HeadlineWrapper>
        <Text color={colors.primaryIndigo.indigo08} fontWeight={500} as="h3">
          {headline}
        </Text>
      </HeadlineWrapper>

      <TextWrapper>
        <Text color={colors.grey.grey08} data-testid="confirmation-text">
          {strapLine}
        </Text>
      </TextWrapper>

      <ViewWardContainer>
        <Button id="update-action" onClick={handleClick}>
          {buttonText}
        </Button>
        <CardWrapper>
          <DataListCard wardName={wardName} data={data} />
        </CardWrapper>
      </ViewWardContainer>
    </ContentWrapper>
  </FlexContainer>
);
