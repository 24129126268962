import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { Loader, RadioButtonToggle } from '@bt-healthcare/ui-toolkit';
import type { ChangeEvent, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { useNavigate } from 'react-router-dom';

import { BedIntraTransferFormFields } from 'components/BedTransfer/BedIntraTransferFormFields';
import { useStore } from 'store';
import { PageNotification } from 'components/PageNotification';
import {
  bedTransferOptions,
  EMPTY_VALUE,
  FETCH_POLICY,
  ROUTES,
} from 'App.constants';
import { useGetWardOptionsQuery } from 'services';
import {
  wardFromOptionsTransform,
  wardToOptionsTransform,
} from 'transforms/wardOptionTransform';
import { BedInterTransferFormFields } from 'components/BedTransfer/BedInterTransferFormFields';
import { useWardBedTransferSort } from 'hooks/useWardBedTransferSort';
import { FormWrapper, Subheading, HeadingWrapper } from '../styles';
import { getWardBedFromTransfers } from './form.utils';

export const BedTransferForm = () => {
  const [wardOptions, setWardOptions] = useState({
    fromWardOptions: [] as TypedOptionType<string>[],
    toWardOptions: [] as TypedOptionType<string>[],
  });

  const [bedTransferType, setBedTransferType] = useState(bedTransferOptions);

  const navigate = useNavigate();

  const isInterWardTransfer =
    bedTransferType.find((item) => item.defaultChecked)?.value === '1';

  const [
    errorMessage,
    setMenuHeader,
    resetBedTransfer,
    resetFormData,
    bedFromTransfer,
    bedToTransfer,
    setBedFromTransfer,
    setBedToTransfer,
    { careSettingId },
  ] = useStore(
    (state) => [
      state.errorMessage,
      state.setMenuHeader,
      state.resetBedTransfer,
      state.resetBedTransferFormData,
      state.bedFromTransfer,
      state.bedToTransfer,
      state.setBedFromTransfer,
      state.setBedToTransfer,
      state.appConfig,
    ],
    shallow
  );

  useEffect(() => setMenuHeader('Bed transfer'), []);

  const { sortedWardBedTransfers } = useWardBedTransferSort(careSettingId);

  const { loading, error } = useGetWardOptionsQuery({
    variables: { careSettingId },
    fetchPolicy: FETCH_POLICY,
    onCompleted: (response) => {
      const fromWardOptions = wardFromOptionsTransform(response);
      const toWardOptions = wardToOptionsTransform(response);
      setWardOptions({ fromWardOptions, toWardOptions });
    },
  });

  const onSubmit = () => {
    navigate(ROUTES.BED_TRANSFER_CONFIRM);
  };

  const handleWardBedFromChange = (options: TypedOptionType<string>) => {
    const wardName = bedFromTransfer.find((item) => item.key === 'Ward');
    const result = getWardBedFromTransfers(options, wardName?.value ?? '');
    setBedFromTransfer(result);
  };

  const handleWardBedToChange = ({ label }: TypedOptionType<string>) => {
    /** For unallocated beds set the gender to match the from bed gender as this is what will be set
     * when the patient is transferred
     */
    const gender = bedFromTransfer.find((item) => item.key === 'Gender');
    const result = [
      {
        key: 'Bed',
        value: label,
      },
      { key: 'Gender', value: gender ? gender.value : EMPTY_VALUE },
    ];

    if (isInterWardTransfer) {
      const wardKey = [{ key: 'Ward', value: bedToTransfer[0].value }];
      setBedToTransfer(wardKey.concat(result));
    } else {
      setBedToTransfer(result);
    }
  };

  const handleToggleChange = (event: SyntheticEvent) => {
    const { value } = (event as ChangeEvent<HTMLInputElement>).target;
    resetBedTransfer();
    resetFormData();
    const bedTransferOption = bedTransferType.map((item) => ({
      ...item,
      defaultChecked: item.value === value,
    }));
    setBedTransferType(bedTransferOption);
  };

  if (loading) return <Loader />;

  // TODO: Review here to use FormLayout component
  return (
    <FormWrapper>
      <HeadingWrapper>
        <Subheading>Bed transfer</Subheading>
        <RadioButtonToggle
          optionPrimary={bedTransferType[0]}
          optionSecondary={bedTransferType[1]}
          onChange={handleToggleChange}
          name="bedTransferOptions"
        />
      </HeadingWrapper>
      {error && (
        <PageNotification message={errorMessage('trying to load Ward data')} />
      )}
      {isInterWardTransfer ? (
        <BedInterTransferFormFields
          wardOptions={wardOptions}
          onSubmit={onSubmit}
          onWardBedFromChange={handleWardBedFromChange}
          onWardBedToChange={handleWardBedToChange}
          activeBedTransfers={sortedWardBedTransfers}
        />
      ) : (
        <BedIntraTransferFormFields
          wardOptions={wardOptions}
          onSubmit={onSubmit}
          onWardBedFromChange={handleWardBedFromChange}
          onWardBedToChange={handleWardBedToChange}
          activeBedTransfers={sortedWardBedTransfers}
        />
      )}
    </FormWrapper>
  );
};
