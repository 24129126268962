import type { PatientAdmissionFormData } from 'components/PatientAdmission/types';
import type { CareSettingPatient } from 'services';
import { isEmpty } from 'validation/rules';
import { getDateAsString, getDateTime } from '../date.utils';

export const patientAdmissionFormMapping = (data: PatientAdmissionFormData) =>
  ({
    attributes: {
      origin: data.origin?.value ?? 'NONE',
      specialty: data.specialty?.value ?? 'NONE',
      socialCareYN: data.socialCareYN ?? 'NONE',
      requiresIsolationYN: data.requiresIsolationYN ?? 'NONE',
      isolationType: data.isolationType?.value ?? 'NONE',
      isolationTypeOther: isEmpty(data.isolationTypeOther)
        ? null
        : data!.isolationTypeOther,
      admissionComments: isEmpty(data?.admissionComments)
        ? null
        : data.admissionComments,
      client: {
        decisionToAdmitDate: isEmpty(data.decisionToAdmitDate)
          ? null
          : getDateAsString(data.decisionToAdmitDate! as Date),
        decisionToAdmitTime: isEmpty(data.decisionToAdmitTime)
          ? null
          : data.decisionToAdmitTime,
      },
      decisionToAdmitDateTime: getDateTime(
        data.decisionToAdmitDate as Date,
        data.decisionToAdmitTime
      ),
      patientWardPreference: isEmpty(data.patientWardPreference)
        ? null
        : data.patientWardPreference,
      furtherWardPreferences: isEmpty(data.furtherWardPreference)
        ? null
        : data.furtherWardPreference,
    },
    person: {
      attributes: {
        personalInformation: {
          firstName: data.firstName.trim(),
          title: 'NONE',
          surname: data.surname.trim(),
          genderIdentifiedAs: data.genderIdentifiedAs,
          dateOfBirth: `${data.yearOfBirth}-${data.monthOfBirth}-${data.dayOfBirth}`,
        },
        contactInformation: null,
      },
    },
  } as CareSettingPatient);
