import {
  Button,
  Col,
  Grid,
  Loader,
  Row,
  SubHeader,
} from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { useStore } from 'store';
import { PageNotification } from 'components/PageNotification';
import { PatientListView } from 'components/PatientListView/PatientListView';
import { PatientSearch } from 'components/PatientSearch';
import { useClearErrors } from 'hooks/useClearErrors';
import { usePatientSort } from 'hooks/usePatientSort';
import { useTracking } from 'hooks/useTracking';
import type { CareSettingPatientQuery } from 'models';
import { ROUTES } from 'App.constants';

export const PatientAdmission = () => {
  const { trackPage } = useTracking();
  const [filter, setFilter] = useState<CareSettingPatientQuery>();

  useClearErrors();
  const [
    setMenuHeader,
    setMenuItem,
    resetForm,
    errorMessage,
    { careSettingId },
  ] = useStore(
    (state) => [
      state.setMenuHeader,
      state.setMenuItem,
      state.resetPatientAdmissionFormData,
      state.errorMessage,
      state.appConfig,
    ],
    shallow
  );

  const { loading, error, sortedPatients } = usePatientSort(careSettingId);
  const navigate = useNavigate();

  useEffect(() => {
    setMenuHeader('Decision to Admit');
    setMenuItem('Admissions');
    resetForm();
  }, []);

  useEffect(() => {
    trackPage('admissions');
  }, []);

  const handleClick = () => navigate(ROUTES.ADMISSIONS_MANAGE);

  if (loading) return <Loader />;
  return (
    <Grid>
      <Row>
        <Col xs={6} alignSelf="center" display={{ xs: 'none', xl: 'block' }}>
          <SubHeader color="indigo08">Decision to admit</SubHeader>
        </Col>
      </Row>
      {error ? (
        <Row>
          <Col from={1}>
            <PageNotification message={errorMessage('displaying patients')} />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col lg={4} xs={12} alignSelf="center">
              <PatientSearch data={sortedPatients!} setFilter={setFilter} />
            </Col>
            <Col
              lg={8}
              xs={12}
              alignSelf="center"
              justifyContent={{ lg: 'right' }}
              order={{ xs: '-1', lg: '1' }}
            >
              <Button id="add-new-patient" onClick={handleClick}>
                Add new patient
              </Button>
            </Col>
          </Row>
          <Row>
            <Col from={1}>
              {sortedPatients?.length === 0 ? (
                <PageNotification
                  type="informative"
                  message="No patients are awaiting a decision to admit. Get started by Adding a new patient above."
                />
              ) : (
                <PatientListView data={filter ?? sortedPatients} />
              )}
            </Col>
          </Row>
        </>
      )}
    </Grid>
  );
};
