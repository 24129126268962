/* eslint-disable no-console */
import Config from './config.json';

type EnvKeys = typeof process.env;
type EnvVars = keyof Pick<
  EnvKeys,
  | 'REACT_APP_ENV'
  | 'REACT_APP_AUTH_CLIENT_ID'
  | 'REACT_APP_AUTH_AUTHORITY'
  | 'REACT_APP_SERVICE_GRAPHQL'
  | 'REACT_APP_ADOBE_ANALYTICS_ID1'
  | 'REACT_APP_ADOBE_ANALYTICS_ID2'
  | 'REACT_APP_ADOBE_ANALYTICS_ID3'
  | 'REACT_APP_MIXPANEL_ANALYTICS_TOKEN'
  | 'REACT_APP_SENTRY_DSN'
>;

export const getEnvVariable = (variable: EnvVars) =>
  Config[variable] && !Config[variable].includes('$REACT_APP')
    ? Config[variable]
    : process.env[variable]!;

export const getVersion = () => {
  // eslint-disable-next-line no-underscore-dangle
  const sentryRelease = (window.__SENTRY__ as any)?.hub?.getClient()._options
    ?.release;
  const defaultVersion = 'Version not found';
  if (sentryRelease) {
    const version = sentryRelease.match(/\d+\.\d+\.\d+/);
    return version ? version[0] : defaultVersion;
  }
  return defaultVersion;
};
