import { device, spacing } from '@bt-healthcare/ui-toolkit';
import type { CSSProperties } from 'react';
import styled from 'styled-components';

export const WardOverviewGrid = styled.div<
  Pick<CSSProperties, 'gridTemplateColumns'>
>`
  display: grid;
  grid-gap: ${spacing.s4};

  @media ${device.mobile} {
    grid-template-columns: ${({ gridTemplateColumns }) =>
      gridTemplateColumns ??
      `repeat(auto-fit, minmax(128px, calc(50% - ${spacing.s2})))`};
  }

  @media ${device.tablet} {
    grid-template-columns: ${({ gridTemplateColumns }) =>
      gridTemplateColumns ??
      `repeat(auto-fit, minmax(160px, calc(25% - ${spacing.s3})))`};
  }

  @media ${device.desktop} {
    grid-template-columns: ${({ gridTemplateColumns }) =>
      gridTemplateColumns ??
      'repeat(auto-fit, minmax(280px, calc(25% - 18px)))'};
    grid-gap: ${spacing.s6};
  }
`;
