import { sortWith, descend, prop, compose, map, omit } from 'ramda';

import { wardBedTransferStatusSortMap } from 'mappings/ragStatus/bedTransfer';
import type { WardBedTransferQuery } from 'models';

export const sortByBedTransferStatus = (data: WardBedTransferQuery) => {
  if (data) {
    const result = data.map((item) => ({
      ...item,
      sortKey: wardBedTransferStatusSortMap[item.attributes?.bedTransferStatus],
    }));
    const sortedResult = sortWith([descend(prop('sortKey'))], result);
    const omitKeys = compose(map, omit);
    return omitKeys(['sortKey'])(sortedResult) as WardBedTransferQuery;
  }
  return data;
};
