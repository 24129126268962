import { hasGenderPref } from 'mappings/mapping.utils';
import type { WardBed } from 'services';
import type { DictionaryList } from 'types';
import { mapBedObjectKeys } from './bedMapping';

// TODO: Review these mappings to simplify
export const bedDischargeSelectionMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'bedStatus',
    'gender',
    'wardPref',
    'furtherPref',
    'dischargeIsolationReason',
    'outlierYN',
    'speciality',
    'expectedArrivalDate',
    'expectedArrivalTime',
    'bedDischargePortering',
  ]);

export const bedDischargeConfirmedSelectionMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'bedStatus',
    'gender',
    'wardPref',
    'furtherPref',
    'speciality',
    'dischargeIsolationReason',
    'outlierYN',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'readyForDischargeDate',
    'readyForDischargeTime',
    'pathway',
    'bedDischargeTTO',
    'pharmacy',
    'bedDischargeCarePackage',
    'bedDischargePortering',
  ]);

export const bedDischargePotentialWithoutPharmacySelectionMapping = (
  data: WardBed
) =>
  mapBedObjectKeys(data, [
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'bedStatus',
    'gender',
    'wardPref',
    'furtherPref',
    'speciality',
    'dischargeIsolationReason',
    'outlierYN',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'bedDischargeEstimatedDate',
    'bedDischargeEstimatedTime',
    'bedDischargeTTO',
    'bedDischargeCarePackage',
    'waitingReasonsForPotentialDischarge',
  ]);

export const bedDischargePotentialSelectionMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'bedStatus',
    'gender',
    'wardPref',
    'furtherPref',
    'speciality',
    'dischargeIsolationReason',
    'outlierYN',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'bedDischargeEstimatedDate',
    'bedDischargeEstimatedTime',
    'bedDischargeTTO',
    'pharmacy',
    'bedDischargeCarePackage',
    'waitingReasonsForPotentialDischarge',
  ]);

export const bedDischargeDelayedSelectionMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'bedStatus',
    'gender',
    'wardPref',
    'furtherPref',
    'dischargeIsolationReason',
    'outlierYN',
    'speciality',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'bedDischargeFailedReason',
  ]);

export const bedDischargeClinicallyReadySelectionMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'bedStatus',
    'gender',
    'wardPref',
    'furtherPref',
    'speciality',
    'dischargeIsolationReason',
    'outlierYN',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'bedDischargeEstimatedDate',
    'bedDischargeEstimatedTime',
    'waitingReasonsForClinicallyReadyForDischarge',
    'requireDischargeToAssess',
    'dischargeAssessmentOutcome',
    'clinicallyReadyForDischargeDate',
    'clinicallyReadyForDischargeTime',
  ]);

export const bedDischargeNotFitSelectionMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'bedStatus',
    'gender',
    'wardPref',
    'furtherPref',
    'speciality',
    'dischargeIsolationReason',
    'outlierYN',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'bedDischargeEstimatedDate',
    'bedDischargeEstimatedTime',
    'socialCareYN',
  ]);

export const bedDischargeAllocatedSelectionMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'bedStatus',
    'gender',
    'wardPref',
    'furtherPref',
    'outlierYN',
    'patientIsolationReason',
    'expectedArrivalDate',
    'expectedArrivalTime',
    'bedAllocationPortering',
  ]);

export const bedDischargeConfirmationMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'bed',
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'gender',
    'wardPref',
    'furtherPref',
    'dischargeIsolationReason',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'readyForDischargeDate',
    'readyForDischargeTime',
    'pathway',
    'bedDischargeTTO',
    'pharmacy',
    'bedDischargeCarePackage',
    'bedDischargePortering',
  ]);

export const bedDischargeFailedConfirmationMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'bed',
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'gender',
    'wardPref',
    'furtherPref',
    'dischargeIsolationReason',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'bedDischargeFailedReason',
  ]);

export const bedDischargeNotFitConfirmationMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'bed',
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'gender',
    'wardPref',
    'furtherPref',
    'dischargeIsolationReason',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'bedDischargeEstimatedDate',
    'bedDischargeEstimatedTime',
    'socialCareYN',
  ]);

export const bedDischargeClinicallyReadyConfirmationMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'bed',
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'gender',
    'wardPref',
    'furtherPref',
    'dischargeIsolationReason',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'bedDischargeEstimatedDate',
    'bedDischargeEstimatedTime',
    'waitingReasonsForClinicallyReadyForDischarge',
    'requireDischargeToAssess',
    'dischargeAssessmentOutcome',
    'clinicallyReadyForDischargeDate',
    'clinicallyReadyForDischargeTime',
  ]);

export const bedDischargeClinicallyReadyWithoutD2AConfirmationMapping = (
  data: WardBed
) =>
  mapBedObjectKeys(data, [
    'bed',
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'gender',
    'wardPref',
    'furtherPref',
    'dischargeIsolationReason',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'bedDischargeEstimatedDate',
    'bedDischargeEstimatedTime',
    'waitingReasonsForClinicallyReadyForDischarge',
    'requireDischargeToAssess',
    'clinicallyReadyForDischargeDate',
    'clinicallyReadyForDischargeTime',
  ]);

export const bedDischargePotentialWithoutPharmacyConfirmationMapping = (
  data: WardBed
) =>
  mapBedObjectKeys(data, [
    'bed',
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'gender',
    'wardPref',
    'furtherPref',
    'dischargeIsolationReason',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'bedDischargeEstimatedDate',
    'bedDischargeEstimatedTime',
    'bedDischargeTTO',
    'bedDischargeCarePackage',
    'waitingReasonsForPotentialDischarge',
  ]);

export const bedDischargePotentialConfirmationMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'bed',
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'gender',
    'wardPref',
    'furtherPref',
    'dischargeIsolationReason',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'bedDischargeEstimatedDate',
    'bedDischargeEstimatedTime',
    'bedDischargeTTO',
    'pharmacy',
    'bedDischargeCarePackage',
    'waitingReasonsForPotentialDischarge',
  ]);

export const bedTransferSelectionMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'patientName',
    'bedStatus',
    'dateOfBirth',
    'patientIdentifier',
    'gender',
    'wardPref',
    'furtherPref',
    'outlierYN',
    'patientIsolationReason',
    'transferFrom',
    'transferTo',
    data.wardBedTransfers?.[0]?.attributes?.intraWardTransferReason === 'NONE'
      ? 'interWardTransferReason'
      : 'intraWardTransferReason',
    'expectedArrivalDate',
    'expectedArrivalTime',
    'bedAllocationPortering',
    'bedCleaning',
    'bedCleaningType',
  ]);

export const bedDischargeConfirmWardTransferSpecialitySelectionMapping = (
  data: WardBed
) =>
  mapBedObjectKeys(data, [
    'patientName',
    'bedStatus',
    'dateOfBirth',
    'patientIdentifier',
    'gender',
    'wardPref',
    'furtherPref',
    'outlierYN',
    'patientIsolationReason',
    'transferFrom',
    'transferTo',
    data.wardBedTransfers?.[0]?.attributes?.intraWardTransferReason === 'NONE'
      ? 'interWardTransferReason'
      : 'intraWardTransferReason',
    'speciality',
    'expectedArrivalDate',
    'expectedArrivalTime',
    'bedAllocationPortering',
    'bedCleaning',
    'bedCleaningType',
  ]);

export const bedDischargeCompletedConfirmationMapping = (data: WardBed) => {
  let mapping: DictionaryList<string | JSX.Element>;

  const defaultMapping = mapBedObjectKeys(data, [
    'bed',
    'patientName',
    'gender',
  ]);

  const completedMapping = mapBedObjectKeys(data, [
    'dischargeIsolationReason',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    'dischargeReason',
    'bedDischargeActualDate',
    'bedDischargeActualTime',
    'bedCleaning',
  ]);

  if (
    hasGenderPref(
      data.wardPatient?.careSettingPatient?.person.attributes
        .personalInformation.genderIdentifiedAs!
    )
  ) {
    mapping = [
      ...defaultMapping,
      ...mapBedObjectKeys(data, ['wardPref', 'furtherPref']),
      ...completedMapping,
    ];
  } else {
    mapping = [...defaultMapping, ...completedMapping];
  }

  if (
    data.wardPatient?.wardDischarge?.attributes.bedCleaningStatus === 'REQUIRED'
  ) {
    return [
      ...mapping,
      ...mapBedObjectKeys(data, ['bedCleaningType', 'bedStatus']),
    ];
  }
  return mapping;
};
