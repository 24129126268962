import { Input, MultipleChoiceButton } from '@bt-healthcare/ui-toolkit';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { yesNoOptions, porterOptions } from 'components/Form/formOptions';
import { RHFDatePicker } from 'components/Form/RHFDatePicker';
import { matchesEnumValue, notEmpty } from 'validation/rules';
import type { BedStatusPatientProps } from '../types';

export const BedStatusPatient = ({
  register,
  control,
  handlePatientClick,
}: BedStatusPatientProps) => (
  <ConditionalFieldRenderer
    control={control}
    fieldName="bedStatus"
    condition={(arg) => matchesEnumValue(arg, 'ALLOCATED')}
  >
    <Input
      id="patientName"
      label="Patient details"
      placeholder="Select"
      onClick={handlePatientClick}
      {...register('patientName')}
      readOnly
    />
    <ConditionalFieldRenderer
      control={control}
      fieldName="patientName"
      condition={notEmpty}
    >
      <RHFDatePicker
        control={control}
        fieldName="arrivalDate"
        timeFieldName="arrivalTime"
        minDate={new Date()}
        label="Expected date/time arrival to ward"
      />
      <MultipleChoiceButton
        label="Is the bed occupied by an outlier?"
        options={yesNoOptions}
        {...register('outlierYN')}
      />
      <MultipleChoiceButton
        label="Porter"
        size="lg"
        options={porterOptions}
        {...register('porterStatus')}
      />
    </ConditionalFieldRenderer>
  </ConditionalFieldRenderer>
);
