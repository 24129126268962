import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import type { EnvTypes } from '@bt-healthcare/ui-toolkit';
import { ErrorBoundary, useAnalytics } from '@bt-healthcare/ui-toolkit';

import App from 'App';
import { AuthApolloProvider } from 'auth/AuthApolloProvider';
import { AuthProvider } from 'auth/AuthProvider';
import { AuthenticationTemplate } from 'auth/AuthTemplate';
import { initializeSentry } from 'sentry';
import { getEnvVariable } from './env.utils';

const { initializeAdobe, initializeMixpanel } = useAnalytics();

initializeAdobe({
  env: getEnvVariable('REACT_APP_ENV') as EnvTypes,
  data: {
    adobeId1: getEnvVariable('REACT_APP_ADOBE_ANALYTICS_ID1'),
    adobeId2: getEnvVariable('REACT_APP_ADOBE_ANALYTICS_ID2'),
    adobeId3: getEnvVariable('REACT_APP_ADOBE_ANALYTICS_ID3'),
  },
});

initializeMixpanel({
  env: getEnvVariable('REACT_APP_ENV') as EnvTypes,
  data: {
    token: getEnvVariable('REACT_APP_MIXPANEL_ANALYTICS_TOKEN'),
  },
});
const container = document.getElementById('root');
const root = createRoot(container!);

initializeSentry(
  getEnvVariable('REACT_APP_SENTRY_DSN'),
  getEnvVariable('REACT_APP_ENV') as EnvTypes
);

root.render(
  <ErrorBoundary>
    <StrictMode>
      <AuthProvider>
        <AuthenticationTemplate>
          <AuthApolloProvider>
            <Router>
              <App />
            </Router>
          </AuthApolloProvider>
        </AuthenticationTemplate>
      </AuthProvider>
    </StrictMode>
  </ErrorBoundary>
);
