import { format, parse, parseISO } from 'date-fns';

import { EMPTY_VALUE } from 'App.constants';
import type { Maybe } from 'services';

/** Add zero padding when only one digit */
const zeroPadding = (value: number) => String(value).padStart(2, '0');

/** Converts a date string to a date object - used for the DatePicker component for client date data */
export const convertDateStringForFormInput = (
  dateValue?: Maybe<string>,
  dateFormat = 'dd/MM/yy'
) =>
  dateValue && dateValue !== EMPTY_VALUE
    ? parse(dateValue, dateFormat, new Date())
    : undefined;

/** Converts a time string or empty value and returns a valid time   - used for time input for client time data */
export const convertTimeStringForFormInput = (timeValue?: Maybe<string>) =>
  timeValue && timeValue !== EMPTY_VALUE ? timeValue : undefined;

/** Returns the local date from a UTC ISO string in the format 'dd/MM/yy' or 'dd/MM/yyyy' for a string
 */
export const getLocalDate = (value?: Maybe<string>, isFullYear = false) => {
  if (value == null) return EMPTY_VALUE;
  const localDateTime = parseISO(value);
  const year = isFullYear
    ? localDateTime.getFullYear()
    : localDateTime.getFullYear().toString().substring(2);
  return `${zeroPadding(localDateTime.getDate())}/${zeroPadding(
    localDateTime.getMonth() + 1
  )}/${year}`;
};

/* Returns the local time from a UTC ISO string in the format 'H:mm' for a string */
export const getLocalTime = (value?: Maybe<string>, showEmptyValue = true) => {
  if (value == null) return showEmptyValue ? EMPTY_VALUE : '';
  const localDateTime = parseISO(value);
  return `${zeroPadding(localDateTime.getHours())}:${zeroPadding(
    localDateTime.getMinutes()
  )}`;
};

/** Returns the date in the format 'dd/MM/yy' for a ISO date string
 */
export const getDate = (value?: Maybe<string>, dateFormat = 'dd/MM/yy') =>
  value != null ? format(parseISO(value), dateFormat) : EMPTY_VALUE;

/** Returns the date in the format 'dd/MM/yy' for a date value
 */
export const getDateAsString = (value?: Date, dateFormat = 'dd/MM/yy') =>
  value != null ? format(value, dateFormat) : EMPTY_VALUE;

/* Returns the time in the format 'H:mm' for a ISO date string */
export const getTime = (value?: Maybe<string>, showEmptyValue = true) => {
  if (value == null) return showEmptyValue ? EMPTY_VALUE : '';
  const time = value.slice(0, -1);
  return format(parseISO(time), 'H:mm');
};

/** Takes a date and time and returns an ISO compatible date string
 */
export const getDateTime = (
  dateValue?: Date,
  timeValue?: string,
  emptyValue: Maybe<string> = EMPTY_VALUE
) => {
  if (!dateValue) return emptyValue;
  const formattedDate = format(dateValue, 'yyyy-MM-dd');
  return timeValue
    ? `${formattedDate}T${timeValue}:00.000000Z`
    : `${formattedDate}T00:00:00.000000Z`;
};

/** Return formatted hours based on a duration in minutes */
export const getHoursFromMins = (duration?: Maybe<number>) => {
  if (duration == null) return EMPTY_VALUE;
  if (duration < 60) return `00h ${zeroPadding(duration)}m`;
  const mins = duration % 60;
  const hours = Math.trunc(duration / 60);
  return `${zeroPadding(hours)}h ${zeroPadding(mins)}m`;
};

/** Converts a Date object to a UTC date */
export const UTCDateTimeConverter = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

/**  Modifies ISO date strings to be UTC for a defined list of attributes in a data set. Required when submitting data */
export const setFormDatesToUTC = <T>(data: T, fields: Array<keyof T>) => {
  const dateFields = fields.map((item) => ({
    [item]: data[item]
      ? UTCDateTimeConverter(
          new Date(data[item] as unknown as Date)
        ).toISOString()
      : null,
  }));
  return {
    ...data,
    ...dateFields.reduce((curr, acc) => ({ ...curr, ...acc }), {}),
  };
};

/** Returns current time "HH:MM" */
export const getCurrentTime = () => {
  const getCurrentHour = () => {
    const hour = new Date().getHours();
    return zeroPadding(hour);
  };
  const getCurrentMinute = () => {
    const minute = new Date().getMinutes();
    return zeroPadding(minute);
  };

  return `${getCurrentHour()}:${getCurrentMinute()}`;
};

/** Returns current UTC Date and Time */
export const getCurrentUTCDateTime = () => {
  const currentDateTime = getDateTime(new Date(), getCurrentTime());
  const currentUTCDateTime = UTCDateTimeConverter(
    new Date(currentDateTime as unknown as Date)
  ).toISOString();

  return currentUTCDateTime;
};
