import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const ConfirmationWrapper = styled.div`
  @media ${device.desktop} {
    max-width: 900px;
  }
`;

export const Headline = styled.h2`
  font-size: ${fontSizes.h4};
  color: ${colors.primaryIndigo.indigo08};
  margin-bottom: ${spacing.s2};
  font-weight: 500;

  @media ${device.desktop} {
    margin-bottom: ${spacing.s4};
  }
`;

export const Strapline = styled.p`
  font-size: ${fontSizes.sm};
  color: ${colors.grey.grey09};
  margin-bottom: ${spacing.s6};

  @media ${device.desktop} {
    font-size: ${fontSizes.base};
    margin-bottom: ${spacing.s8};
  }
`;

export const FormFooterWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.base.light};
  padding: ${spacing.s4};

  @media ${device.desktop} {
    padding: ${spacing.s8} ${spacing.s10};
    box-shadow: 0px 4px 30px 1px rgba(112, 144, 176, 0.15);
  }
`;

export const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};
  max-width: 1560px;
  margin: auto;

  @media ${device.desktop} {
    display: grid;
    grid-template-columns: 450px 450px;

    #form-layout-tertiary-button {
      text-align: left;
    }
  }
`;
