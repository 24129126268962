import type { DischargeStatusType, WardBed } from 'services';
import { getClinicallyReadyDefaultValues } from './clinicallyReady.utils';
import { getConfirmedDefaultValues } from './confirmed.utils';
import { getDelayedDefaultValues } from './delayed.utils';
import {
  getDischargeStatus,
  initialValues,
  getRequiresIsolation,
  getIsolationType,
  getIsolationTypeOther,
} from '../form.utils';
import { getNotFitDefaultValues } from './notFit.utils';
import { getPotentialDefaultValues } from './potential.utils';

export const defaultValues = (wardBed: WardBed) => {
  const dischargeStatus = getDischargeStatus(wardBed)
    ?.value as DischargeStatusType;

  const defaultFields = {
    ...initialValues,
    requiresIsolationYN: getRequiresIsolation(wardBed),
    isolationType: getIsolationType(wardBed),
    isolationTypeOther: getIsolationTypeOther(wardBed) ?? '',
    decisionMakerYN:
      wardBed.wardPatient?.wardDischarge?.attributes.decisionMakerYN ?? '',
    dischargeStatus: getDischargeStatus(wardBed),
  };

  switch (dischargeStatus) {
    case 'DELAYED':
      return getDelayedDefaultValues(defaultFields, wardBed);
    case 'CONFIRMED':
      return getConfirmedDefaultValues(defaultFields, wardBed);
    case 'NOT_FIT':
      return getNotFitDefaultValues(defaultFields, wardBed);
    case 'CLINICALLY_READY':
      return getClinicallyReadyDefaultValues(defaultFields, wardBed);
    case 'POTENTIAL':
      return getPotentialDefaultValues(defaultFields, wardBed);
    default:
      return defaultFields;
  }
};
