import {
  Card,
  CardHeader,
  Tooltip,
  toHyphenCase,
} from '@bt-healthcare/ui-toolkit';
import { Link } from 'react-router-dom';

import { BedCount } from 'components/BedCount';
import { useStore } from 'store';
import { ROUTES, TOOLTIP_MESSAGES } from 'App.constants';
import {
  FlexContainer,
  Stat,
  Divider,
  DischargeHeadline,
  StatsContent,
} from './styles';
import type { WardCardProps } from './types';

export const WardCard = ({
  id,
  name,
  capacity,
  statistics,
  active,
}: WardCardProps) => {
  const cardStatistics = statistics.filter((item) => !item.group);
  const setWardHeader = useStore((state) => state.setWardHeader);
  const dischargeStatistics = statistics.filter(
    (item) => item.group === 'Discharges Today'
  );
  const handleClick = () => {
    setWardHeader(name, capacity);
  };

  const wardCard = (
    <Card id={name} active={active}>
      <FlexContainer>
        <CardHeader hasTextOverflow={false}>{name}</CardHeader>
        <BedCount capacity={capacity} />
      </FlexContainer>
      <StatsContent>
        {cardStatistics.map((stat) => (
          <Stat key={stat.name}>
            <span>{stat.value}</span> {stat.name}
          </Stat>
        ))}
      </StatsContent>
      <Divider />
      <DischargeHeadline>Discharges today</DischargeHeadline>
      <StatsContent>
        {dischargeStatistics.map((stat) => (
          <Stat key={stat.name}>
            <span>{stat.value}</span> {stat.name}
          </Stat>
        ))}
      </StatsContent>
    </Card>
  );

  return active ? (
    <Link to={ROUTES.WARDS} onClick={handleClick} state={{ wardId: id }}>
      {wardCard}
    </Link>
  ) : (
    <Tooltip
      id={`tooltip-${toHyphenCase(name)}`}
      content={TOOLTIP_MESSAGES.WARD_TRIAL_SCOPE}
      triggerOnClick
      direction="top-start"
      offset="s2"
    >
      {wardCard}
    </Tooltip>
  );
};
