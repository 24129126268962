import {
  Modal,
  Button,
  ModalFooter,
  colors,
  Stack,
} from '@bt-healthcare/ui-toolkit';

import { bedConfirmationMapping } from 'mappings/datalists';
import { DataListCard } from 'components/Card/DataListCard/DataListCard';
import type { BedConfirmationModalProps } from '../types';
import { CardWrapper, Strapline, SubHeader } from '../styles';

export const BedConfirmationModal = ({
  wardName,
  data,
  handleClose,
  handleClick,
  modalOpen,
  title,
  strapLine,
  ragType = 'DischargeStatus',
}: BedConfirmationModalProps) => (
  <Modal
    onClose={handleClose}
    isModalOpen={modalOpen}
    id="bed-confirmation"
    inPortal
  >
    <Stack space="s1" id="confirmation" alignItems="center">
      <SubHeader>{title}</SubHeader>
      <Strapline>{strapLine}</Strapline>
    </Stack>
    <CardWrapper>
      <DataListCard
        wardName={wardName}
        data={bedConfirmationMapping(data, ragType)}
        background={colors.primaryIndigo.indigo01}
      />
    </CardWrapper>
    <ModalFooter flex>
      <Button
        aria-label="Cancel modal"
        id="cancel"
        onClick={handleClose}
        variant="secondary"
      >
        Cancel
      </Button>
      <Button id="confirm" onClick={handleClick}>
        Confirm
      </Button>
    </ModalFooter>
  </Modal>
);
