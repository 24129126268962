import { useState } from 'react';

import { FETCH_POLICY, POLL_INTERVAL } from 'App.constants';
import { useGetWardBedTransfersQuery } from 'services';
import { sortByBedTransferStatus } from 'sorting/bedTransfers';
import type { WardBedTransferQuery } from 'models';

export const useWardBedTransferSort = (
  careSettingId: string,
  includePollInterval = false
) => {
  const [sortedWardBedTransfers, setSortedWardBedTransfers] =
    useState<WardBedTransferQuery>([]);
  const { loading, error } = useGetWardBedTransfersQuery({
    variables: {
      careSettingId,
    },
    fetchPolicy: FETCH_POLICY,
    pollInterval: includePollInterval ? POLL_INTERVAL : undefined,
    onCompleted: (data) => {
      const sortedData = sortByBedTransferStatus(data.wardBedTransfers!);
      setSortedWardBedTransfers(sortedData);
    },
  });

  return { sortedWardBedTransfers, loading, error };
};
