import type { ListViewRow } from '@bt-healthcare/ui-toolkit';
import { ListView } from '@bt-healthcare/ui-toolkit';
import { useNavigate } from 'react-router-dom';

import type { ListViewProps } from 'types';
import type { WardStatisticsView } from 'models';
import { useStore } from 'store';
import { ROUTES } from 'App.constants';
import { headerData, rowData } from './WardOverviewListViewConfig';

export const WardOverviewListView = ({
  data,
}: ListViewProps<WardStatisticsView>) => {
  const navigate = useNavigate();
  const setWardHeader = useStore((state) => state.setWardHeader);

  const handleRowClick = ({ id, items }: ListViewRow) => {
    const wardNameItem = items.find(
      (item) => item.dataValue?.key === 'wardName'
    );
    const capacityItem = items.find(
      (item) => item.dataValue?.key === 'capacity'
    );
    setWardHeader(
      wardNameItem?.dataValue?.value
        ? wardNameItem.dataValue.value.toString()
        : '',
      capacityItem?.dataValue?.value ? +capacityItem.dataValue.value : 0
    );
    navigate(ROUTES.WARDS, { state: { wardId: id } });
  };

  return (
    <ListView
      headerData={headerData}
      rowData={rowData(data!)}
      id="ward-overview"
      minColWidth="30px"
      onRowClick={handleRowClick}
    />
  );
};
