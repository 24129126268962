import * as R from 'ramda';
import { getDate } from 'mappings/date.utils';
import {
  GenderIdentifiedAsAbbrevMap,
  SpecialtyMap,
  YesNoMap,
} from 'mappings/enums';
import { getIsolationReason, getPatientName } from 'mappings/mapping.utils';

import type { BedStatusType, GetWardBedOptionsQuery, WardBed } from 'services';
import type { WardBedTransferQuery } from 'models';

/** Transform query to return ward bed options for DropDownList */
export const wardBedOptionsTransform = (
  response: GetWardBedOptionsQuery,
  activeBedTransfers: WardBedTransferQuery,
  bedStatus: BedStatusType = 'ALLOCATED'
) => {
  const { wardBeds } = response;
  const unallocatedWardBeds = wardBeds?.filter(
    (wardBed) => wardBed.attributes.bedStatus === bedStatus
  );
  const wardBedOptions =
    unallocatedWardBeds?.map((wardBed) => ({
      value: wardBed.id,
      label: wardBed.attributes.wardBedReference,
      gender: wardBed.wardPatient?.careSettingPatient?.person.attributes
        .personalInformation.genderIdentifiedAs
        ? GenderIdentifiedAsAbbrevMap[
            wardBed.wardPatient.careSettingPatient?.person.attributes
              .personalInformation.genderIdentifiedAs
          ]
        : null,
      wardPref:
        wardBed.wardPatient?.careSettingPatient?.attributes
          .patientWardPreference,
      furtherPref:
        wardBed.wardPatient?.careSettingPatient?.attributes
          .furtherWardPreferences,
      careSettingPatientId: wardBed.wardPatient?.careSettingPatient?.id,
      wardPatientId: wardBed.wardPatient?.id,
      patientName: getPatientName(
        wardBed.wardPatient?.careSettingPatient,
        'Unallocated'
      ),
      dateOfBirth: getDate(
        wardBed.wardPatient?.careSettingPatient?.person.attributes
          .personalInformation.dateOfBirth
      ),
      patientIdentifier:
        wardBed.wardPatient?.careSettingPatient?.person.attributes
          .hospitalPatientReference,
      bedStatus,
      isolation: getIsolationReason(wardBed as WardBed),
      speciality:
        SpecialtyMap[wardBed.wardPatient?.attributes.specialty ?? 'NONE'],
      outlier: YesNoMap[wardBed.wardPatient?.attributes.outlierYN ?? 'NONE'],
    })) ?? [];

  const activeFromWardRefs = activeBedTransfers.map(
    (transfer) =>
      transfer.attributes.bedTransferStatus !== 'COMPLETE' &&
      transfer.fromWardBed?.attributes.wardBedReference
  );
  const activeToWardRefs = activeBedTransfers.map(
    (transfer) =>
      transfer.attributes.bedTransferStatus !== 'COMPLETE' &&
      transfer.toWardBed?.attributes.wardBedReference
  );
  const allActiveWardRefs = activeFromWardRefs.concat(activeToWardRefs);
  const filteredWardBedOptions = wardBedOptions.filter((option) =>
    allActiveWardRefs.every((ref) => ref !== option.label)
  );

  return R.sortWith([R.ascend(R.prop('label'))], filteredWardBedOptions);
};
