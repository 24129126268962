import { spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const PageNotificationText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`;

export const PageNotificationWrapper = styled.div`
  margin: ${spacing.s4} 0;
`;
