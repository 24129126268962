import { Text, colors } from '@bt-healthcare/ui-toolkit';
import type { DataListCardLabelProps } from './types';

export const DataListCardLabel = ({
  cardKey,
  value,
  cardType,
}: DataListCardLabelProps) => {
  if (cardKey === 'Bed' && cardType === 'secondaryBedTransfer') {
    return <Text color={colors.secondaryBlue.blue07}>{value}</Text>;
  }
  if (
    (cardKey === 'Ward' || cardKey === 'Bed') &&
    cardType === 'primaryBedTransfer'
  ) {
    return <Text color={colors.primaryIndigo.indigo08}>{value}</Text>;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{value}</>;
};
