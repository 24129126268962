import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { parseISO } from 'date-fns';

import { getDateTime, setFormDatesToUTC } from 'mappings/date.utils';
import type { Maybe, WardBedTransferInput } from 'services';

export type BedWardTransferSubmissionMappingArgs = {
  intraWardTransferReason: Maybe<TypedOptionType<string>>;
  interWardTransferReason: Maybe<TypedOptionType<string>>;
  otherTransferReason: string;
  fromWardId: string;
  fromBed: Maybe<TypedOptionType<string>>;
  toWardId: string;
  toBed: Maybe<TypedOptionType<string>>;
  porterStatus?: Maybe<string>;
  arrivalToWardDate?: Date | string;
  arrivalToWardTime?: string;
  specialty: Maybe<TypedOptionType<string>>;
  careSettingId: string;
  bedCleaningMethod: Maybe<TypedOptionType<string>>;
  bedCleaningStatus: string;
};

export const bedWardTransferSubmissionMapping = ({
  intraWardTransferReason,
  interWardTransferReason,
  otherTransferReason,
  fromWardId,
  fromBed,
  toWardId,
  toBed,
  porterStatus,
  arrivalToWardDate,
  arrivalToWardTime,
  specialty,
  careSettingId,
  bedCleaningMethod,
  bedCleaningStatus,
}: BedWardTransferSubmissionMappingArgs) => {
  const arrivalToWardDateTime = arrivalToWardDate
    ? getDateTime(
        parseISO(arrivalToWardDate as string),
        arrivalToWardTime,
        null
      )
    : null;
  const mappedData = {
    fromWardPatientId: fromBed!.wardPatientId,
    fromWardBedId: fromBed!.value,
    bedTransferStatus: intraWardTransferReason?.value
      ? 'COMPLETE'
      : 'IN_PROGRESS',
    bedTransferType: intraWardTransferReason?.value
      ? 'INTRA_WARD_TRANSFER'
      : 'INTER_WARD_TRANSFER',
    otherTransferReason,
    toWardId,
    toWardBedId: toBed!.value,
    fromWardId,
    porterStatus: porterStatus ?? null,
    arrivalToWardDateTime,
    interWardTransferReason: interWardTransferReason?.value ?? 'NONE',
    intraWardTransferReason: intraWardTransferReason?.value ?? 'NONE',
    careSettingPatientId: fromBed!.careSettingPatientId,
    careSettingId,
    specialty: specialty?.value ?? null,
    bedCleaningMethod: bedCleaningMethod?.value ?? null,
    bedCleaningStatus,
  } as WardBedTransferInput;

  return setFormDatesToUTC(mappedData, ['arrivalToWardDateTime']);
};
