import type { BedAllocationFormData } from 'components/BedAllocation/types';
import type { WardBed } from 'services';
import { getBedCleaningMethod } from '../form.utils';

export const getInCleaningDefaultValues = (
  defaultFields: Partial<BedAllocationFormData>,
  wardBed: WardBed
): Partial<BedAllocationFormData> => ({
  ...defaultFields,
  bedStatus: wardBed.attributes.bedStatus,
  bedCleaningMethod: getBedCleaningMethod(wardBed),
});
