import { MultipleChoiceButton } from '@bt-healthcare/ui-toolkit';

import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { yesNoOptions } from 'components/Form/formOptions';
import { RHFDatePicker } from 'components/Form/RHFDatePicker';
import type { DischargeStatusType } from 'services';
import { matchesOption } from 'validation/rules';
import type { BedDischargeFormFieldsProps } from '../types';

export const DischargeNotFit = ({
  control,
  register,
  errors,
}: BedDischargeFormFieldsProps) => (
  <ConditionalFieldRenderer
    control={control}
    fieldName="dischargeStatus"
    condition={(arg) => matchesOption<DischargeStatusType>(arg, 'NOT_FIT')}
  >
    <RHFDatePicker
      control={control}
      fieldName="dischargeExpectedDate"
      timeFieldName="dischargeExpectedTime"
      label="Estimated date and time of discharge"
      minDate={new Date()}
      markAsRequired
      fieldErrorText={errors?.dischargeExpectedDate?.message}
      timeErrorText={errors?.dischargeExpectedTime?.message}
    />
    <MultipleChoiceButton
      label="Could the patient require social care package?"
      options={yesNoOptions}
      {...register('socialCareYN')}
    />
  </ConditionalFieldRenderer>
);
