import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const GroupWrapper = styled.div`
  display: grid;
  grid-gap: ${spacing.s4};
  grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));

  @media ${device.tablet} {
    grid-gap: ${spacing.s6};
  }
`;
