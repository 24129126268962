import { Notification } from '@bt-healthcare/ui-toolkit';

import { PageNotificationText, PageNotificationWrapper } from './styles';
import type { PageNotificationProps } from './types';

export const PageNotification = ({
  message,
  type = 'warning',
}: PageNotificationProps) => (
  <PageNotificationWrapper data-testid="page-notification">
    <Notification type={type}>
      <PageNotificationText>{message}</PageNotificationText>
    </Notification>
  </PageNotificationWrapper>
);
