import { BTHealthIcon, colors } from '@bt-healthcare/ui-toolkit';

import { bedAllocatedWithNoDischarge } from 'validation/rules';
import type { BedListViewIconProps } from '../components/BedDischarge/types';

export const BedListViewIcon = ({
  bedStatus,
  bedTransferStatus,
  dischargeStatus,
  isWarning = false,
  ragType = 'DischargeStatus',
}: BedListViewIconProps) => {
  if (bedStatus === 'CLEANING')
    return (
      <BTHealthIcon
        icon="Cleaning"
        size={13}
        color={colors.rag.light[isWarning ? 'red' : 'purple']}
      />
    );

  if (
    (ragType === 'DischargeStatus' &&
      bedAllocatedWithNoDischarge(bedStatus, dischargeStatus)) ||
    bedTransferStatus === 'IN_PROGRESS' ||
    bedTransferStatus === 'DELAYED'
  )
    return (
      <BTHealthIcon
        icon="Warning"
        size={13}
        color={colors.notifications.light.errorDark}
      />
    );
  return null;
};
