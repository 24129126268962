import type { ChangeEvent } from 'react';
import { useEffect, useState, useRef } from 'react';
import debounce from 'lodash.debounce';
import { Loader, SearchBar } from '@bt-healthcare/ui-toolkit';
import { shallow } from 'zustand/shallow';

import { WardGroupCarousel } from 'components/WardOverview/WardGroupCarousel';
import { GridToggle } from 'components/WardOverview/GridToggle';
import { useStore } from 'store';
import {
  useGetWardStatisticsQuery,
  useGetWardGroupStatisticsQuery,
} from 'services';
import { COOKIES, NETWORK_FETCH_POLICY, POLL_INTERVAL } from 'App.constants';
import { WardOverviewView } from 'components/WardOverview/WardOverviewView';
import { wardStatisticsFilter } from 'filters/wardStatistics';
import {
  mapWardStatisticsToWardGroups,
  mapWardStatisticsToWards,
} from 'mappings/statistics/ward';
import type { WardStatisticsView } from 'models';
import { useTracking } from 'hooks/useTracking';
import { PageNotification } from 'components/PageNotification';
import { Cookie } from 'cookies/utils';
import { SearchWrapper } from '../styles';

export const Overview = () => {
  const listViewDefault = useRef(Cookie.get(COOKIES.WARD_LIST_VIEW) === 'true');

  const { trackPage } = useTracking();
  const [setMenuHeader, setMenuItem, { careSettingId }, errorMessage] =
    useStore(
      (state) => [
        state.setMenuHeader,
        state.setMenuItem,
        state.appConfig,
        state.errorMessage,
      ],
      shallow
    );

  const [list, setList] = useState(listViewDefault.current);
  const [selectedWardGroupId, setSelectedWardGroupId] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [filteredWardStatistics, setFilteredWardStatistics] =
    useState<WardStatisticsView[]>();

  const {
    loading: wardStatLoading,
    data: wardStatData,
    error: wardStatError,
  } = useGetWardStatisticsQuery({
    variables: { careSettingId },
    fetchPolicy: NETWORK_FETCH_POLICY,
    pollInterval: POLL_INTERVAL,
  });

  const {
    loading: wardGroupStatLoading,
    data: wardGroupStatData,
    error: wardGroupStatError,
  } = useGetWardGroupStatisticsQuery({
    variables: { careSettingId },
    fetchPolicy: NETWORK_FETCH_POLICY,
    pollInterval: POLL_INTERVAL,
  });

  useEffect(() => {
    setMenuHeader('Overview');
    setMenuItem('Overview');
  }, []);

  useEffect(() => {
    trackPage('overview');
  }, []);

  useEffect(() => {
    if (
      wardStatData?.wardStatistics &&
      wardStatData?.wardStatistics?.length > 0
    ) {
      const data = mapWardStatisticsToWards(wardStatData!);
      setFilteredWardStatistics(
        wardStatisticsFilter(data, searchInput, selectedWardGroupId)
      );
    }
  }, [selectedWardGroupId, wardGroupStatData]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setSearchInput(value);
    const data = mapWardStatisticsToWards(wardStatData!);
    setFilteredWardStatistics(
      wardStatisticsFilter(data, value, selectedWardGroupId)
    );
  };

  const handleViewChange = () => {
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);
    const showList = !list;
    Cookie.set(COOKIES.WARD_LIST_VIEW, showList, {
      path: '/',
      expires: expiryDate,
    });
    setList(showList);
  };

  const debouncedHandleChange = debounce((evt) => handleChange(evt), 1000);
  const hasError = wardStatError || wardGroupStatError;

  if (wardGroupStatLoading || wardStatLoading) return <Loader />;

  const pageLoaded =
    wardGroupStatData?.wardGroupStatistics &&
    wardGroupStatData.wardGroupStatistics.length > 0 &&
    wardStatData?.wardStatistics &&
    wardStatData.wardStatistics.length > 0;

  if (hasError)
    return (
      <PageNotification
        message={errorMessage('trying to load the ward overview details')}
      />
    );

  if (pageLoaded)
    return (
      <>
        <WardGroupCarousel
          id="wardgroup"
          data={mapWardStatisticsToWardGroups(wardGroupStatData)}
          setSelectedWardGroupId={setSelectedWardGroupId}
          selectedWardGroupId={selectedWardGroupId}
        />
        <SearchWrapper>
          <SearchBar
            id="ward"
            placeholder="Search ward name"
            onChange={debouncedHandleChange}
          />
          <GridToggle
            onViewChange={handleViewChange}
            isList={list}
            id="grid-toggle"
          />
        </SearchWrapper>

        {filteredWardStatistics?.length === 0 ? (
          <PageNotification
            type="informative"
            message="Search returned no results. Please try again."
          />
        ) : (
          <WardOverviewView
            list={list}
            filteredData={filteredWardStatistics!}
            data={mapWardStatisticsToWards(wardStatData!)}
          />
        )}
      </>
    );
  return null;
};
