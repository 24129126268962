import type { CareSettingPatient } from 'services';
import { mapPatientObjectKeys } from './patientMapping';

export const patientAdmissionDefaultMapping = (data: CareSettingPatient) =>
  mapPatientObjectKeys(data, [
    'patientName',
    'dateOfBirth',
    'gender',
    'decisionToAdmitDate',
    'decisionToAdmitTime',
    'isolationReason',
    'origin',
    'specialty',
    'socialCare',
  ]);

export const patientAdmissionGenderMapping = (data: CareSettingPatient) =>
  mapPatientObjectKeys(data, [
    'patientName',
    'dateOfBirth',
    'gender',
    'wardPref',
    'furtherPref',
    'decisionToAdmitDate',
    'decisionToAdmitTime',
    'isolationReason',
    'origin',
    'specialty',
    'socialCare',
  ]);

export const patientSelectionDefaultMapping = (data: CareSettingPatient) =>
  mapPatientObjectKeys(data, [
    'dateOfBirth',
    'gender',
    'decisionToAdmitDate',
    'decisionToAdmitTime',
    'isolationReason',
    'origin',
    'specialty',
    'socialCare',
  ]);

export const patientSelectionFurtherPreferencesMapping = (
  data: CareSettingPatient
) =>
  mapPatientObjectKeys(data, [
    'dateOfBirth',
    'gender',
    'wardPref',
    'furtherPref',
    'decisionToAdmitDate',
    'decisionToAdmitTime',
    'isolationReason',
    'origin',
    'specialty',
    'socialCare',
  ]);
