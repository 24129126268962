import * as yup from 'yup';

import {
  isRequired,
  isRequiredDropDownListOption,
  isRequiredTime,
  isRequiredWhenSelection,
  isRequiredWhenValueIsEnum,
} from 'validation/schema';
import type { FormKey } from '../Form/types';
import type { BedAllocationFormData } from './types';

const bedAllocationFormRules = {
  bedStatus: isRequired(),
  patientName: isRequiredWhenValueIsEnum({
    field: 'bedStatus',
    value: 'ALLOCATED',
  }),
  arrivalDate: isRequiredWhenValueIsEnum({
    field: 'bedStatus',
    value: 'ALLOCATED',
  }),
  arrivalTime: isRequiredWhenValueIsEnum({
    field: 'bedStatus',
    value: 'ALLOCATED',
    fn: () => isRequiredTime(),
  }),
  bedStatusDate: isRequiredWhenValueIsEnum({
    field: 'bedStatus',
    value: 'CLOSED|UNALLOCATED',
  }),
  bedStatusTime: isRequiredWhenValueIsEnum({
    field: 'bedStatus',
    value: 'CLOSED|UNALLOCATED',
    fn: () => isRequiredTime(),
  }),
  reasonForBedClosure: isRequiredWhenValueIsEnum({
    field: 'bedStatus',
    value: 'CLOSED',
    fn: isRequiredDropDownListOption,
    yupSchema: yup.object(),
  }),
  otherReasonForBedClosure: isRequiredWhenSelection({
    field: 'reasonForBedClosure',
    value: 'OTHER',
  }),
  outlierYN: isRequiredWhenValueIsEnum({
    field: 'bedStatus',
    value: 'ALLOCATED',
  }),
  porterStatus: isRequiredWhenValueIsEnum({
    field: 'bedStatus',
    value: 'ALLOCATED',
  }),
  bedCleaningMethod: isRequiredWhenValueIsEnum({
    field: 'bedStatus',
    value: 'CLEANING',
    fn: () => isRequiredDropDownListOption(),
    yupSchema: yup.object().nullable(),
  }),
} as { [key in FormKey<BedAllocationFormData>]: any };

export const bedAllocationSchema = yup.object(bedAllocationFormRules);
