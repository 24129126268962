import { useLocation, useNavigate } from 'react-router-dom';
import type { MenuBarOptions } from '@bt-healthcare/ui-toolkit';
import { Exception, Loader, Page } from '@bt-healthcare/ui-toolkit';
import { useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';
import { equals, isEmpty } from 'ramda';

import { useAuthUser } from 'auth/useAuthUser';
import { getMenuConfig, getNavItems, getTopNavItems } from 'navigation';
import type { UserModuleAvailability } from 'services';
import { mapBackRoute } from 'routes/routes.utils';
import { getVersion } from 'env.utils';
import { useStore } from './store';
import { AppRoutes } from './routes/Routes';
import { MODULE_SHORT_NAME, ROUTES } from './App.constants';
import { PageWrapper } from './styles';

const App = () => {
  const navigate = useNavigate();
  const { pathname, state: locationState } = useLocation();
  const { auth, user } = useAuthUser();
  const [menuHeader, setAppConfig, menuItems, setMenuItems] = useStore(
    (state) => [
      state.menuHeader,
      state.setAppConfig,
      state.menuItems,
      state.setMenuItems,
    ],
    shallow
  );

  const navItems = user.data
    ? getNavItems(
        user.data.userProfile
          ?.userModuleAvailability as UserModuleAvailability[]
      )
    : [];

  const topNavMenuItems = getTopNavItems(auth.userRoles);
  const prevTopNavItems = useRef([] as MenuBarOptions[]);

  useEffect(() => {
    if (!equals(topNavMenuItems, prevTopNavItems.current)) {
      prevTopNavItems.current = topNavMenuItems;
      setMenuItems(topNavMenuItems);
    }
  }, [topNavMenuItems]);

  useEffect(() => {
    if (user.data) {
      setAppConfig({
        careSettingId:
          user.data.userProfile?.attributes.defaultCareSettingId ?? '',
        organisationName:
          user.data.userProfile?.careSetting?.attributes.name ?? '',
      });
    }
  }, [user.data]);

  const loading = auth.loading || user.loading;

  // eslint-disable-next-line no-underscore-dangle
  window.__VERSION__ = getVersion();

  const handleMenuItemClick = (label: string) => {
    switch (label) {
      case 'Admissions':
        navigate(ROUTES.ADMISSIONS_HOME);
        break;
      case 'Bed Transfer':
        navigate(ROUTES.BED_TRANSFER_HOME);
        break;
      default:
        navigate(ROUTES.HOME);
        break;
    }
  };

  if (loading || (isEmpty(navItems) && !user.error)) return <Loader />;
  return (
    <Page
      profile={user.profile}
      showThemeToggle={false}
      menuConfig={getMenuConfig(navItems ?? [], MODULE_SHORT_NAME)}
      menuHeader={menuHeader}
      appTitle="Bed Optimiser"
      topNavMenuItems={menuItems}
      topNavMenuItemOnClick={handleMenuItemClick}
      size="wide"
      onBackClick={mapBackRoute(pathname, navigate, locationState?.wardId)}
      onLogout={auth.logout}
    >
      <PageWrapper>{user.error ? <Exception /> : <AppRoutes />}</PageWrapper>
    </Page>
  );
};

export default App;
