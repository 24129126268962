import { spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const AlertWrapper = styled.div`
  div {
    margin-bottom: ${spacing.s2};

    &:last-child {
      margin-bottom: ${spacing.none};
    }
  }
`;
