import { Input } from '@bt-healthcare/ui-toolkit';

import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { failedDischargeOptions } from 'components/Form/formOptions';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import type { DischargeStatusType } from 'services';
import { isOtherSelectedMulti, matchesOption } from 'validation/rules';
import type { BedDischargeFormFieldsProps } from '../types';

export const DischargeFailed = ({
  control,
  register,
  errors,
}: BedDischargeFormFieldsProps) => (
  <ConditionalFieldRenderer
    control={control}
    fieldName="dischargeStatus"
    condition={(arg) => matchesOption<DischargeStatusType>(arg, 'DELAYED')}
  >
    <RHFDropDown
      label="Reason"
      fieldName="failedDischargeStatusReasons"
      control={control}
      options={failedDischargeOptions}
      isMulti
    />
    <ConditionalFieldRenderer
      control={control}
      fieldName="failedDischargeStatusReasons"
      condition={(arg) => isOtherSelectedMulti(arg)}
    >
      <Input
        id="failedDischargeStatusReasonOther"
        label="Other reason"
        {...register('failedDischargeStatusReasonOther')}
        markAsRequired
        errorText={errors?.failedDischargeStatusReasonOther?.message}
      />
    </ConditionalFieldRenderer>
  </ConditionalFieldRenderer>
);
