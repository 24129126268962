import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { RHFDatePicker } from 'components/Form/RHFDatePicker';
import { matchesEnumValue } from 'validation/rules';

import type { BedStatusUnallocatedProps } from '../types';

export const BedStatusUnallocated = ({
  control,
}: BedStatusUnallocatedProps) => (
  <ConditionalFieldRenderer
    control={control}
    fieldName="bedStatus"
    condition={(arg) => matchesEnumValue(arg, 'UNALLOCATED')}
  >
    <RHFDatePicker
      control={control}
      fieldName="bedStatusDate"
      timeFieldName="bedStatusTime"
      label="Date and time"
      minDate={new Date()}
    />
  </ConditionalFieldRenderer>
);
