import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { colors, Loader, Input } from '@bt-healthcare/ui-toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import { useEffect, useState } from 'react';

import type { IntraWardTransferReason } from 'services';
import { useGetWardBedOptionsLazyQuery } from 'services';
import { useStore } from 'store';
import { wardBedOptionsTransform } from 'transforms/wardBedOptionTransform';
import { matchesOption, notEmpty } from 'validation/rules';
import { FormFieldLayout } from 'pages/styles';
import { FETCH_POLICY } from 'App.constants';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { FormFooter } from 'components/Form/FormFooter';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import { BedCleaningFormFields } from 'components/BedCleaning/BedCleaningFormFields';
import { DataListCard } from '../Card/DataListCard/DataListCard';
import { intraWardTransferReasonOptions } from '../Form/formOptions';
import { RHFButtonDropDown } from '../Form/RHFButtonDropDown';
import { BedTransferFormatOptionLabel } from './BedTransferFormatOptionLabel';
import type { BedTransferFormData, BedTransferFormFieldsProps } from './types';
import { bedIntraTransferSchema } from './validationSchema';
import { MultipleButtonWrapper } from './styles';
import {
  bedFromTransferCardColumns,
  bedFromTransferCardItemGap,
} from './config';

export const BedIntraTransferFormFields = ({
  wardOptions,
  onSubmit,
  onWardBedFromChange,
  onWardBedToChange,
  activeBedTransfers,
}: BedTransferFormFieldsProps) => {
  const [wardToBedOptions, setWardToBedOptions] = useState(
    [] as TypedOptionType<string>[]
  );

  const [wardFromBedOptions, setWardFromBedOptions] = useState(
    [] as TypedOptionType<string>[]
  );

  const [
    setFormData,
    bedFromTransfer,
    bedToTransfer,
    resetBedTransfer,
    setBedFromTransfer,
  ] = useStore(
    (state) => [
      state.setBedTransferForm,
      state.bedFromTransfer,
      state.bedToTransfer,
      state.resetBedTransfer,
      state.setBedFromTransfer,
    ],
    shallow
  );
  const defaultValues: Partial<BedTransferFormData> = {
    fromWard: null,
    fromBed: null,
    toBed: null,
    bedCleaningMethod: null,
    bedCleaningStatus: '',
  };

  useEffect(() => {
    resetBedTransfer();
  }, []);

  const {
    control,
    register,
    getValues,
    handleSubmit,
    resetField,
    formState: { isValid },
  } = useForm<BedTransferFormData>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(bedIntraTransferSchema),
  });

  const [getWardBedsByWardBedId, { loading: wardBedOptionsLoading }] =
    useGetWardBedOptionsLazyQuery();

  const isLoading = wardBedOptionsLoading;

  const handleClick = () => {
    setFormData(getValues());
    handleSubmit(onSubmit)();
  };

  const handleWardChange = ({ label, value }: TypedOptionType<string>) => {
    resetField('toBed');
    resetField('fromBed');
    resetField('intraWardTransferReason');
    getWardBedsByWardBedId({
      variables: { wardId: value },
      fetchPolicy: FETCH_POLICY,
      onCompleted: (response) => {
        const allocatedWardBeds = wardBedOptionsTransform(
          response,
          activeBedTransfers
        );
        setBedFromTransfer([
          {
            key: 'Ward',
            value: label,
          },
        ]);
        setWardToBedOptions(allocatedWardBeds);
        const unallocatedWardBeds = wardBedOptionsTransform(
          response,
          activeBedTransfers,
          'UNALLOCATED'
        );
        setWardFromBedOptions(unallocatedWardBeds);
      },
    });
  };

  if (isLoading) return <Loader />;
  return (
    <form>
      <FormFieldLayout id="bedTransferFormFields">
        <MultipleButtonWrapper>
          <RHFButtonDropDown
            label="From"
            fieldName="fromWard"
            control={control}
            options={wardOptions.fromWardOptions}
            defaultValue={null}
            buttonLabel="Ward"
            handleChange={handleWardChange}
          />
          <RHFButtonDropDown
            fieldName="fromBed"
            control={control}
            options={wardToBedOptions}
            buttonLabel="Bed"
            formatOptionLabel={BedTransferFormatOptionLabel}
            handleChange={onWardBedFromChange}
            disabled={wardToBedOptions.length === 0}
          />
        </MultipleButtonWrapper>
        {bedFromTransfer?.length !== 0 && (
          <DataListCard
            columns={bedFromTransferCardColumns}
            itemGap={bedFromTransferCardItemGap}
            background={colors.primaryIndigo.indigo01}
            data={bedFromTransfer!}
            cardType="primaryBedTransfer"
          />
        )}
        <RHFButtonDropDown
          label="To"
          fieldName="toBed"
          control={control}
          options={wardFromBedOptions}
          buttonLabel="Bed"
          formatOptionLabel={BedTransferFormatOptionLabel}
          variant="secondary"
          handleChange={onWardBedToChange}
          disabled={wardToBedOptions.length === 0}
        />
        {bedToTransfer.length !== 0 && (
          <DataListCard
            columns="2"
            background={colors.secondaryBlue.blue01}
            data={bedToTransfer}
            cardType="secondaryBedTransfer"
          />
        )}
        <ConditionalFieldRenderer
          control={control}
          fieldName="toBed"
          condition={notEmpty}
        >
          <RHFDropDown
            label="Reason for transfer"
            fieldName="intraWardTransferReason"
            control={control}
            options={intraWardTransferReasonOptions}
          />
          <ConditionalFieldRenderer
            control={control}
            fieldName="intraWardTransferReason"
            condition={(arg) =>
              matchesOption<IntraWardTransferReason>(arg, 'OTHER')
            }
          >
            <Input
              id="otherTransferReason"
              label="Other reason"
              {...register('otherTransferReason')}
            />
          </ConditionalFieldRenderer>
          <BedCleaningFormFields
            resetField={resetField}
            register={register}
            control={control}
            journey="transfer"
          />
        </ConditionalFieldRenderer>
        <FormFooter id="continue" handleClick={handleClick} isValid={isValid} />
      </FormFieldLayout>
    </form>
  );
};
