import type { Subset } from '@bt-healthcare/ui-toolkit';
import { curry, omit } from 'ramda';

import type { ExtendedWardBed } from 'types';
import { EMPTY_VALUE } from 'App.constants';
import type {
  IsolationType,
  Maybe,
  CareSettingPatient,
  Statistics,
  WardBed,
  WardPatient,
  CareSettingPatientAttributes,
  WardBedTransferAttributes,
  WardDischarge,
  GenderIdentifiedAs,
  WardBedTransfer,
} from 'services';
import {
  BedCleaningMethodMap,
  BedCleaningStatusMap,
  InterWardTransferReasonMap,
  IntraWardTransferReasonMap,
  IsolationMap,
  PatientWardPreferencesMap,
  SpecialtyMap,
  WaitingForPotentialDischargeStatusMap,
  YesNoMap,
} from './enums';
import { getEnumMapOptions } from './enums/enum.utils';

const getIsolation = (
  isolationType: IsolationType,
  isolationTypeOther?: Maybe<string>
) =>
  isolationType === 'OTHER'
    ? isolationTypeOther ?? EMPTY_VALUE
    : IsolationMap[isolationType];

export const getWardDischargeIsolationReason = (data: WardBed) =>
  data?.wardPatient?.wardDischarge?.attributes?.requiresIsolationYN === 'YES' &&
  data?.wardPatient?.wardDischarge?.attributes?.isolationType
    ? getIsolation(
        data.wardPatient.wardDischarge?.attributes?.isolationType,
        data.wardPatient.wardDischarge?.attributes?.isolationTypeOther
      )
    : YesNoMap[
        data?.wardPatient?.wardDischarge?.attributes?.requiresIsolationYN ??
          'NONE'
      ];

export const getWardPatientIsolationReason = (data?: WardBed) =>
  data?.wardPatient?.attributes?.requiresIsolationYN === 'YES' &&
  data.wardPatient.attributes.isolationType
    ? getIsolation(
        data.wardPatient.attributes.isolationType,
        data.wardPatient.attributes.isolationTypeOther
      )
    : YesNoMap[data?.wardPatient?.attributes?.requiresIsolationYN ?? 'NONE'];

export const getPersonIsolationReason = (
  attributes: Partial<CareSettingPatientAttributes>
) =>
  attributes?.requiresIsolationYN === 'YES' && attributes.isolationType
    ? getIsolation(attributes.isolationType, attributes.isolationTypeOther)
    : YesNoMap[attributes?.requiresIsolationYN ?? 'NONE'];

export const getIsolationReason = (data?: WardBed) => {
  if (data?.attributes.bedStatus === 'UNALLOCATED') return EMPTY_VALUE;
  return data?.wardPatient?.wardDischarge?.attributes?.isolationType
    ? getWardDischargeIsolationReason(data)
    : getWardPatientIsolationReason(data);
};

export const getPatientName = (
  patient?: Maybe<Subset<CareSettingPatient>>,
  emptyValue = EMPTY_VALUE
) =>
  patient?.person?.attributes?.personalInformation
    ? `${patient.person.attributes.personalInformation.firstName?.trim()} ${patient.person.attributes.personalInformation.surname?.trim()}`
    : emptyValue;

export const getWardStatistic = (statistics: Statistics[], name: string) => {
  const stat = statistics.find((item) => item.name === name);
  return stat ? stat.value.toString() : '0';
};

export const getDischargeStatus = (
  patient?: Maybe<Subset<WardPatient>>,
  emptyValue = 'NONE'
) =>
  patient?.wardDischarge?.attributes?.dischargeStatus
    ? patient?.wardDischarge?.attributes?.dischargeStatus
    : emptyValue;

export const getWardBedTransferReason = (
  wardBedTransferAttributes: Partial<WardBedTransferAttributes>
) => {
  const { interWardTransferReason, intraWardTransferReason, bedTransferType } =
    wardBedTransferAttributes;

  return bedTransferType === 'INTER_WARD_TRANSFER'
    ? InterWardTransferReasonMap[interWardTransferReason!]
    : IntraWardTransferReasonMap[intraWardTransferReason!];
};

export const getSpecialty = (data: ExtendedWardBed) => {
  if (data.wardBedTransfers?.[0]?.attributes) {
    return SpecialtyMap[
      data.wardBedTransfers[0]?.attributes.specialty ?? 'NONE'
    ];
  }
  return SpecialtyMap[
    data.wardPatient?.careSettingPatient?.attributes?.specialty ?? 'NONE'
  ];
};

export const getPotentialDischargeWaitingReason = (
  data?: Maybe<WardDischarge>
) => {
  if (!data || !data.attributes.waitingReasonsForPotentialDischarge)
    return EMPTY_VALUE;
  return getEnumMapOptions(
    WaitingForPotentialDischargeStatusMap,
    data.attributes.waitingReasonsForPotentialDischarge,
    data.attributes.waitingReasonOther
  );
};

export const getWardBedReference = (wardBed?: Maybe<WardBed>) =>
  wardBed?.attributes.bedStatus === 'PREALLOCATED'
    ? EMPTY_VALUE
    : wardBed?.attributes.wardBedReference;

export const hasGenderPref = (gender: GenderIdentifiedAs) =>
  gender === 'NON_BINARY' || gender === 'PREFER_NONE';

export const isBedTransferInProgress = (bedTransfer?: Maybe<WardBedTransfer>) =>
  !(
    bedTransfer == null ||
    bedTransfer.attributes.bedTransferStatus === 'CANCELLED' ||
    bedTransfer.attributes.bedTransferStatus === 'NONE'
  );

export const getBedCleaningMappingOptions = (data: WardBed) => {
  const isBedTransfer = isBedTransferInProgress(data?.wardBedTransfers?.[0]);
  let typeOfClean;
  let bedCleaning;
  if (isBedTransfer) {
    // Only show Bed Cleaning on Ward From Bed transfers on Interward bed transfers
    if (
      data.wardBedTransfers![0]?.attributes.bedTransferType ===
        'INTER_WARD_TRANSFER' &&
      data.wardBedTransfers![0]?.toWard?.id === data.attributes.wardId
    )
      return null;
    typeOfClean = data.wardBedTransfers![0]?.attributes.bedCleaningMethod;
    bedCleaning = data.wardBedTransfers![0]!.attributes.bedCleaningStatus;
  } else {
    typeOfClean = data.attributes.bedCleaningMethod;
    bedCleaning = data.wardPatient?.wardDischarge?.attributes.bedCleaningStatus;
  }
  return {
    bedCleaning: {
      key: 'Bed cleaning',
      value:
        bedCleaning == null ? EMPTY_VALUE : BedCleaningStatusMap[bedCleaning],
    },
    bedCleaningType: {
      key: 'Type of clean',
      value:
        typeOfClean == null ? EMPTY_VALUE : BedCleaningMethodMap[typeOfClean],
    },
  };
};

export const getPatientIdentifierMappingOptions = (data: WardBed) => {
  const patientReference =
    data.wardPatient?.careSettingPatient?.person?.attributes
      ?.hospitalPatientReference;

  if (patientReference)
    return {
      patientIdentifier: {
        key: 'Hospital No',
        value: patientReference,
      },
    };

  return null;
};

export const getGenderPrefMappingOptions = (data: WardBed) => {
  if (
    hasGenderPref(
      data.wardPatient?.careSettingPatient?.person?.attributes
        ?.personalInformation?.genderIdentifiedAs!
    )
  )
    return {
      wardPref: {
        key: 'Ward pref',
        value:
          PatientWardPreferencesMap[
            data.wardPatient?.careSettingPatient?.attributes
              ?.patientWardPreference ?? 'NONE'
          ],
      },
      furtherPref: {
        key: 'Further pref',
        value:
          data.wardPatient?.careSettingPatient?.attributes
            ?.furtherWardPreferences ?? EMPTY_VALUE,
      },
    };

  return null;
};

/**
 * Utility function to always omit typename and client fields from GraphQL submissions
 * */
export const omitGraphQLFields = curry((keysToOmit: string[], obj: Object) =>
  omit([...keysToOmit, '__typename', 'client'], obj)
);
