import { BTHealthIcon, colors, toHyphenCase } from '@bt-healthcare/ui-toolkit';
import { SubheadingWrapper, Subheading } from './styles';
import type { PageHeaderProps } from './types';

export const PageHeader = ({ title, capacity }: PageHeaderProps) => (
  <SubheadingWrapper data-testid={toHyphenCase(`${title}-header`)}>
    <Subheading>{title}</Subheading>
    <div className="icon-wrapper">
      <BTHealthIcon icon="Bed" color={colors.secondaryBlue.blue06} size={20} />
      <span>{capacity}</span>
    </div>
  </SubheadingWrapper>
);
