import type { UseFormResetField } from 'react-hook-form';

import type { BedAllocationFormData } from 'components/BedAllocation/types';
import type { WardBed } from 'services';
import { getBedCleaningMethod } from '../form.utils';

export const resetInCleaningFormFields = (
  resetField: UseFormResetField<BedAllocationFormData>,
  isCurrent: boolean,
  wardBed: WardBed
) => {
  resetField('bedCleaningMethod', {
    defaultValue: isCurrent ? getBedCleaningMethod(wardBed) : null,
  });
};
