import type { UseFormResetField } from 'react-hook-form';

import type { BedDischargeFormData } from 'components/BedDischarge/types';
import type { WardBed } from 'services';
import {
  convertDateStringForFormInput,
  convertTimeStringForFormInput,
} from 'mappings/date.utils';
import {
  getClinicallyReadyWaitingReasons,
  getDischargeAssessmentOutcome,
} from '../form.utils';

export const resetClinicallyReadyFormFields = (
  resetField: UseFormResetField<BedDischargeFormData>,
  isCurrent: boolean,
  wardBed: WardBed
) => {
  resetField('dischargeExpectedDate', {
    defaultValue: isCurrent
      ? convertDateStringForFormInput(
          wardBed.wardPatient?.wardDischarge?.attributes.client
            ?.dischargeExpectedDate
        )
      : '',
  });
  resetField('dischargeExpectedTime', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes.client
          ?.dischargeExpectedTime
      : '',
  });
  resetField('waitingReasonsForClinicallyReadyForDischarge', {
    defaultValue: isCurrent ? getClinicallyReadyWaitingReasons(wardBed) : null,
  });
  resetField('requireDischargeToAssess', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes
          .requireDischargeToAssess ?? ''
      : '',
  });
  resetField('dischargeAssessmentOutcome', {
    defaultValue: isCurrent ? getDischargeAssessmentOutcome(wardBed) : null,
  });
  resetField('clinicallyReadyForDischargeDate', {
    defaultValue: isCurrent
      ? convertDateStringForFormInput(
          wardBed.wardPatient?.wardDischarge?.attributes.client
            ?.clinicallyReadyForDischargeDate
        )
      : '',
  });
  resetField('clinicallyReadyForDischargeTime', {
    defaultValue: isCurrent
      ? convertTimeStringForFormInput(
          wardBed.wardPatient?.wardDischarge?.attributes.client
            ?.clinicallyReadyForDischargeTime
        ) ?? ''
      : '',
  });
};
