import { onError } from '@apollo/client/link/error';
import { StatusCodes } from 'http-status-codes';
import type { LogoutOptions } from '@auth0/auth0-react';
import type { MutableRefObject } from 'react';
import type {
  ApolloClient,
  NextLink,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client';
import type { GraphQLErrors, NetworkError } from '@apollo/client/errors';

import { moduleLogout } from 'auth/modules';
import { useStore } from 'store';
import { HTTP_HEADER } from 'services/httpHeaders';
import { captureSentryException, captureSentryGraphQLErrors } from 'sentry';

const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
const UNAUTHENTICATED = 'UNAUTHENTICATED';

const handleNetworkError = (
  networkError: NetworkError,
  correlationId: string
) => {
  captureSentryException(networkError, correlationId, { type: 'networkError' });
  const { setErrors } = useStore.getState();
  setErrors({ errorType: 'Network', error: 'A network error occurred' });
};

export const handleGraphQLErrors = (
  graphQLErrors: GraphQLErrors,
  correlationId: string,
  operation: Operation,
  forward: NextLink
  // eslint-disable-next-line consistent-return
) => {
  const isRetryException = graphQLErrors.some(
    (err) =>
      err.extensions.code === INTERNAL_SERVER_ERROR &&
      (err.message.includes('ECONNRESET') ||
        err.extensions.status === StatusCodes.SERVICE_UNAVAILABLE)
  );

  captureSentryGraphQLErrors(
    graphQLErrors,
    operation.operationName,
    correlationId
  );

  if (isRetryException) return forward(operation);

  const { setErrors } = useStore.getState();
  setErrors({ errorType: 'GraphQLError', error: graphQLErrors });
};

export const errorLink = (
  logout: (options?: LogoutOptions | undefined) => void,
  client: MutableRefObject<ApolloClient<NormalizedCacheObject> | undefined>
) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    const correlationId =
      operation.getContext().headers?.[HTTP_HEADER.apigwTracking];
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError?.statusCode === StatusCodes.UNAUTHORIZED
    ) {
      moduleLogout(logout, client?.current);
    } else if (networkError) {
      handleNetworkError(networkError, correlationId);
    } else if (graphQLErrors) {
      const isUnauthenticated = graphQLErrors.some(
        (err) => err.extensions.code === UNAUTHENTICATED
      );
      if (isUnauthenticated) moduleLogout(logout, client?.current);
      else {
        handleGraphQLErrors(graphQLErrors, correlationId, operation, forward);
      }
    }
  });
