import { EMPTY_VALUE } from 'App.constants';
import { YesNoMap } from 'mappings/enums';
import { getIsolationReason } from 'mappings/mapping.utils';
import type {
  BedStatusType,
  DischargeStatusType,
  BedTransferStatus,
  WardBed,
} from 'services';
import type { RagType } from 'types';
import type { DischargeMessageStatusType } from './types';

const dischargeStatusNotificationTypeMap = {
  CONFIRMED: 'informative',
  CLINICALLY_READY: 'softWarning',
  POTENTIAL: 'softWarning',
  DELAYED: 'warning',
};

const getBedStatusNotificationType = (
  status: BedStatusType,
  hasExceededDuration: boolean
) => {
  if (status === 'UNALLOCATED') return 'positive';
  if (status === 'CLOSED') return 'informative';
  if (status === 'CLEANING')
    return hasExceededDuration ? 'warning' : 'secondaryInformative';

  return null;
};

const bedTransferNotificationTypeMap = {
  IN_PROGRESS: 'informative',
  DELAYED: 'warning',
};

export const getNotificationType = (
  ragType: RagType,
  dischargeStatus: DischargeStatusType,
  bedStatus: BedStatusType,
  bedTransferStatus: BedTransferStatus | undefined,
  data?: WardBed
) => {
  if (bedStatus === 'CLEANING') {
    return getBedStatusNotificationType(
      bedStatus,
      data?.attributes?.res_bedCleaning?.hasExceededDuration!
    );
  }

  if (ragType === 'BedStatus') {
    const isolationReason = getIsolationReason(data);
    if (isolationReason !== EMPTY_VALUE && isolationReason !== YesNoMap.NO)
      return 'warning';

    if (bedTransferStatus) {
      return bedTransferNotificationTypeMap[
        bedTransferStatus as 'IN_PROGRESS' | 'DELAYED'
      ];
    }

    return getBedStatusNotificationType(bedStatus, false);
  }

  if (bedTransferStatus) {
    return bedTransferNotificationTypeMap[
      bedTransferStatus as 'IN_PROGRESS' | 'DELAYED'
    ];
  }

  // TODO: Need to handle all discharge reasons that are not set as no alert should be shown in this
  // instance
  const waitingReasonsForClinicallyReadyForDischarge =
    data?.wardPatient?.wardDischarge?.attributes
      .waitingReasonsForClinicallyReadyForDischarge;

  const waitingReasonsForPotentialDischarge =
    data?.wardPatient?.wardDischarge?.attributes
      .waitingReasonsForPotentialDischarge;

  if (
    (dischargeStatus === 'CLINICALLY_READY' &&
      waitingReasonsForClinicallyReadyForDischarge == null) ||
    (dischargeStatus === 'POTENTIAL' &&
      waitingReasonsForPotentialDischarge == null)
  )
    return null;

  return dischargeStatusNotificationTypeMap[
    dischargeStatus as DischargeMessageStatusType
  ];
};
