import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const SubheadingWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;

  .icon-wrapper {
    display: flex;
  }

  @media ${device.desktop} {
    display: flex;
    justify-content: flex-start;
    margin-bottom: ${spacing.s10};

    span {
      margin-left: ${spacing.s2};
      color: ${colors.grey.grey08};
    }
  }
`;

/* @deprecated use SubHeading from the ui-toolkit */
export const Subheading = styled.h2`
  @media ${device.desktop} {
    font-size: ${fontSizes.h4};
    line-height: 30px;
    font-weight: 500;
    margin-right: ${spacing.s6};
    color: ${colors.primaryIndigo.indigo08};
  }
`;
