import type { NamedSet } from 'zustand/middleware';

import type { BedAllocationFormData } from 'components/BedAllocation/types';
import type { BedDischargeFormData } from 'components/BedDischarge/types';
import type { BedTransferFormData } from 'components/BedTransfer/types';
import type { PatientAdmissionFormData } from 'components/PatientAdmission/types';
import type { AppState } from './types';

export const formSlice = (set: NamedSet<AppState>) => ({
  bedDischargeFormData: {} as BedDischargeFormData,
  patientAdmissionFormData: {} as PatientAdmissionFormData,
  setBedDischargeForm: (bedDischargeFormData: BedDischargeFormData) =>
    set({ bedDischargeFormData }, false, 'form/setBedDischargeForm'),
  resetBedDischargeFormData: () =>
    set(
      { bedDischargeFormData: {} as BedDischargeFormData },
      false,
      'form/resetBedDischargeFormData'
    ),
  bedAllocationFormData: {} as BedAllocationFormData,
  setBedAllocationForm: (bedAllocationFormData: BedAllocationFormData) =>
    set({ bedAllocationFormData }, false, 'form/setBedAllocationForm'),
  resetBedAllocationFormData: () =>
    set(
      { bedAllocationFormData: {} as BedAllocationFormData },
      false,
      'form/resetBedAllocationFormData'
    ),
  setPatientAdmissionForm: (
    patientAdmissionFormData: PatientAdmissionFormData
  ) => set({ patientAdmissionFormData }, false, 'form/setPatientAdmissionForm'),
  resetPatientAdmissionFormData: () =>
    set(
      { patientAdmissionFormData: {} as PatientAdmissionFormData },
      false,
      'form/resetPatientAdmissionFormData'
    ),
  bedTransferFormData: {} as BedTransferFormData,
  setBedTransferForm: (bedTransferFormData: BedTransferFormData) =>
    set({ bedTransferFormData }, false, 'form/setBedTransferForm'),
  resetBedTransferFormData: () =>
    set(
      { bedTransferFormData: {} as BedTransferFormData },
      false,
      'form/resetBedTransferFormData'
    ),
});
