import {
  Button,
  Col,
  Grid,
  Loader,
  Row,
  SubHeader,
} from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { BedTransferListView } from 'components/BedTransfer/BedTransferListView/BedTransferListView';
import { PageNotification } from 'components/PageNotification';
import { PatientSearch } from 'components/PatientSearch';
import { useClearErrors } from 'hooks/useClearErrors';
import { useStore } from 'store';
import type { WardBedTransferQuery } from 'models';
import { useWardBedTransferSort } from 'hooks/useWardBedTransferSort';
import { useTracking } from 'hooks/useTracking';
import { ROUTES } from 'App.constants';

export const BedTransfer = () => {
  const { trackPage } = useTracking();

  const [
    setMenuHeader,
    setMenuItem,
    resetForm,
    errorMessage,
    resetBedTransfer,
    { careSettingId },
  ] = useStore(
    (state) => [
      state.setMenuHeader,
      state.setMenuItem,
      state.resetBedTransferFormData,
      state.errorMessage,
      state.resetBedTransfer,
      state.appConfig,
    ],
    shallow
  );
  const { loading, sortedWardBedTransfers, error } = useWardBedTransferSort(
    careSettingId,
    true
  );

  const [filter, setFilter] = useState<WardBedTransferQuery>();
  useClearErrors();

  const navigate = useNavigate();

  useEffect(() => {
    setMenuHeader('Bed Transfer');
    setMenuItem('Bed Transfer');
    resetForm();
    resetBedTransfer();
  }, []);

  useEffect(() => {
    trackPage(`Bed Transfer`);
  }, []);

  const handleClick = () => navigate(ROUTES.BED_TRANSFER_MANAGE);

  if (loading) return <Loader />;

  return (
    <Grid>
      <Row>
        <Col xs={6} alignSelf="center" display={{ xs: 'none', xl: 'block' }}>
          <SubHeader color="indigo08">Bed transfer</SubHeader>
        </Col>
      </Row>
      {error ? (
        <Row>
          <Col from={1}>
            <PageNotification
              message={errorMessage('displaying bed transfers')}
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col lg={4} xs={12} alignSelf="center">
              <PatientSearch
                data={sortedWardBedTransfers}
                setFilter={setFilter}
                placeholder="Search patients"
                searchType="WardBedTransferQuery"
              />
            </Col>
            <Col
              lg={8}
              xs={12}
              alignSelf="center"
              justifyContent={{ lg: 'right' }}
              order={{ xs: '-1', lg: '1' }}
            >
              <Button id="start-bed-transfer" onClick={handleClick}>
                Start transfer
              </Button>
            </Col>
          </Row>
          <Row>
            <Col from={1}>
              {sortedWardBedTransfers.length === 0 ? (
                <PageNotification
                  type="informative"
                  message="No patients are awaiting bed transfer."
                />
              ) : (
                <BedTransferListView data={filter ?? sortedWardBedTransfers} />
              )}
            </Col>
          </Row>
        </>
      )}
    </Grid>
  );
};
