import { Carousel } from '@bt-healthcare/ui-toolkit';

import { WardGroupCard } from 'components/Card/WardGroupCard';
import { GroupWrapper } from './styles';
import type { WardGroupCarouselProps } from './types';

export const WardGroupCarousel = ({
  id,
  data,
  setSelectedWardGroupId,
  selectedWardGroupId,
}: WardGroupCarouselProps) => (
  <Carousel id={id}>
    {data.map((item, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <GroupWrapper key={idx}>
        {item.map((group) => (
          <WardGroupCard
            {...group}
            id={`card-${group.name}`}
            key={group.id}
            setSelectedWardGroupId={setSelectedWardGroupId}
            selectedWardGroupId={selectedWardGroupId}
          />
        ))}
      </GroupWrapper>
    ))}
  </Carousel>
);
