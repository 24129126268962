import type { ListViewRow } from '@bt-healthcare/ui-toolkit';
import { ListView } from '@bt-healthcare/ui-toolkit';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { isEmpty } from 'ramda';

import { useStore } from 'store';
import type { ExtendedWardBed, ListViewQueryProps } from 'types';
import { bedAllocationDisabled } from 'validation/rules';
import { sortByRagStatus } from 'sorting';
import { BedSelectionModal } from 'components/Modal';
import { useAuthUser } from 'auth/useAuthUser';
import { RBACAction, useHasAccess } from 'auth/useHasAccess';
import { ROUTES } from 'App.constants';
import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';
import {
  columnWidthSizes,
  headerData,
  rowData,
} from './BedAllocationListViewConfig';

export const BedAllocationListView = ({
  data,
}: ListViewQueryProps<ExtendedWardBed[]>) => {
  const wardId = useRedirectAndReturnState('wardId');
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [setBed, bed] = useStore((state) => [state.setBed, state.bed], shallow);
  const { auth } = useAuthUser();
  const bedManagerAccess = useHasAccess(
    [RBACAction.BED_ALLOCATION_BED_CLOSURE_UPDATE],
    auth.userRoles
  );

  const handleClose = () => setModalOpen(false);
  const handleRowClick = ({ id }: ListViewRow) => {
    setModalOpen(true);
    const selectedWardBed = data?.find((item) => item.id === id);
    if (selectedWardBed) setBed(selectedWardBed);
  };

  const handleClick = () => {
    handleClose();
    navigate(ROUTES.WARDS_BED_ALLOCATION_MANAGE, { state: { wardId } });
  };

  return (
    <>
      {!isEmpty(bed) && (
        <BedSelectionModal
          data={bed}
          modalOpen={modalOpen}
          handleClose={handleClose}
          handleClick={handleClick}
          ragType="BedStatus"
          disabled={bedAllocationDisabled(bed, bedManagerAccess)}
        />
      )}
      {data.length > 0 && (
        <ListView
          headerData={headerData}
          rowData={rowData(sortByRagStatus(data))}
          id="bed-allocation"
          onRowClick={handleRowClick}
          columnWidths={columnWidthSizes}
        />
      )}
    </>
  );
};
