import { BTHealthIcon, colors } from '@bt-healthcare/ui-toolkit';
import { GridToggleWrapper } from './styles';
import type { GridToggleProps } from './types';

export const GridToggle = ({ onViewChange, isList, id }: GridToggleProps) => (
  <GridToggleWrapper
    id={id}
    data-testid={id}
    onClick={() => onViewChange(!isList)}
  >
    {!isList ? (
      <>
        <BTHealthIcon icon="Menu" color={colors.grey.grey08} size={20} />
        <span>Show list</span>
      </>
    ) : (
      <>
        <BTHealthIcon icon="Grid" color={colors.grey.grey08} size={16} />
        <span>Show grid</span>
      </>
    )}
  </GridToggleWrapper>
);
