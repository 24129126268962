import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { StatusFormConfirmation } from 'components/Form/StatusFormConfirmation';
import { patientAdmissionMapping } from 'mappings/datalists';
import { useStore } from 'store';
import { patientAdmissionFormMapping } from 'mappings/forms/patientAdmission';
import { ConfirmationIllustration } from 'assets';
import { ROUTES } from 'App.constants';

export const PatientAdmissionFormConfirmation = () => {
  const [formData] = useStore(
    (state) => [state.patientAdmissionFormData],
    shallow
  );

  const data = patientAdmissionFormMapping(formData);
  const navigate = useNavigate();

  const handleClick = () => navigate(ROUTES.ADMISSIONS_HOME);

  return (
    <StatusFormConfirmation
      strapLine="Your patient was successfully admitted. Find your admission summary below."
      data={patientAdmissionMapping(data)}
      handleClick={handleClick}
      buttonText="View decision to admit"
      icon={<ConfirmationIllustration />}
    />
  );
};
