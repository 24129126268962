import { colors, fontSizes, device } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const GridToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none; /* Safari */

  span {
    margin-left: 12px;
    color: ${colors.grey.grey10};
    font-size: ${fontSizes.sm};

    @media ${device.desktop} {
      font-size: ${fontSizes.base};
    }
  }
`;
