import { Text, colors, toHyphenCase, Wrapper } from '@bt-healthcare/ui-toolkit';

import { AdditionalDetailsWrapper } from '../styles';
import type { BedTransferConfirmationAdditionDetailsProps } from '../types';

export const BedTransferConfirmationAdditionDetails = ({
  data,
}: BedTransferConfirmationAdditionDetailsProps) => (
  <AdditionalDetailsWrapper>
    {data.map((item) => (
      <Wrapper key={item.key}>
        <Text
          fontWeight={500}
          color={colors.grey.grey10}
          data-testid={`bed-transfer-confirmation-${toHyphenCase(
            item.key
          )}-text`}
        >
          {item.key}:
        </Text>
        <Text as="div" color={colors.grey.grey10}>
          {item.value}
        </Text>
      </Wrapper>
    ))}
  </AdditionalDetailsWrapper>
);
