import { colors, Text } from '@bt-healthcare/ui-toolkit';

import type { BedTransferAccordionTitleProps } from '../types';

export const BedTransferAccordionTitle = ({
  wardName,
  wardBedReference,
  colour,
  action = 'From',
}: BedTransferAccordionTitleProps) => (
  <Text fontWeight={500} color={colors.grey.grey10}>
    {action}: &nbsp;
    <Text fontWeight={500} color={colour}>
      {wardName}, Bed {wardBedReference}
    </Text>
  </Text>
);
