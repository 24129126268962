import { spacing, fontSizes, device } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import type { ContentWrapperProps, DataListItemProps } from './types';

// Likely to be extended for mobile/desktop configuration
const getColumns = (columns: any) => columns;

export const ContentWrapper = styled.div<ContentWrapperProps>`
  padding: ${spacing.s2} ${spacing.none};
  margin: auto;

  @media ${device.desktop} {
    display: ${({ columns }) => columns && 'grid'};
    grid-template-columns: ${({ columns }) =>
      columns && `repeat(${getColumns(columns)}, 1fr)`};
    grid-column-gap: ${({ columns }) => columns && spacing.s9};
    padding: ${spacing.none};
  }
`;

export const DataListItem = styled.p<DataListItemProps>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${({ itemGap }) => (itemGap && itemGap) || spacing.s8};
  font-size: ${fontSizes.sm};
  margin-bottom: ${spacing.s4};

  span:first-child {
    font-weight: 500;
  }

  &:last-child {
    margin-bottom: ${spacing.none};
  }

  @media ${device.desktop} {
    font-size: ${fontSizes.base};
  }
`;
