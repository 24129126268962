import { ListView } from '@bt-healthcare/ui-toolkit';
import { PageNotification } from 'components/PageNotification';
import type { WardBedTransferQuery } from 'models';

import type { ListViewQueryProps } from 'types';
import { headerData, rowData } from './BedTransferListConfig';

export const BedTransferListView = ({
  data,
}: ListViewQueryProps<WardBedTransferQuery>) =>
  data.length === 0 ? (
    <PageNotification
      type="informative"
      message="Search returned no results. Please try again."
    />
  ) : (
    <ListView
      headerData={headerData}
      rowData={rowData(data)}
      id="ward-bed-transfer"
    />
  );
