import { Card, CardHeader } from '@bt-healthcare/ui-toolkit';

import { BedCount } from 'components/BedCount';
import {
  CardWrapper,
  StatisticsWrapper,
  Stat,
  FlexContainer,
  CardHeaderWrapper,
  ClearButton,
} from './styles';
import type { WardGroupCardProps } from './types';

export const WardGroupCard = ({
  id,
  name,
  capacity,
  statistics,
  wardGroupId,
  setSelectedWardGroupId,
  selectedWardGroupId,
}: WardGroupCardProps) => {
  const selected = selectedWardGroupId === wardGroupId;
  const handleClick = () => {
    setSelectedWardGroupId?.(selected ? '' : wardGroupId);
  };

  return (
    <CardWrapper onClick={handleClick} selected={selected}>
      <Card id={id}>
        <FlexContainer>
          <CardHeaderWrapper>
            <CardHeader hasTextOverflow={false}>{name}</CardHeader>
            <BedCount capacity={capacity} />
          </CardHeaderWrapper>
          {selected && <ClearButton>Clear</ClearButton>}
        </FlexContainer>
        <StatisticsWrapper>
          {statistics.map((stats) => (
            <Stat key={stats.name}>
              <span>{stats.name}</span>
              <span className="stat-total">{stats.value}</span>
            </Stat>
          ))}
        </StatisticsWrapper>
      </Card>
    </CardWrapper>
  );
};
