import type { NotificationType } from '@bt-healthcare/ui-toolkit';
import {
  Card,
  Checkbox,
  colors,
  Text,
  Notification,
} from '@bt-healthcare/ui-toolkit';

import { getPatientName } from 'mappings/mapping.utils';
import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';
import { BedSelectionNotificationMessageRenderer } from './BedSelectionNotificationMessageRenderer';
import { getNotificationType } from './bedSelectionNotificationTypes.utils';
import { CheckboxWrapper } from './styles';
import type { BedSelectionAlertsProps } from './types';

export const BedSelectionAlert = ({
  needsConfirmation,
  ragType,
  dischargeStatus,
  bedTransferStatus,
  data,
  handleConfirmation,
}: BedSelectionAlertsProps) => {
  const wardId = useRedirectAndReturnState('wardId');
  const bedTransferFromWardId =
    data?.wardBedTransfers?.[0]?.attributes?.fromWardId ?? null;
  const { bedStatus } = data.attributes;

  const notificationType = getNotificationType(
    ragType,
    dischargeStatus,
    bedStatus,
    bedTransferStatus,
    data
  ) as NotificationType;

  return (
    <>
      {notificationType ? (
        <Notification
          type={notificationType}
          alignSelf="baseline"
          icon={bedStatus === 'CLEANING' ? 'Cleaning' : undefined}
        >
          <BedSelectionNotificationMessageRenderer
            data={data}
            bedStatus={bedStatus}
            bedTransferStatus={bedTransferStatus}
            ragType={ragType}
            dischargeStatus={dischargeStatus}
          />
        </Notification>
      ) : null}

      {needsConfirmation &&
        (wardId !== bedTransferFromWardId ||
          bedTransferStatus === 'COMPLETE') && (
          <Card
            id="confirmation-warning"
            background={colors.notifications.light.successLight}
          >
            <CheckboxWrapper>
              <Checkbox
                onChange={handleConfirmation}
                variant="secondary"
                label={
                  <>
                    Tick to confirm patient&nbsp;
                    <Text color={colors.grey.grey10} fontWeight={500}>
                      {getPatientName(data.wardPatient?.careSettingPatient)}
                    </Text>{' '}
                    has arrived to the ward.
                  </>
                }
                id="patient-confirmation"
              />
            </CheckboxWrapper>
          </Card>
        )}
    </>
  );
};
