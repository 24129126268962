import type { UseFormResetField } from 'react-hook-form';

import type { BedDischargeFormData } from 'components/BedDischarge/types';
import type { WardBed } from 'services';
import { convertDateStringForFormInput } from 'mappings/date.utils';

export const resetNotFitFormFields = (
  resetField: UseFormResetField<BedDischargeFormData>,
  isCurrent: boolean,
  wardBed: WardBed
) => {
  resetField('dischargeExpectedDate', {
    defaultValue: isCurrent
      ? convertDateStringForFormInput(
          wardBed.wardPatient?.wardDischarge?.attributes.client
            ?.dischargeExpectedDate
        )
      : '',
  });
  resetField('dischargeExpectedTime', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes.client
          ?.dischargeExpectedTime
      : '',
  });
  resetField('socialCareYN', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes.socialCareYN ?? ''
      : '',
  });
};
