import { Notification, Text, colors } from '@bt-healthcare/ui-toolkit';

import { BedCleaningAlertTextWrapper } from './BedCleaningAlert.styles';
import type { BedCleaningAlertProps } from './types';

export const BedCleaningAlert = ({ journey }: BedCleaningAlertProps) => (
  <Notification type="softWarning" alignSelf="baseline">
    <BedCleaningAlertTextWrapper>
      <Text fontWeight={500} color={colors.grey.grey10}>
        {journey === 'discharge'
          ? 'Bed status will be updated to in cleaning.'
          : 'Bed status will be updated to in cleaning as soon as the transfer is completed.'}{' '}
      </Text>
      Once the clean is complete, you can update the bed status to unallocated.
    </BedCleaningAlertTextWrapper>
  </Notification>
);
