import {
  Accordion,
  AccordionItem,
  Card,
  colors,
  Divider,
} from '@bt-healthcare/ui-toolkit';
import { shallow } from 'zustand/shallow';

import { DataListCard } from 'components/Card/DataListCard/DataListCard';
import { useStore } from 'store';
import { bedTransferConfirmationMapping } from 'mappings/datalists/ward/bedTransfer';
import { BedTransferAccordionTitle } from './BedTransferAccordionTitle';
import { BedTransferConfirmationAdditionDetails } from './BedTransferConfirmationAdditionalDetails';

export const BedTransferConfirmation = () => {
  const [formData, bedToTransfer, bedFromTransfer] = useStore(
    (state) => [
      state.bedTransferFormData,
      state.bedToTransfer,
      state.bedFromTransfer,
    ],
    shallow
  );
  const BED_KEY = 'Bed';
  const WARD_KEY = 'Ward';
  const { toWard, fromBed, toBed, fromWard } = formData;
  return (
    <Card id="bed-transfer-card">
      <Accordion id="bed-transfer">
        <AccordionItem
          title={
            <BedTransferAccordionTitle
              colour={colors.primaryIndigo.indigo08}
              wardName={fromWard!.label}
              wardBedReference={fromBed!.label}
            />
          }
          id="bed-transfer-from-ward"
        >
          <DataListCard
            columns="2"
            background={colors.primaryIndigo.indigo01}
            data={bedFromTransfer.filter(
              (item) => item.key !== BED_KEY && item.key !== WARD_KEY
            )}
          />
        </AccordionItem>
        <AccordionItem
          title={
            <BedTransferAccordionTitle
              colour={colors.secondaryBlue.blue07}
              wardName={toWard?.label ?? fromWard!.label}
              wardBedReference={toBed!.label}
              action="To"
            />
          }
          id="bed-transfer-to-ward"
        >
          <DataListCard
            columns="2"
            background={colors.secondaryBlue.blue01}
            data={bedToTransfer.filter(
              (item) => item.key !== BED_KEY && item.key !== WARD_KEY
            )}
          />
        </AccordionItem>
      </Accordion>
      <Divider marginPadding="s8" />
      <BedTransferConfirmationAdditionDetails
        data={bedTransferConfirmationMapping(formData)}
      />
    </Card>
  );
};
