import { BTHealthIcon, colors } from '@bt-healthcare/ui-toolkit';
import { IconWrapper } from './styles';
import type { BedCountProps } from './types';

export const BedCount = ({ capacity }: BedCountProps) => (
  <IconWrapper>
    <BTHealthIcon icon="Bed" color={colors.secondaryBlue.blue06} />
    <span>{capacity}</span>
  </IconWrapper>
);
