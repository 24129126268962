import { device } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const BedCleaningAlertTextWrapper = styled.div`
  span {
    display: block;
  }

  @media ${device.desktop} {
    span {
      display: inline;
    }
  }
`;
