import { Tooltip } from '@bt-healthcare/ui-toolkit';

import { EMPTY_VALUE } from 'App.constants';
import { DischargeDateTimeMap } from 'mappings/tooltips';
import {
  getDischargeDateTime,
  getDischargeDateTimeType,
} from '../BedDischarge/BedDischargeListView/getDischargeDateTime.util';
import type { DischargeDateTimeTooltipProps } from './types';

export const DischargeDateTimeTooltip = ({
  data,
  dateTimeType,
}: DischargeDateTimeTooltipProps) => {
  const dischargeDateTime = getDischargeDateTime(
    dateTimeType,
    data.wardPatient?.wardDischarge?.attributes!
  );
  const dischargeDateTimeType = getDischargeDateTimeType(
    data.wardPatient?.wardDischarge?.attributes!
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (dischargeDateTime === EMPTY_VALUE) return <>{dischargeDateTime}</>;

  return (
    <Tooltip
      content={`${
        DischargeDateTimeMap[dischargeDateTimeType!]
      } ${dateTimeType}`}
      id={`tooltip-${data.id}`}
      direction="top-start"
      zIndex={0}
    >
      {dischargeDateTime}
    </Tooltip>
  );
};
