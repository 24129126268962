import { Input } from '@bt-healthcare/ui-toolkit';

import { matchesEnumValue, matchesOption } from 'validation/rules';
import { bedStatusClosedOptions } from 'components/Form/formOptions';
import { RHFDatePicker } from 'components/Form/RHFDatePicker';
import type { ReasonForBedClosure } from 'services';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import type { BedStatusClosedProps } from '../types';

export const BedStatusClosed = ({
  register,
  control,
}: BedStatusClosedProps) => (
  <ConditionalFieldRenderer
    control={control}
    fieldName="bedStatus"
    condition={(arg) => matchesEnumValue(arg, 'CLOSED')}
  >
    <RHFDropDown
      label="Reason"
      fieldName="reasonForBedClosure"
      control={control}
      options={bedStatusClosedOptions}
    />
    <ConditionalFieldRenderer
      control={control}
      fieldName="reasonForBedClosure"
      condition={(arg) => matchesOption<ReasonForBedClosure>(arg, 'OTHER')}
    >
      <Input
        id="otherReasonForBedClosure"
        label="Other reason"
        {...register('otherReasonForBedClosure')}
      />
    </ConditionalFieldRenderer>
    <RHFDatePicker
      control={control}
      fieldName="bedStatusDate"
      timeFieldName="bedStatusTime"
      label="Date and time"
      minDate={new Date()}
    />
  </ConditionalFieldRenderer>
);
