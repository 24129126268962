import { isEmpty } from 'ramda';

import type { ModuleRole } from 'services';

/** Define all actions required on the app */
export enum RBACAction {
  ADMIT_PATIENT,
  TRANSFER_BED,
  BED_ALLOCATION_BED_CLOSURE_UPDATE,
}

/** Only define actions assigned to specific roles  */
export const RBACConfig: Partial<Record<ModuleRole, RBACAction[]>> = {
  BED_OPTIMISER_BED_MANAGER: [RBACAction.BED_ALLOCATION_BED_CLOSURE_UPDATE],
  BED_OPTIMISER_SUPER_USER: [
    RBACAction.ADMIT_PATIENT,
    RBACAction.TRANSFER_BED,
    RBACAction.BED_ALLOCATION_BED_CLOSURE_UPDATE,
  ],
};

export const useHasAccess = (
  actions: RBACAction[],
  userRoles?: ModuleRole[]
) => {
  if (!userRoles || isEmpty(userRoles)) return false;
  const userAccess = userRoles.some((role) => {
    const allowedActions = RBACConfig[role];
    if (!allowedActions) return false;

    return actions.every((action) => allowedActions.includes(action));
  });

  return userAccess;
};
