import { Card, Text, colors } from '@bt-healthcare/ui-toolkit';

import { ContentWrapper, DataListItem } from './DataListCard.styles';
import { DataListCardLabel } from './DataListCardLabel';
import type { DataListCardProps } from './types';

export const DataListCard = ({
  wardName,
  data,
  background = colors.base.white,
  columns,
  itemGap,
  cardType = 'default',
}: DataListCardProps) => (
  <Card id="data-list" background={background}>
    <ContentWrapper columns={columns}>
      {wardName && (
        <DataListItem>
          <Text color={colors.grey.grey10}>Ward:</Text>
          <span>{wardName}</span>
        </DataListItem>
      )}
      {data.map((item) => (
        <DataListItem key={item.key} itemGap={itemGap}>
          <Text color={colors.grey.grey10}>{item.key}:</Text>
          <span>
            <DataListCardLabel
              cardType={cardType}
              value={item.value}
              cardKey={item.key}
            />
          </span>
        </DataListItem>
      ))}
    </ContentWrapper>
  </Card>
);
