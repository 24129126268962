import type {
  CareSettingPatient,
  DischargeStatusType,
  WardBed,
} from 'services';
import type { RagType } from 'types';
import { bedAllocatedWithNoDischarge } from 'validation/rules';
import {
  patientAdmissionDefaultMapping,
  patientAdmissionGenderMapping,
} from './patient/patientAdmission';
import {
  bedAllocationClosedConfirmationMapping,
  bedAllocationClosedMapping,
  bedAllocationConfirmationMapping,
  bedAllocationMapping,
  bedAllocationUnallocatedConfirmationMapping,
  bedAllocationUnallocatedMapping,
  bedPreAllocationMapping,
  bedCleaningMapping,
  bedAllocationInCleaningConfirmationMapping,
} from './ward/bedAllocation';
import {
  bedDischargeConfirmWardTransferSpecialitySelectionMapping,
  bedTransferSelectionMapping,
  bedDischargeConfirmationMapping,
  bedDischargeFailedConfirmationMapping,
  bedDischargeNotFitConfirmationMapping,
  bedDischargeConfirmedSelectionMapping,
  bedDischargePotentialWithoutPharmacySelectionMapping,
  bedDischargePotentialSelectionMapping,
  bedDischargeDelayedSelectionMapping,
  bedDischargeAllocatedSelectionMapping,
  bedDischargeSelectionMapping,
  bedDischargeClinicallyReadyConfirmationMapping,
  bedDischargeClinicallyReadySelectionMapping,
  bedDischargeNotFitSelectionMapping,
  bedDischargePotentialWithoutPharmacyConfirmationMapping,
  bedDischargePotentialConfirmationMapping,
  bedDischargeClinicallyReadyWithoutD2AConfirmationMapping,
  bedDischargeCompletedConfirmationMapping,
} from './ward/bedDischarge';

// TODO: simplify additional gender prefs as to not duplicate these mappings
export const bedSelectionMapping = (
  bed: WardBed,
  ragType: RagType,
  dischargeStatus: DischargeStatusType,
  bedTransferInProgress?: boolean,
  hasFlag?: boolean
) => {
  // TODO: TOBE removed when feature flag is disabled
  const data = hasFlag ? { ...bed, hasFlag } : bed;

  const interWardTransferReason =
    data.wardBedTransfers?.[0]?.attributes?.interWardTransferReason;

  if (
    bedTransferInProgress &&
    interWardTransferReason === 'PATIENT_CARE_NEEDS_CHANGED'
  ) {
    return bedDischargeConfirmWardTransferSpecialitySelectionMapping(data);
  }

  if (bedTransferInProgress) {
    return bedTransferSelectionMapping(data);
  }

  if (data.attributes.bedStatus === 'CLEANING') return bedCleaningMapping(data);

  if (ragType === 'DischargeStatus') {
    if (data.attributes.bedStatus === 'UNALLOCATED')
      return bedAllocationUnallocatedMapping(data);
    if (data.attributes.bedStatus === 'CLOSED')
      return bedAllocationClosedMapping(data);
    if (data.attributes.bedStatus === 'PREALLOCATED')
      return bedDischargeSelectionMapping(data);

    switch (dischargeStatus) {
      case 'CONFIRMED':
        return bedDischargeConfirmedSelectionMapping(data);
      case 'POTENTIAL':
        if (
          data.wardPatient?.wardDischarge?.attributes.toTakeOutStatus !==
          'COMPLETE'
        )
          return bedDischargePotentialWithoutPharmacySelectionMapping(data);
        return bedDischargePotentialSelectionMapping(data);
      case 'DELAYED':
        return bedDischargeDelayedSelectionMapping(data);
      case 'CLINICALLY_READY':
        if (
          data.wardPatient?.wardDischarge?.attributes
            .requireDischargeToAssess !== 'YES'
        )
          return bedDischargeClinicallyReadyWithoutD2AConfirmationMapping(data);
        return bedDischargeClinicallyReadySelectionMapping(data);
      case 'NOT_FIT':
        return bedDischargeNotFitSelectionMapping(data);
      default:
        if (
          bedAllocatedWithNoDischarge(
            data.attributes.bedStatus,
            data.wardPatient?.wardDischarge?.attributes.dischargeStatus
          )
        ) {
          return bedDischargeAllocatedSelectionMapping(data);
        }
        return bedDischargeSelectionMapping(data);
    }
  }
  switch (data.attributes.bedStatus) {
    case 'UNALLOCATED':
      return bedAllocationUnallocatedMapping(data);
    case 'CLOSED':
      return bedAllocationClosedMapping(data);
    case 'PREALLOCATED':
      return bedPreAllocationMapping(data);
    default:
      return bedAllocationMapping(data);
  }
};

export const patientAdmissionMapping = (data: CareSettingPatient) => {
  const patientGender =
    data.person.attributes.personalInformation.genderIdentifiedAs;

  if (patientGender === 'NON_BINARY' || patientGender === 'PREFER_NONE')
    return patientAdmissionGenderMapping(data);

  return patientAdmissionDefaultMapping(data);
};

export const bedConfirmationMapping = (
  bed: WardBed,
  ragType: RagType,
  hasFlag?: boolean
) => {
  // TODO: TOBE removed when feature flag is disabled
  const data = hasFlag ? { ...bed, hasFlag } : bed;

  if (ragType === 'DischargeStatus') {
    switch (data.wardPatient?.wardDischarge?.attributes.dischargeStatus) {
      case 'COMPLETED':
        return bedDischargeCompletedConfirmationMapping(data);
      case 'DELAYED':
        return bedDischargeFailedConfirmationMapping(data);
      case 'NOT_FIT':
        return bedDischargeNotFitConfirmationMapping(data);
      case 'CLINICALLY_READY':
        if (
          data.wardPatient.wardDischarge.attributes.requireDischargeToAssess !==
          'YES'
        )
          return bedDischargeClinicallyReadyWithoutD2AConfirmationMapping(data);

        return bedDischargeClinicallyReadyConfirmationMapping(data);
      case 'POTENTIAL':
        if (
          data.wardPatient.wardDischarge.attributes.toTakeOutStatus !==
          'COMPLETE'
        )
          return bedDischargePotentialWithoutPharmacyConfirmationMapping(data);
        return bedDischargePotentialConfirmationMapping(data);
      default:
        return bedDischargeConfirmationMapping(data);
    }
  }
  switch (data.attributes.bedStatus) {
    case 'CLOSED':
      return bedAllocationClosedConfirmationMapping(data);
    case 'UNALLOCATED':
      return bedAllocationUnallocatedConfirmationMapping(data);
    case 'CLEANING':
      return bedAllocationInCleaningConfirmationMapping(data);
    default:
      return bedAllocationConfirmationMapping(data);
  }
};
