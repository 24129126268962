import { fontSizes, colors, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import type { ModalHeadlineProps } from './types';

export const ModalHeadlineWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const ModalHeadline = styled.span<ModalHeadlineProps>`
  font-size: ${fontSizes.h4};
  font-weight: 500;
  line-height: 30px;
  color: ${colors.primaryIndigo.indigo08};
  padding: ${({ hasRagStatus }) =>
    !hasRagStatus && `${spacing.none} ${spacing.s3}`};

  @media ${device.tablet} {
    font-size: ${fontSizes.h3};
    line-height: 54px;
    padding: ${({ hasRagStatus }) =>
      !hasRagStatus && `${spacing.none} ${spacing.s6}`};
  }
`;

// todo: align with Mark re updating this in the toolkit
export const SubHeader = styled.h3`
  color: ${colors.grey.grey10};
  font-weight: 500;
  font-size: ${fontSizes.h4};
  text-align: center;
  margin-bottom: ${spacing.s3};

  @media ${device.desktop} {
    font-size: ${fontSizes.h3};
    margin-bottom: ${spacing.s4};
  }
`;

export const Paragraph = styled.p`
  font-size: ${fontSizes.sm};
  color: ${colors.grey.grey09};
  text-align: center;
  margin: ${spacing.s4} auto ${spacing.s6} auto;

  @media ${device.desktop} {
    font-size: ${fontSizes.base};
    margin-bottom: ${spacing.s8};
  }
`;

export const Strapline = styled(Paragraph)`
  margin: ${spacing.none} ${spacing.none} ${spacing.s4} ${spacing.none};
`;

export const CardWrapper = styled.div`
  overflow-y: auto;
  border-radius: ${spacing.s4};

  @media ${device.desktop} {
    margin: auto;
    width: 90%;
  }
`;

export const IconWrapper = styled.div`
  text-align: center;
  padding-bottom: ${spacing.s2};
  svg {
    max-width: 205px;
    height: auto;
    @media ${device.desktop} {
      max-width: 254px;
    }
  }
`;
