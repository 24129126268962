import type { UseFormResetField } from 'react-hook-form';

import type { BedDischargeFormData } from 'components/BedDischarge/types';

export const resetCompletedFormFields = (
  resetField: UseFormResetField<BedDischargeFormData>
) => {
  resetField('dischargeAssessmentOutcome', { defaultValue: null });
  resetField('requireDischargeToAssess', {
    defaultValue: '',
  });
  resetField('dischargeActualDate', { defaultValue: '' });
  resetField('dischargeActualTime', { defaultValue: '' });
  resetField('bedCleaningStatus', { defaultValue: '' });
  resetField('bedCleaningMethod', { defaultValue: null });
};
