import { Button } from '@bt-healthcare/ui-toolkit';
import { FormFooterWrapper, ButtonWrapper } from './styles';
import type { FormFooterProps } from './types';

export const FormFooter = ({
  id,
  handleClick,
  primaryText = 'Continue',
  isValid = false,
}: FormFooterProps) => (
  <FormFooterWrapper id="sticky-footer">
    <ButtonWrapper>
      <Button id={id} type="button" onClick={handleClick} disabled={!isValid}>
        {primaryText}
      </Button>
    </ButtonWrapper>
  </FormFooterWrapper>
);
