import { pick } from 'ramda';
import { EMPTY_VALUE } from 'App.constants';

import type { WardBed } from 'services';
import { getDate } from 'mappings/date.utils';
import {
  AwaitingDischargeAssessMap,
  BedStatusClosedMap,
  BedStatusMap,
  CarePackageMap,
  DischargePathwayReasonMap,
  DischargeStatusMap,
  FailedDischargeStatusMap,
  GenderIdentifiedAsMap,
  InterWardTransferReasonMap,
  IntraWardTransferReasonMap,
  PharmacyStatusMap,
  PorteringMap,
  TTOStatusMap,
  WaitingForDischargeStatusMap,
  YesNoMap,
} from 'mappings/enums';
import {
  getBedCleaningMappingOptions,
  getGenderPrefMappingOptions,
  getPatientIdentifierMappingOptions,
  getPatientName,
  getPotentialDischargeWaitingReason,
  getSpecialty,
  getWardDischargeIsolationReason,
  getWardPatientIsolationReason,
  isBedTransferInProgress,
} from 'mappings/mapping.utils';
import type { ExtendedWardBed } from 'types';
import { getEnumMapOption, getEnumMapOptions } from 'mappings/enums/enum.utils';

export const bedMapping = (data: WardBed) => ({
  bedStatus: {
    key: 'Bed status',
    value: BedStatusMap[data.attributes.bedStatus],
  },
  bedStatusClosedReason: {
    key: 'Reason',
    value: getEnumMapOption(
      BedStatusClosedMap,
      data.attributes.reasonForBedClosure,
      data.attributes.otherReasonForBedClosure
    ),
  },
  bedDischargeFailedReason: {
    key: 'Reason',
    value: getEnumMapOptions(
      FailedDischargeStatusMap,
      data.wardPatient?.wardDischarge?.attributes.dischargeStatusFailedReasons,
      data.wardPatient?.wardDischarge?.attributes
        .dischargeStatusFailedReasonOther
    ),
  },
  patientName: {
    key: 'Patient',
    value: getPatientName(data.wardPatient?.careSettingPatient),
  },
  dateOfBirth: {
    key: 'D.O.B',
    value: getDate(
      data.wardPatient?.careSettingPatient?.person?.attributes
        ?.personalInformation?.dateOfBirth,
      'dd/MM/yyyy'
    ),
  },
  gender: {
    key: 'Gender',
    value: data.wardPatient?.careSettingPatient?.person?.attributes
      .personalInformation.genderIdentifiedAs
      ? GenderIdentifiedAsMap[
          data.wardPatient.careSettingPatient.person.attributes
            .personalInformation.genderIdentifiedAs
        ]
      : EMPTY_VALUE,
  },
  bed: {
    key: 'Bed',
    value: data.attributes.wardBedReference,
  },
  expectedArrivalDate: {
    key: 'Exp. date of arrival',
    value: isBedTransferInProgress(data.wardBedTransfers?.[0])
      ? data.wardBedTransfers?.[0]?.attributes.client?.arrivalToWardDate ??
        EMPTY_VALUE
      : data.wardPatient?.attributes?.client?.arrivalExpectedDate ??
        EMPTY_VALUE,
  },
  expectedArrivalTime: {
    key: 'Exp. time of arrival',
    value: isBedTransferInProgress(data.wardBedTransfers?.[0])
      ? data.wardBedTransfers?.[0]?.attributes.client?.arrivalToWardTime ??
        EMPTY_VALUE
      : data.wardPatient?.attributes?.client?.arrivalExpectedTime ??
        EMPTY_VALUE,
  },
  bedStatusDate: {
    key: 'Date',
    value: data.attributes.client?.bedStatusDate ?? EMPTY_VALUE,
  },
  bedStatusTime: {
    key: 'Time',
    value: data.attributes.client?.bedStatusTime ?? EMPTY_VALUE,
  },
  bedDischargeActualDate: {
    key: 'Date',
    value:
      data.wardPatient?.wardDischarge?.attributes.client?.dischargeActualDate ??
      EMPTY_VALUE,
  },
  bedDischargeActualTime: {
    key: 'Time',
    value:
      data.wardPatient?.wardDischarge?.attributes.client?.dischargeActualTime ??
      EMPTY_VALUE,
  },
  bedDischargeEstimatedDate: {
    key: 'Est. Date',
    value:
      data.wardPatient?.wardDischarge?.attributes.client
        ?.dischargeExpectedDate ?? EMPTY_VALUE,
  },
  bedDischargeEstimatedTime: {
    key: 'Est. Time',
    value:
      data.wardPatient?.wardDischarge?.attributes.client
        ?.dischargeExpectedTime ?? EMPTY_VALUE,
  },
  outlierYN: {
    key: 'Outlier',
    value: YesNoMap[data.wardPatient?.attributes?.outlierYN ?? 'NONE'],
  },
  bedAllocationPortering: {
    key: 'Porter',
    value: data.wardPatient?.attributes?.porterStatus
      ? PorteringMap[data.wardPatient.attributes.porterStatus]
      : EMPTY_VALUE,
  },
  bedDischargePortering: {
    key: 'Porter',
    value: data.wardPatient?.wardDischarge?.attributes?.porterStatus
      ? PorteringMap[data.wardPatient.wardDischarge.attributes.porterStatus]
      : EMPTY_VALUE,
  },
  bedStatusComments: {
    key: 'Comments',
    value: data.attributes?.bedStatusComments ?? EMPTY_VALUE,
  },
  dischargeIsolationReason: {
    key: 'Isolation',
    value: getWardDischargeIsolationReason(data),
  },
  patientIsolationReason: {
    key: 'Isolation',
    value: getWardPatientIsolationReason(data),
  },
  patientAdmissionComments: {
    key: 'Comments',
    value: data.wardPatient?.attributes?.comments ?? EMPTY_VALUE,
  },
  seniorDecisionMaker: {
    key: 'Senior review',
    value:
      YesNoMap[
        data.wardPatient?.wardDischarge?.attributes?.decisionMakerYN ?? 'NONE'
      ],
  },
  bedDischargeStatus: {
    key: 'Discharge',
    value:
      DischargeStatusMap[
        data.wardPatient?.wardDischarge?.attributes?.dischargeStatus ?? 'NONE'
      ],
  },
  bedDischargeTTO: {
    key: 'TTO',
    value:
      TTOStatusMap[
        data.wardPatient?.wardDischarge?.attributes?.toTakeOutStatus ?? 'NONE'
      ],
  },
  bedDischargeCarePackage: {
    key: 'Care Package',
    value:
      CarePackageMap[
        data.wardPatient?.wardDischarge?.attributes?.carePackageStatus ?? 'NONE'
      ],
  },
  speciality: {
    key: 'Speciality',
    value: getSpecialty(data as ExtendedWardBed),
  },
  socialCareYN: {
    key: 'Social care',
    value:
      YesNoMap[
        data.wardPatient?.wardDischarge?.attributes.socialCareYN ?? 'NONE'
      ],
  },
  waitingReasonsForPotentialDischarge: {
    key: 'Waiting for',
    value: getPotentialDischargeWaitingReason(data.wardPatient?.wardDischarge),
  },
  waitingReasonsForClinicallyReadyForDischarge: {
    key: 'Waiting for',
    value: getEnumMapOptions(
      WaitingForDischargeStatusMap,
      data.wardPatient?.wardDischarge?.attributes
        .waitingReasonsForClinicallyReadyForDischarge
    ),
  },
  requireDischargeToAssess: {
    key: 'D2A required',
    value:
      YesNoMap[
        data.wardPatient?.wardDischarge?.attributes.requireDischargeToAssess ??
          'NONE'
      ],
  },
  dischargeAssessmentOutcome: {
    key: 'Awaiting from D2A',
    value:
      AwaitingDischargeAssessMap[
        data.wardPatient?.wardDischarge?.attributes
          .dischargeAssessmentOutcome ?? 'NONE'
      ],
  },
  pathway: {
    key: 'Pathway',
    value:
      AwaitingDischargeAssessMap[
        data.wardPatient?.wardDischarge?.attributes
          .dischargeAssessmentOutcome ?? 'NONE'
      ],
  },
  dischargeReason: {
    key: 'Reason',
    value:
      DischargePathwayReasonMap[
        data.wardPatient?.wardDischarge?.attributes
          .dischargeAssessmentOutcome ?? 'NONE'
      ],
  },
  pharmacy: {
    key: 'Pharmacy',
    value:
      PharmacyStatusMap[
        data.wardPatient?.wardDischarge?.attributes?.pharmacy ?? 'NONE'
      ],
  },
  readyForDischargeDate: {
    key: 'RFD. Date',
    value:
      data.wardPatient?.wardDischarge?.attributes.client
        ?.readyForDischargeDate ?? EMPTY_VALUE,
  },
  readyForDischargeTime: {
    key: 'RFD. Time',
    value:
      data.wardPatient?.wardDischarge?.attributes.client
        ?.readyForDischargeTime ?? EMPTY_VALUE,
  },
  clinicallyReadyForDischargeDate: {
    key: 'CRFD Date',
    value:
      data.wardPatient?.wardDischarge?.attributes.client
        ?.clinicallyReadyForDischargeDate ?? EMPTY_VALUE,
  },
  clinicallyReadyForDischargeTime: {
    key: 'CRFD Time',
    value:
      data.wardPatient?.wardDischarge?.attributes.client
        ?.clinicallyReadyForDischargeTime ?? EMPTY_VALUE,
  },
  transferFrom: {
    key: 'Transfer from',
    value: `${data?.wardBedTransfers?.[0]?.fromWard?.attributes?.name}, ${data?.wardBedTransfers?.[0]?.fromWardBed?.attributes.wardBedReference}`,
  },
  transferTo: {
    key: 'Transfer to',
    value: `${data.wardBedTransfers?.[0]?.toWard?.attributes.name}, ${data.wardBedTransfers?.[0]?.toWardBed?.attributes.wardBedReference}`,
  },
  intraWardTransferReason: {
    key: 'Transfer reason',
    value: getEnumMapOptions(
      IntraWardTransferReasonMap,
      [data.wardBedTransfers?.[0]?.attributes?.intraWardTransferReason],
      data.wardBedTransfers?.[0]?.attributes?.otherTransferReason
    ),
  },
  interWardTransferReason: {
    key: 'Transfer reason',
    value: getEnumMapOptions(
      InterWardTransferReasonMap,
      [data.wardBedTransfers?.[0]?.attributes?.interWardTransferReason],
      data.wardBedTransfers?.[0]?.attributes?.otherTransferReason
    ),
  },
  ...getGenderPrefMappingOptions(data),
  ...getBedCleaningMappingOptions(data),
  // Specific to ESNEFT
  ...getPatientIdentifierMappingOptions(data),
});

export const mapBedObjectKeys = (
  data: WardBed,
  keys: Array<keyof ReturnType<typeof bedMapping>>
) => Object.values(pick(keys, bedMapping(data)));
