import type { WardBed } from 'services';
import { getDischargeDateTimeType } from 'components/BedDischarge/BedDischargeListView/getDischargeDateTime.util';
import { mapBedObjectKeys } from './bedMapping';

export const bedAllocationConfirmationMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'bed',
    'gender',
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'bedStatus',
    'expectedArrivalDate',
    'expectedArrivalTime',
    'outlierYN',
    'speciality',
    'bedAllocationPortering',
  ]);

export const bedAllocationConfirmationGenderMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'bed',
    'gender',
    'wardPref',
    'furtherPref',
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'bedStatus',
    'expectedArrivalDate',
    'expectedArrivalTime',
    'outlierYN',
    'speciality',
    'bedAllocationPortering',
  ]);

export const bedAllocationClosedConfirmationMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'bed',
    'bedStatus',
    'bedStatusClosedReason',
    'bedStatusDate',
    'bedStatusTime',
  ]);

export const bedAllocationUnallocatedConfirmationMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'bed',
    'bedStatus',
    'bedStatusDate',
    'bedStatusTime',
  ]);

export const bedAllocationInCleaningConfirmationMapping = (data: WardBed) =>
  mapBedObjectKeys(data, ['bed', 'gender', 'bedStatus', 'bedCleaningType']);

export const bedAllocationUnallocatedMapping = (data: WardBed) =>
  mapBedObjectKeys(data, ['bedStatus', 'gender']);

export const bedAllocationClosedMapping = (data: WardBed) =>
  mapBedObjectKeys(data, ['bedStatus', 'bedStatusClosedReason', 'gender']);

export const bedAllocationMapping = (data: WardBed) => {
  const dischargeDateTimeType = getDischargeDateTimeType(
    data.wardPatient?.wardDischarge?.attributes!
  );

  return mapBedObjectKeys(data, [
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'bedStatus',
    'gender',
    'outlierYN',
    'speciality',
    'patientIsolationReason',
    'seniorDecisionMaker',
    'bedDischargeStatus',
    dischargeDateTimeType === 'readyForDischargeDateTime'
      ? 'readyForDischargeDate'
      : 'bedDischargeEstimatedDate',
    dischargeDateTimeType === 'readyForDischargeDateTime'
      ? 'readyForDischargeTime'
      : 'bedDischargeEstimatedTime',
    'bedDischargeTTO',
    'bedDischargeCarePackage',
    'bedAllocationPortering',
  ]);
};

export const bedPreAllocationMapping = (data: WardBed) =>
  mapBedObjectKeys(data, [
    'patientName',
    'dateOfBirth',
    'patientIdentifier',
    'bedStatus',
    'gender',
    'speciality',
    'patientIsolationReason',
    'outlierYN',
    'expectedArrivalDate',
    'expectedArrivalTime',
    'bedAllocationPortering',
  ]);

export const bedCleaningMapping = (data: WardBed) =>
  mapBedObjectKeys(data, ['bedStatus', 'bedCleaningType', 'gender']);
