import type { BedDischargeFormData } from 'components/BedDischarge/types';
import type { WardBed } from 'services';
import { getFailedDischargeStatusReasons } from '../form.utils';

export const getDelayedDefaultValues = (
  defaultFields: Partial<BedDischargeFormData>,
  wardBed: WardBed
): Partial<BedDischargeFormData> => ({
  ...defaultFields,
  failedDischargeStatusReasons: getFailedDischargeStatusReasons(wardBed),
  failedDischargeStatusReasonOther: getFailedDischargeStatusReasons(
    wardBed
  )?.some((reason) => reason?.value === 'OTHER')
    ? wardBed.wardPatient?.wardDischarge?.attributes
        .dischargeStatusFailedReasonOther ?? ''
    : '',
});
