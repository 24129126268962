import { Wrapper, MultipleChoiceButton } from '@bt-healthcare/ui-toolkit';

import { yesNoOptions } from 'components/Form/formOptions';
import { getLocalDate, getLocalTime } from 'mappings/date.utils';
import { getPreviousDateTime } from 'pages/BedDischarge/form.utils';
import type { ChangeEvent, SyntheticEvent } from 'react';
import type { YesNoType } from 'services';
import type { BedDischargeFormFieldsProps } from '../types';

export const SeniorDecisionMaker = ({
  register,
  previousDMDateTime,
  decisionMakerLastDateTime,
  setDecisionMakerLastDateTime,
}: BedDischargeFormFieldsProps) => {
  const handleDecisionMakerLastSeen = (event: SyntheticEvent) => {
    const { value } = (event as ChangeEvent<HTMLInputElement>).target;
    const decisionMakerLastSeenDateTime = getPreviousDateTime(
      value as YesNoType,
      previousDMDateTime,
      false
    );

    setDecisionMakerLastDateTime?.(decisionMakerLastSeenDateTime);
  };

  return (
    <Wrapper onChange={(event) => handleDecisionMakerLastSeen?.(event)}>
      <MultipleChoiceButton
        label="Has a senior decision maker seen the patient?"
        options={yesNoOptions}
        {...register('decisionMakerYN')}
        helperText={
          decisionMakerLastDateTime
            ? `Last seen: ${getLocalDate(
                decisionMakerLastDateTime
              )} at ${getLocalTime(decisionMakerLastDateTime)}`
            : undefined
        }
      />
    </Wrapper>
  );
};
