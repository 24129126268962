import { Wrapper } from '@bt-healthcare/ui-toolkit';
import { EMPTY_VALUE } from 'App.constants';
import { RagStatus } from 'components/RagStatus';
import { RagStatusWrapper } from 'components/RagStatus/styles';
import {
  CarePackageMap,
  DischargeStatusMap,
  GenderIdentifiedAsAbbrevMap,
  PorteringMap,
  TTOStatusMap,
  YesNoMap,
} from 'mappings/enums';
import {
  getPatientName,
  getIsolationReason,
  getWardBedReference,
} from 'mappings/mapping.utils';
import type { ExtendedWardBed } from 'types';
import { BedListViewIcon } from '../../../assets/BedListViewIcon';
import {
  DischargeDateTimeTooltip,
  SameNamePatientTooltip,
} from '../../Tooltips';

export const columnWidthSizes = {
  desktop: '1fr 2fr 18px 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 18px',
  mobile: '50px 3fr 4fr 3fr 14px',
};

export const headerData: any = [
  { id: '1', label: 'Bed' },
  { id: '2', label: 'Patient', hide: 'mobile' },
  { id: '3', label: '', hide: 'mobile' },
  { id: '4', label: 'Gender', hide: 'mobile', align: 'center' },
  { id: '5', label: { desktop: 'Disch Status', mobile: 'Discharge' } },
  { id: '6', label: 'ED/RFD Date' },
  { id: '7', label: 'ED/RFD Time' },
  { id: '8', label: 'Isolation', hide: 'mobile' },
  { id: '9', label: 'TTO', hide: 'mobile' },
  { id: '10', label: 'Care Package', hide: 'mobile' },
  { id: '11', label: 'Portering', hide: 'mobile' },
  { id: '12', label: 'Outlier', hide: 'mobile', align: 'center' },
  { id: '13', label: '', align: 'center' },
];
export const rowData: any = (data: ExtendedWardBed[]) =>
  data.map((rowItem) => {
    const patientName = getPatientName(rowItem.wardPatient?.careSettingPatient);
    return {
      id: rowItem.id,
      items: [
        {
          id: '1',
          item: (
            <RagStatusWrapper>
              <RagStatus
                dischargeStatus={
                  rowItem.wardPatient?.wardDischarge?.attributes
                    ?.dischargeStatus ?? 'NONE'
                }
                bedStatus={rowItem.attributes?.bedStatus}
              />
              {getWardBedReference(rowItem)}
            </RagStatusWrapper>
          ),
        },
        {
          id: '2',
          item: (
            <Wrapper as="span" title={patientName}>
              {patientName}
            </Wrapper>
          ),
          hide: 'mobile',
        },
        {
          id: '3',
          item: <SameNamePatientTooltip data={rowItem} />,
          align: 'center',
          hide: 'mobile',
          overflow: false,
        },
        {
          id: '4',
          item: rowItem.wardPatient?.careSettingPatient?.person.attributes
            .personalInformation.genderIdentifiedAs
            ? GenderIdentifiedAsAbbrevMap[
                rowItem.wardPatient.careSettingPatient?.person.attributes
                  .personalInformation.genderIdentifiedAs
              ]
            : EMPTY_VALUE,
          hide: 'mobile',
          align: 'center',
        },
        {
          id: '5',
          item: DischargeStatusMap[
            rowItem.wardPatient?.wardDischarge?.attributes?.dischargeStatus ??
              'NONE'
          ],
        },
        {
          id: '6',
          item: <DischargeDateTimeTooltip data={rowItem} dateTimeType="Date" />,
          overflow: false,
        },
        {
          id: '7',
          item: <DischargeDateTimeTooltip data={rowItem} dateTimeType="Time" />,
          overflow: false,
        },
        {
          id: '8',
          hide: 'mobile',
          item: getIsolationReason(rowItem),
        },
        {
          id: '9',
          hide: 'mobile',
          item: TTOStatusMap[
            rowItem.wardPatient?.wardDischarge?.attributes?.toTakeOutStatus ??
              'NONE'
          ],
        },
        {
          id: '10',
          hide: 'mobile',
          item: CarePackageMap[
            rowItem.wardPatient?.wardDischarge?.attributes?.carePackageStatus ??
              'NONE'
          ],
        },
        {
          id: '11',
          hide: 'mobile',
          item: rowItem.wardPatient?.wardDischarge?.attributes?.porterStatus
            ? PorteringMap[
                rowItem.wardPatient.wardDischarge.attributes?.porterStatus
              ]
            : EMPTY_VALUE,
        },
        {
          id: '12',
          hide: 'mobile',
          align: 'center',
          item: YesNoMap[rowItem.wardPatient?.attributes?.outlierYN ?? 'NONE'],
        },
        {
          id: '13',
          align: 'center',
          item: (
            <BedListViewIcon
              bedStatus={rowItem.attributes.bedStatus}
              dischargeStatus={
                rowItem.wardPatient?.wardDischarge?.attributes.dischargeStatus
              }
              bedTransferStatus={
                rowItem.wardBedTransfers?.[0]?.attributes.bedTransferStatus
              }
              isWarning={
                rowItem.attributes.res_bedCleaning?.hasExceededDuration === true
              }
            />
          ),
        },
      ],
    };
  });
