import { bedAllocationRagStatusSortMap } from 'mappings/ragStatus/bedAllocation';
import { bedDischargeRagStatusSortMap } from 'mappings/ragStatus/bedDischarge';
import type { ExtendedWardBed, RagType } from 'types';
import type { ExtendedWardBedSort } from './types';
import { wardBedSortComparator } from './sort.utils';

export const sortByRagStatus = (
  data: ExtendedWardBed[],
  ragType: RagType = 'BedStatus'
): ExtendedWardBedSort[] => {
  const sortFn =
    ragType === 'BedStatus'
      ? bedAllocationRagStatusSortMap
      : bedDischargeRagStatusSortMap;

  const result = data.map((item) => ({
    sortOrder: sortFn(
      item.wardPatient?.wardDischarge?.attributes.dischargeStatus ?? 'NONE',
      item.attributes?.bedStatus
    ),
    sortName: item.attributes?.wardBedReference,
    ...item,
  }));

  return result.sort(wardBedSortComparator);
};
