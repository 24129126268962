import { MultipleChoiceButton } from '@bt-healthcare/ui-toolkit';
import { useEffect } from 'react';

import { getPatientName } from 'mappings/mapping.utils';
import { useAuthUser } from 'auth/useAuthUser';
import { RBACAction, useHasAccess } from 'auth/useHasAccess';
import { bedStatusOptions } from '../Form/formOptions';
import type { BedAllocationFormFieldsProps } from './types';
import {
  BedStatusClosed,
  BedStatusUnallocated,
  BedStatusInCleaning,
  BedStatusPatient,
} from './FormSections';

export const BedAllocationFormFields = ({
  register,
  control,
  setValue,
  formData,
  setFormData,
  patient,
  currentBedStatus,
  isInactive,
  handlePatientClick,
  handleStatusChange,
}: BedAllocationFormFieldsProps) => {
  const { auth } = useAuthUser();
  const bedManagerAccess = useHasAccess(
    [RBACAction.BED_ALLOCATION_BED_CLOSURE_UPDATE],
    auth.userRoles
  );

  // Set the patientId value
  useEffect(() => {
    if (patient) {
      setValue('patient', patient);
      setValue('patientName', getPatientName(patient, ''));
    }
  }, [patient]);

  return (
    <>
      <MultipleChoiceButton
        label="Update bed status to"
        size="lg"
        options={bedStatusOptions(
          currentBedStatus,
          bedManagerAccess ? undefined : 'CLOSED'
        )}
        {...register('bedStatus', {
          onChange: (event) => handleStatusChange?.(event),
        })}
      />
      <BedStatusClosed register={register} control={control} />
      <BedStatusUnallocated control={control} />
      <BedStatusInCleaning
        control={control}
        formData={formData}
        setFormData={setFormData}
        currentBedStatus={currentBedStatus}
        isInactive={isInactive}
      />
      <BedStatusPatient
        register={register}
        control={control}
        handlePatientClick={handlePatientClick}
      />
    </>
  );
};
