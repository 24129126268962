import { SearchBar } from '@bt-healthcare/ui-toolkit';
import { careSettingPatientFilter } from 'filters/careSettingPatients';
import { wardBedTransfersFilter } from 'filters/wardBedTransfers';
import debounce from 'lodash.debounce';
import type { CareSettingPatientQuery, WardBedTransferQuery } from 'models';
import type { ChangeEvent } from 'react';

import type { PatientSearchProps } from './types';

export const PatientSearch = <
  T extends CareSettingPatientQuery | WardBedTransferQuery
>({
  data,
  setFilter,
  placeholder = 'Search for patient, gender or speciality',
  searchType = 'CareSettingPatientQuery',
}: PatientSearchProps<T>) => {
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    if (value === '') {
      setFilter(data);
    } else {
      let filter: any;
      if (searchType === 'CareSettingPatientQuery') {
        filter = careSettingPatientFilter(
          data as CareSettingPatientQuery,
          value
        );
      } else {
        filter = wardBedTransfersFilter(data as WardBedTransferQuery, value);
      }
      setFilter(filter);
    }
  };

  const debouncedHandleChange = debounce((evt) => handleChange(evt), 1000);

  return (
    <SearchBar
      id="search"
      placeholder={placeholder}
      onChange={debouncedHandleChange}
    />
  );
};
