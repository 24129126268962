import { ROUTES } from 'App.constants';
import { BedStatusIcon, DischargeStatusIcon } from 'assets';
import { Link } from 'react-router-dom';

export const getStatusOptions = (wardId: string) => [
  {
    renderLinkComponent: (children: JSX.Element) => (
      <Link to={ROUTES.WARDS_BED_ALLOCATION_HOME} state={{ wardId }}>
        {children}
      </Link>
    ),
    svgComponent: <BedStatusIcon />,
    title: 'Update bed status',
    id: 'update-bed-status',
    includeIconBackground: true,
  },
  {
    renderLinkComponent: (children: JSX.Element) => (
      <Link to={ROUTES.WARDS_BED_DISCHARGE_HOME} state={{ wardId }}>
        {children}
      </Link>
    ),
    svgComponent: <DischargeStatusIcon />,
    title: 'Update discharge status',
    id: 'update-discharge-status',
    includeIconBackground: true,
  },
];
