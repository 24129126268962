import type { UseFormResetField } from 'react-hook-form';

import type { BedDischargeFormData } from 'components/BedDischarge/types';
import type { WardBed } from 'services';
import { convertDateStringForFormInput } from 'mappings/date.utils';
import { getDischargeAssessmentOutcome } from '../form.utils';

export const resetConfirmedFormFields = (
  resetField: UseFormResetField<BedDischargeFormData>,
  isCurrent: boolean,
  wardBed: WardBed
) => {
  resetField('readyForDischargeDate', {
    defaultValue: isCurrent
      ? convertDateStringForFormInput(
          wardBed.wardPatient?.wardDischarge?.attributes.client
            ?.readyForDischargeDate
        )
      : '',
  });
  resetField('readyForDischargeTime', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes.client
          ?.readyForDischargeTime
      : '',
  });
  resetField('dischargeAssessmentOutcome', {
    defaultValue: isCurrent
      ? getDischargeAssessmentOutcome(wardBed, 'CONFIRMED')
      : null,
  });
  resetField('toTakeOutStatus', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes.toTakeOutStatus ?? ''
      : '',
  });
  resetField('carePackageStatus', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes.carePackageStatus ?? ''
      : '',
  });
  resetField('porterStatus', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes.porterStatus ?? ''
      : '',
  });
  resetField('pharmacy', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes.pharmacy ?? ''
      : '',
  });
  resetField('requireDischargeToAssess', { defaultValue: '' });
};
