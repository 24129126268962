import { BTHealthIcon, colors } from '@bt-healthcare/ui-toolkit';
import { BedStatusMap, DischargeStatusMap } from 'mappings/enums';

import { GenderOption } from './GenderOption';
import {
  PatientDetailsWrapper,
  PatientHeaderWrapper,
  FlexContainer,
  MainFlexContainer,
  WardWrapper,
  StatusWrapper,
} from './styles';
import type { PatientHeaderProps } from './types';

export const PatientHeader = ({
  name,
  gender,
  person,
  bedStatus,
  dischargeStatus,
}: PatientHeaderProps) => (
  <PatientHeaderWrapper>
    <MainFlexContainer>
      <FlexContainer>
        <BTHealthIcon
          icon="Bed"
          color={colors.primaryIndigo.indigo02}
          size={20}
        />
        <WardWrapper>{name}</WardWrapper>
        {bedStatus && (
          <StatusWrapper>
            <span>{BedStatusMap[bedStatus]}</span>
          </StatusWrapper>
        )}
        {dischargeStatus && (
          <StatusWrapper>
            <span>{DischargeStatusMap[dischargeStatus]}</span>
          </StatusWrapper>
        )}
      </FlexContainer>
      <PatientDetailsWrapper>
        <span>
          {person &&
            `${person.attributes.personalInformation.firstName} ${person.attributes.personalInformation.surname}`}
        </span>
        <GenderOption gender={gender} />
      </PatientDetailsWrapper>
    </MainFlexContainer>
  </PatientHeaderWrapper>
);
