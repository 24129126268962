import type { WardBedTransferQuery } from 'models';

export const wardBedTransfersFilter = (
  data: WardBedTransferQuery,
  value: string
) => {
  if (!data || value === '') return data;
  return data.filter(
    (item) =>
      item.careSettingPatient?.person.attributes.personalInformation.firstName
        ?.toLowerCase()
        .includes(value.toLowerCase()) ||
      item.careSettingPatient?.person?.attributes.personalInformation.surname
        ?.toLowerCase()
        .includes(value.toLowerCase())
  );
};
