import { colors } from '@bt-healthcare/ui-toolkit';
import type { BedTransferStatus } from 'services';

export const wardBedTransferStatusSortMap = {
  CANCELLED: 3,
  COMPLETE: 2,
  DELAYED: 5,
  IN_PROGRESS: 4,
  NONE: 1,
};

export const wardBedTransferRagStatusColorMap = (
  bedTransferStatus: BedTransferStatus
) => {
  const map = {
    COMPLETE: colors.rag.light.black,
    DELAYED: colors.rag.light.red,
    IN_PROGRESS: colors.rag.light.green,
    CANCELLED: colors.rag.light.amber,
    NONE: 'inherit',
  };
  return map[bedTransferStatus];
};
