import { WardOverviewGridView } from '../WardOverviewGridView';
import { WardOverviewListView } from '../WardOverviewListView/WardOverviewListView';
import type { WardOverviewViewProps } from './types';

export const WardOverviewView = ({
  list,
  filteredData,
  data,
}: WardOverviewViewProps) =>
  !list ? (
    <WardOverviewGridView data={filteredData || data} />
  ) : (
    <WardOverviewListView data={filteredData || data} />
  );
