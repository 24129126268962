import { colors, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

import { bedAllocationRagStatusColorMap } from 'mappings/ragStatus/bedAllocation';
import { bedDischargeRagStatusColorMap } from 'mappings/ragStatus/bedDischarge';
import type { RagStatusProps } from './types';

export const StyledRagStatus = styled.span<RagStatusProps>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: ${spacing.s2};
  background: ${({ dischargeStatus, bedStatus, ragType, theme }) =>
    ragType === 'DischargeStatus'
      ? bedDischargeRagStatusColorMap(dischargeStatus, bedStatus!, theme)
      : bedAllocationRagStatusColorMap(dischargeStatus, bedStatus!, theme)};
  && {
    padding: ${spacing.none};
  }

  @media ${device.desktop} {
    margin-right: ${spacing.s4};
    height: 10px;
    width: 10px;
  }
`;

export const RagStatusWrapper = styled.div`
  color: ${colors.primaryIndigo.indigo08};
  font-weight: 500;
`;
