import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  ISO8601Duration: string;
};

export type AimHumanName = {
  __typename?: 'AIMHumanName';
  family?: Maybe<Scalars['String']>;
  given?: Maybe<Scalars['String']>;
};

export type AimHumanNameInput = {
  family?: InputMaybe<Scalars['String']>;
  given?: InputMaybe<Scalars['String']>;
};

export type Audit = {
  __typename?: 'Audit';
  created?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdByUser?: Maybe<UserProfile>;
  lastUpdated?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedByUser?: Maybe<UserProfile>;
  version: Scalars['Int'];
};

export type AwaitingFromDischargeAssess =
  | 'AGAINST_MEDICAL_ADVICE'
  | 'ASSESSMENT_TO_BE_COMPLETED'
  | 'CARE_PACKAGE_AT_HOME_PATHWAY_1'
  | 'COMPLEX_CARE_PLACEMENT_PATHWAY_3'
  | 'END_OF_LIFE_PATHWAY_1'
  | 'EQUIPMENT_AT_HOME_PATHWAY_1'
  | 'INTERMEDIATE_CARE_PATHWAY_2'
  | 'NONE'
  | 'PATIENT_DEATH'
  | 'SIMPLE_DISCHARGE_PATHWAY_0';

export type BedCleaningDuration = {
  __typename?: 'BedCleaningDuration';
  /** Bed cleaning duration in milliseconds */
  asMs: Scalars['Int'];
  /** Bed cleaning duration as a text string */
  asText: Scalars['String'];
};

export type BedCleaningMethod =
  | 'FOG_CLEAN'
  | 'INFECTION_CONTROL_CLEAN'
  | 'STANDARD_CLEAN';

export type BedCleaningStatus = 'NOT_REQUIRED' | 'REQUIRED';

export type BedStatusReasonCodeType =
  | 'BROKEN'
  | 'COVID_19_CLEAN'
  | 'NONE'
  | 'OTHER'
  | 'REPAIRED'
  | 'STAFFING';

export type BedStatusType =
  | 'ALLOCATED'
  | 'CLEANING'
  | 'CLOSED'
  | 'NONE'
  | 'PREALLOCATED'
  | 'UNALLOCATED';

export type BedTransferStatus =
  | 'CANCELLED'
  | 'COMPLETE'
  | 'DELAYED'
  | 'IN_PROGRESS'
  | 'NONE';

export type BedTransferType = 'INTER_WARD_TRANSFER' | 'INTRA_WARD_TRANSFER';

export type BloodPressure = {
  __typename?: 'BloodPressure';
  /** The diastolic blood pressure */
  diastolic: Scalars['Int'];
  /** The systolic blood pressure */
  systolic: Scalars['Int'];
};

export type BodyHeight = {
  __typename?: 'BodyHeight';
  cm: Scalars['Float'];
};

export type BodyTemperature = {
  __typename?: 'BodyTemperature';
  celsius: Scalars['Float'];
};

export type BodyWeight = {
  __typename?: 'BodyWeight';
  /** Metric system */
  kg: Scalars['Float'];
  /** Imperial system */
  lb: Scalars['Float'];
  userInputUnit: BodyWeightUnitInput;
};

export type BodyWeightUnitInput = 'kg' | 'lb';

export type CarePackageStatusType =
  | 'COMPLETE'
  | 'DELAYED'
  | 'INCOMPLETE'
  | 'NONE'
  | 'NOT_REQUIRED';

export type CareSetting = Identifier & {
  __typename?: 'CareSetting';
  attributes: CareSettingAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingAttributes = {
  __typename?: 'CareSettingAttributes';
  contactPoints?: Maybe<Array<Maybe<ContactPoint>>>;
  description: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  res_phone?: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
};

export type CareSettingClinician = Identifier & {
  __typename?: 'CareSettingClinician';
  attributes: CareSettingClinicianAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingClinicianAttributes = {
  __typename?: 'CareSettingClinicianAttributes';
  audit?: Maybe<Audit>;
  careSettingClinicianModuleAvailability: Array<Maybe<UserModuleAvailability>>;
  careSettingClinicianModuleRoles: Array<Maybe<UserModuleRole>>;
  careSettingClinicianUser?: Maybe<User>;
  careSettingId: Scalars['ID'];
  /** @deprecated Use userId */
  clinicianId?: Maybe<Scalars['ID']>;
  /** @deprecated Review usage with contactPoints */
  contactInformation?: Maybe<CareSettingClinicianAttributesContactInformation>;
  contactPoints?: Maybe<Array<Maybe<ContactPoint>>>;
  displayName?: Maybe<Scalars['String']>;
  /** @deprecated Use displayName */
  dnHumanNameTextCurrent?: Maybe<Scalars['String']>;
  /** @deprecated Use res_mobileNumber */
  dnPhoneNumberCurrent?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  humanNames?: Maybe<Array<Maybe<HumanName>>>;
  receiveBedAvailabilitySmsAlerts?: Maybe<Scalars['Boolean']>;
  res_mobileNumber?: Maybe<Scalars['String']>;
  specialties?: Maybe<Array<Maybe<SpecialtyType>>>;
  surname?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type CareSettingClinicianAttributesContactInformation = {
  __typename?: 'CareSettingClinicianAttributesContactInformation';
  primaryContactNumber?: Maybe<Scalars['String']>;
  primaryEmailAddress?: Maybe<Scalars['String']>;
};

export type CareSettingPatient = Identifier & {
  __typename?: 'CareSettingPatient';
  attributes: CareSettingPatientAttributes;
  id: Scalars['ID'];
  person: Person;
  type: Scalars['String'];
};

export type CareSettingPatientAdmission = Identifier & {
  __typename?: 'CareSettingPatientAdmission';
  attributes: CareSettingPatientAdmissionAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingPatientAdmissionAttributes = {
  __typename?: 'CareSettingPatientAdmissionAttributes';
  careSettingPatient?: Maybe<CareSettingPatientDocument>;
};

export type CareSettingPatientAdmissionInput = {
  careSettingPatient: CareSettingPatientInput;
  person: PersonInput;
  user?: InputMaybe<UserInput>;
};

export type CareSettingPatientAttributes = {
  __typename?: 'CareSettingPatientAttributes';
  admissionComments?: Maybe<Scalars['String']>;
  audit?: Maybe<Audit>;
  careSettingId?: Maybe<Scalars['ID']>;
  client?: Maybe<ClientCareSettingPatientAttributes>;
  decisionToAdmitDateTime?: Maybe<Scalars['DateTime']>;
  decisionToAdmitWaitTime?: Maybe<Scalars['Int']>;
  furtherWardPreferences?: Maybe<Scalars['String']>;
  isolationType: IsolationType;
  isolationTypeOther?: Maybe<Scalars['String']>;
  origin?: Maybe<OriginType>;
  patientWardPreference?: Maybe<PatientWardPreferencesType>;
  personId: Scalars['ID'];
  requiresIsolationYN?: Maybe<YesNoType>;
  socialCareYN?: Maybe<YesNoType>;
  specialty?: Maybe<SpecialtyType>;
};

export type CareSettingPatientDocument = {
  __typename?: 'CareSettingPatientDocument';
  data: CareSettingPatient;
};

export type CareSettingPatientInput = {
  admissionComments?: InputMaybe<Scalars['String']>;
  careSettingId: Scalars['ID'];
  decisionToAdmitDateTime?: InputMaybe<Scalars['DateTime']>;
  furtherWardPreferences?: InputMaybe<Scalars['String']>;
  isolationType: IsolationType;
  isolationTypeOther?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<OriginType>;
  patientWardPreference?: InputMaybe<PatientWardPreferencesType>;
  requiresIsolationYN?: InputMaybe<YesNoType>;
  socialCareYN?: InputMaybe<YesNoType>;
  specialty?: InputMaybe<SpecialtyType>;
};

export type ClientCareSettingPatientAttributes = {
  __typename?: 'ClientCareSettingPatientAttributes';
  decisionToAdmitDate: Scalars['String'];
  decisionToAdmitTime: Scalars['String'];
};

export type ClientWardBedAttributes = {
  __typename?: 'ClientWardBedAttributes';
  bedStatusDate: Scalars['String'];
  bedStatusTime: Scalars['String'];
};

export type ClientWardBedTransferAttributes = {
  __typename?: 'ClientWardBedTransferAttributes';
  arrivalToWardDate: Scalars['String'];
  arrivalToWardTime: Scalars['String'];
};

export type ClientWardDischargeAttributes = {
  __typename?: 'ClientWardDischargeAttributes';
  clinicallyReadyForDischargeDate: Scalars['String'];
  clinicallyReadyForDischargeTime: Scalars['String'];
  dischargeActualDate: Scalars['String'];
  dischargeActualTime: Scalars['String'];
  dischargeExpectedDate: Scalars['String'];
  dischargeExpectedTime: Scalars['String'];
  readyForDischargeDate: Scalars['String'];
  readyForDischargeTime: Scalars['String'];
};

export type ClientWardPatientAttributes = {
  __typename?: 'ClientWardPatientAttributes';
  admissionDate: Scalars['String'];
  admissionTime: Scalars['String'];
  arrivalExpectedDate: Scalars['String'];
  arrivalExpectedTime: Scalars['String'];
};

export type ContactPoint = {
  __typename?: 'ContactPoint';
  period: Period;
  rank?: Maybe<Scalars['Int']>;
  system: ContactPointsSystem;
  use?: Maybe<ContactPointsUse>;
  value: Scalars['String'];
};

export type ContactPointsSystem =
  | 'EMAIL'
  | 'FAX'
  | 'OTHER'
  | 'PAGER'
  | 'PHONE'
  | 'SMS'
  | 'URL';

export type ContactPointsUse = 'HOME' | 'MOBILE' | 'OLD' | 'TEMP' | 'WORK';

export type Covid19Status =
  | 'CONFIRMED_NEGATIVE'
  | 'CONFIRMED_POSITIVE'
  | 'NONE'
  | 'QUARANTINED'
  | 'SYMPTOMATIC';

export type DischargeReasonType =
  | 'NONE'
  | 'OTHER'
  | 'PATIENT_ADMITTED_TO_HOSPITAL'
  | 'PATIENT_REFUSED_REMOTE_MONITORING'
  | 'REMOTE_MONITORING_COMPLETED';

export type DischargeStatusFailed =
  | 'DELAYED_PHARMACY'
  | 'DELAYED_PORTERING'
  | 'DELAYED_TEST'
  | 'DELAYED_TRANSFER'
  | 'NONE'
  | 'NO_CARE_PACKAGE'
  | 'OTHER'
  | 'PATIENT_CHANGE';

export type DischargeStatusType =
  | 'CLINICALLY_READY'
  | 'COMPLETED'
  | 'CONFIRMED'
  | 'DELAYED'
  | 'NONE'
  | 'NOT_FIT'
  | 'POTENTIAL';

export type Ethnicity =
  | 'ASIAN_OR_ASIAN_BRITISH_ANY_OTHER_ASIAN_BACKGROUND'
  | 'ASIAN_OR_ASIAN_BRITISH_BANGLADESHI'
  | 'ASIAN_OR_ASIAN_BRITISH_INDIAN'
  | 'ASIAN_OR_ASIAN_BRITISH_PAKISTANI'
  | 'BLACK_OR_BLACK_BRITISH_AFRICAN'
  | 'BLACK_OR_BLACK_BRITISH_ANY_OTHER_BLACK_BACKGROUND'
  | 'BLACK_OR_BLACK_BRITISH_CARIBBEAN'
  | 'MIXED_ANY_OTHER_MIXED'
  | 'MIXED_WHITE_AND_ASIAN'
  | 'MIXED_WHITE_AND_BLACK_AFRICAN'
  | 'MIXED_WHITE_AND_BLACK_CARIBBEAN'
  | 'NOT_KNOWN'
  | 'NOT_STATED'
  | 'OTHER_ETHNIC_GROUPS_ANY_OTHER_ETHNIC_GROUP'
  | 'OTHER_ETHNIC_GROUPS_CHINESE'
  | 'WHITE_ANY_OTHER_WHITE_BACKGROUND'
  | 'WHITE_BRITISH'
  | 'WHITE_IRISH';

export type GenderAssignedAtBirth = 'FEMALE' | 'MALE';

export type GenderIdentifiedAs =
  | 'FEMALE'
  | 'MALE'
  | 'NONE'
  | 'NON_BINARY'
  | 'PREFER_NONE';

export type GenderType = 'FEMALE' | 'MALE' | 'NONE';

export type HumanName = {
  __typename?: 'HumanName';
  family?: Maybe<Scalars['String']>;
  given?: Maybe<Array<Maybe<Scalars['String']>>>;
  period: Period;
  prefix?: Maybe<Array<Maybe<Scalars['String']>>>;
  suffix?: Maybe<Array<Maybe<Scalars['String']>>>;
  text?: Maybe<Scalars['String']>;
  use: HumanNamesUse;
};

export type HumanNamesUse =
  | 'ANONYMOUS'
  | 'MAIDEN'
  | 'NICKNAME'
  | 'OFFICIAL'
  | 'OLD'
  | 'TEMP'
  | 'USUAL';

export type Identifier = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type InterWardTransferReason =
  | 'NONE'
  | 'OTHER'
  | 'OUTLIER_TRANSFER'
  | 'PATIENT_CARE_NEEDS_CHANGED'
  | 'PATIENT_REQUEST';

export type IntraWardTransferReason =
  | 'NONE'
  | 'NO_LONGER_REQUIRE_ISOLATION'
  | 'OTHER'
  | 'REQUIRES_ISOLATION';

export type IsolationType =
  | 'COMMON_COLD'
  | 'COVID_19'
  | 'DV'
  | 'MRSA'
  | 'NONE'
  | 'OTHER'
  | 'PATIENT_AGGRESSIVE'
  | 'PATIENT_CONFUSED';

export type Mobility =
  | 'MAXIMUM_ASSISTANCE'
  | 'MINIMUM_ASSISTANCE'
  | 'MODERATE_ASSISTANCE'
  | 'NO_ASSISTANCE';

export type ModuleIdentifier =
  | 'BED_OPTIMISER'
  | 'COMMAND_CENTRE'
  | 'HEALTHYYOU_CLINICIAN'
  | 'HEALTHYYOU_PATIENT'
  | 'SMART_MESSAGING';

export type ModuleRole =
  /** Bed Optimiser bed manager role */
  | 'BED_OPTIMISER_BED_MANAGER'
  | 'BED_OPTIMISER_DEFAULT_USER'
  /** Bed Optimiser super user role */
  | 'BED_OPTIMISER_SUPER_USER'
  | 'CARE_SETTING_ADMINISTRATOR'
  | 'COMMAND_CENTRE_DEFAULT_USER'
  | 'HEALTHYYOU_CLINICIAN_DEFAULT_USER'
  | 'HEALTHYYOU_PATIENT_DEFAULT_USER'
  | 'SMART_MESSAGING_DEFAULT_USER';

export type MonitoredCondition = {
  __typename?: 'MonitoredCondition';
  comments?: Maybe<Scalars['String']>;
  condition: PatientDiagnosis;
  dischargeStatus?: Maybe<DischargeStatusType>;
  period: Period;
  reasonForDischarge?: Maybe<DischargeReasonType>;
};

export type MonitoredConditionInput = {
  condition: PatientDiagnosis;
  period: PeriodInput;
};

export type Mutation = {
  __typename?: 'Mutation';
  admitCareSettingPatient?: Maybe<CareSettingPatientAdmission>;
  createWardBedTransfer: WardBedTransfer;
  createWardDischarge: WardDischarge;
  createWardPatient: WardPatient;
  updateWardBed: WardBed;
  updateWardDischarge: WardDischarge;
};

export type MutationAdmitCareSettingPatientArgs = {
  input: CareSettingPatientAdmissionInput;
};

export type MutationCreateWardBedTransferArgs = {
  input: WardBedTransferInput;
};

export type MutationCreateWardDischargeArgs = {
  input: WardDischargeInput;
};

export type MutationCreateWardPatientArgs = {
  input: WardPatientInput;
};

export type MutationUpdateWardBedArgs = {
  input: WardBedInput;
};

export type MutationUpdateWardDischargeArgs = {
  input: WardDischargeInput;
};

export type ObservationType =
  | 'BLOOD_PRESSURE'
  | 'CONFUSION'
  | 'HEIGHT'
  | 'PULSE'
  | 'RESPIRATORY_RATE'
  | 'SPO2'
  | 'TEMPERATURE'
  | 'WEIGHT';

export type ObservationValue =
  | BloodPressure
  | BodyHeight
  | BodyTemperature
  | BodyWeight
  | Pulse
  | Spo2;

export type OriginType =
  | 'A_E'
  | 'MEDICAL_ASS_UNIT'
  | 'NONE'
  | 'OUTPATIENTS'
  | 'SURGICAL_ASS_UNIT';

export type PatientDiagnosis = 'COPD' | 'HYPERTENSION' | 'NONE';

export type PatientWardPreferencesType =
  | 'FEMALE_WARD'
  | 'GENDER_NEUTRAL'
  | 'MALE_WARD'
  | 'NONE';

export type Period = {
  __typename?: 'Period';
  end?: Maybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
};

export type PeriodInput = {
  end?: InputMaybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
};

export type Person = Identifier & {
  __typename?: 'Person';
  attributes: PersonAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type PersonAttributes = {
  __typename?: 'PersonAttributes';
  audit?: Maybe<Audit>;
  contactInformation?: Maybe<PersonContactInformation>;
  contactParty?: Maybe<Array<Maybe<PersonContactPartyFields>>>;
  generalPracticeRegistration?: Maybe<PersonGeneralPracticeRegistration>;
  /** Unique hospital reference to identify a patient e.g. for ESNEFT this is the hospital no. in the format of D1234567 */
  hospitalPatientReference?: Maybe<Scalars['String']>;
  personalInformation: PersonPersonalInformation;
  registeredGPSurgery?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type PersonContactInformation = {
  __typename?: 'PersonContactInformation';
  primaryContactNumber?: Maybe<Scalars['String']>;
  primaryEmailAddress?: Maybe<Scalars['String']>;
};

export type PersonContactInformationInput = {
  primaryContactNumber?: InputMaybe<Scalars['String']>;
  primaryEmailAddress?: InputMaybe<Scalars['String']>;
};

export type PersonContactPartyFields = {
  __typename?: 'PersonContactPartyFields';
  humanNames?: Maybe<Array<Maybe<AimHumanName>>>;
  personRelationshipType?: Maybe<PersonRelationshipType>;
  primaryContactNumber?: Maybe<Scalars['String']>;
};

export type PersonContactPartyInput = {
  humanNames?: InputMaybe<Array<InputMaybe<AimHumanNameInput>>>;
  personRelationshipType: PersonRelationshipType;
  primaryContactNumber?: InputMaybe<Scalars['String']>;
};

export type PersonGeneralPracticeRegistration = {
  __typename?: 'PersonGeneralPracticeRegistration';
  accountId: Scalars['ID'];
  linkageKey: Scalars['String'];
  odsCode: Scalars['String'];
  patientIdentifier: Scalars['ID'];
};

export type PersonInput = {
  contactInformation?: InputMaybe<PersonContactInformationInput>;
  contactParty?: InputMaybe<Array<InputMaybe<PersonContactPartyInput>>>;
  personalInformation: PersonPersonalInformationInput;
  registeredGPSurgery?: InputMaybe<Scalars['String']>;
};

export type PersonPersonalInformation = {
  __typename?: 'PersonPersonalInformation';
  dateOfBirth?: Maybe<Scalars['Date']>;
  ethnicity?: Maybe<Ethnicity>;
  firstName: Scalars['String'];
  genderAssignedAtBirth?: Maybe<GenderAssignedAtBirth>;
  genderIdentifiedAs: GenderIdentifiedAs;
  mobility?: Maybe<Mobility>;
  nhsNumber?: Maybe<Scalars['String']>;
  primaryAddress?: Maybe<PrimaryAddress>;
  pronouns?: Maybe<Pronouns>;
  surname: Scalars['String'];
  title: Scalars['String'];
};

export type PersonPersonalInformationInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  ethnicity?: InputMaybe<Ethnicity>;
  firstName: Scalars['String'];
  genderAssignedAtBirth?: InputMaybe<GenderAssignedAtBirth>;
  genderIdentifiedAs: GenderIdentifiedAs;
  mobility?: InputMaybe<Mobility>;
  nhsNumber?: InputMaybe<Scalars['String']>;
  primaryAddress?: InputMaybe<PrimaryAddressInput>;
  pronouns?: InputMaybe<Pronouns>;
  surname: Scalars['String'];
  title: Scalars['String'];
};

export type PersonRelationshipType = 'NEXT_OF_KIN' | 'NONE' | 'OTHER';

export type PharmacyStatusType =
  | 'DELAYED'
  | 'NONE'
  | 'NOT_REQUIRED'
  | 'ORDERED'
  | 'RECEIVED';

export type PorterStatusType = 'NONE' | 'NOT_REQUIRED' | 'REQUESTED';

export type PrimaryAddress = {
  __typename?: 'PrimaryAddress';
  line1?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type PrimaryAddressInput = {
  line1?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

export type Pronouns = 'HE_HIM' | 'SHE_HER' | 'THEY_THEM';

export type Pulse = {
  __typename?: 'Pulse';
  /** The beats per minute */
  bpm: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  careSettingPatientsByCareSetting?: Maybe<Array<CareSettingPatient>>;
  userProfile?: Maybe<UserProfile>;
  wardBedTransfers?: Maybe<Array<WardBedTransfer>>;
  wardBeds?: Maybe<Array<WardBed>>;
  wardGroupStatistics?: Maybe<Array<WardStatistic>>;
  wardStatistics?: Maybe<Array<WardStatistic>>;
};

export type QueryCareSettingPatientsByCareSettingArgs = {
  careSettingId: Scalars['ID'];
  unallocated?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserProfileArgs = {
  safId: Scalars['String'];
};

export type QueryWardBedTransfersArgs = {
  careSettingId: Scalars['ID'];
};

export type QueryWardBedsArgs = {
  wardId: Scalars['ID'];
};

export type QueryWardGroupStatisticsArgs = {
  careSettingId: Scalars['ID'];
  wardType?: WardType;
};

export type QueryWardStatisticsArgs = {
  careSettingId: Scalars['ID'];
  wardType?: WardType;
};

export type ReasonForBedClosure =
  | 'BROKEN'
  | 'ESTATE_WORKS'
  | 'NONE'
  | 'OTHER'
  | 'PATIENT_DEATH'
  | 'RING_FENCED'
  | 'STAFFING';

export type RegistrationStatus = 'UNVERIFIED' | 'VERIFIED';

export type SpecialtyType =
  | 'CARDIOLOGY'
  | 'EAR_NOSE_AND_THROAT'
  | 'ENDOC_DIABETES'
  | 'GASTRO'
  | 'GENI_MEDICINE'
  | 'GEN_INT_MEDICINE'
  | 'GEN_SURGERY'
  | 'GERI_MEDICINE'
  | 'MAX_FAX'
  | 'NEUROLOGY'
  | 'NONE'
  | 'ORTHO'
  | 'PLASTIC_SURGERY'
  | 'REN_MEDICINE'
  | 'RESP_MEDICINE'
  | 'RHREU'
  | 'STROKE_MEDICINE'
  | 'UROLOGY'
  | 'VASC_SURGERY';

export type Spo2 = {
  __typename?: 'Spo2';
  /** The oxygen saturation percentage */
  oxygenSaturation: Scalars['Int'];
};

export type Statistics = {
  __typename?: 'Statistics';
  group?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value: Scalars['Int'];
};

export type TtoStatusType = 'COMPLETE' | 'INCOMPLETE' | 'NONE';

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions';
  dateTime: Scalars['DateTime'];
  decision?: Maybe<TermsAndConditionsStatus>;
  version: Scalars['String'];
};

export type TermsAndConditionsStatus = 'ACCEPTED' | 'DECLINED';

export type User = Identifier & {
  __typename?: 'User';
  attributes: UserAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserAttributes = {
  __typename?: 'UserAttributes';
  audit?: Maybe<Audit>;
  defaultCareSettingId: Scalars['ID'];
  defaultWardId: Scalars['ID'];
  registrationStatus?: Maybe<RegistrationStatus>;
  safId: Scalars['String'];
  termsAndConditions?: Maybe<TermsAndConditions>;
  /** @deprecated To be removed in a future release */
  userRegistrationId: Scalars['ID'];
  userState?: Maybe<UserState>;
};

export type UserInput = {
  defaultCareSettingId: Scalars['ID'];
  defaultWardId: Scalars['ID'];
  userRegistrationId: Scalars['ID'];
};

export type UserModuleAvailability = Identifier & {
  __typename?: 'UserModuleAvailability';
  attributes: UserModuleAvailabilityAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserModuleAvailabilityAttributes = {
  __typename?: 'UserModuleAvailabilityAttributes';
  availabilityStatus: UserModuleAvailabilityStatus;
  careSettingModuleId: Scalars['ID'];
  moduleId: ModuleIdentifier;
  moduleName: Scalars['String'];
  moduleShortName: Scalars['String'];
  userId: Scalars['ID'];
};

export type UserModuleAvailabilityStatus = 'ACCESSIBLE' | 'VISIBLE';

export type UserModuleRole = Identifier & {
  __typename?: 'UserModuleRole';
  attributes: UserModuleRoleAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserModuleRoleAttributes = {
  __typename?: 'UserModuleRoleAttributes';
  careSettingModuleId: Scalars['ID'];
  moduleRoleId: ModuleRole;
  userId: Scalars['ID'];
};

export type UserProfile = Identifier & {
  __typename?: 'UserProfile';
  attributes: UserAttributes;
  careSetting?: Maybe<CareSetting>;
  careSettingClinician?: Maybe<CareSettingClinician>;
  id: Scalars['ID'];
  type: Scalars['String'];
  userModuleAvailability: Array<Maybe<UserModuleAvailability>>;
  userModuleRoles: Array<Maybe<UserModuleRole>>;
};

export type UserState = 'DISABLED' | 'ENABLED';

export type WaitingForDischarge =
  | 'FINAL_ASSESSMENT'
  | 'NONE'
  | 'OCCUPATIONAL_THERAPY_SIGN_OFF'
  | 'PHARMACY_ITEMS'
  | 'PHYSIOTHERAPY_SIGN_OFF'
  | 'RESULTS_OF_INVESTIGATIONS'
  | 'SOCIAL_CARE_INPUT';

export type WaitingForPotentialDischarge =
  | 'BLOOD_TEST_RESULTS'
  | 'CLINICAL_SIGN_OFF'
  | 'CT_SCAN'
  | 'FURTHER_INVESTIGATIONS'
  | 'MEDICAL_REVIEWS'
  | 'MRI_SCAN'
  | 'NONE'
  | 'OTHER'
  | 'PATIENT_TRANSPORT'
  | 'RESULTS_OF_INVESTIGATIONS'
  | 'SPECIALITY_NURSE_REVIEW'
  | 'SURGICAL_REVIEW'
  | 'VIRTUAL_WARD_REFERRAL'
  | 'X_RAYS';

export type Ward = Identifier & {
  __typename?: 'Ward';
  attributes: WardAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardAttributes = {
  __typename?: 'WardAttributes';
  audit?: Maybe<Audit>;
  name: Scalars['String'];
  specialty?: Maybe<SpecialtyType>;
  staffActualCount?: Maybe<Scalars['Int']>;
  staffRequiredCount?: Maybe<Scalars['Int']>;
  wardGroupId: Scalars['ID'];
  wardType?: Maybe<WardType>;
};

export type WardBed = Identifier & {
  __typename?: 'WardBed';
  attributes: WardBedAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
  wardBedTransfers?: Maybe<Array<Maybe<WardBedTransfer>>>;
  wardPatient?: Maybe<WardPatient>;
};

export type WardBedAttributes = {
  __typename?: 'WardBedAttributes';
  audit?: Maybe<Audit>;
  bedCleaningMethod?: Maybe<BedCleaningMethod>;
  bedGender: GenderType;
  bedStatus: BedStatusType;
  bedStatusComments?: Maybe<Scalars['String']>;
  bedStatusDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use reasonForBedClosure */
  bedStatusReasonCode?: Maybe<BedStatusReasonCodeType>;
  /** @deprecated Use otherReasonForBedClosure */
  bedStatusReasonComments?: Maybe<Scalars['String']>;
  client?: Maybe<ClientWardBedAttributes>;
  otherReasonForBedClosure?: Maybe<Scalars['String']>;
  reasonForBedClosure?: Maybe<ReasonForBedClosure>;
  res_bedCleaning?: Maybe<WardBedCleaningResolvedAttributes>;
  wardBedReference: Scalars['String'];
  wardGroupId: Scalars['ID'];
  wardId: Scalars['ID'];
};

export type WardBedCleaningResolvedAttributes = {
  __typename?: 'WardBedCleaningResolvedAttributes';
  cleanDuration: BedCleaningDuration;
  expectedCompletedDateTime?: Maybe<Scalars['DateTime']>;
  hasExceededDuration: Scalars['Boolean'];
};

export type WardBedInput = {
  bedCleaningMethod?: InputMaybe<BedCleaningMethod>;
  bedGender: GenderType;
  bedStatus: BedStatusType;
  bedStatusComments?: InputMaybe<Scalars['String']>;
  bedStatusDateTime?: InputMaybe<Scalars['DateTime']>;
  bedStatusReasonCode?: InputMaybe<BedStatusReasonCodeType>;
  bedStatusReasonComments?: InputMaybe<Scalars['String']>;
  careSettingId: Scalars['ID'];
  otherReasonForBedClosure?: InputMaybe<Scalars['String']>;
  reasonForBedClosure?: InputMaybe<ReasonForBedClosure>;
  version?: InputMaybe<Scalars['Int']>;
  wardBedId: Scalars['ID'];
  wardBedReference: Scalars['String'];
  wardGroupId: Scalars['ID'];
  wardId: Scalars['ID'];
};

export type WardBedTransfer = Identifier & {
  __typename?: 'WardBedTransfer';
  attributes: WardBedTransferAttributes;
  careSettingPatient?: Maybe<CareSettingPatient>;
  fromWard?: Maybe<Ward>;
  fromWardBed?: Maybe<WardBed>;
  id: Scalars['ID'];
  toWard?: Maybe<Ward>;
  toWardBed?: Maybe<WardBed>;
  type: Scalars['String'];
};

export type WardBedTransferAttributes = {
  __typename?: 'WardBedTransferAttributes';
  arrivalToWardDateTime?: Maybe<Scalars['DateTime']>;
  audit?: Maybe<Audit>;
  bedCleaningMethod?: Maybe<BedCleaningMethod>;
  bedCleaningStatus?: Maybe<BedCleaningStatus>;
  bedTransferStatus: BedTransferStatus;
  bedTransferType: BedTransferType;
  careSettingId: Scalars['ID'];
  careSettingPatientId: Scalars['ID'];
  client?: Maybe<ClientWardBedTransferAttributes>;
  fromWardBedId: Scalars['ID'];
  fromWardId: Scalars['ID'];
  fromWardPatientId: Scalars['ID'];
  interWardTransferReason: InterWardTransferReason;
  intraWardTransferReason: IntraWardTransferReason;
  otherTransferReason?: Maybe<Scalars['String']>;
  porterStatus?: Maybe<PorterStatusType>;
  specialty?: Maybe<SpecialtyType>;
  toWardBedId: Scalars['ID'];
  toWardId: Scalars['ID'];
  toWardPatientId: Scalars['ID'];
};

export type WardBedTransferInput = {
  arrivalToWardDateTime?: InputMaybe<Scalars['DateTime']>;
  bedCleaningMethod?: InputMaybe<BedCleaningMethod>;
  bedCleaningStatus?: InputMaybe<BedCleaningStatus>;
  bedTransferStatus: BedTransferStatus;
  bedTransferType: BedTransferType;
  careSettingId: Scalars['ID'];
  careSettingPatientId: Scalars['ID'];
  fromWardBedId: Scalars['ID'];
  fromWardId: Scalars['ID'];
  fromWardPatientId: Scalars['ID'];
  interWardTransferReason: InterWardTransferReason;
  intraWardTransferReason: IntraWardTransferReason;
  otherTransferReason?: InputMaybe<Scalars['String']>;
  porterStatus?: InputMaybe<PorterStatusType>;
  specialty?: InputMaybe<SpecialtyType>;
  toWardBedId: Scalars['ID'];
  toWardId: Scalars['ID'];
};

export type WardDischarge = Identifier & {
  __typename?: 'WardDischarge';
  attributes: WardDischargeAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardDischargeAttributes = {
  __typename?: 'WardDischargeAttributes';
  audit?: Maybe<Audit>;
  bedCleaningStatus?: Maybe<BedCleaningStatus>;
  carePackageStatus: CarePackageStatusType;
  client?: Maybe<ClientWardDischargeAttributes>;
  clinicallyReadyForDischargeDateTime?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Scalars['String']>;
  decisionMakerLastVisitedDateTime?: Maybe<Scalars['DateTime']>;
  decisionMakerYN: YesNoType;
  dischargeActualDateTime?: Maybe<Scalars['DateTime']>;
  dischargeAssessmentOutcome?: Maybe<AwaitingFromDischargeAssess>;
  dischargeExpectedDateTime?: Maybe<Scalars['DateTime']>;
  dischargeReason?: Maybe<DischargeReasonType>;
  dischargeStatus: DischargeStatusType;
  /** @deprecated Use dischargeStatusFailedReasons */
  dischargeStatusFailedReason?: Maybe<DischargeStatusFailed>;
  dischargeStatusFailedReasonOther?: Maybe<Scalars['String']>;
  dischargeStatusFailedReasons?: Maybe<Array<Maybe<DischargeStatusFailed>>>;
  dischargeToAssessDateTime?: Maybe<Scalars['DateTime']>;
  isolationType: IsolationType;
  isolationTypeOther?: Maybe<Scalars['String']>;
  pharmacy?: Maybe<PharmacyStatusType>;
  porterStatus?: Maybe<PorterStatusType>;
  readyForDischargeDateTime?: Maybe<Scalars['DateTime']>;
  requireDischargeToAssess?: Maybe<YesNoType>;
  requiresIsolationYN?: Maybe<YesNoType>;
  socialCareYN?: Maybe<YesNoType>;
  toTakeOutStatus: TtoStatusType;
  /** @deprecated Use waitingReasonForClinicallyReadyForDischarge */
  waitingReasonForClinicallyReadyForDischarge?: Maybe<WaitingForDischarge>;
  /** @deprecated Use waitingReasonsForPotentialDischarge */
  waitingReasonForPotentialDischarge?: Maybe<WaitingForPotentialDischarge>;
  waitingReasonOther?: Maybe<Scalars['String']>;
  waitingReasonsForClinicallyReadyForDischarge?: Maybe<
    Array<Maybe<WaitingForDischarge>>
  >;
  waitingReasonsForPotentialDischarge?: Maybe<
    Array<Maybe<WaitingForPotentialDischarge>>
  >;
};

export type WardDischargeInput = {
  bedCleaningMethod?: InputMaybe<BedCleaningMethod>;
  bedCleaningStatus?: InputMaybe<BedCleaningStatus>;
  bedTransferConfirmed?: InputMaybe<Scalars['Boolean']>;
  carePackageStatus: CarePackageStatusType;
  careSettingId: Scalars['ID'];
  clinicallyReadyForDischargeDateTime?: InputMaybe<Scalars['DateTime']>;
  comments?: InputMaybe<Scalars['String']>;
  decisionMakerLastVisitedDateTime?: InputMaybe<Scalars['DateTime']>;
  decisionMakerYN: YesNoType;
  dischargeActualDateTime?: InputMaybe<Scalars['DateTime']>;
  dischargeAssessmentOutcome?: InputMaybe<AwaitingFromDischargeAssess>;
  dischargeExpectedDateTime?: InputMaybe<Scalars['DateTime']>;
  dischargeReason?: InputMaybe<DischargeReasonType>;
  dischargeStatus: DischargeStatusType;
  dischargeStatusFailedReason?: InputMaybe<DischargeStatusFailed>;
  dischargeStatusFailedReasonOther?: InputMaybe<Scalars['String']>;
  dischargeStatusFailedReasons?: InputMaybe<
    Array<InputMaybe<DischargeStatusFailed>>
  >;
  dischargeToAssessDateTime?: InputMaybe<Scalars['DateTime']>;
  isolationType: IsolationType;
  isolationTypeOther?: InputMaybe<Scalars['String']>;
  pharmacy?: InputMaybe<PharmacyStatusType>;
  porterStatus?: InputMaybe<PorterStatusType>;
  readyForDischargeDateTime?: InputMaybe<Scalars['DateTime']>;
  requireDischargeToAssess?: InputMaybe<YesNoType>;
  requiresIsolationYN?: InputMaybe<YesNoType>;
  socialCareYN?: InputMaybe<YesNoType>;
  toTakeOutStatus: TtoStatusType;
  version?: InputMaybe<Scalars['Int']>;
  waitingReasonForClinicallyReadyForDischarge?: InputMaybe<WaitingForDischarge>;
  waitingReasonForPotentialDischarge?: InputMaybe<WaitingForPotentialDischarge>;
  waitingReasonOther?: InputMaybe<Scalars['String']>;
  waitingReasonsForClinicallyReadyForDischarge?: InputMaybe<
    Array<InputMaybe<WaitingForDischarge>>
  >;
  waitingReasonsForPotentialDischarge?: InputMaybe<
    Array<InputMaybe<WaitingForPotentialDischarge>>
  >;
  wardDischargeId?: InputMaybe<Scalars['ID']>;
  wardGroupId: Scalars['ID'];
  wardId: Scalars['ID'];
  wardPatientId: Scalars['ID'];
};

export type WardPatient = Identifier & {
  __typename?: 'WardPatient';
  attributes: WardPatientAttributes;
  careSettingPatient?: Maybe<CareSettingPatient>;
  id: Scalars['ID'];
  lastObservation?: Maybe<WardPatientObservation>;
  lastReview?: Maybe<WardPatientObservationReview>;
  type: Scalars['String'];
  ward?: Maybe<Ward>;
  wardDischarge?: Maybe<WardDischarge>;
};

export type WardPatientAttributes = {
  __typename?: 'WardPatientAttributes';
  admissionDateTime?: Maybe<Scalars['DateTime']>;
  arrivalExpectedDateTime?: Maybe<Scalars['DateTime']>;
  audit?: Maybe<Audit>;
  careSettingId: Scalars['ID'];
  careSettingPatientId: Scalars['ID'];
  client?: Maybe<ClientWardPatientAttributes>;
  comments?: Maybe<Scalars['String']>;
  covid19Status: Covid19Status;
  decisionToAdmitDateTime?: Maybe<Scalars['DateTime']>;
  isolationType: IsolationType;
  isolationTypeOther?: Maybe<Scalars['String']>;
  lastObservationDateTime?: Maybe<Scalars['DateTime']>;
  lastReviewedDateTime?: Maybe<Scalars['DateTime']>;
  monitoredConditions?: Maybe<Array<MonitoredCondition>>;
  observationTypes?: Maybe<Array<ObservationType>>;
  origin?: Maybe<OriginType>;
  outlierYN?: Maybe<YesNoType>;
  porterStatus?: Maybe<PorterStatusType>;
  predictedLengthOfStay?: Maybe<Scalars['ISO8601Duration']>;
  primaryDiagnosis: PatientDiagnosis;
  requiresIsolationYN?: Maybe<YesNoType>;
  res_activeMonitoredConditions: Array<MonitoredCondition>;
  secondaryDiagnosis: PatientDiagnosis;
  specialty?: Maybe<SpecialtyType>;
  wardId: Scalars['ID'];
};

export type WardPatientInput = {
  admissionDateTime?: InputMaybe<Scalars['DateTime']>;
  arrivalExpectedDateTime?: InputMaybe<Scalars['DateTime']>;
  careSettingId: Scalars['ID'];
  careSettingPatientId: Scalars['ID'];
  comments?: InputMaybe<Scalars['String']>;
  covid19Status: Covid19Status;
  isolationType: IsolationType;
  isolationTypeOther?: InputMaybe<Scalars['String']>;
  monitoredConditions?: InputMaybe<Array<InputMaybe<MonitoredConditionInput>>>;
  observationTypes?: InputMaybe<Array<InputMaybe<ObservationType>>>;
  origin: OriginType;
  outlierYN: YesNoType;
  porterStatus?: InputMaybe<PorterStatusType>;
  predictedLengthOfStay?: InputMaybe<Scalars['ISO8601Duration']>;
  primaryDiagnosis?: InputMaybe<PatientDiagnosis>;
  requiresIsolationYN?: InputMaybe<YesNoType>;
  secondaryDiagnosis?: InputMaybe<PatientDiagnosis>;
  specialty: SpecialtyType;
  wardBedId: Scalars['ID'];
  wardId: Scalars['ID'];
};

export type WardPatientObservation = Identifier & {
  __typename?: 'WardPatientObservation';
  attributes: WardPatientObservationAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardPatientObservationAttributes = {
  __typename?: 'WardPatientObservationAttributes';
  audit?: Maybe<Audit>;
  observationType: ObservationType;
  recordedDateTime: Scalars['DateTime'];
  res_observationValue?: Maybe<ObservationValue>;
  value: Scalars['Float'];
  wardPatientId: Scalars['ID'];
};

export type WardPatientObservationReview = Identifier & {
  __typename?: 'WardPatientObservationReview';
  attributes: WardPatientObservationReviewAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardPatientObservationReviewAttributes = {
  __typename?: 'WardPatientObservationReviewAttributes';
  audit?: Maybe<Audit>;
  recordedDateTime: Scalars['DateTime'];
  wardPatientId: Scalars['ID'];
};

export type WardStatistic = Identifier & {
  __typename?: 'WardStatistic';
  attributes: WardStatisticsAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardStatisticsAttributes = {
  __typename?: 'WardStatisticsAttributes';
  capacity: Scalars['Int'];
  /** Only used for Ward Statistics to determine if a Ward is active */
  isActiveRecord?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  statistics: Array<Statistics>;
  wardGroupId: Scalars['ID'];
  wardId: Scalars['ID'];
};

export type WardType = 'PHYSICAL' | 'VIRTUAL';

export type YesNoType = 'NO' | 'NONE' | 'YES';

export type CareSettingPatientFragmentFragment = {
  __typename?: 'CareSettingPatient';
  id: string;
  attributes: {
    __typename?: 'CareSettingPatientAttributes';
    decisionToAdmitDateTime?: string | null;
    decisionToAdmitWaitTime?: number | null;
    careSettingId?: string | null;
    isolationType: IsolationType;
    isolationTypeOther?: string | null;
    requiresIsolationYN?: YesNoType | null;
    origin?: OriginType | null;
    specialty?: SpecialtyType | null;
    socialCareYN?: YesNoType | null;
    admissionComments?: string | null;
    patientWardPreference?: PatientWardPreferencesType | null;
    furtherWardPreferences?: string | null;
    client?: {
      __typename?: 'ClientCareSettingPatientAttributes';
      decisionToAdmitDate: string;
      decisionToAdmitTime: string;
    } | null;
  };
};

export type PersonFragmentFragment = {
  __typename?: 'Person';
  id: string;
  attributes: {
    __typename?: 'PersonAttributes';
    hospitalPatientReference?: string | null;
    personalInformation: {
      __typename?: 'PersonPersonalInformation';
      dateOfBirth?: string | null;
      firstName: string;
      surname: string;
      genderIdentifiedAs: GenderIdentifiedAs;
    };
  };
};

export type WardBedFragmentFragment = {
  __typename?: 'WardBed';
  id: string;
  attributes: {
    __typename?: 'WardBedAttributes';
    wardGroupId: string;
    wardId: string;
    bedGender: GenderType;
    bedStatus: BedStatusType;
    wardBedReference: string;
    bedStatusComments?: string | null;
    bedStatusDateTime?: string | null;
    otherReasonForBedClosure?: string | null;
    reasonForBedClosure?: ReasonForBedClosure | null;
    bedCleaningMethod?: BedCleaningMethod | null;
    audit?: { __typename?: 'Audit'; version: number } | null;
    res_bedCleaning?: {
      __typename?: 'WardBedCleaningResolvedAttributes';
      expectedCompletedDateTime?: string | null;
      hasExceededDuration: boolean;
      cleanDuration: { __typename?: 'BedCleaningDuration'; asText: string };
    } | null;
    client?: {
      __typename?: 'ClientWardBedAttributes';
      bedStatusDate: string;
      bedStatusTime: string;
    } | null;
  };
};

export type WardDischargeFragmentFragment = {
  __typename?: 'WardDischarge';
  id: string;
  attributes: {
    __typename?: 'WardDischargeAttributes';
    decisionMakerYN: YesNoType;
    dischargeExpectedDateTime?: string | null;
    dischargeStatus: DischargeStatusType;
    carePackageStatus: CarePackageStatusType;
    toTakeOutStatus: TtoStatusType;
    dischargeActualDateTime?: string | null;
    dischargeStatusFailedReasons?: Array<DischargeStatusFailed | null> | null;
    dischargeStatusFailedReasonOther?: string | null;
    isolationType: IsolationType;
    isolationTypeOther?: string | null;
    requiresIsolationYN?: YesNoType | null;
    porterStatus?: PorterStatusType | null;
    socialCareYN?: YesNoType | null;
    dischargeAssessmentOutcome?: AwaitingFromDischargeAssess | null;
    readyForDischargeDateTime?: string | null;
    clinicallyReadyForDischargeDateTime?: string | null;
    requireDischargeToAssess?: YesNoType | null;
    waitingReasonsForPotentialDischarge?: Array<WaitingForPotentialDischarge | null> | null;
    waitingReasonsForClinicallyReadyForDischarge?: Array<WaitingForDischarge | null> | null;
    pharmacy?: PharmacyStatusType | null;
    decisionMakerLastVisitedDateTime?: string | null;
    waitingReasonOther?: string | null;
    dischargeToAssessDateTime?: string | null;
    bedCleaningStatus?: BedCleaningStatus | null;
    audit?: { __typename?: 'Audit'; version: number } | null;
    client?: {
      __typename?: 'ClientWardDischargeAttributes';
      dischargeExpectedDate: string;
      dischargeExpectedTime: string;
      dischargeActualDate: string;
      dischargeActualTime: string;
      readyForDischargeDate: string;
      readyForDischargeTime: string;
      clinicallyReadyForDischargeDate: string;
      clinicallyReadyForDischargeTime: string;
    } | null;
  };
};

export type WardPatientFragmentFragment = {
  __typename?: 'WardPatient';
  id: string;
  attributes: {
    __typename?: 'WardPatientAttributes';
    isolationTypeOther?: string | null;
    isolationType: IsolationType;
    requiresIsolationYN?: YesNoType | null;
    outlierYN?: YesNoType | null;
    porterStatus?: PorterStatusType | null;
    admissionDateTime?: string | null;
    arrivalExpectedDateTime?: string | null;
    comments?: string | null;
    origin?: OriginType | null;
    specialty?: SpecialtyType | null;
    client?: {
      __typename?: 'ClientWardPatientAttributes';
      admissionDate: string;
      arrivalExpectedDate: string;
      admissionTime: string;
      arrivalExpectedTime: string;
    } | null;
  };
};

export type AdmitCareSettingPatientMutationVariables = Exact<{
  input: CareSettingPatientAdmissionInput;
}>;

export type AdmitCareSettingPatientMutation = {
  __typename?: 'Mutation';
  admitCareSettingPatient?: {
    __typename?: 'CareSettingPatientAdmission';
    id: string;
  } | null;
};

export type CreateWardBedTransferMutationVariables = Exact<{
  input: WardBedTransferInput;
}>;

export type CreateWardBedTransferMutation = {
  __typename?: 'Mutation';
  createWardBedTransfer: { __typename?: 'WardBedTransfer'; id: string };
};

export type CreateWardDischargeMutationVariables = Exact<{
  input: WardDischargeInput;
}>;

export type CreateWardDischargeMutation = {
  __typename?: 'Mutation';
  createWardDischarge: { __typename?: 'WardDischarge'; id: string };
};

export type CreateWardPatientMutationVariables = Exact<{
  input: WardPatientInput;
}>;

export type CreateWardPatientMutation = {
  __typename?: 'Mutation';
  createWardPatient: { __typename?: 'WardPatient'; id: string };
};

export type UpdateWardBedMutationVariables = Exact<{
  input: WardBedInput;
}>;

export type UpdateWardBedMutation = {
  __typename?: 'Mutation';
  updateWardBed: { __typename?: 'WardBed'; id: string };
};

export type UpdateWardDischargeMutationVariables = Exact<{
  input: WardDischargeInput;
}>;

export type UpdateWardDischargeMutation = {
  __typename?: 'Mutation';
  updateWardDischarge: { __typename?: 'WardDischarge'; id: string };
};

export type GetPatientsQueryVariables = Exact<{
  careSettingId: Scalars['ID'];
  unallocated?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetPatientsQuery = {
  __typename?: 'Query';
  careSettingPatientsByCareSetting?: Array<{
    __typename?: 'CareSettingPatient';
    id: string;
    person: {
      __typename?: 'Person';
      id: string;
      attributes: {
        __typename?: 'PersonAttributes';
        hospitalPatientReference?: string | null;
        personalInformation: {
          __typename?: 'PersonPersonalInformation';
          dateOfBirth?: string | null;
          firstName: string;
          surname: string;
          genderIdentifiedAs: GenderIdentifiedAs;
        };
      };
    };
    attributes: {
      __typename?: 'CareSettingPatientAttributes';
      decisionToAdmitDateTime?: string | null;
      decisionToAdmitWaitTime?: number | null;
      careSettingId?: string | null;
      isolationType: IsolationType;
      isolationTypeOther?: string | null;
      requiresIsolationYN?: YesNoType | null;
      origin?: OriginType | null;
      specialty?: SpecialtyType | null;
      socialCareYN?: YesNoType | null;
      admissionComments?: string | null;
      patientWardPreference?: PatientWardPreferencesType | null;
      furtherWardPreferences?: string | null;
      client?: {
        __typename?: 'ClientCareSettingPatientAttributes';
        decisionToAdmitDate: string;
        decisionToAdmitTime: string;
      } | null;
    };
  }> | null;
};

export type GetUserProfileQueryVariables = Exact<{
  safId: Scalars['String'];
}>;

export type GetUserProfileQuery = {
  __typename?: 'Query';
  userProfile?: {
    __typename?: 'UserProfile';
    id: string;
    attributes: {
      __typename?: 'UserAttributes';
      userState?: UserState | null;
      defaultCareSettingId: string;
    };
    userModuleAvailability: Array<{
      __typename?: 'UserModuleAvailability';
      attributes: {
        __typename?: 'UserModuleAvailabilityAttributes';
        availabilityStatus: UserModuleAvailabilityStatus;
        moduleId: ModuleIdentifier;
        moduleName: string;
        moduleShortName: string;
        userId: string;
      };
    } | null>;
    userModuleRoles: Array<{
      __typename?: 'UserModuleRole';
      attributes: {
        __typename?: 'UserModuleRoleAttributes';
        moduleRoleId: ModuleRole;
      };
    } | null>;
    careSetting?: {
      __typename?: 'CareSetting';
      attributes: {
        __typename?: 'CareSettingAttributes';
        name: string;
        shortName: string;
      };
    } | null;
  } | null;
};

export type GetWardBedOptionsQueryVariables = Exact<{
  wardId: Scalars['ID'];
}>;

export type GetWardBedOptionsQuery = {
  __typename?: 'Query';
  wardBeds?: Array<{
    __typename?: 'WardBed';
    id: string;
    wardPatient?: {
      __typename?: 'WardPatient';
      id: string;
      careSettingPatient?: {
        __typename?: 'CareSettingPatient';
        id: string;
        person: {
          __typename?: 'Person';
          id: string;
          attributes: {
            __typename?: 'PersonAttributes';
            hospitalPatientReference?: string | null;
            personalInformation: {
              __typename?: 'PersonPersonalInformation';
              dateOfBirth?: string | null;
              firstName: string;
              surname: string;
              genderIdentifiedAs: GenderIdentifiedAs;
            };
          };
        };
        attributes: {
          __typename?: 'CareSettingPatientAttributes';
          decisionToAdmitDateTime?: string | null;
          decisionToAdmitWaitTime?: number | null;
          careSettingId?: string | null;
          isolationType: IsolationType;
          isolationTypeOther?: string | null;
          requiresIsolationYN?: YesNoType | null;
          origin?: OriginType | null;
          specialty?: SpecialtyType | null;
          socialCareYN?: YesNoType | null;
          admissionComments?: string | null;
          patientWardPreference?: PatientWardPreferencesType | null;
          furtherWardPreferences?: string | null;
          client?: {
            __typename?: 'ClientCareSettingPatientAttributes';
            decisionToAdmitDate: string;
            decisionToAdmitTime: string;
          } | null;
        };
      } | null;
      attributes: {
        __typename?: 'WardPatientAttributes';
        isolationTypeOther?: string | null;
        isolationType: IsolationType;
        requiresIsolationYN?: YesNoType | null;
        outlierYN?: YesNoType | null;
        porterStatus?: PorterStatusType | null;
        admissionDateTime?: string | null;
        arrivalExpectedDateTime?: string | null;
        comments?: string | null;
        origin?: OriginType | null;
        specialty?: SpecialtyType | null;
        client?: {
          __typename?: 'ClientWardPatientAttributes';
          admissionDate: string;
          arrivalExpectedDate: string;
          admissionTime: string;
          arrivalExpectedTime: string;
        } | null;
      };
    } | null;
    attributes: {
      __typename?: 'WardBedAttributes';
      wardGroupId: string;
      wardId: string;
      bedGender: GenderType;
      bedStatus: BedStatusType;
      wardBedReference: string;
      bedStatusComments?: string | null;
      bedStatusDateTime?: string | null;
      otherReasonForBedClosure?: string | null;
      reasonForBedClosure?: ReasonForBedClosure | null;
      bedCleaningMethod?: BedCleaningMethod | null;
      audit?: { __typename?: 'Audit'; version: number } | null;
      res_bedCleaning?: {
        __typename?: 'WardBedCleaningResolvedAttributes';
        expectedCompletedDateTime?: string | null;
        hasExceededDuration: boolean;
        cleanDuration: { __typename?: 'BedCleaningDuration'; asText: string };
      } | null;
      client?: {
        __typename?: 'ClientWardBedAttributes';
        bedStatusDate: string;
        bedStatusTime: string;
      } | null;
    };
  }> | null;
};

export type GetWardBedTransfersQueryVariables = Exact<{
  careSettingId: Scalars['ID'];
}>;

export type GetWardBedTransfersQuery = {
  __typename?: 'Query';
  wardBedTransfers?: Array<{
    __typename?: 'WardBedTransfer';
    id: string;
    attributes: {
      __typename?: 'WardBedTransferAttributes';
      intraWardTransferReason: IntraWardTransferReason;
      interWardTransferReason: InterWardTransferReason;
      bedTransferType: BedTransferType;
      bedTransferStatus: BedTransferStatus;
      porterStatus?: PorterStatusType | null;
      arrivalToWardDateTime?: string | null;
      toWardId: string;
      fromWardId: string;
      specialty?: SpecialtyType | null;
      bedCleaningStatus?: BedCleaningStatus | null;
      otherTransferReason?: string | null;
      bedCleaningMethod?: BedCleaningMethod | null;
      client?: {
        __typename?: 'ClientWardBedTransferAttributes';
        arrivalToWardDate: string;
        arrivalToWardTime: string;
      } | null;
    };
    toWard?: {
      __typename?: 'Ward';
      id: string;
      attributes: { __typename?: 'WardAttributes'; name: string };
    } | null;
    careSettingPatient?: {
      __typename?: 'CareSettingPatient';
      id: string;
      person: {
        __typename?: 'Person';
        id: string;
        attributes: {
          __typename?: 'PersonAttributes';
          hospitalPatientReference?: string | null;
          personalInformation: {
            __typename?: 'PersonPersonalInformation';
            dateOfBirth?: string | null;
            firstName: string;
            surname: string;
            genderIdentifiedAs: GenderIdentifiedAs;
          };
        };
      };
      attributes: {
        __typename?: 'CareSettingPatientAttributes';
        decisionToAdmitDateTime?: string | null;
        decisionToAdmitWaitTime?: number | null;
        careSettingId?: string | null;
        isolationType: IsolationType;
        isolationTypeOther?: string | null;
        requiresIsolationYN?: YesNoType | null;
        origin?: OriginType | null;
        specialty?: SpecialtyType | null;
        socialCareYN?: YesNoType | null;
        admissionComments?: string | null;
        patientWardPreference?: PatientWardPreferencesType | null;
        furtherWardPreferences?: string | null;
        client?: {
          __typename?: 'ClientCareSettingPatientAttributes';
          decisionToAdmitDate: string;
          decisionToAdmitTime: string;
        } | null;
      };
    } | null;
    toWardBed?: {
      __typename?: 'WardBed';
      id: string;
      attributes: {
        __typename?: 'WardBedAttributes';
        wardGroupId: string;
        wardId: string;
        bedGender: GenderType;
        bedStatus: BedStatusType;
        wardBedReference: string;
        bedStatusComments?: string | null;
        bedStatusDateTime?: string | null;
        otherReasonForBedClosure?: string | null;
        reasonForBedClosure?: ReasonForBedClosure | null;
        bedCleaningMethod?: BedCleaningMethod | null;
        audit?: { __typename?: 'Audit'; version: number } | null;
        res_bedCleaning?: {
          __typename?: 'WardBedCleaningResolvedAttributes';
          expectedCompletedDateTime?: string | null;
          hasExceededDuration: boolean;
          cleanDuration: { __typename?: 'BedCleaningDuration'; asText: string };
        } | null;
        client?: {
          __typename?: 'ClientWardBedAttributes';
          bedStatusDate: string;
          bedStatusTime: string;
        } | null;
      };
    } | null;
    fromWardBed?: {
      __typename?: 'WardBed';
      id: string;
      wardPatient?: {
        __typename?: 'WardPatient';
        id: string;
        careSettingPatient?: {
          __typename?: 'CareSettingPatient';
          id: string;
          person: {
            __typename?: 'Person';
            id: string;
            attributes: {
              __typename?: 'PersonAttributes';
              hospitalPatientReference?: string | null;
              personalInformation: {
                __typename?: 'PersonPersonalInformation';
                dateOfBirth?: string | null;
                firstName: string;
                surname: string;
                genderIdentifiedAs: GenderIdentifiedAs;
              };
            };
          };
          attributes: {
            __typename?: 'CareSettingPatientAttributes';
            decisionToAdmitDateTime?: string | null;
            decisionToAdmitWaitTime?: number | null;
            careSettingId?: string | null;
            isolationType: IsolationType;
            isolationTypeOther?: string | null;
            requiresIsolationYN?: YesNoType | null;
            origin?: OriginType | null;
            specialty?: SpecialtyType | null;
            socialCareYN?: YesNoType | null;
            admissionComments?: string | null;
            patientWardPreference?: PatientWardPreferencesType | null;
            furtherWardPreferences?: string | null;
            client?: {
              __typename?: 'ClientCareSettingPatientAttributes';
              decisionToAdmitDate: string;
              decisionToAdmitTime: string;
            } | null;
          };
        } | null;
        wardDischarge?: {
          __typename?: 'WardDischarge';
          id: string;
          attributes: {
            __typename?: 'WardDischargeAttributes';
            decisionMakerYN: YesNoType;
            dischargeExpectedDateTime?: string | null;
            dischargeStatus: DischargeStatusType;
            carePackageStatus: CarePackageStatusType;
            toTakeOutStatus: TtoStatusType;
            dischargeActualDateTime?: string | null;
            dischargeStatusFailedReasons?: Array<DischargeStatusFailed | null> | null;
            dischargeStatusFailedReasonOther?: string | null;
            isolationType: IsolationType;
            isolationTypeOther?: string | null;
            requiresIsolationYN?: YesNoType | null;
            porterStatus?: PorterStatusType | null;
            socialCareYN?: YesNoType | null;
            dischargeAssessmentOutcome?: AwaitingFromDischargeAssess | null;
            readyForDischargeDateTime?: string | null;
            clinicallyReadyForDischargeDateTime?: string | null;
            requireDischargeToAssess?: YesNoType | null;
            waitingReasonsForPotentialDischarge?: Array<WaitingForPotentialDischarge | null> | null;
            waitingReasonsForClinicallyReadyForDischarge?: Array<WaitingForDischarge | null> | null;
            pharmacy?: PharmacyStatusType | null;
            decisionMakerLastVisitedDateTime?: string | null;
            waitingReasonOther?: string | null;
            dischargeToAssessDateTime?: string | null;
            bedCleaningStatus?: BedCleaningStatus | null;
            audit?: { __typename?: 'Audit'; version: number } | null;
            client?: {
              __typename?: 'ClientWardDischargeAttributes';
              dischargeExpectedDate: string;
              dischargeExpectedTime: string;
              dischargeActualDate: string;
              dischargeActualTime: string;
              readyForDischargeDate: string;
              readyForDischargeTime: string;
              clinicallyReadyForDischargeDate: string;
              clinicallyReadyForDischargeTime: string;
            } | null;
          };
        } | null;
        attributes: {
          __typename?: 'WardPatientAttributes';
          isolationTypeOther?: string | null;
          isolationType: IsolationType;
          requiresIsolationYN?: YesNoType | null;
          outlierYN?: YesNoType | null;
          porterStatus?: PorterStatusType | null;
          admissionDateTime?: string | null;
          arrivalExpectedDateTime?: string | null;
          comments?: string | null;
          origin?: OriginType | null;
          specialty?: SpecialtyType | null;
          client?: {
            __typename?: 'ClientWardPatientAttributes';
            admissionDate: string;
            arrivalExpectedDate: string;
            admissionTime: string;
            arrivalExpectedTime: string;
          } | null;
        };
      } | null;
      attributes: {
        __typename?: 'WardBedAttributes';
        wardGroupId: string;
        wardId: string;
        bedGender: GenderType;
        bedStatus: BedStatusType;
        wardBedReference: string;
        bedStatusComments?: string | null;
        bedStatusDateTime?: string | null;
        otherReasonForBedClosure?: string | null;
        reasonForBedClosure?: ReasonForBedClosure | null;
        bedCleaningMethod?: BedCleaningMethod | null;
        audit?: { __typename?: 'Audit'; version: number } | null;
        res_bedCleaning?: {
          __typename?: 'WardBedCleaningResolvedAttributes';
          expectedCompletedDateTime?: string | null;
          hasExceededDuration: boolean;
          cleanDuration: { __typename?: 'BedCleaningDuration'; asText: string };
        } | null;
        client?: {
          __typename?: 'ClientWardBedAttributes';
          bedStatusDate: string;
          bedStatusTime: string;
        } | null;
      };
    } | null;
    fromWard?: {
      __typename?: 'Ward';
      id: string;
      attributes: { __typename?: 'WardAttributes'; name: string };
    } | null;
  }> | null;
};

export type GetWardBedsQueryVariables = Exact<{
  wardId: Scalars['ID'];
}>;

export type GetWardBedsQuery = {
  __typename?: 'Query';
  wardBeds?: Array<{
    __typename?: 'WardBed';
    id: string;
    wardPatient?: {
      __typename?: 'WardPatient';
      id: string;
      careSettingPatient?: {
        __typename?: 'CareSettingPatient';
        id: string;
        person: {
          __typename?: 'Person';
          id: string;
          attributes: {
            __typename?: 'PersonAttributes';
            hospitalPatientReference?: string | null;
            personalInformation: {
              __typename?: 'PersonPersonalInformation';
              dateOfBirth?: string | null;
              firstName: string;
              surname: string;
              genderIdentifiedAs: GenderIdentifiedAs;
            };
          };
        };
        attributes: {
          __typename?: 'CareSettingPatientAttributes';
          decisionToAdmitDateTime?: string | null;
          decisionToAdmitWaitTime?: number | null;
          careSettingId?: string | null;
          isolationType: IsolationType;
          isolationTypeOther?: string | null;
          requiresIsolationYN?: YesNoType | null;
          origin?: OriginType | null;
          specialty?: SpecialtyType | null;
          socialCareYN?: YesNoType | null;
          admissionComments?: string | null;
          patientWardPreference?: PatientWardPreferencesType | null;
          furtherWardPreferences?: string | null;
          client?: {
            __typename?: 'ClientCareSettingPatientAttributes';
            decisionToAdmitDate: string;
            decisionToAdmitTime: string;
          } | null;
        };
      } | null;
      wardDischarge?: {
        __typename?: 'WardDischarge';
        id: string;
        attributes: {
          __typename?: 'WardDischargeAttributes';
          decisionMakerYN: YesNoType;
          dischargeExpectedDateTime?: string | null;
          dischargeStatus: DischargeStatusType;
          carePackageStatus: CarePackageStatusType;
          toTakeOutStatus: TtoStatusType;
          dischargeActualDateTime?: string | null;
          dischargeStatusFailedReasons?: Array<DischargeStatusFailed | null> | null;
          dischargeStatusFailedReasonOther?: string | null;
          isolationType: IsolationType;
          isolationTypeOther?: string | null;
          requiresIsolationYN?: YesNoType | null;
          porterStatus?: PorterStatusType | null;
          socialCareYN?: YesNoType | null;
          dischargeAssessmentOutcome?: AwaitingFromDischargeAssess | null;
          readyForDischargeDateTime?: string | null;
          clinicallyReadyForDischargeDateTime?: string | null;
          requireDischargeToAssess?: YesNoType | null;
          waitingReasonsForPotentialDischarge?: Array<WaitingForPotentialDischarge | null> | null;
          waitingReasonsForClinicallyReadyForDischarge?: Array<WaitingForDischarge | null> | null;
          pharmacy?: PharmacyStatusType | null;
          decisionMakerLastVisitedDateTime?: string | null;
          waitingReasonOther?: string | null;
          dischargeToAssessDateTime?: string | null;
          bedCleaningStatus?: BedCleaningStatus | null;
          audit?: { __typename?: 'Audit'; version: number } | null;
          client?: {
            __typename?: 'ClientWardDischargeAttributes';
            dischargeExpectedDate: string;
            dischargeExpectedTime: string;
            dischargeActualDate: string;
            dischargeActualTime: string;
            readyForDischargeDate: string;
            readyForDischargeTime: string;
            clinicallyReadyForDischargeDate: string;
            clinicallyReadyForDischargeTime: string;
          } | null;
        };
      } | null;
      attributes: {
        __typename?: 'WardPatientAttributes';
        isolationTypeOther?: string | null;
        isolationType: IsolationType;
        requiresIsolationYN?: YesNoType | null;
        outlierYN?: YesNoType | null;
        porterStatus?: PorterStatusType | null;
        admissionDateTime?: string | null;
        arrivalExpectedDateTime?: string | null;
        comments?: string | null;
        origin?: OriginType | null;
        specialty?: SpecialtyType | null;
        client?: {
          __typename?: 'ClientWardPatientAttributes';
          admissionDate: string;
          arrivalExpectedDate: string;
          admissionTime: string;
          arrivalExpectedTime: string;
        } | null;
      };
    } | null;
    wardBedTransfers?: Array<{
      __typename?: 'WardBedTransfer';
      id: string;
      attributes: {
        __typename?: 'WardBedTransferAttributes';
        bedTransferStatus: BedTransferStatus;
        bedTransferType: BedTransferType;
        bedCleaningStatus?: BedCleaningStatus | null;
        bedCleaningMethod?: BedCleaningMethod | null;
        arrivalToWardDateTime?: string | null;
        fromWardId: string;
        interWardTransferReason: InterWardTransferReason;
      };
      careSettingPatient?: {
        __typename?: 'CareSettingPatient';
        id: string;
        person: {
          __typename?: 'Person';
          id: string;
          attributes: {
            __typename?: 'PersonAttributes';
            hospitalPatientReference?: string | null;
            personalInformation: {
              __typename?: 'PersonPersonalInformation';
              dateOfBirth?: string | null;
              firstName: string;
              surname: string;
              genderIdentifiedAs: GenderIdentifiedAs;
            };
          };
        };
        attributes: {
          __typename?: 'CareSettingPatientAttributes';
          decisionToAdmitDateTime?: string | null;
          decisionToAdmitWaitTime?: number | null;
          careSettingId?: string | null;
          isolationType: IsolationType;
          isolationTypeOther?: string | null;
          requiresIsolationYN?: YesNoType | null;
          origin?: OriginType | null;
          specialty?: SpecialtyType | null;
          socialCareYN?: YesNoType | null;
          admissionComments?: string | null;
          patientWardPreference?: PatientWardPreferencesType | null;
          furtherWardPreferences?: string | null;
          client?: {
            __typename?: 'ClientCareSettingPatientAttributes';
            decisionToAdmitDate: string;
            decisionToAdmitTime: string;
          } | null;
        };
      } | null;
      toWard?: {
        __typename?: 'Ward';
        id: string;
        attributes: { __typename?: 'WardAttributes'; name: string };
      } | null;
      toWardBed?: {
        __typename?: 'WardBed';
        id: string;
        attributes: {
          __typename?: 'WardBedAttributes';
          wardGroupId: string;
          wardId: string;
          bedGender: GenderType;
          bedStatus: BedStatusType;
          wardBedReference: string;
          bedStatusComments?: string | null;
          bedStatusDateTime?: string | null;
          otherReasonForBedClosure?: string | null;
          reasonForBedClosure?: ReasonForBedClosure | null;
          bedCleaningMethod?: BedCleaningMethod | null;
          audit?: { __typename?: 'Audit'; version: number } | null;
          res_bedCleaning?: {
            __typename?: 'WardBedCleaningResolvedAttributes';
            expectedCompletedDateTime?: string | null;
            hasExceededDuration: boolean;
            cleanDuration: {
              __typename?: 'BedCleaningDuration';
              asText: string;
            };
          } | null;
          client?: {
            __typename?: 'ClientWardBedAttributes';
            bedStatusDate: string;
            bedStatusTime: string;
          } | null;
        };
      } | null;
      fromWard?: {
        __typename?: 'Ward';
        id: string;
        attributes: { __typename?: 'WardAttributes'; name: string };
      } | null;
      fromWardBed?: {
        __typename?: 'WardBed';
        id: string;
        attributes: {
          __typename?: 'WardBedAttributes';
          wardGroupId: string;
          wardId: string;
          bedGender: GenderType;
          bedStatus: BedStatusType;
          wardBedReference: string;
          bedStatusComments?: string | null;
          bedStatusDateTime?: string | null;
          otherReasonForBedClosure?: string | null;
          reasonForBedClosure?: ReasonForBedClosure | null;
          bedCleaningMethod?: BedCleaningMethod | null;
          audit?: { __typename?: 'Audit'; version: number } | null;
          res_bedCleaning?: {
            __typename?: 'WardBedCleaningResolvedAttributes';
            expectedCompletedDateTime?: string | null;
            hasExceededDuration: boolean;
            cleanDuration: {
              __typename?: 'BedCleaningDuration';
              asText: string;
            };
          } | null;
          client?: {
            __typename?: 'ClientWardBedAttributes';
            bedStatusDate: string;
            bedStatusTime: string;
          } | null;
        };
      } | null;
    } | null> | null;
    attributes: {
      __typename?: 'WardBedAttributes';
      wardGroupId: string;
      wardId: string;
      bedGender: GenderType;
      bedStatus: BedStatusType;
      wardBedReference: string;
      bedStatusComments?: string | null;
      bedStatusDateTime?: string | null;
      otherReasonForBedClosure?: string | null;
      reasonForBedClosure?: ReasonForBedClosure | null;
      bedCleaningMethod?: BedCleaningMethod | null;
      audit?: { __typename?: 'Audit'; version: number } | null;
      res_bedCleaning?: {
        __typename?: 'WardBedCleaningResolvedAttributes';
        expectedCompletedDateTime?: string | null;
        hasExceededDuration: boolean;
        cleanDuration: { __typename?: 'BedCleaningDuration'; asText: string };
      } | null;
      client?: {
        __typename?: 'ClientWardBedAttributes';
        bedStatusDate: string;
        bedStatusTime: string;
      } | null;
    };
  }> | null;
};

export type GetWardGroupStatisticsQueryVariables = Exact<{
  careSettingId: Scalars['ID'];
}>;

export type GetWardGroupStatisticsQuery = {
  __typename?: 'Query';
  wardGroupStatistics?: Array<{
    __typename?: 'WardStatistic';
    id: string;
    type: string;
    attributes: {
      __typename?: 'WardStatisticsAttributes';
      wardGroupId: string;
      capacity: number;
      name: string;
      statistics: Array<{
        __typename?: 'Statistics';
        name: string;
        value: number;
      }>;
    };
  }> | null;
};

export type GetWardOptionsQueryVariables = Exact<{
  careSettingId: Scalars['ID'];
}>;

export type GetWardOptionsQuery = {
  __typename?: 'Query';
  wardStatistics?: Array<{
    __typename?: 'WardStatistic';
    attributes: {
      __typename?: 'WardStatisticsAttributes';
      wardId: string;
      name: string;
      statistics: Array<{
        __typename?: 'Statistics';
        name: string;
        value: number;
      }>;
    };
  }> | null;
};

export type GetWardStatisticsQueryVariables = Exact<{
  careSettingId: Scalars['ID'];
}>;

export type GetWardStatisticsQuery = {
  __typename?: 'Query';
  wardStatistics?: Array<{
    __typename?: 'WardStatistic';
    id: string;
    type: string;
    attributes: {
      __typename?: 'WardStatisticsAttributes';
      wardId: string;
      wardGroupId: string;
      capacity: number;
      name: string;
      isActiveRecord?: boolean | null;
      statistics: Array<{
        __typename?: 'Statistics';
        name: string;
        value: number;
        group?: string | null;
      }>;
    };
  }> | null;
};

export const CareSettingPatientFragmentFragmentDoc = gql`
  fragment CareSettingPatientFragment on CareSettingPatient {
    id
    attributes {
      decisionToAdmitDateTime
      decisionToAdmitWaitTime
      careSettingId
      isolationType
      isolationTypeOther
      requiresIsolationYN
      origin
      specialty
      socialCareYN
      admissionComments
      patientWardPreference
      furtherWardPreferences
      client @client {
        decisionToAdmitDate
        decisionToAdmitTime
      }
    }
  }
`;
export const PersonFragmentFragmentDoc = gql`
  fragment PersonFragment on Person {
    id
    attributes {
      hospitalPatientReference
      personalInformation {
        dateOfBirth
        firstName
        surname
        genderIdentifiedAs
      }
    }
  }
`;
export const WardBedFragmentFragmentDoc = gql`
  fragment WardBedFragment on WardBed {
    id
    attributes {
      audit {
        version
      }
      wardGroupId
      wardId
      bedGender
      bedStatus
      wardBedReference
      bedStatusComments
      bedStatusDateTime
      otherReasonForBedClosure
      reasonForBedClosure
      bedCleaningMethod
      res_bedCleaning {
        cleanDuration {
          asText
        }
        expectedCompletedDateTime
        hasExceededDuration
      }
      client @client {
        bedStatusDate
        bedStatusTime
      }
    }
  }
`;
export const WardDischargeFragmentFragmentDoc = gql`
  fragment WardDischargeFragment on WardDischarge {
    id
    attributes {
      audit {
        version
      }
      decisionMakerYN
      dischargeExpectedDateTime
      dischargeStatus
      carePackageStatus
      toTakeOutStatus
      dischargeActualDateTime
      dischargeStatusFailedReasons
      dischargeStatusFailedReasonOther
      isolationType
      isolationTypeOther
      requiresIsolationYN
      porterStatus
      socialCareYN
      dischargeAssessmentOutcome
      readyForDischargeDateTime
      clinicallyReadyForDischargeDateTime
      requireDischargeToAssess
      waitingReasonsForPotentialDischarge
      waitingReasonsForClinicallyReadyForDischarge
      pharmacy
      decisionMakerLastVisitedDateTime
      waitingReasonOther
      dischargeToAssessDateTime
      bedCleaningStatus
      client @client {
        dischargeExpectedDate
        dischargeExpectedTime
        dischargeActualDate
        dischargeActualTime
        readyForDischargeDate
        readyForDischargeTime
        clinicallyReadyForDischargeDate
        clinicallyReadyForDischargeTime
      }
    }
  }
`;
export const WardPatientFragmentFragmentDoc = gql`
  fragment WardPatientFragment on WardPatient {
    id
    attributes {
      isolationTypeOther
      isolationType
      requiresIsolationYN
      outlierYN
      porterStatus
      admissionDateTime
      arrivalExpectedDateTime
      comments
      origin
      specialty
      client @client {
        admissionDate
        arrivalExpectedDate
        admissionTime
        arrivalExpectedTime
      }
    }
  }
`;
export const AdmitCareSettingPatientDocument = gql`
  mutation AdmitCareSettingPatient($input: CareSettingPatientAdmissionInput!) {
    admitCareSettingPatient(input: $input) {
      id
    }
  }
`;
export type AdmitCareSettingPatientMutationFn = Apollo.MutationFunction<
  AdmitCareSettingPatientMutation,
  AdmitCareSettingPatientMutationVariables
>;

/**
 * __useAdmitCareSettingPatientMutation__
 *
 * To run a mutation, you first call `useAdmitCareSettingPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmitCareSettingPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [admitCareSettingPatientMutation, { data, loading, error }] = useAdmitCareSettingPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmitCareSettingPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdmitCareSettingPatientMutation,
    AdmitCareSettingPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdmitCareSettingPatientMutation,
    AdmitCareSettingPatientMutationVariables
  >(AdmitCareSettingPatientDocument, options);
}
export type AdmitCareSettingPatientMutationHookResult = ReturnType<
  typeof useAdmitCareSettingPatientMutation
>;
export type AdmitCareSettingPatientMutationResult =
  Apollo.MutationResult<AdmitCareSettingPatientMutation>;
export type AdmitCareSettingPatientMutationOptions = Apollo.BaseMutationOptions<
  AdmitCareSettingPatientMutation,
  AdmitCareSettingPatientMutationVariables
>;
export const CreateWardBedTransferDocument = gql`
  mutation CreateWardBedTransfer($input: WardBedTransferInput!) {
    createWardBedTransfer(input: $input) {
      id
    }
  }
`;
export type CreateWardBedTransferMutationFn = Apollo.MutationFunction<
  CreateWardBedTransferMutation,
  CreateWardBedTransferMutationVariables
>;

/**
 * __useCreateWardBedTransferMutation__
 *
 * To run a mutation, you first call `useCreateWardBedTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWardBedTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWardBedTransferMutation, { data, loading, error }] = useCreateWardBedTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWardBedTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWardBedTransferMutation,
    CreateWardBedTransferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWardBedTransferMutation,
    CreateWardBedTransferMutationVariables
  >(CreateWardBedTransferDocument, options);
}
export type CreateWardBedTransferMutationHookResult = ReturnType<
  typeof useCreateWardBedTransferMutation
>;
export type CreateWardBedTransferMutationResult =
  Apollo.MutationResult<CreateWardBedTransferMutation>;
export type CreateWardBedTransferMutationOptions = Apollo.BaseMutationOptions<
  CreateWardBedTransferMutation,
  CreateWardBedTransferMutationVariables
>;
export const CreateWardDischargeDocument = gql`
  mutation CreateWardDischarge($input: WardDischargeInput!) {
    createWardDischarge(input: $input) {
      id
    }
  }
`;
export type CreateWardDischargeMutationFn = Apollo.MutationFunction<
  CreateWardDischargeMutation,
  CreateWardDischargeMutationVariables
>;

/**
 * __useCreateWardDischargeMutation__
 *
 * To run a mutation, you first call `useCreateWardDischargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWardDischargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWardDischargeMutation, { data, loading, error }] = useCreateWardDischargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWardDischargeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWardDischargeMutation,
    CreateWardDischargeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWardDischargeMutation,
    CreateWardDischargeMutationVariables
  >(CreateWardDischargeDocument, options);
}
export type CreateWardDischargeMutationHookResult = ReturnType<
  typeof useCreateWardDischargeMutation
>;
export type CreateWardDischargeMutationResult =
  Apollo.MutationResult<CreateWardDischargeMutation>;
export type CreateWardDischargeMutationOptions = Apollo.BaseMutationOptions<
  CreateWardDischargeMutation,
  CreateWardDischargeMutationVariables
>;
export const CreateWardPatientDocument = gql`
  mutation createWardPatient($input: WardPatientInput!) {
    createWardPatient(input: $input) {
      id
    }
  }
`;
export type CreateWardPatientMutationFn = Apollo.MutationFunction<
  CreateWardPatientMutation,
  CreateWardPatientMutationVariables
>;

/**
 * __useCreateWardPatientMutation__
 *
 * To run a mutation, you first call `useCreateWardPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWardPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWardPatientMutation, { data, loading, error }] = useCreateWardPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWardPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWardPatientMutation,
    CreateWardPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWardPatientMutation,
    CreateWardPatientMutationVariables
  >(CreateWardPatientDocument, options);
}
export type CreateWardPatientMutationHookResult = ReturnType<
  typeof useCreateWardPatientMutation
>;
export type CreateWardPatientMutationResult =
  Apollo.MutationResult<CreateWardPatientMutation>;
export type CreateWardPatientMutationOptions = Apollo.BaseMutationOptions<
  CreateWardPatientMutation,
  CreateWardPatientMutationVariables
>;
export const UpdateWardBedDocument = gql`
  mutation UpdateWardBed($input: WardBedInput!) {
    updateWardBed(input: $input) {
      id
    }
  }
`;
export type UpdateWardBedMutationFn = Apollo.MutationFunction<
  UpdateWardBedMutation,
  UpdateWardBedMutationVariables
>;

/**
 * __useUpdateWardBedMutation__
 *
 * To run a mutation, you first call `useUpdateWardBedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWardBedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWardBedMutation, { data, loading, error }] = useUpdateWardBedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWardBedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWardBedMutation,
    UpdateWardBedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWardBedMutation,
    UpdateWardBedMutationVariables
  >(UpdateWardBedDocument, options);
}
export type UpdateWardBedMutationHookResult = ReturnType<
  typeof useUpdateWardBedMutation
>;
export type UpdateWardBedMutationResult =
  Apollo.MutationResult<UpdateWardBedMutation>;
export type UpdateWardBedMutationOptions = Apollo.BaseMutationOptions<
  UpdateWardBedMutation,
  UpdateWardBedMutationVariables
>;
export const UpdateWardDischargeDocument = gql`
  mutation UpdateWardDischarge($input: WardDischargeInput!) {
    updateWardDischarge(input: $input) {
      id
    }
  }
`;
export type UpdateWardDischargeMutationFn = Apollo.MutationFunction<
  UpdateWardDischargeMutation,
  UpdateWardDischargeMutationVariables
>;

/**
 * __useUpdateWardDischargeMutation__
 *
 * To run a mutation, you first call `useUpdateWardDischargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWardDischargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWardDischargeMutation, { data, loading, error }] = useUpdateWardDischargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWardDischargeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWardDischargeMutation,
    UpdateWardDischargeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWardDischargeMutation,
    UpdateWardDischargeMutationVariables
  >(UpdateWardDischargeDocument, options);
}
export type UpdateWardDischargeMutationHookResult = ReturnType<
  typeof useUpdateWardDischargeMutation
>;
export type UpdateWardDischargeMutationResult =
  Apollo.MutationResult<UpdateWardDischargeMutation>;
export type UpdateWardDischargeMutationOptions = Apollo.BaseMutationOptions<
  UpdateWardDischargeMutation,
  UpdateWardDischargeMutationVariables
>;
export const GetPatientsDocument = gql`
  query GetPatients($careSettingId: ID!, $unallocated: Boolean) {
    careSettingPatientsByCareSetting(
      careSettingId: $careSettingId
      unallocated: $unallocated
    ) {
      ...CareSettingPatientFragment
      person {
        ...PersonFragment
      }
    }
  }
  ${CareSettingPatientFragmentFragmentDoc}
  ${PersonFragmentFragmentDoc}
`;

/**
 * __useGetPatientsQuery__
 *
 * To run a query within a React component, call `useGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsQuery({
 *   variables: {
 *      careSettingId: // value for 'careSettingId'
 *      unallocated: // value for 'unallocated'
 *   },
 * });
 */
export function useGetPatientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientsQuery,
    GetPatientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientsQuery, GetPatientsQueryVariables>(
    GetPatientsDocument,
    options
  );
}
export function useGetPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientsQuery,
    GetPatientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(
    GetPatientsDocument,
    options
  );
}
export type GetPatientsQueryHookResult = ReturnType<typeof useGetPatientsQuery>;
export type GetPatientsLazyQueryHookResult = ReturnType<
  typeof useGetPatientsLazyQuery
>;
export type GetPatientsQueryResult = Apollo.QueryResult<
  GetPatientsQuery,
  GetPatientsQueryVariables
>;
export const GetUserProfileDocument = gql`
  query GetUserProfile($safId: String!) {
    userProfile(safId: $safId) {
      id
      attributes {
        userState
        defaultCareSettingId
      }
      userModuleAvailability {
        attributes {
          availabilityStatus
          moduleId
          moduleName
          moduleShortName
          userId
        }
      }
      userModuleRoles {
        attributes {
          moduleRoleId
        }
      }
      careSetting {
        attributes {
          name
          shortName
        }
      }
    }
  }
`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      safId: // value for 'safId'
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options
  );
}
export function useGetUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options
  );
}
export type GetUserProfileQueryHookResult = ReturnType<
  typeof useGetUserProfileQuery
>;
export type GetUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetUserProfileLazyQuery
>;
export type GetUserProfileQueryResult = Apollo.QueryResult<
  GetUserProfileQuery,
  GetUserProfileQueryVariables
>;
export const GetWardBedOptionsDocument = gql`
  query GetWardBedOptions($wardId: ID!) {
    wardBeds(wardId: $wardId) {
      ...WardBedFragment
      wardPatient {
        ...WardPatientFragment
        careSettingPatient {
          ...CareSettingPatientFragment
          person {
            ...PersonFragment
          }
        }
      }
    }
  }
  ${WardBedFragmentFragmentDoc}
  ${WardPatientFragmentFragmentDoc}
  ${CareSettingPatientFragmentFragmentDoc}
  ${PersonFragmentFragmentDoc}
`;

/**
 * __useGetWardBedOptionsQuery__
 *
 * To run a query within a React component, call `useGetWardBedOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardBedOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardBedOptionsQuery({
 *   variables: {
 *      wardId: // value for 'wardId'
 *   },
 * });
 */
export function useGetWardBedOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardBedOptionsQuery,
    GetWardBedOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWardBedOptionsQuery,
    GetWardBedOptionsQueryVariables
  >(GetWardBedOptionsDocument, options);
}
export function useGetWardBedOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardBedOptionsQuery,
    GetWardBedOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWardBedOptionsQuery,
    GetWardBedOptionsQueryVariables
  >(GetWardBedOptionsDocument, options);
}
export type GetWardBedOptionsQueryHookResult = ReturnType<
  typeof useGetWardBedOptionsQuery
>;
export type GetWardBedOptionsLazyQueryHookResult = ReturnType<
  typeof useGetWardBedOptionsLazyQuery
>;
export type GetWardBedOptionsQueryResult = Apollo.QueryResult<
  GetWardBedOptionsQuery,
  GetWardBedOptionsQueryVariables
>;
export const GetWardBedTransfersDocument = gql`
  query GetWardBedTransfers($careSettingId: ID!) {
    wardBedTransfers(careSettingId: $careSettingId) {
      id
      attributes {
        intraWardTransferReason
        interWardTransferReason
        bedTransferType
        bedTransferStatus
        porterStatus
        arrivalToWardDateTime
        toWardId
        fromWardId
        specialty
        bedCleaningStatus
        otherTransferReason
        bedCleaningMethod
        client @client {
          arrivalToWardDate
          arrivalToWardTime
        }
      }
      toWard {
        id
        attributes {
          name
        }
      }
      careSettingPatient {
        ...CareSettingPatientFragment
        person {
          ...PersonFragment
        }
      }
      toWardBed {
        ...WardBedFragment
      }
      fromWardBed {
        ...WardBedFragment
        wardPatient {
          ...WardPatientFragment
          careSettingPatient {
            ...CareSettingPatientFragment
            person {
              ...PersonFragment
            }
          }
          wardDischarge {
            ...WardDischargeFragment
          }
        }
      }
      fromWard {
        id
        attributes {
          name
        }
      }
    }
  }
  ${CareSettingPatientFragmentFragmentDoc}
  ${PersonFragmentFragmentDoc}
  ${WardBedFragmentFragmentDoc}
  ${WardPatientFragmentFragmentDoc}
  ${WardDischargeFragmentFragmentDoc}
`;

/**
 * __useGetWardBedTransfersQuery__
 *
 * To run a query within a React component, call `useGetWardBedTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardBedTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardBedTransfersQuery({
 *   variables: {
 *      careSettingId: // value for 'careSettingId'
 *   },
 * });
 */
export function useGetWardBedTransfersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardBedTransfersQuery,
    GetWardBedTransfersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWardBedTransfersQuery,
    GetWardBedTransfersQueryVariables
  >(GetWardBedTransfersDocument, options);
}
export function useGetWardBedTransfersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardBedTransfersQuery,
    GetWardBedTransfersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWardBedTransfersQuery,
    GetWardBedTransfersQueryVariables
  >(GetWardBedTransfersDocument, options);
}
export type GetWardBedTransfersQueryHookResult = ReturnType<
  typeof useGetWardBedTransfersQuery
>;
export type GetWardBedTransfersLazyQueryHookResult = ReturnType<
  typeof useGetWardBedTransfersLazyQuery
>;
export type GetWardBedTransfersQueryResult = Apollo.QueryResult<
  GetWardBedTransfersQuery,
  GetWardBedTransfersQueryVariables
>;
export const GetWardBedsDocument = gql`
  query GetWardBeds($wardId: ID!) {
    wardBeds(wardId: $wardId) {
      ...WardBedFragment
      wardPatient {
        ...WardPatientFragment
        careSettingPatient {
          ...CareSettingPatientFragment
          person {
            ...PersonFragment
          }
        }
        wardDischarge {
          ...WardDischargeFragment
        }
      }
      wardBedTransfers {
        id
        attributes {
          bedTransferStatus
          bedTransferType
          bedCleaningStatus
          bedCleaningMethod
          arrivalToWardDateTime
          fromWardId
          interWardTransferReason
        }
        careSettingPatient {
          ...CareSettingPatientFragment
          person {
            ...PersonFragment
          }
        }
        toWard {
          id
          attributes {
            name
          }
        }
        toWardBed {
          ...WardBedFragment
        }
        fromWard {
          id
          attributes {
            name
          }
        }
        fromWardBed {
          ...WardBedFragment
        }
      }
    }
  }
  ${WardBedFragmentFragmentDoc}
  ${WardPatientFragmentFragmentDoc}
  ${CareSettingPatientFragmentFragmentDoc}
  ${PersonFragmentFragmentDoc}
  ${WardDischargeFragmentFragmentDoc}
`;

/**
 * __useGetWardBedsQuery__
 *
 * To run a query within a React component, call `useGetWardBedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardBedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardBedsQuery({
 *   variables: {
 *      wardId: // value for 'wardId'
 *   },
 * });
 */
export function useGetWardBedsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardBedsQuery,
    GetWardBedsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWardBedsQuery, GetWardBedsQueryVariables>(
    GetWardBedsDocument,
    options
  );
}
export function useGetWardBedsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardBedsQuery,
    GetWardBedsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWardBedsQuery, GetWardBedsQueryVariables>(
    GetWardBedsDocument,
    options
  );
}
export type GetWardBedsQueryHookResult = ReturnType<typeof useGetWardBedsQuery>;
export type GetWardBedsLazyQueryHookResult = ReturnType<
  typeof useGetWardBedsLazyQuery
>;
export type GetWardBedsQueryResult = Apollo.QueryResult<
  GetWardBedsQuery,
  GetWardBedsQueryVariables
>;
export const GetWardGroupStatisticsDocument = gql`
  query GetWardGroupStatistics($careSettingId: ID!) {
    wardGroupStatistics(careSettingId: $careSettingId) {
      id
      type
      attributes {
        wardGroupId
        capacity
        name
        statistics {
          name
          value
        }
      }
    }
  }
`;

/**
 * __useGetWardGroupStatisticsQuery__
 *
 * To run a query within a React component, call `useGetWardGroupStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardGroupStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardGroupStatisticsQuery({
 *   variables: {
 *      careSettingId: // value for 'careSettingId'
 *   },
 * });
 */
export function useGetWardGroupStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardGroupStatisticsQuery,
    GetWardGroupStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWardGroupStatisticsQuery,
    GetWardGroupStatisticsQueryVariables
  >(GetWardGroupStatisticsDocument, options);
}
export function useGetWardGroupStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardGroupStatisticsQuery,
    GetWardGroupStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWardGroupStatisticsQuery,
    GetWardGroupStatisticsQueryVariables
  >(GetWardGroupStatisticsDocument, options);
}
export type GetWardGroupStatisticsQueryHookResult = ReturnType<
  typeof useGetWardGroupStatisticsQuery
>;
export type GetWardGroupStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetWardGroupStatisticsLazyQuery
>;
export type GetWardGroupStatisticsQueryResult = Apollo.QueryResult<
  GetWardGroupStatisticsQuery,
  GetWardGroupStatisticsQueryVariables
>;
export const GetWardOptionsDocument = gql`
  query GetWardOptions($careSettingId: ID!) {
    wardStatistics(careSettingId: $careSettingId) {
      attributes {
        wardId
        name
        statistics {
          name
          value
        }
      }
    }
  }
`;

/**
 * __useGetWardOptionsQuery__
 *
 * To run a query within a React component, call `useGetWardOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardOptionsQuery({
 *   variables: {
 *      careSettingId: // value for 'careSettingId'
 *   },
 * });
 */
export function useGetWardOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardOptionsQuery,
    GetWardOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWardOptionsQuery, GetWardOptionsQueryVariables>(
    GetWardOptionsDocument,
    options
  );
}
export function useGetWardOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardOptionsQuery,
    GetWardOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWardOptionsQuery, GetWardOptionsQueryVariables>(
    GetWardOptionsDocument,
    options
  );
}
export type GetWardOptionsQueryHookResult = ReturnType<
  typeof useGetWardOptionsQuery
>;
export type GetWardOptionsLazyQueryHookResult = ReturnType<
  typeof useGetWardOptionsLazyQuery
>;
export type GetWardOptionsQueryResult = Apollo.QueryResult<
  GetWardOptionsQuery,
  GetWardOptionsQueryVariables
>;
export const GetWardStatisticsDocument = gql`
  query GetWardStatistics($careSettingId: ID!) {
    wardStatistics(careSettingId: $careSettingId) {
      id
      type
      attributes {
        wardId
        wardGroupId
        capacity
        name
        isActiveRecord
        statistics {
          name
          value
          group
        }
      }
    }
  }
`;

/**
 * __useGetWardStatisticsQuery__
 *
 * To run a query within a React component, call `useGetWardStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardStatisticsQuery({
 *   variables: {
 *      careSettingId: // value for 'careSettingId'
 *   },
 * });
 */
export function useGetWardStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardStatisticsQuery,
    GetWardStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWardStatisticsQuery,
    GetWardStatisticsQueryVariables
  >(GetWardStatisticsDocument, options);
}
export function useGetWardStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardStatisticsQuery,
    GetWardStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWardStatisticsQuery,
    GetWardStatisticsQueryVariables
  >(GetWardStatisticsDocument, options);
}
export type GetWardStatisticsQueryHookResult = ReturnType<
  typeof useGetWardStatisticsQuery
>;
export type GetWardStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetWardStatisticsLazyQuery
>;
export type GetWardStatisticsQueryResult = Apollo.QueryResult<
  GetWardStatisticsQuery,
  GetWardStatisticsQueryVariables
>;
