import {
  BedStatusClosedMap,
  BedStatusMap,
  CarePackageMap,
  DischargeStatusMap,
  FailedDischargeStatusMap,
  GenderIdentifiedAsMap,
  getOptionsForEnum,
  IsolationMap,
  IntraWardTransferReasonMap,
  OriginMap,
  PatientWardPreferencesMap,
  PorteringMap,
  SpecialtyMap,
  TTOStatusMap,
  YesNoMap,
  InterWardTransferReasonMap,
  WaitingForDischargeStatusMap,
  AwaitingDischargeAssessMap,
  DischargePathwayMap,
  PharmacyStatusMap,
  WaitingForPotentialDischargeStatusMap,
  DischargePathwayReasonMap,
  BedCleaningStatusMap,
  BedCleaningMethodMap,
} from 'mappings/enums';
import type { BedStatusType } from 'services';
import type { DisabledMultiChoiceButtonType } from 'types';

export const bedStatusOptions = (
  currentStatus?: BedStatusType,
  disabledOption?: BedStatusType
) => {
  const disabledOptionField: DisabledMultiChoiceButtonType = {
    field: disabledOption ?? '',
    toolTip: "You don't have access to this feature",
  };
  return getOptionsForEnum(
    BedStatusMap,
    currentStatus === 'CLEANING'
      ? ['PREALLOCATED']
      : [currentStatus!, 'PREALLOCATED'],
    true,
    disabledOption ? disabledOptionField : undefined
  );
};

export const bedStatusClosedOptions = getOptionsForEnum(BedStatusClosedMap);
export const dischargeStatusOptions = getOptionsForEnum(
  DischargeStatusMap,
  undefined,
  true
);
export const yesNoOptions = getOptionsForEnum(YesNoMap);
export const ttoOptions = getOptionsForEnum(TTOStatusMap);
export const bedCleaningStatusOptions = getOptionsForEnum(BedCleaningStatusMap);
export const bedCleaningMethodOptions = getOptionsForEnum(BedCleaningMethodMap);
export const pharmacyOptions = getOptionsForEnum(PharmacyStatusMap);
export const carePackageOptions = getOptionsForEnum(CarePackageMap);
export const porterOptions = getOptionsForEnum(PorteringMap);
export const isolationOptions = getOptionsForEnum(IsolationMap);
export const failedDischargeOptions = getOptionsForEnum(
  FailedDischargeStatusMap
);
export const waitingForPotentialDischargeOptions = getOptionsForEnum(
  WaitingForPotentialDischargeStatusMap,
  undefined,
  true
);
export const waitingForDischargeOptions = getOptionsForEnum(
  WaitingForDischargeStatusMap,
  undefined,
  true
);
export const awaitingDischargeAssessOptions = getOptionsForEnum(
  AwaitingDischargeAssessMap
);
export const dischargePathwayOptions = getOptionsForEnum(DischargePathwayMap);
export const dischargePathwayReasonOptions = getOptionsForEnum(
  DischargePathwayReasonMap,
  undefined,
  true
);
export const genderOptions = getOptionsForEnum(GenderIdentifiedAsMap);
export const patientWardPreferenceOptions = getOptionsForEnum(
  PatientWardPreferencesMap
);
export const originOptions = getOptionsForEnum(OriginMap);
export const specialtyOptions = getOptionsForEnum(SpecialtyMap);

export const intraWardTransferReasonOptions = getOptionsForEnum(
  IntraWardTransferReasonMap
);

export const interWardTransferReasonOptions = getOptionsForEnum(
  InterWardTransferReasonMap
);
