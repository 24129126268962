import { FormLayout, Loader } from '@bt-healthcare/ui-toolkit';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { yupResolver } from '@hookform/resolvers/yup';

import { PatientConfirmationModal } from 'components/Modal';
import { patientAdmissionSchema } from 'components/PatientAdmission/validationSchema';
import type { PatientAdmissionFormData } from 'components/PatientAdmission/types';
import { PatientAdmissionFormFields } from 'components/PatientAdmission/PatientAdmissionFormFields';
import { patientAdmissionFormMapping } from 'mappings/forms/patientAdmission';
import { useStore } from 'store';
import type { CareSettingPatientInput, PersonInput } from 'services';
import {
  GetPatientsDocument,
  useAdmitCareSettingPatientMutation,
} from 'services';
import { PageNotification } from 'components/PageNotification';
import { setFormDatesToUTC } from 'mappings/date.utils';
import { ROUTES } from 'App.constants';
import { omitGraphQLFields } from 'mappings/mapping.utils';

export const PatientAdmissionForm = () => {
  const [
    formData,
    setFormData,
    errorMessage,
    setMenuHeader,
    { careSettingId },
  ] = useStore(
    (state) => [
      state.patientAdmissionFormData,
      state.setPatientAdmissionForm,
      state.errorMessage,
      state.setMenuHeader,
      state.appConfig,
    ],
    shallow
  );
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const defaultValues = {
    requiresIsolationYN: '',
    isolationTypeOther: '',
    firstName: '',
    surname: '',
    patientWardPreference: '',
    furtherWardPreference: '',
  };

  useEffect(() => setMenuHeader('New Patient Form'));

  const {
    register,
    control,
    getValues,
    handleSubmit,
    resetField,
    formState: { isValid },
  } = useForm<PatientAdmissionFormData>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(patientAdmissionSchema),
  });

  const [
    admitCareSettingPatient,
    { loading: personLoading, error: personError },
  ] = useAdmitCareSettingPatientMutation();

  const handleClose = () => setModalOpen(false);

  const isLoading = personLoading;
  const hasError = personError;

  const onSubmit = () => {
    const mappedPatientData = patientAdmissionFormMapping(formData);
    const careSettingPatient = {
      ...mappedPatientData.attributes,
      careSettingId,
    };

    const careSettingPatientInput: CareSettingPatientInput = omitGraphQLFields(
      [],
      setFormDatesToUTC(careSettingPatient, ['decisionToAdmitDateTime'])
    );
    admitCareSettingPatient({
      variables: {
        input: {
          person: mappedPatientData.person.attributes as PersonInput,
          careSettingPatient: careSettingPatientInput,
        },
      },
      refetchQueries: [
        {
          query: GetPatientsDocument,
          variables: { careSettingId, unallocated: true },
        },
      ],
      onCompleted: () => {
        navigate(ROUTES.ADMISSIONS_CONFIRM);
      },
    });
  };

  const handleClick = () => {
    handleSubmit(onSubmit)();
    handleClose();
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <FormLayout
        id="admissionForm"
        header="New patient form"
        primaryButtonClick={() => {
          setFormData(getValues());
          setModalOpen(true);
        }}
        primaryButtonDisabled={!isValid}
      >
        {hasError && (
          <PageNotification message={errorMessage('admitting a patient')} />
        )}
        <PatientAdmissionFormFields
          register={register}
          control={control}
          resetField={resetField}
        />
      </FormLayout>
      {modalOpen && (
        <PatientConfirmationModal
          data={patientAdmissionFormMapping(formData)}
          modalOpen={modalOpen}
          handleClose={handleClose}
          handleClick={handleClick}
          title="Confirm patient details"
          strapLine="Please confirm you are about to admit a new patient to the system"
        />
      )}
    </>
  );
};
