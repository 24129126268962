import type { BedDischargeFormData } from 'components/BedDischarge/types';
import type { WardBed } from 'services';
import {
  convertDateStringForFormInput,
  convertTimeStringForFormInput,
} from 'mappings/date.utils';
import {
  getClinicallyReadyWaitingReasons,
  getDischargeAssessmentOutcome,
} from '../form.utils';

export const getClinicallyReadyDefaultValues = (
  defaultFields: Partial<BedDischargeFormData>,
  wardBed: WardBed
): Partial<BedDischargeFormData> => ({
  ...defaultFields,
  dischargeExpectedDate: convertDateStringForFormInput(
    wardBed.wardPatient?.wardDischarge?.attributes.client?.dischargeExpectedDate
  ),
  dischargeExpectedTime:
    wardBed.wardPatient?.wardDischarge?.attributes.client
      ?.dischargeExpectedTime ?? '',
  waitingReasonsForClinicallyReadyForDischarge:
    getClinicallyReadyWaitingReasons(wardBed),
  requireDischargeToAssess:
    wardBed.wardPatient?.wardDischarge?.attributes.requireDischargeToAssess ??
    '',
  dischargeAssessmentOutcome: getDischargeAssessmentOutcome(wardBed),
  clinicallyReadyForDischargeDate: convertDateStringForFormInput(
    wardBed.wardPatient?.wardDischarge?.attributes.client
      ?.clinicallyReadyForDischargeDate
  ),
  clinicallyReadyForDischargeTime:
    convertTimeStringForFormInput(
      wardBed.wardPatient?.wardDischarge?.attributes.client
        ?.clinicallyReadyForDischargeTime
    ) ?? '',
});
