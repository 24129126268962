import type { GraphQLErrors } from '@apollo/client/errors';
import type { EnvTypes } from '@bt-healthcare/ui-toolkit';
import {
  init,
  Replay,
  setUser,
  captureException,
  captureMessage,
} from '@sentry/react';

const needsMask = (ENV: EnvTypes) => ENV === 'staging' || ENV === 'live';

export const initializeSentry = (SENTRY_DSN: string, ENV: EnvTypes) =>
  init({
    dsn: SENTRY_DSN,
    environment: ENV,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    maxValueLength: 2048,
    integrations: [
      new Replay({
        maskAllText: needsMask(ENV),
        blockAllMedia: needsMask(ENV),
      }),
    ],
  });

export const setSentryUser = (userId: string) => setUser({ id: userId });

export const clearSentryUser = () => setUser(null);

export const captureSentryException = (
  exception: any,
  correlationId: string,
  tags: { [key: string]: string }
) => {
  captureException(exception, {
    tags: { ...tags, 'x-correlation-id': correlationId },
  });
};

export const captureSentryGraphQLErrors = (
  graphQLErrors: GraphQLErrors,
  operationName: string,
  correlationId: string
) => {
  captureMessage(`[GQL]${operationName}`, {
    extra: { errors: JSON.stringify(graphQLErrors) },
    tags: {
      type: 'graphql',
      'x-correlation-id': correlationId,
    },
  });
};
