import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { ConfirmationIllustration } from 'assets';
import { StatusFormConfirmation } from 'components/Form/StatusFormConfirmation';
import { bedDischargeFormMapping } from 'mappings/forms/bedDischarge';
import { bedConfirmationMapping } from 'mappings/datalists';
import { useStore } from 'store';
import { ROUTES } from 'App.constants';
import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';

export const BedDischargeFormConfirmation = () => {
  const wardId = useRedirectAndReturnState('wardId');
  const [wardHeader, bed, formData] = useStore(
    (state) => [state.wardHeader, state.bed, state.bedDischargeFormData],
    shallow
  );

  const data = bedDischargeFormMapping(bed, formData);
  const navigate = useNavigate();

  const handleClick = () =>
    navigate(ROUTES.WARDS_BED_DISCHARGE_HOME, { state: { wardId } });

  return (
    <StatusFormConfirmation
      strapLine="Your discharge status was successfully updated. Find your updated
    summary below."
      wardName={wardHeader.wardName}
      data={bedConfirmationMapping(data, 'DischargeStatus')}
      handleClick={handleClick}
      buttonText="View ward"
      icon={<ConfirmationIllustration />}
    />
  );
};
