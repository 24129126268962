import type { UseFormResetField } from 'react-hook-form';

import type { BedDischargeFormData } from 'components/BedDischarge/types';
import type { WardBed } from 'services';
import { getFailedDischargeStatusReasons } from '../form.utils';

export const resetDelayedFormFields = (
  resetField: UseFormResetField<BedDischargeFormData>,
  isCurrent: boolean,
  wardBed: WardBed
) => {
  resetField('failedDischargeStatusReasons', {
    defaultValue: isCurrent ? getFailedDischargeStatusReasons(wardBed) : null,
  });
  resetField('failedDischargeStatusReasonOther', {
    defaultValue: getFailedDischargeStatusReasons(wardBed)?.some(
      (reason) => reason?.value === 'OTHER'
    )
      ? wardBed.wardPatient?.wardDischarge?.attributes
          .dischargeStatusFailedReasonOther ?? ''
      : '',
  });
};
