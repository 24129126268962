import { fontSizes, device } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  font-size: ${fontSizes.sm};

  label {
    align-items: initial;
  }

  @media ${device.desktop} {
    font-size: ${fontSizes.base};
  }
`;
