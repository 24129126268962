import type {
  MenuBarOptions,
  MenuConfig,
  NavItem,
} from '@bt-healthcare/ui-toolkit';

import { RBACAction, useHasAccess } from 'auth/useHasAccess';
import type { Maybe, ModuleRole, UserModuleAvailability } from 'services';

export const getTopNavItems = (roles: ModuleRole[]): MenuBarOptions[] => {
  const homeMenuItem = { label: 'Overview', active: true };
  const hasAccess = useHasAccess(
    [RBACAction.TRANSFER_BED, RBACAction.ADMIT_PATIENT],
    roles
  );
  if (hasAccess) {
    return [
      homeMenuItem,
      { label: 'Admissions', active: false },
      { label: 'Bed Transfer', active: false },
    ];
  }
  return [homeMenuItem];
};

export const getMenuConfig = (navItems: NavItem[], module: string) =>
  ({
    navItems,
    hostname: window.location.hostname,
    module,
  } as MenuConfig);

export const getNavItems = (
  userModuleAvailability?: Maybe<Maybe<UserModuleAvailability>[]>
) =>
  userModuleAvailability?.map((item) => {
    const { attributes } = item!;
    const isActive = attributes.availabilityStatus === 'ACCESSIBLE';
    return {
      module: attributes.moduleShortName,
      label: attributes.moduleName,
      isActive,
    } as NavItem;
  });
