import { Text, colors } from '@bt-healthcare/ui-toolkit';

import { getLocalTime } from 'mappings/date.utils';
import { getPotentialDischargeWaitingReason } from 'mappings/mapping.utils';
import {
  FailedDischargeStatusMap,
  WaitingForDischargeStatusMap,
} from 'mappings/enums';
import { getEnumMapOptions } from 'mappings/enums/enum.utils';
import type {
  BedCleaningMessageProps,
  IsolationTypesType,
  MessageProps,
  PatientTransferMessageProps,
} from './types';

export const IsolationMessage = ({ reason }: IsolationTypesType) => (
  <>
    Bed isolated:{' '}
    <Text
      fontWeight={500}
      color={colors.grey.grey10}
      data-testid="isolation-reason"
    >
      {reason}
    </Text>
  </>
);

export const BedTransferInProgressMessage = ({
  patient,
}: PatientTransferMessageProps) => (
  <>
    Bed transfer expected to be completed by{' '}
    <Text
      fontWeight={500}
      color={colors.grey.grey10}
      data-testid="completed-by-date"
    >
      {patient?.[0]?.attributes.client?.arrivalToWardDate}{' '}
    </Text>
    at{' '}
    <Text
      fontWeight={500}
      color={colors.grey.grey10}
      data-testid="completed-by-time"
    >
      {patient?.[0]?.attributes.client?.arrivalToWardTime}h
    </Text>
  </>
);

export const BedStatusBedTransferDelayedMessage = () => (
  <>
    Bed transfer delayed:{' '}
    <Text fontWeight={500} color={colors.grey.grey10}>
      Ward to confirm patient arrival to ward.
    </Text>
  </>
);

export const BedDischargeBedTransferDelayedMessage = () => (
  <Text fontWeight={500} color={colors.notifications.light.errorDark}>
    Bed transfer delayed
  </Text>
);

export const UnallocatedMessage = () => <>Bed ready to be allocated.</>;

export const ClosedMessage = () => <>Can this bed be re-opened?</>;

export const BedCleaningMessage = ({ bedAttrs }: BedCleaningMessageProps) => {
  const expectedCompletedDateTime = getLocalTime(
    bedAttrs.res_bedCleaning?.expectedCompletedDateTime
  );
  const message = bedAttrs.res_bedCleaning?.hasExceededDuration
    ? `Bed should've been ready by ${expectedCompletedDateTime}`
    : `Bed expected to be ready by ${expectedCompletedDateTime}`;
  return (
    <>
      Cleaning time: {bedAttrs.res_bedCleaning?.cleanDuration.asText}.
      <br />
      <Text
        fontWeight={500}
        color={colors.grey.grey10}
        data-testid="ready-by-time"
      >
        {message}
      </Text>
    </>
  );
};

export const ConfirmedMessage = ({ patient }: MessageProps) => (
  <>
    Discharge should be completed by:{' '}
    <Text
      fontWeight={500}
      color={colors.grey.grey10}
      data-testid="completed-by-date-time"
    >
      {patient?.wardDischarge?.attributes?.client?.readyForDischargeDate} at{' '}
      {patient?.wardDischarge?.attributes?.client?.readyForDischargeTime}h
    </Text>
  </>
);

export const PotentialMessage = ({ patient }: MessageProps) => (
  <>
    Waiting for{' '}
    <Text
      fontWeight={500}
      color={colors.grey.grey10}
      data-testid="waiting-reason"
    >
      {getPotentialDischargeWaitingReason(patient?.wardDischarge)}
    </Text>
  </>
);

export const DelayedMessage = ({ patient }: MessageProps) => {
  const reasons =
    patient?.wardDischarge?.attributes.dischargeStatusFailedReasons;
  const hasReason = reasons && reasons.length > 0;

  const message = hasReason
    ? 'Discharge is delayed due to: '
    : 'Discharge is delayed';
  return (
    <>
      {message}
      {hasReason && (
        <Text
          fontWeight={500}
          color={colors.grey.grey10}
          data-testid="delayed-reason"
        >
          {getEnumMapOptions(
            FailedDischargeStatusMap,
            reasons,
            patient?.wardDischarge?.attributes.dischargeStatusFailedReasonOther
          )}
        </Text>
      )}
    </>
  );
};

export const ClinicallyReadyMessage = ({ patient }: MessageProps) => (
  <>
    Waiting for{' '}
    <Text
      fontWeight={500}
      color={colors.grey.grey10}
      data-testid="waiting-reason"
    >
      {getEnumMapOptions(
        WaitingForDischargeStatusMap,
        patient?.wardDischarge?.attributes
          .waitingReasonsForClinicallyReadyForDischarge
      )}
    </Text>
  </>
);
