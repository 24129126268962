import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { FETCH_POLICY } from 'App.constants';
import { clearStore } from 'store';
import { setSentryUser } from 'sentry';
import { useGetUserProfileQuery } from '../services';
import {
  getUserProfile,
  getUserRoles,
  hasAuthorisation,
  moduleLogout,
} from './modules';

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const client = useApolloClient();
  const { data, error, loading } = useGetUserProfileQuery({
    variables: user?.sub ? { safId: user.sub } : undefined,
    skip: !isAuthenticated || !user,
    onCompleted: () => {
      if (!isAuthenticated || !user) clearStore();
    },
    fetchPolicy: FETCH_POLICY,
  });

  const handleLogout = () => {
    moduleLogout(logout, client);
  };

  if (data?.userProfile?.id) {
    setSentryUser(data.userProfile.id);
  }

  return {
    user: {
      data,
      loading,
      error,
      profile: getUserProfile(user, data),
    },
    auth: {
      logout: handleLogout,
      loading: isLoading,
      userProfile: user,
      isAuthenticated,
      isAuthorised: hasAuthorisation(data),
      userRoles: getUserRoles(data),
    },
  };
};
