import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@bt-healthcare/ui-toolkit';
import type { ChangeEvent } from 'react';
import { useState } from 'react';

import { bedSelectionMapping } from 'mappings/datalists';
import { bedAllocatedWithNoDischarge } from 'validation/rules';
import {
  getDischargeStatus,
  getWardBedReference,
} from 'mappings/mapping.utils';
import type { DischargeStatusType } from 'services';
import { RagStatus } from 'components/RagStatus';
import { DataListCard } from 'components/Card/DataListCard/DataListCard';
import { BedSelectionAlert } from 'components/BedSelectionAlert/BedSelectionAlert';
import { MatchingPatientAlert } from 'components/MatchingPatientAlert';
import type { ExtendedWardBed } from 'types';
import type { BedSelectionModalProps } from '../types';
import { ModalHeadlineWrapper, ModalHeadline } from '../styles';
import { AlertWrapper } from './styles';

export const BedSelectionModal = ({
  data,
  handleClose,
  handleClick,
  modalOpen,
  disabled = false,
  ragType = 'DischargeStatus',
}: BedSelectionModalProps) => {
  const bedTransferStatus =
    data?.wardBedTransfers?.[0]?.attributes?.bedTransferStatus;
  const bedTransferInProgress =
    bedTransferStatus === 'DELAYED' || bedTransferStatus === 'IN_PROGRESS';

  const needsConfirmation =
    (ragType === 'DischargeStatus' &&
      bedAllocatedWithNoDischarge(
        data.attributes.bedStatus,
        data.wardPatient?.wardDischarge?.attributes.dischargeStatus
      )) ||
    (ragType === 'DischargeStatus' && bedTransferInProgress);

  const dischargeStatus = getDischargeStatus(
    data.wardPatient
  ) as DischargeStatusType;

  const [action, setAction] = useState(needsConfirmation);

  const handleConfirmation = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setAction(!target.checked);
  };

  const handleReset = () => {
    setAction(true);
    handleClose();
  };

  return (
    <Modal
      onClose={handleReset}
      isModalOpen={modalOpen}
      id="bed-selection"
      inPortal
    >
      <ModalHeader>
        <ModalHeadlineWrapper>
          <RagStatus
            dischargeStatus={
              data.wardPatient?.wardDischarge?.attributes.dischargeStatus ??
              'NONE'
            }
            bedStatus={data.attributes.bedStatus}
            ragType={ragType}
          />
          &nbsp;
          <ModalHeadline hasRagStatus>
            {getWardBedReference(data!)}
          </ModalHeadline>
        </ModalHeadlineWrapper>
      </ModalHeader>
      <ModalBody>
        <DataListCard
          data={bedSelectionMapping(
            data,
            ragType,
            dischargeStatus,
            bedTransferInProgress
          )}
        />
        <AlertWrapper>
          <MatchingPatientAlert data={data as ExtendedWardBed} />
          <BedSelectionAlert
            needsConfirmation={needsConfirmation}
            ragType={ragType}
            dischargeStatus={dischargeStatus}
            bedTransferStatus={bedTransferStatus}
            data={data}
            handleConfirmation={handleConfirmation}
          />
        </AlertWrapper>
      </ModalBody>
      <ModalFooter flex>
        <Button
          aria-label="Cancel modal"
          id="cancel"
          onClick={handleReset}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          aria-label={`Select bed ${data!.attributes.wardBedReference}`}
          id="select-bed"
          onClick={handleClick}
          disabled={disabled || (needsConfirmation && action)}
        >
          Select
        </Button>
      </ModalFooter>
    </Modal>
  );
};
