import type { CareSettingPatient, GenderIdentifiedAs } from 'services';
import {
  patientSelectionDefaultMapping,
  patientSelectionFurtherPreferencesMapping,
} from './patientAdmission';

export const patientSelectionMapping = (data: CareSettingPatient) => {
  const patientGender = data.person.attributes.personalInformation
    .genderIdentifiedAs as GenderIdentifiedAs;
  if (patientGender === 'NON_BINARY' || patientGender === 'PREFER_NONE')
    return patientSelectionFurtherPreferencesMapping(data);

  return patientSelectionDefaultMapping(data);
};
