import { EMPTY_VALUE } from 'App.constants';
import { YesNoMap } from 'mappings/enums';
import { getIsolationReason } from 'mappings/mapping.utils';
import {
  UnallocatedMessage,
  ClosedMessage,
  ConfirmedMessage,
  PotentialMessage,
  DelayedMessage,
  ClinicallyReadyMessage,
  BedTransferInProgressMessage,
  BedStatusBedTransferDelayedMessage,
  BedDischargeBedTransferDelayedMessage,
  IsolationMessage,
  BedCleaningMessage,
} from './BedSelectionAlertMessages';
import type { BedSelectionNotificationMessageRendererProps } from './types';

export const BedSelectionNotificationMessageRenderer = ({
  ragType,
  dischargeStatus,
  bedStatus,
  bedTransferStatus,
  data,
}: BedSelectionNotificationMessageRendererProps) => {
  if (bedStatus === 'CLEANING')
    return <BedCleaningMessage bedAttrs={data?.attributes!} />;

  const isolationReason = getIsolationReason(data);

  if (ragType === 'BedStatus') {
    if (isolationReason !== EMPTY_VALUE && isolationReason !== YesNoMap.NO)
      return <IsolationMessage reason={isolationReason} />;

    if (bedTransferStatus) {
      switch (bedTransferStatus) {
        case 'IN_PROGRESS':
          return (
            <BedTransferInProgressMessage patient={data?.wardBedTransfers} />
          );
        case 'DELAYED':
          return <BedStatusBedTransferDelayedMessage />;
        default:
          return null;
      }
    }

    switch (bedStatus) {
      case 'UNALLOCATED':
        return <UnallocatedMessage />;
      case 'CLOSED':
        return <ClosedMessage />;
      default:
        return null;
    }
  } else {
    if (bedTransferStatus) {
      switch (bedTransferStatus) {
        case 'IN_PROGRESS':
          return (
            <BedTransferInProgressMessage patient={data?.wardBedTransfers} />
          );
        case 'DELAYED':
          return <BedDischargeBedTransferDelayedMessage />;
        default:
          return null;
      }
    }
    switch (dischargeStatus) {
      case 'CONFIRMED':
        return <ConfirmedMessage patient={data?.wardPatient} />;
      case 'POTENTIAL':
        return <PotentialMessage patient={data?.wardPatient} />;
      case 'DELAYED':
        return <DelayedMessage patient={data?.wardPatient} />;
      case 'CLINICALLY_READY':
        return <ClinicallyReadyMessage patient={data?.wardPatient} />;
      default:
        return null;
    }
  }
};
