import { GenderIdentifiedAsAbbrevMap, SpecialtyMap } from 'mappings/enums';
import type { CareSettingPatientQuery } from 'models';
import type { GenderIdentifiedAs } from 'services';

export const careSettingPatientFilter = (
  data: CareSettingPatientQuery,
  value: string
) => {
  if (!data || value === '') return data;
  if (value === 'M' || value === 'F' || value === 'NB') {
    return data.filter(
      (item) =>
        item.person?.attributes.personalInformation.genderIdentifiedAs ===
        Object.keys(GenderIdentifiedAsAbbrevMap).find(
          (key) =>
            GenderIdentifiedAsAbbrevMap[key as GenderIdentifiedAs] === value
        )
    );
  }
  return data.filter(
    (item) =>
      item.person?.attributes.personalInformation.firstName
        ?.toLowerCase()
        .includes(value.toLowerCase()) ||
      item.person?.attributes.personalInformation.surname
        ?.toLowerCase()
        .includes(value.toLowerCase()) ||
      SpecialtyMap[item.attributes.specialty!]
        .toLowerCase()
        .includes(value.toLowerCase())
  );
};
