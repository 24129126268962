import { Grid, Col, Row, Loader, SubHeader } from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { PatientSearch } from 'components/PatientSearch';
import { PageNotification } from 'components/PageNotification';
import { useStore } from 'store';
import { PatientListView } from 'components/PatientListView/PatientListView';
import { usePatientSort } from 'hooks/usePatientSort';
import type { CareSettingPatientQuery } from 'models';

export const BedAllocationPatients = () => {
  const [setMenuHeader, errorMessage, { careSettingId }] = useStore(
    (state) => [state.setMenuHeader, state.errorMessage, state.appConfig],
    shallow
  );

  useEffect(() => {
    setMenuHeader('Patient list');
  }, []);

  const { loading, error, sortedPatients } = usePatientSort(careSettingId);

  const [filter, setFilter] = useState<CareSettingPatientQuery>();
  if (loading) return <Loader />;

  return (
    <Grid>
      <Row>
        <Col xs={6} alignSelf="center" display={{ xs: 'none' }}>
          <SubHeader>Patient list</SubHeader>
        </Col>
      </Row>
      {error ? (
        <Row>
          <Col from={1}>
            <PageNotification message={errorMessage('displaying patients')} />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col xs={6} alignSelf="center">
              <PatientSearch data={sortedPatients!} setFilter={setFilter} />
            </Col>
          </Row>
          <Row>
            <Col from={1}>
              <PatientListView data={filter ?? sortedPatients} />
            </Col>
          </Row>
        </>
      )}
    </Grid>
  );
};
