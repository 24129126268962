import {
  DatePicker,
  RequiredFieldIndicator,
  TimeInput,
} from '@bt-healthcare/ui-toolkit';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { RHFDatePickerFieldset, RHFDatePickerLegend } from './styles';
import type { RHFDateTimeProps } from './types';

/** React hook form wrapper for DatePicker and TimeInput component  */
export const RHFDatePicker = <T extends FieldValues>({
  label,
  fieldName,
  fieldValue,
  timeFieldName,
  control,
  minDate,
  markAsRequired = false,
  fieldErrorText,
  timeErrorText,
}: RHFDateTimeProps<T>) => (
  <RHFDatePickerFieldset>
    <RHFDatePickerLegend>
      {label} {markAsRequired && <RequiredFieldIndicator />}
    </RHFDatePickerLegend>
    <Controller
      name={fieldName}
      control={control}
      defaultValue={fieldValue}
      render={({ field: { value, onChange, onBlur, name } }) => (
        <DatePicker
          id={name}
          onBlur={onBlur}
          dateFormat="dd/MM/yy"
          selected={value}
          onChange={onChange}
          name={name}
          minDate={minDate}
          aria-label="Date input"
          placeholder="DD/MM/YY"
          errorText={fieldErrorText}
        />
      )}
    />
    <Controller
      name={timeFieldName as any}
      control={control}
      defaultValue={fieldValue}
      render={({ field: { value, name, onChange, onBlur } }) => (
        <TimeInput
          id={timeFieldName}
          defaultValue={value}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          aria-label="Time input"
          errorText={timeErrorText}
        />
      )}
    />
  </RHFDatePickerFieldset>
);
