import { Wrapper } from '@bt-healthcare/ui-toolkit';
import { EMPTY_VALUE } from 'App.constants';
import { BedListViewIcon } from 'assets/BedListViewIcon';
import { RagStatus } from 'components/RagStatus';
import { RagStatusWrapper } from 'components/RagStatus/styles';
import {
  DischargeDateTimeTooltip,
  SameNamePatientTooltip,
} from 'components/Tooltips';
import {
  BedStatusMap,
  DischargeStatusMap,
  GenderIdentifiedAsAbbrevMap,
  PorteringMap,
  YesNoMap,
} from 'mappings/enums';
import {
  getIsolationReason,
  getPatientName,
  getWardBedReference,
} from 'mappings/mapping.utils';
import type { ExtendedWardBed } from 'types';

export const columnWidthSizes = {
  desktop: '1fr 2fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 18px',
  mobile: '1fr 2fr 1fr 18px',
};
export const headerData: any = [
  { id: '1', label: 'Bed' },
  { id: '2', label: 'Patient', hide: 'mobile' },
  { id: '3', label: '', hide: 'mobile' },
  { id: '4', label: 'Gender' },
  { id: '5', label: 'Bed Status' },
  {
    id: '6',
    label: 'Disch Status',
    hide: 'mobile',
  },
  { id: '7', label: 'ED/RFD Date', hide: 'mobile' },
  { id: '8', label: 'ED/RFD Time', hide: 'mobile' },
  { id: '9', label: 'Isolation', hide: 'mobile' },
  { id: '10', label: 'Portering', hide: 'mobile' },
  { id: '11', label: 'Outlier', hide: 'mobile', align: 'center' },
  { id: '12', label: '', align: 'center' },
];

export const rowData: any = (data: ExtendedWardBed[]) =>
  data.map((rowItem) => {
    const patientName = getPatientName(rowItem.wardPatient?.careSettingPatient);
    return {
      id: rowItem.id,
      items: [
        {
          id: '1',
          item: (
            <RagStatusWrapper>
              <RagStatus
                dischargeStatus={
                  rowItem.wardPatient?.wardDischarge?.attributes
                    ?.dischargeStatus ?? 'NONE'
                }
                bedStatus={rowItem.attributes.bedStatus}
                ragType="BedStatus"
              />
              {getWardBedReference(rowItem)}
            </RagStatusWrapper>
          ),
        },
        {
          id: '2',
          item: (
            <Wrapper as="span" title={patientName}>
              {patientName}
            </Wrapper>
          ),
          hide: 'mobile',
        },
        {
          id: '3',
          item: <SameNamePatientTooltip data={rowItem} />,
          align: 'center',
          hide: 'mobile',
          overflow: false,
        },
        {
          id: '4',
          item: rowItem.wardPatient?.careSettingPatient?.person.attributes
            .personalInformation.genderIdentifiedAs
            ? GenderIdentifiedAsAbbrevMap[
                rowItem.wardPatient.careSettingPatient?.person.attributes
                  .personalInformation.genderIdentifiedAs
              ]
            : EMPTY_VALUE,
        },
        {
          id: '5',
          item: BedStatusMap[rowItem.attributes.bedStatus],
        },
        {
          id: '6',
          item: DischargeStatusMap[
            rowItem.wardPatient?.wardDischarge?.attributes.dischargeStatus ??
              'NONE'
          ],
          hide: 'mobile',
        },
        {
          id: '7',
          item: <DischargeDateTimeTooltip data={rowItem} dateTimeType="Date" />,
          hide: 'mobile',
          overflow: false,
        },
        {
          id: '8',
          item: <DischargeDateTimeTooltip data={rowItem} dateTimeType="Time" />,
          hide: 'mobile',
          overflow: false,
        },
        {
          id: '9',
          hide: 'mobile',
          item: getIsolationReason(rowItem),
        },
        {
          id: '10',
          hide: 'mobile',
          item: rowItem.wardPatient?.attributes?.porterStatus
            ? PorteringMap[rowItem.wardPatient.attributes.porterStatus]
            : EMPTY_VALUE,
        },
        {
          id: '11',
          hide: 'mobile',
          align: 'center',
          item: YesNoMap[rowItem.wardPatient?.attributes?.outlierYN ?? 'NONE'],
        },
        {
          id: '12',
          align: 'center',
          item: (
            <BedListViewIcon
              ragType="BedStatus"
              bedStatus={rowItem.attributes.bedStatus}
              dischargeStatus={
                rowItem.wardPatient?.wardDischarge?.attributes.dischargeStatus
              }
              bedTransferStatus={
                rowItem.wardBedTransfers?.[0]?.attributes.bedTransferStatus
              }
              isWarning={
                rowItem.attributes.res_bedCleaning?.hasExceededDuration === true
              }
            />
          ),
        },
      ],
    };
  });
