import type { WardBedTransferQuery } from 'models';
import type { GetWardBedsQuery, WardBed, WardBedTransfer } from 'services';

export const wardBedTransform = (
  data: GetWardBedsQuery | undefined,
  wardBedTransfers: WardBedTransferQuery,
  wardId: string | undefined
) => {
  const wardBeds = data?.wardBeds as WardBed[];

  // returns an array of wardBedTransfers if toWardId matches wardId
  const relevantToWardBedTransfers = wardBedTransfers?.filter(
    (transfer) =>
      transfer.attributes.toWardId === wardId &&
      transfer.attributes.bedTransferStatus !== 'COMPLETE'
  ) as WardBedTransfer[];

  // returns an array of wardBedTransfers if fromWardId matches wardId
  const relevantFromWardBedTransfers = wardBedTransfers?.filter(
    (transfer) => transfer.attributes.fromWardId === wardId
  ) as WardBedTransfer[];

  // transforms the relevantToWardBedTransfers into a WardBed object with patient data
  const transformedWardBedTransfers = relevantToWardBedTransfers.map(
    (bedTransfer) =>
      ({
        attributes: {
          bedGender:
            bedTransfer.fromWardBed?.wardPatient?.careSettingPatient?.person
              .attributes.personalInformation.genderIdentifiedAs,
          wardBedReference: bedTransfer.toWardBed?.attributes.wardBedReference,
          bedStatus: 'ALLOCATED',
          wardGroupId: wardId,
          wardId,
        },
        wardPatient: {
          ...bedTransfer.fromWardBed?.wardPatient,
        },
        id: bedTransfer.id,
        wardBedTransfers: [{ ...bedTransfer }],
      } as WardBed)
  );

  // retrieves bed transfer data for bed card in the source ward
  const transformedFromWardBedTransfers = relevantFromWardBedTransfers.map(
    (bedTransfer) => {
      const bedDetails = wardBeds?.find(
        (bed) =>
          bed.wardPatient?.id === bedTransfer.fromWardBed?.wardPatient?.id
      ) as WardBed;

      return {
        ...bedDetails,
        wardBedTransfers: [{ ...bedTransfer }],
      } as WardBed;
    }
  );

  // creates new array with all updated ward beds
  const allWardBeds = transformedFromWardBedTransfers.concat(
    transformedWardBedTransfers,
    wardBeds
  );

  // removes duplicate beds
  const filteredBeds = allWardBeds.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (target) =>
          target?.attributes.wardBedReference ===
          value?.attributes.wardBedReference
      )
  );

  return filteredBeds;
};
