import { Loader } from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { isEmpty } from 'ramda';

import { BedAllocationListView } from 'components/BedAllocation/BedAllocationListView/BedAllocationListView';
import { PageHeader } from 'components/PageHeader';
import { useGetWardBedsQuery } from 'services';
import { PageNotification } from 'components/PageNotification';
import { useStore } from 'store';
import { useClearErrors } from 'hooks/useClearErrors';
import { useTracking } from 'hooks/useTracking';
import { FETCH_POLICY, POLL_INTERVAL } from 'App.constants';
import { useWardBedTransferSort } from 'hooks/useWardBedTransferSort';
import { wardBedTransform } from 'transforms/wardBedTransform';
import { wardBedMatchingPatientTransform } from 'transforms/wardBedMatchingPatientTransform';
import type { ExtendedWardBed } from 'types';
import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';

export const BedAllocation = () => {
  useClearErrors();
  const wardId = useRedirectAndReturnState('wardId');
  const { trackPage } = useTracking();
  const [wardBedsTransform, setWardBedsTransform] = useState<ExtendedWardBed[]>(
    []
  );

  const [
    wardHeader,
    resetPatient,
    resetBed,
    resetFormData,
    errorMessage,
    errors,
    { careSettingId },
  ] = useStore(
    (state) => [
      state.wardHeader,
      state.resetPatient,
      state.resetBed,
      state.resetBedAllocationFormData,
      state.errorMessage,
      state.errors,
      state.appConfig,
    ],
    shallow
  );

  const { sortedWardBedTransfers, loading: bedTransferLoading } =
    useWardBedTransferSort(careSettingId);

  const { loading: wardBedsLoading } = useGetWardBedsQuery({
    variables: {
      wardId: wardId!,
    },
    pollInterval: POLL_INTERVAL,
    fetchPolicy: FETCH_POLICY,
    skip: bedTransferLoading,
    onCompleted: (data) => {
      const allRelevantWardBeds = wardBedTransform(
        data,
        sortedWardBedTransfers,
        wardId
      );

      const matchPatientData =
        wardBedMatchingPatientTransform(allRelevantWardBeds);
      setWardBedsTransform(matchPatientData);
    },
  });

  useEffect(() => {
    resetPatient();
    resetFormData();
    resetBed();
  }, []);

  useEffect(() => {
    trackPage(`${wardHeader ? wardHeader.wardName : 'ward'} - Allocate Bed`);
  }, []);

  if (bedTransferLoading || wardBedsLoading) return <Loader />;
  return (
    <>
      {wardHeader && (
        <PageHeader
          title={wardHeader.wardName}
          capacity={wardHeader.capacity}
        />
      )}
      {!isEmpty(errors) ? (
        <PageNotification
          message={errorMessage('displaying ward beds for allocation')}
        />
      ) : (
        <BedAllocationListView data={wardBedsTransform} />
      )}
    </>
  );
};
