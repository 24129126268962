import * as yup from 'yup';

import { isNo, isValidOption, isYes, notEmpty } from './rules';

export type ConditionParams = {
  field: string;
  fn?: Function;
  yupSchema?: any;
  value?: any;
};

export type DependentSelectionParams = {
  optionField: string;
  dependentField: string;
  optionMatchValue?: any;
} & Pick<ConditionParams, 'fn' | 'yupSchema'>;

export const TIME_REGEX = /^([01]\d|2[0-3]):([0-5]\d)$/;
export const REQUIRED_TEXT = 'This field is required';
export const DATE_REQUIRED_TEXT = 'Date is required';

export const isRequired = (
  isNullable = false,
  yupSchema = yup.string(),
  message = REQUIRED_TEXT
) =>
  isNullable
    ? yupSchema.nullable().required(message)
    : yupSchema.required(message);

export const isRequiredNullable = (
  yupSchema = yup.string(),
  message?: string
) => isRequired(true, yupSchema, message);

export const isRequiredDropDownListOption = () =>
  yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .default(null)
    .nullable()
    .required(REQUIRED_TEXT);

export const isRequiredDropDownListMultiOption = () =>
  yup
    .array()
    .required()
    .min(1, 'Please select at least one item')
    .of(isRequiredDropDownListOption());

export const isRequiredTime = () =>
  yup
    .string()
    .required('Time is required')
    .matches(TIME_REGEX, 'Invalid time input');

export const isRequiredYear = (min = 1900) =>
  yup
    .number()
    .nullable()
    .typeError(REQUIRED_TEXT)
    .min(min, `Year must be after ${min}`)
    .max(new Date().getFullYear(), 'Year cannot be in the future');

export const isRequiredWhenYes = ({
  field,
  fn = isRequired,
  yupSchema = yup.string(),
}: ConditionParams) =>
  yupSchema.nullable().when(field, {
    is: (arg: string) => isYes(arg),
    then: fn(),
  });

export const isRequiredWhenNo = ({
  field,
  fn = isRequired,
  yupSchema = yup.string(),
}: ConditionParams) =>
  yupSchema.when(field, {
    is: (arg: string) => isNo(arg),
    then: fn(),
  });

export const isRequiredLength = (maxLength: number, message: string) =>
  yup.string().required(REQUIRED_TEXT).length(maxLength, message);

export const isRequiredWhenValueIsEnum = ({
  field,
  value,
  fn = isRequired,
  yupSchema = yup.string(),
}: ConditionParams) =>
  yupSchema.when(field, {
    is: (arg: string) => isValidOption(arg, value),
    then: fn(),
  });

export const isRequiredWhenSelection = ({
  field,
  value,
  fn = isRequired,
  yupSchema = yup.string(),
}: ConditionParams) =>
  yupSchema.nullable().when(field, {
    is: (arg: any) =>
      value == null ? arg === '' || arg == null : isValidOption(arg, value),
    then: fn(),
  });

export const isRequiredSelectionWithDependentField = ({
  optionField,
  dependentField,
  optionMatchValue,
  fn = isRequired,
  yupSchema = yup.string(),
}: DependentSelectionParams) =>
  yupSchema.nullable().when([optionField, dependentField], {
    is: (optionFieldValue?: any, dependentFieldValue?: string) => {
      if (isValidOption(optionFieldValue, optionMatchValue)) {
        return notEmpty(dependentFieldValue);
      }
      return false;
    },
    then: () => fn(),
  });
