import type { NamedSet } from 'zustand/middleware';

import type { CareSettingPatient } from 'services';
import type { AppState } from './types';

export const patientSlice = (set: NamedSet<AppState>) => ({
  patient: {} as CareSettingPatient,
  setPatient: (patient: CareSettingPatient) =>
    set({ patient }, false, 'patient/setPatient'),
  resetPatient: () =>
    set({ patient: {} as CareSettingPatient }, false, 'patient/resetPatient'),
});
