import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { ListViewRow } from '@bt-healthcare/ui-toolkit';
import { ListView } from '@bt-healthcare/ui-toolkit';
import { shallow } from 'zustand/shallow';

import { useStore } from 'store';
import type { ExtendedWardBed, ListViewQueryProps } from 'types';
import type { WardBed } from 'services';
import { bedDischargeDisabled } from 'validation/rules';
import { sortByRagStatus } from 'sorting';
import { BedSelectionModal } from 'components/Modal';
import { isEmpty } from 'ramda';
import { ROUTES } from 'App.constants';
import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';
import {
  columnWidthSizes,
  headerData,
  rowData,
} from './BedDischargeListViewConfig';

export const BedDischargeListView = ({
  data,
}: ListViewQueryProps<ExtendedWardBed[]>) => {
  const wardId = useRedirectAndReturnState('wardId');
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const [setBed, bed] = useStore((state) => [state.setBed, state.bed], shallow);

  const handleClose = () => setModalOpen(false);

  const handleRowClick = ({ id }: ListViewRow) => {
    setModalOpen(true);
    const selectedWardBed = data?.find((item) => item.id === id);
    if (selectedWardBed) setBed(selectedWardBed as WardBed);
  };

  const handleClick = () => {
    handleClose();
    navigate(ROUTES.WARDS_BED_DISCHARGE_MANAGE, { state: { wardId } });
  };

  return (
    <>
      {!isEmpty(bed) && (
        <BedSelectionModal
          data={bed}
          modalOpen={modalOpen}
          handleClose={handleClose}
          handleClick={handleClick}
          disabled={bedDischargeDisabled(bed)}
        />
      )}
      {data.length > 0 && (
        <ListView
          headerData={headerData}
          rowData={rowData(sortByRagStatus(data, 'DischargeStatus'))}
          id="discharge-status"
          onRowClick={handleRowClick}
          columnWidths={columnWidthSizes}
        />
      )}
    </>
  );
};
