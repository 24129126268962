import * as yup from 'yup';

import {
  isRequired,
  isRequiredDropDownListOption,
  isRequiredTime,
  isRequiredWhenSelection,
  isRequiredWhenYes,
  isRequiredYear,
} from 'validation/schema';
import type { FormKey } from '../Form/types';
import type { PatientAdmissionFormData } from './types';

const patientAdmissionFormRules = {
  firstName: isRequired(),
  surname: isRequired(),
  genderIdentifiedAs: isRequired(),
  origin: isRequiredDropDownListOption(),
  specialty: isRequiredDropDownListOption(),
  requiresIsolationYN: isRequired(),
  isolationType: isRequiredWhenYes({
    field: 'requiresIsolationYN',
    fn: () => isRequiredDropDownListOption(),
    yupSchema: yup.object(),
  }),
  isolationTypeOther: isRequiredWhenSelection({
    field: 'isolationType',
    value: 'OTHER',
  }),
  decisionToAdmitDate: isRequired(),
  decisionToAdmitTime: isRequiredTime(),
  dayOfBirth: isRequired(),
  monthOfBirth: isRequired(),
  yearOfBirth: isRequiredYear(),
} as { [key in FormKey<PatientAdmissionFormData>]: any };

export const patientAdmissionSchema = yup.object(patientAdmissionFormRules);
