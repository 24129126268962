import { BTHealthIcon, colors, Tooltip } from '@bt-healthcare/ui-toolkit';
import { TOOLTIP_MESSAGES } from 'App.constants';
import type { TooltipProps } from './types';

export const SameNamePatientTooltip = ({ data }: TooltipProps) => {
  if (!data.hasMatchingPatientName) return null;

  return (
    <Tooltip
      content={TOOLTIP_MESSAGES.SAME_NAME_PATIENT}
      id={`tooltip-${data.id}`}
      direction="top-end"
    >
      <BTHealthIcon
        icon="People"
        size={20}
        color={colors.grey.grey08}
        title=""
      />
    </Tooltip>
  );
};
