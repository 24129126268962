import type { BedDischargeFormData } from 'components/BedDischarge/types';
import { convertDateStringForFormInput } from 'mappings/date.utils';
import type { WardBed } from 'services';

export const getNotFitDefaultValues = (
  defaultFields: Partial<BedDischargeFormData>,
  wardBed: WardBed
): Partial<BedDischargeFormData> => ({
  ...defaultFields,
  dischargeExpectedDate: convertDateStringForFormInput(
    wardBed.wardPatient?.wardDischarge?.attributes.client?.dischargeExpectedDate
  ),
  dischargeExpectedTime:
    wardBed.wardPatient?.wardDischarge?.attributes.client
      ?.dischargeExpectedTime ?? '',
  socialCareYN:
    wardBed.wardPatient?.wardDischarge?.attributes.socialCareYN ?? '',
});
