import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const PatientHeaderWrapper = styled.header`
  background: ${colors.primaryIndigo.indigo08};
  height: 56px;
  display: flex;
  align-items: center;
  color: ${colors.base.white};
  padding: 0 ${spacing.s4};
  border-radius: ${spacing.s4} ${spacing.s4} ${spacing.none} ${spacing.none};

  @media ${device.desktop} {
    height: 64px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: baseline;

  > svg {
    transform: translateY(2.5px);
  }
`;

export const MainFlexContainer = styled(FlexContainer)`
  justify-content: space-between;
  width: 100%;
`;

export const WardWrapper = styled.span`
  font-size: ${fontSizes.h4};
  padding: 0 ${spacing.s4} 0 ${spacing.s2};
`;

export const PatientDetailsWrapper = styled.span`
  display: flex;
  align-items: baseline;
  font-size: ${fontSizes.xs};

  > span {
    &:last-child {
      padding-left: ${spacing.s3};
    }
  }

  @media ${device.desktop} {
    font-size: ${fontSizes.sm};
  }
`;

export const GenderOptionItem = styled.span<{ active: boolean }>`
  color: ${({ active }) =>
    active ? 'inherit' : colors.primaryIndigo.indigo04};
  padding: 0 calc(${spacing.s1} / 4);
`;

/* TODO Expose font calc mixin from toolkit */
export const StatusWrapper = styled.div`
  font-size: ${fontSizes.xs};
  line-height: calc(${fontSizes.xs} * 1.5);

  @media ${device.desktop} {
    font-size: ${fontSizes.sm};
    line-height: calc(${fontSizes.sm} * 1.5);
  }
`;
