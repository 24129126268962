import type { ListViewRow } from '@bt-healthcare/ui-toolkit';
import { ListView } from '@bt-healthcare/ui-toolkit';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useStore } from 'store';
import type { ExtendedCareSettingPatient, ListViewQueryProps } from 'types';
import type { CareSettingPatientQuery } from 'models';
import { PageNotification } from 'components/PageNotification';
import { ROUTES } from 'App.constants';
import { PatientSelectionModal } from '../Modal/PatientSelectionModal';
import { headerData, rowData } from './PatientListViewConfig';

export const PatientListView = ({
  data,
}: ListViewQueryProps<
  CareSettingPatientQuery | ExtendedCareSettingPatient[]
>) => {
  const { state: routeState } = useLocation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const patient = useStore(
    (state) => state.patient
  ) as ExtendedCareSettingPatient;
  const setPatient = useStore((state) => state.setPatient);

  const handleRowClick = ({ id }: ListViewRow) => {
    setModalOpen(true);
    const selectedPatient = data.find((item) => item.id === id);
    if (selectedPatient) {
      setPatient(selectedPatient as ExtendedCareSettingPatient);
    }
  };

  const handleClose = () => setModalOpen(false);

  const handleClick = () => {
    handleClose();
    navigate(ROUTES.WARDS_BED_ALLOCATION_MANAGE, {
      state: { wardId: routeState?.wardId },
    });
  };

  return (
    <>
      {patient?.person && (
        <PatientSelectionModal
          data={patient}
          modalOpen={modalOpen}
          handleClose={handleClose}
          handleClick={handleClick}
        />
      )}
      {data.length === 0 ? (
        <PageNotification
          type="informative"
          message="Search returned no results. Please try again."
        />
      ) : (
        <ListView
          headerData={headerData}
          rowData={rowData(data)}
          id="patient-admission"
          onRowClick={handleRowClick}
        />
      )}
    </>
  );
};
