import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { getLocalDate, getLocalTime } from 'mappings/date.utils';

export const AttributeCacheMergeTypePolicy = {
  fields: {
    attributes: {
      merge: true,
    },
  },
};

export const WardBedAttributesClientTypePolicy = () => ({
  read(_: any, { readField }: { readField: ReadFieldFunction }) {
    return {
      bedStatusDate: getLocalDate(readField('bedStatusDateTime')),
      bedStatusTime: getLocalTime(readField('bedStatusDateTime')),
    };
  },
});

export const WardDischargeAttributesClientTypePolicy = () => ({
  read(_: any, { readField }: { readField: ReadFieldFunction }) {
    return {
      dischargeExpectedDate: getLocalDate(
        readField('dischargeExpectedDateTime')
      ),
      dischargeExpectedTime: getLocalTime(
        readField('dischargeExpectedDateTime')
      ),
      dischargeActualDate: getLocalDate(readField('dischargeActualDateTime')),
      dischargeActualTime: getLocalTime(readField('dischargeActualDateTime')),
      readyForDischargeDate: getLocalDate(
        readField('readyForDischargeDateTime')
      ),
      readyForDischargeTime: getLocalTime(
        readField('readyForDischargeDateTime')
      ),
      clinicallyReadyForDischargeDate: getLocalDate(
        readField('clinicallyReadyForDischargeDateTime')
      ),
      clinicallyReadyForDischargeTime: getLocalTime(
        readField('clinicallyReadyForDischargeDateTime')
      ),
    };
  },
});

export const WardBedTransferAttributesClientTypePolicy = () => ({
  read(_: any, { readField }: { readField: ReadFieldFunction }) {
    return {
      arrivalToWardDate: getLocalDate(readField('arrivalToWardDateTime')),
      arrivalToWardTime: getLocalTime(readField('arrivalToWardDateTime')),
    };
  },
});

export const WardPatientAttributesClientTypePolicy = () => ({
  read(_: any, { readField }: { readField: ReadFieldFunction }) {
    return {
      admissionDate: getLocalDate(readField('admissionDateTime')),
      admissionTime: getLocalTime(readField('admissionDateTime')),
      arrivalExpectedDate: getLocalDate(readField('arrivalExpectedDateTime')),
      arrivalExpectedTime: getLocalTime(readField('arrivalExpectedDateTime')),
    };
  },
});

export const CareSettingPatientAttributesClientTypePolicy = () => ({
  read(_: any, { readField }: { readField: ReadFieldFunction }) {
    return {
      decisionToAdmitDate: getLocalDate(readField('decisionToAdmitDateTime')),
      decisionToAdmitTime: getLocalTime(readField('decisionToAdmitDateTime')),
    };
  },
});
