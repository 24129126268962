import { RHFDropDown } from 'components/Form/RHFDropDown';
import { dischargeStatusOptions } from '../Form/formOptions';

import {
  DischargeFailed,
  Isolation,
  DischargeConfirmed,
  DischargeCompleted,
  DischargeNotFit,
  DischargeClinicallyReady,
  DischargePotential,
  SeniorDecisionMaker,
} from './FormSections';
import type { BedDischargeFormFieldsProps } from './types';

export const BedDischargeFormFields = ({
  register,
  control,
  previousDMDateTime,
  decisionMakerLastDateTime,
  setDecisionMakerLastDateTime,
  previousD2ADateTime,
  dischargeToAssessDateTime,
  setDischargeToAssessDateTime,
  setValue,
  handleStatusChange,
  resetField,
  errors,
}: BedDischargeFormFieldsProps) => (
  <>
    <Isolation
      control={control}
      register={register}
      setValue={setValue}
      errors={errors}
    />
    <SeniorDecisionMaker
      control={control}
      register={register}
      setValue={setValue}
      previousDMDateTime={previousDMDateTime}
      decisionMakerLastDateTime={decisionMakerLastDateTime}
      setDecisionMakerLastDateTime={setDecisionMakerLastDateTime}
    />
    <RHFDropDown
      label="Discharge status"
      fieldName="dischargeStatus"
      control={control}
      options={dischargeStatusOptions}
      handleChange={handleStatusChange}
      markAsRequired
      errorText={
        errors?.dischargeStatus?.message
          ? String(errors.dischargeStatus.message)
          : undefined
      }
    />
    <DischargeCompleted
      register={register}
      control={control}
      resetField={resetField}
      errors={errors}
    />
    <DischargeFailed control={control} register={register} errors={errors} />
    <DischargeConfirmed
      control={control}
      register={register}
      resetField={resetField}
      errors={errors}
    />
    <DischargeNotFit control={control} register={register} errors={errors} />
    <DischargeClinicallyReady
      control={control}
      register={register}
      previousD2ADateTime={previousD2ADateTime}
      dischargeToAssessDateTime={dischargeToAssessDateTime}
      setDischargeToAssessDateTime={setDischargeToAssessDateTime}
      resetField={resetField}
      errors={errors}
    />
    <DischargePotential control={control} register={register} errors={errors} />
  </>
);
