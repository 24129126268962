import { Routes, Route } from 'react-router-dom';
import { PageNotFound } from '@bt-healthcare/ui-toolkit';

import {
  BedDischarge,
  BedDischargeForm,
  Overview,
  BedAllocation,
  WardOptions,
  BedAllocationForm,
  BedAllocationPatients,
  BedDischargeFormConfirmation,
  BedAllocationFormConfirmation,
  PatientAdmission,
  PatientAdmissionForm,
  PatientAdmissionFormConfirmation,
  BedTransfer,
  BedTransferForm,
  BedTransferFormConfirmation,
} from 'pages';
import { ROUTES } from 'App.constants';
import { RBACAction } from 'auth/useHasAccess';

import { ProtectedRoute } from './ProtectedRoute';
import { RBACRoute } from './RBACRoute';

export const AppRoutes = () => (
  <Routes>
    <Route path={ROUTES.HOME} element={<ProtectedRoute />}>
      <Route path={ROUTES.HOME} element={<Overview />} />
      <Route element={<RBACRoute actions={[RBACAction.ADMIT_PATIENT]} />}>
        <Route path={ROUTES.ADMISSIONS_HOME} element={<PatientAdmission />} />
        <Route
          path={ROUTES.ADMISSIONS_MANAGE}
          element={<PatientAdmissionForm />}
        />
        <Route
          path={ROUTES.ADMISSIONS_CONFIRM}
          element={<PatientAdmissionFormConfirmation />}
        />
      </Route>
      <Route element={<RBACRoute actions={[RBACAction.TRANSFER_BED]} />}>
        <Route path={ROUTES.BED_TRANSFER_HOME} element={<BedTransfer />} />
        <Route
          path={ROUTES.BED_TRANSFER_MANAGE}
          element={<BedTransferForm />}
        />
        <Route
          path={ROUTES.BED_TRANSFER_CONFIRM}
          element={<BedTransferFormConfirmation />}
        />
      </Route>
      <Route path={ROUTES.WARDS} element={<WardOptions />} />
      <Route
        path={ROUTES.WARDS_BED_DISCHARGE_HOME}
        element={<BedDischarge />}
      />
      <Route
        path={ROUTES.WARDS_BED_DISCHARGE_MANAGE}
        element={<BedDischargeForm />}
      />
      <Route
        path={ROUTES.WARDS_BED_DISCHARGE_CONFIRM}
        element={<BedDischargeFormConfirmation />}
      />
      <Route
        path={ROUTES.WARDS_BED_ALLOCATION_HOME}
        element={<BedAllocation />}
      />
      <Route
        path={ROUTES.WARDS_BED_ALLOCATION_PATIENTS}
        element={<BedAllocationPatients />}
      />
      <Route
        path={ROUTES.WARDS_BED_ALLOCATION_CONFIRM}
        element={<BedAllocationFormConfirmation />}
      />

      <Route
        path={ROUTES.WARDS_BED_ALLOCATION_MANAGE}
        element={<BedAllocationForm />}
      />
    </Route>
    <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
  </Routes>
);
