import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import type { CardWrapperProps } from './types';

export const CardWrapper = styled.div<CardWrapperProps>`
  cursor: pointer;
  border: ${({ selected }) =>
    selected
      ? `1.5px solid ${colors.primaryIndigo.indigo08}`
      : `1.5px solid ${colors.base.white}`};
  border-radius: ${spacing.s4};
  background: ${({ selected }) =>
    selected &&
    `linear-gradient(0deg, rgba(239, 229, 247, 0.3), rgba(239, 229, 247, 0.3)), ${colors.base.white}`};
  box-shadow: ${({ selected }) =>
    selected && `0px 4px 10px 1px rgba(112, 144, 176, 0.2)`};
  transition: all 0.1s ease-in-out;

  @media ${device.desktop} {
    &:hover {
      > div:first-child {
        background: linear-gradient(
            0deg,
            rgba(239, 229, 247, 0.3),
            rgba(239, 229, 247, 0.3)
          ),
          ${colors.base.white};
        box-shadow: 0px 4px 10px 1px rgba(112, 144, 176, 0.2);
      }
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  margin-bottom: ${spacing.s4};

  @media ${device.desktop} {
    h3 {
      margin-right: ${spacing.s2};
      font-size: ${fontSizes.h4};
    }
  }
`;

export const CardHeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;

  @media ${device.desktop} {
    justify-content: initial;
  }
`;

export const ClearButton = styled.div`
  display: none;
  color: ${colors.base.white};
  background-color: ${colors.primaryIndigo.indigo08};
  padding: ${spacing.s1} ${spacing.s4};
  border-radius: ${spacing.s4};

  @media ${device.desktop} {
    display: inline-block;
  }
`;

export const StatisticsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${device.desktop} {
    flex-direction: row;
  }
`;

export const Stat = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.8;

  span {
    display: inline-block;
    font-size: ${fontSizes.sm};

    &.stat-total {
      font-weight: 400;
      color: ${({ theme }) => theme.headlineColor};
    }
  }

  @media ${device.desktop} {
    flex-direction: row-reverse;
    align-items: baseline;

    span {
      display: inline;
      font-size: ${fontSizes.sm};

      &.stat-total {
        margin-right: ${spacing.s2};
        font-size: ${fontSizes.h3};
      }
    }
  }
`;
