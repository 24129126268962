import {
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  colors,
  Stack,
  Paragraph,
} from '@bt-healthcare/ui-toolkit';

import { patientAdmissionMapping } from 'mappings/datalists';
import { DataListCard } from 'components/Card/DataListCard/DataListCard';
import type { PatientConfirmationModalProps } from '../types';
import { CardWrapper, SubHeader } from '../styles';

export const PatientConfirmationModal = ({
  data,
  handleClose,
  handleClick,
  modalOpen,
  title,
  strapLine,
}: PatientConfirmationModalProps) => (
  <Modal
    onClose={handleClose}
    isModalOpen={modalOpen}
    id="patient-admission"
    inPortal
  >
    <ModalBody>
      <Stack space="s1" id="confirmation" alignItems="center">
        <SubHeader>{title}</SubHeader>
        <Paragraph textAlign="center">{strapLine}</Paragraph>
      </Stack>
    </ModalBody>
    <CardWrapper>
      <DataListCard
        data={patientAdmissionMapping(data)}
        background={colors.primaryIndigo.indigo01}
      />
    </CardWrapper>
    <ModalFooter flex>
      <Button
        aria-label="Cancel modal"
        id="cancel"
        onClick={handleClose}
        variant="secondary"
      >
        Cancel
      </Button>
      <Button id="confirm" onClick={handleClick}>
        Confirm
      </Button>
    </ModalFooter>
  </Modal>
);
