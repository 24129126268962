import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { StatusFormConfirmation } from 'components/Form/StatusFormConfirmation';
import { bedConfirmationMapping } from 'mappings/datalists';
import { bedAllocationFormMapping } from 'mappings/forms/bedAllocation';
import { useStore } from 'store';
import { ConfirmationIllustration } from 'assets';
import { ROUTES } from 'App.constants';
import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';

export const BedAllocationFormConfirmation = () => {
  const wardId = useRedirectAndReturnState('wardId');
  const [wardHeader, bed, formData] = useStore(
    (state) => [state.wardHeader, state.bed, state.bedAllocationFormData],
    shallow
  );

  const mappedFormData = bedAllocationFormMapping(bed, formData);
  const navigate = useNavigate();

  const handleClick = () =>
    navigate(ROUTES.WARDS_BED_ALLOCATION_HOME, { state: { wardId } });

  return (
    <StatusFormConfirmation
      strapLine="Your bed status was successfully updated. Find your updated
    summary below."
      wardName={wardHeader.wardName}
      data={bedConfirmationMapping(mappedFormData, 'BedStatus')}
      handleClick={handleClick}
      buttonText="View wards"
      icon={<ConfirmationIllustration />}
    />
  );
};
