import type { BedDischargeFormData } from 'components/BedDischarge/types';
import { isEmpty } from 'ramda';
import type { WardBed, IsolationType } from 'services';

export const isolationTypeBuilder = (
  currentData: WardBed,
  data?: BedDischargeFormData
) => {
  if (isEmpty(data?.isolationType)) {
    if (data?.requiresIsolationYN === 'NO') return 'NONE';
    return (
      currentData.wardPatient?.wardDischarge?.attributes.isolationType ?? 'NONE'
    );
  }
  return data?.isolationType?.value ?? 'NONE';
};

export const isolationTypeOtherBuilder = (
  currentData: WardBed,
  data?: BedDischargeFormData
) => {
  const isolationType = isolationTypeBuilder(
    currentData,
    data
  ) as IsolationType;

  if (isolationType !== 'OTHER') return '';

  if (isEmpty(data?.isolationTypeOther)) {
    return currentData.wardPatient?.wardDischarge?.attributes
      .isolationTypeOther;
  }
  return data!.isolationTypeOther;
};

export const pharmacyBuilder = (
  currentData: WardBed,
  data?: BedDischargeFormData
) => {
  if (isEmpty(data?.pharmacy)) {
    return currentData.wardPatient?.wardDischarge?.attributes
      .dischargeStatus !== 'CONFIRMED' &&
      currentData.wardPatient?.wardDischarge?.attributes.dischargeStatus !==
        'POTENTIAL'
      ? currentData.wardPatient?.wardDischarge?.attributes.pharmacy ?? null
      : null;
  }
  return data!.pharmacy;
};
