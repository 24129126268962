import type { CareSettingPatient, WardBed } from 'services';
import type { BedAllocationFormData } from 'components/BedAllocation/types';
import { isEmpty } from 'ramda';
import { getInCleaningDefaultValues } from './inCleaning.utils';

export const defaultValues = (
  wardBed: WardBed,
  formData: BedAllocationFormData,
  patient: CareSettingPatient
) => {
  const { bedStatus } = wardBed.attributes;
  const currentBedStatus = formData.bedStatus ?? '';

  const defaultFields = {
    bedStatus: currentBedStatus,
    patient: {},
    patientName: '',
    outlierYN: '',
    porterStatus: '',
    bedCleaningMethod: null,
    arrivalTime: '',
    arrivalDate: undefined,
    bedStatusTime: '',
    bedStatusDate: undefined,
  };

  if (bedStatus === 'CLEANING' && isEmpty(patient))
    return getInCleaningDefaultValues(defaultFields, wardBed);

  return defaultFields;
};
