import * as R from 'ramda';

import type { GetWardOptionsQuery } from 'services';

/** Transform query to return ward from options for DropDownList */
export const wardFromOptionsTransform = (response: GetWardOptionsQuery) => {
  const { wardStatistics } = response;
  const wardsWithAllocatedPatients = wardStatistics?.filter((item) =>
    item.attributes.statistics.some(
      (patient) => patient.name === 'Patients' && patient.value > 0
    )
  );

  const wardOptions =
    wardsWithAllocatedPatients?.map((ward) => ({
      value: ward.attributes.wardId,
      label: ward.attributes.name,
    })) ?? [];

  return R.sortWith([R.ascend(R.prop('label'))], wardOptions);
};

/** Transform query to return ward to options for DropDownList */
export const wardToOptionsTransform = (response: GetWardOptionsQuery) => {
  const { wardStatistics } = response;

  const wardOptions =
    wardStatistics?.map((ward) => ({
      value: ward.attributes.wardId,
      label: ward.attributes.name,
    })) ?? [];

  return R.sortWith([R.ascend(R.prop('label'))], wardOptions);
};
