import { Input, MultipleChoiceButton } from '@bt-healthcare/ui-toolkit';

import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import {
  genderOptions,
  patientWardPreferenceOptions,
} from 'components/Form/formOptions';
import { matchesEnumValue } from 'validation/rules';
import type { PatientAdmissionFormFieldsProps } from '../types';

export const WardPreferences = ({
  register,
  control,
  markAsRequired = false,
}: PatientAdmissionFormFieldsProps) => (
  <>
    <MultipleChoiceButton
      size="lg"
      label="Gender"
      options={genderOptions}
      markAsRequired={markAsRequired}
      {...register('genderIdentifiedAs')}
    />
    <ConditionalFieldRenderer
      control={control}
      fieldName="genderIdentifiedAs"
      condition={(arg) =>
        matchesEnumValue(arg, 'NON_BINARY') ||
        matchesEnumValue(arg, 'PREFER_NONE')
      }
    >
      <MultipleChoiceButton
        size="lg"
        label="Patient ward preference"
        options={patientWardPreferenceOptions}
        {...register('patientWardPreference')}
      />
      <Input
        id="furtherWardPreferences"
        label="Further ward preferences"
        helperText="e.g. Place in side room"
        {...register('furtherWardPreference')}
      />
    </ConditionalFieldRenderer>
  </>
);
