import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { DropDown } from '@bt-healthcare/ui-toolkit';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { RHFDropDownProps } from './types';

/** React hook form wrapper for DropDown component  */
export const RHFDropDown = <T extends FieldValues>({
  label,
  options,
  fieldName,
  control,
  defaultValue,
  handleChange,
  errorText,
  isMulti = false,
  markAsRequired = false,
}: RHFDropDownProps<T>) => (
  <Controller
    name={fieldName}
    control={control}
    defaultValue={defaultValue}
    render={({ field: { onChange, onBlur, name, value } }) => (
      <DropDown
        id={name}
        value={value as TypedOptionType<string>}
        name={name}
        label={label}
        options={options}
        errorText={errorText}
        onBlur={onBlur}
        onChange={(option: TypedOptionType<string>) => {
          onChange(option);
          if (handleChange && option != null) handleChange(option);
        }}
        isMulti={isMulti}
        markAsRequired={markAsRequired}
      />
    )}
  />
);
