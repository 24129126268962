import { Input, MultipleChoiceButton } from '@bt-healthcare/ui-toolkit';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import {
  ttoOptions,
  carePackageOptions,
  pharmacyOptions,
  waitingForPotentialDischargeOptions,
} from 'components/Form/formOptions';
import { RHFDatePicker } from 'components/Form/RHFDatePicker';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import type { DischargeStatusType } from 'services';
import {
  isOtherSelectedMulti,
  matchesEnumValue,
  matchesOption,
} from 'validation/rules';
import type { BedDischargeFormFieldsProps } from '../types';

export const DischargePotential = ({
  control,
  register,
  errors,
}: BedDischargeFormFieldsProps) => (
  <ConditionalFieldRenderer
    control={control}
    fieldName="dischargeStatus"
    condition={(arg) => matchesOption<DischargeStatusType>(arg, 'POTENTIAL')}
  >
    <RHFDatePicker
      control={control}
      fieldName="dischargeExpectedDate"
      timeFieldName="dischargeExpectedTime"
      label="Estimated date and time of discharge"
      minDate={new Date()}
      markAsRequired
      fieldErrorText={errors?.dischargeExpectedDate?.message}
      timeErrorText={errors?.dischargeExpectedTime?.message}
    />
    <MultipleChoiceButton
      size="lg"
      label="TTO status"
      options={ttoOptions}
      {...register('toTakeOutStatus')}
    />
    <ConditionalFieldRenderer
      control={control}
      fieldName="toTakeOutStatus"
      condition={(arg) => matchesEnumValue(arg, 'COMPLETE')}
    >
      <MultipleChoiceButton
        label="Pharmacy"
        size="lg"
        options={pharmacyOptions}
        {...register('pharmacy')}
      />
    </ConditionalFieldRenderer>
    <MultipleChoiceButton
      label="Care package"
      size="lg"
      options={carePackageOptions}
      {...register('carePackageStatus')}
    />
    <RHFDropDown
      label="Waiting for"
      fieldName="waitingReasonsForPotentialDischarge"
      control={control}
      options={waitingForPotentialDischargeOptions}
      isMulti
    />
    <ConditionalFieldRenderer
      control={control}
      fieldName="waitingReasonsForPotentialDischarge"
      condition={(arg) => isOtherSelectedMulti(arg)}
    >
      <Input
        id="waitingReasonOther"
        label="Other reason"
        {...register('waitingReasonOther')}
        markAsRequired
        errorText={errors?.waitingReasonOther?.message}
      />
    </ConditionalFieldRenderer>
  </ConditionalFieldRenderer>
);
