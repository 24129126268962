import { FormLayout, Loader } from '@bt-healthcare/ui-toolkit';
import { useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import type { ChangeEvent, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from 'store';
import { bedAllocationSchema } from 'components/BedAllocation/validationSchema';
import { PatientHeader } from 'components/PatientHeader';
import { BedAllocationFormFields } from 'components/BedAllocation/BedAllocationFormFields';
import type { BedAllocationFormData } from 'components/BedAllocation/types';
import {
  bedAllocateWardBedSubmissionMap,
  bedAllocationFormMapping,
  bedUpdateWardBedSubmissionMap,
} from 'mappings/forms/bedAllocation';
import { BedConfirmationModal } from 'components/Modal';
import { PageHeader } from 'components/PageHeader';
import type { BedStatusType, WardBed } from 'services';
import {
  GetWardBedsDocument,
  useCreateWardPatientMutation,
  useGetWardBedsQuery,
  useUpdateWardBedMutation,
} from 'services';
import { PageNotification } from 'components/PageNotification';
import { MatchingPatientModal } from 'components/Modal/MatchingPatientModal';
import { wardHasMatchingPatientCheck } from 'transforms/hasMatchingPatient.utils';
import { useTracking } from 'hooks/useTracking';
import { ROUTES } from 'App.constants';
import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';
import { defaultValues } from './formDefaultValues';
import { setFormValues } from './formResetValues';

export const BedAllocationForm = () => {
  const wardId = useRedirectAndReturnState('wardId');
  const navigate = useNavigate();
  const { trackPage } = useTracking();
  const [wardBeds, setWardBeds] = useState<WardBed[]>([]);

  const [
    wardHeader,
    bed,
    formData,
    setFormData,
    patient,
    errorMessage,
    setMenuHeader,
    { careSettingId },
    hasFlag,
  ] = useStore(
    (state) => [
      state.wardHeader,
      state.bed,
      state.bedAllocationFormData,
      state.setBedAllocationForm,
      state.patient,
      state.errorMessage,
      state.setMenuHeader,
      state.appConfig,
      state.hasFlag,
    ],
    shallow
  );

  useEffect(() => {
    setMenuHeader(wardHeader!.wardName);
  }, [wardHeader]);

  useEffect(() => {
    trackPage(
      `${wardHeader ? wardHeader.wardName : 'ward'} - Allocate Bed Form`
    );
  }, []);

  const [
    createWardPatient,
    { loading: allocateLoading, error: allocateError },
  ] = useCreateWardPatientMutation();
  const [updateWardBed, { loading: updateLoading, error: updateError }] =
    useUpdateWardBedMutation();

  const [activeStatus, setActiveStatus] = useState<string>(
    bed.attributes.bedStatus
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [matchingPatientAlert, setMatchingPatientAlert] = useState(false);

  const { loading: wardBedsLoading, error: wardBedsError } =
    useGetWardBedsQuery({
      variables: {
        wardId: wardId!,
      },
      onCompleted: (data) => {
        setWardBeds(data?.wardBeds as WardBed[]);
      },
    });

  const hasError = allocateError || updateError || wardBedsError;
  const loading = allocateLoading || updateLoading || wardBedsLoading;

  const {
    register,
    control,
    setValue,
    getValues,
    resetField,
    handleSubmit,
    formState: { isValid },
  } = useForm<BedAllocationFormData>({
    mode: 'onChange',
    defaultValues: defaultValues(bed, formData, patient),
    resolver: yupResolver(bedAllocationSchema),
  });

  const mappedBedAllocationData = bedAllocationFormMapping(bed, formData);

  useEffect(() => {
    const isMatchingPatient = wardHasMatchingPatientCheck(wardBeds, patient);
    if (isMatchingPatient) setMatchingPatientAlert(true);
  }, [patient, wardBeds]);

  const onSubmit = () => {
    const isAllocated =
      mappedBedAllocationData.attributes.bedStatus === 'ALLOCATED';
    const refetchQueries = [
      { query: GetWardBedsDocument, variables: { wardId } },
    ];

    const onCompleted = () =>
      navigate(ROUTES.WARDS_BED_ALLOCATION_CONFIRM, {
        state: { wardId },
      });

    if (isAllocated) {
      createWardPatient({
        variables: {
          input: bedAllocateWardBedSubmissionMap(
            mappedBedAllocationData,
            wardId!,
            formData.patient,
            careSettingId
          ),
        },
        onCompleted,
        refetchQueries,
      });
    } else {
      updateWardBed({
        variables: {
          input: bedUpdateWardBedSubmissionMap(
            mappedBedAllocationData,
            wardId!,
            careSettingId
          ),
        },
        onCompleted,
        refetchQueries,
      });
    }
  };

  const handlePatientClick = () => {
    const data = getValues();
    if (patient) {
      /** Reset form fields if entered, if a new patient is selected */
      const resetData = {
        ...data,
        arrivalTime: '',
        arrivalDate: '',
        outlierYN: '',
        porterStatus: '',
      };
      setFormData(resetData);
    } else {
      setFormData(data);
    }
    navigate(ROUTES.WARDS_BED_ALLOCATION_PATIENTS, { state: { wardId } });
  };

  const handleStatusChange = (event: SyntheticEvent) => {
    const { value } = (event as ChangeEvent<HTMLInputElement>).target;
    setActiveStatus(value);

    const { bedStatus } = bed.attributes;
    const isCurrent = bedStatus === value;

    setFormValues(isCurrent, resetField, bed, value as BedStatusType);
  };

  const handleClose = () => setModalOpen(false);

  const handleClick = () => {
    handleSubmit(onSubmit)();
    handleClose();
  };

  useEffect(() => {
    setFormData(getValues());
  }, [activeStatus]);

  const isInactive =
    activeStatus === 'CLEANING' &&
    bed.attributes.bedCleaningMethod === formData.bedCleaningMethod?.value;

  if (loading) return <Loader />;

  return (
    <>
      <FormLayout
        id="statusForm"
        header={
          <PageHeader
            title={wardHeader.wardName}
            capacity={wardHeader.capacity}
          />
        }
        primaryButtonClick={() => {
          const data = getValues();
          setFormData(data);
          if (hasFlag)
            navigate(ROUTES.WARDS_MANAGE_BED_ALLOCATION_CONFIRM, {
              state: { wardId },
            });
          setModalOpen(true);
        }}
        primaryButtonDisabled={!isValid || isInactive}
      >
        {hasError && (
          <PageNotification message={errorMessage('allocating a bed')} />
        )}
        <PatientHeader
          name={bed.attributes.wardBedReference}
          person={bed.wardPatient?.careSettingPatient?.person}
          gender={bed.attributes.bedGender}
          bedStatus={bed.attributes.bedStatus}
        />
        <BedAllocationFormFields
          register={register}
          control={control}
          setValue={setValue}
          formData={formData}
          setFormData={setFormData}
          currentBedStatus={bed.attributes.bedStatus}
          isInactive={isInactive}
          patient={patient}
          handlePatientClick={handlePatientClick}
          handleStatusChange={handleStatusChange}
        />
      </FormLayout>
      <BedConfirmationModal
        wardName={wardHeader.wardName}
        data={mappedBedAllocationData}
        modalOpen={modalOpen}
        handleClose={handleClose}
        handleClick={handleClick}
        ragType="BedStatus"
        title="Confirm bed status"
        strapLine="Please confirm you are about to update the status of this
        bed."
      />
      <MatchingPatientModal
        modalOpen={matchingPatientAlert}
        setModalOpen={setMatchingPatientAlert}
        redirectPath={ROUTES.WARDS_BED_ALLOCATION_PATIENTS}
        wardId={wardId}
      />
    </>
  );
};
