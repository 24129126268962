import * as yup from 'yup';

import {
  isRequired,
  isRequiredDropDownListOption,
  isRequiredTime,
  isRequiredWhenSelection,
} from 'validation/schema';
import type { FormKey } from '../Form/types';
import type { BedTransferFormData } from './types';

const bedTransferFormRules = {
  fromWard: isRequiredDropDownListOption(),
  fromBed: isRequiredDropDownListOption(),
  toBed: isRequiredDropDownListOption(),
  bedCleaningStatus: isRequired(),
  bedCleaningMethod: isRequiredWhenSelection({
    field: 'bedCleaningStatus',
    value: 'REQUIRED',
    fn: () => isRequiredDropDownListOption(),
    yupSchema: yup.object(),
  }),
};

const bedIntraTransferFormRules = {
  ...bedTransferFormRules,
  intraWardTransferReason: isRequiredDropDownListOption(),
  otherTransferReason: isRequiredWhenSelection({
    field: 'intraWardTransferReason',
    value: 'OTHER',
  }),
} as { [key in FormKey<BedTransferFormData>]: any };

const bedInterTransferFormRules = {
  ...bedTransferFormRules,
  toWard: isRequiredDropDownListOption(),
  interWardTransferReason: isRequiredDropDownListOption(),
  otherTransferReason: isRequiredWhenSelection({
    field: 'interWardTransferReason',
    value: 'OTHER',
  }),
  porterStatus: isRequired(),
  arrivalToWardDate: isRequired(),
  arrivalToWardTime: isRequiredTime(),
  specialty: isRequiredWhenSelection({
    field: 'interWardTransferReason',
    value: 'PATIENT_CARE_NEEDS_CHANGED',
    fn: () => isRequiredDropDownListOption(),
    yupSchema: yup.object(),
  }),
} as { [key in FormKey<BedTransferFormData>]: any };

export const bedIntraTransferSchema = yup.object(bedIntraTransferFormRules);
export const bedInterTransferSchema = yup.object(bedInterTransferFormRules);
