import { colors, Text } from '@bt-healthcare/ui-toolkit';
import { EMPTY_VALUE } from 'App.constants';

import { BedTransferStatusMap, PorteringMap } from 'mappings/enums';

import {
  getPatientName,
  getWardBedTransferReason,
} from 'mappings/mapping.utils';
import { wardBedTransferRagStatusColorMap } from 'mappings/ragStatus/bedTransfer';
import type { WardBedTransferQuery } from 'models';

export const headerData: any = [
  { id: '1', label: 'Patient' },
  { id: '2', label: 'From', hide: 'mobile' },
  { id: '3', label: 'To', hide: 'mobile' },
  {
    id: '4',
    label: 'Date arrival to ward',
    hide: 'mobile',
  },
  {
    id: '5',
    label: 'Time arrival to ward',
    hide: 'mobile',
  },
  { id: '6', label: 'Reason' },
  { id: '7', label: 'Porter', hide: 'mobile' },
  {
    id: '8',
    label: 'Status',
  },
];

export const rowData: any = (data: WardBedTransferQuery) =>
  data.map((rowItem) => ({
    id: rowItem.id,
    items: [
      {
        id: '1',
        item: (
          <Text fontWeight={500} color={colors.primaryIndigo.indigo08}>
            {getPatientName(rowItem.careSettingPatient)}
          </Text>
        ),
      },
      {
        id: '2',
        item: `${rowItem.fromWard?.attributes.name}, Bed ${rowItem.fromWardBed?.attributes.wardBedReference}`,
        hide: 'mobile',
      },
      {
        id: '3',
        item: `${rowItem.toWard?.attributes.name}, Bed ${rowItem.toWardBed?.attributes.wardBedReference}`,
        hide: 'mobile',
      },
      {
        id: '4',
        item: rowItem.attributes.client?.arrivalToWardDate ?? EMPTY_VALUE,
        hide: 'mobile',
      },
      {
        id: '5',
        item: rowItem.attributes.client?.arrivalToWardTime ?? EMPTY_VALUE,
        hide: 'mobile',
      },
      {
        id: '6',
        item: getWardBedTransferReason(rowItem.attributes),
      },
      {
        id: '7',
        item: rowItem.attributes.porterStatus
          ? PorteringMap[rowItem.attributes.porterStatus]
          : EMPTY_VALUE,
        hide: 'mobile',
      },
      {
        id: '8',
        item: (
          <Text
            fontWeight={500}
            color={wardBedTransferRagStatusColorMap(
              rowItem.attributes.bedTransferStatus ?? 'NONE'
            )}
          >
            {
              BedTransferStatusMap[
                rowItem.attributes.bedTransferStatus ?? 'NONE'
              ]
            }
          </Text>
        ),
      },
    ],
  }));
