import { StatusCodes } from 'http-status-codes';
import type { GraphQLError } from 'graphql';
import type { Errors } from 'store/errorSlice';

export const createUserError = (
  { error, errorType }: Errors,
  defaultErrorMessage: string
) => {
  if (errorType === 'GraphQLError') {
    const graphQLErrorExtensions: any = (error as GraphQLError[])[0]
      ?.extensions;

    // Patient has active bed transfer while completing discharge
    if (
      graphQLErrorExtensions &&
      graphQLErrorExtensions.response?.status ===
        StatusCodes.UNPROCESSABLE_ENTITY
    ) {
      return 'Discharges cannot complete while a transfer is in progress. Please complete the transfer, then the discharge.';
    }
  }
  return defaultErrorMessage;
};
