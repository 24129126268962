import { matchesEnumValue } from 'validation/rules';
import { bedCleaningMethodOptions } from 'components/Form/formOptions';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { Notification } from '@bt-healthcare/ui-toolkit';
import type { BedStatusInCleaningProps } from '../types';

export const BedStatusInCleaning = ({
  control,
  formData,
  setFormData,
  currentBedStatus,
  isInactive,
}: BedStatusInCleaningProps) => {
  const handleChange = ({ value }: TypedOptionType<string>) => {
    const cleaningOption = bedCleaningMethodOptions.find(
      (item) => item.value === value
    ) as TypedOptionType<string>;

    const resetData = {
      ...formData,
      bedCleaningMethod: cleaningOption,
    };
    setFormData(resetData);
  };

  return (
    <ConditionalFieldRenderer
      control={control}
      fieldName="bedStatus"
      condition={(arg) => matchesEnumValue(arg, 'CLEANING')}
    >
      <RHFDropDown
        label="Type of clean"
        fieldName="bedCleaningMethod"
        control={control}
        options={bedCleaningMethodOptions}
        handleChange={(event) => handleChange(event)}
      />

      {currentBedStatus === 'CLEANING' && !isInactive && (
        <Notification type="softWarning">
          By choosing a different type of clean, the estimated cleaning time
          will be adjusted accordingly.
        </Notification>
      )}
    </ConditionalFieldRenderer>
  );
};
