import { colors } from '@bt-healthcare/ui-toolkit';
import type { DischargeStatusType, BedStatusType } from 'services';

const hasNoRagColour = (bedStatus: BedStatusType) =>
  bedStatus === 'UNALLOCATED' ||
  bedStatus === 'CLOSED' ||
  bedStatus === 'CLEANING';

const getDischargeSort = (bedStatus: BedStatusType) => {
  if (bedStatus === 'CLEANING') return 7;
  return bedStatus === 'UNALLOCATED' || bedStatus === 'CLOSED' ? 8 : 1;
};

// TODO: Need to assoc. Bed transfers to this color map
export const bedDischargeRagStatusColorMap = (
  dischargeStatus: DischargeStatusType,
  bedStatus: BedStatusType,
  theme: any
) => {
  const map = {
    CONFIRMED: theme.rag.green,
    POTENTIAL: theme.rag.amber,
    DELAYED: theme.rag.red,
    NOT_FIT: colors.grey.grey08,
    CLINICALLY_READY: colors.rag.light.purple,
    COMPLETED: 'transparent', // This state should never exist - added to map to prevent errors
    NONE: hasNoRagColour(bedStatus)
      ? 'transparent'
      : colors.secondaryBlue.blue06,
  };
  return map[dischargeStatus];
};

export const bedDischargeRagStatusSortMap = (
  dischargeStatus: DischargeStatusType,
  bedStatus: BedStatusType
) => {
  const map = {
    DELAYED: 2,
    POTENTIAL: 3,
    CONFIRMED: 4,
    CLINICALLY_READY: 5,
    NOT_FIT: 6,
    COMPLETED: 9, // This state should never exist - added to map to prevent errors
    NONE: getDischargeSort(bedStatus),
  };
  return map[dischargeStatus];
};
