import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { insert } from 'ramda';

import {
  GenderIdentifiedAsMap,
  PatientWardPreferencesMap,
} from 'mappings/enums';
import type { PatientWardPreferencesType } from 'services';
import { isEmpty } from 'validation/rules';
import { EMPTY_VALUE } from 'App.constants';

export const getGenderFromAbbrev = (abbrev: string) => {
  if (abbrev === 'M') return GenderIdentifiedAsMap.MALE;
  if (abbrev === 'F') return GenderIdentifiedAsMap.FEMALE;
  if (abbrev === 'NB') return GenderIdentifiedAsMap.NON_BINARY;
  return EMPTY_VALUE;
};

export const requireGender = (genderAbbrev: string) =>
  genderAbbrev !== 'M' && genderAbbrev !== 'F';

export const getWardBedFromTransfers = (
  {
    label,
    gender,
    wardPref,
    furtherPref,
    patientName,
    dateOfBirth,
    patientIdentifier,
    isolation,
    speciality,
    outlier,
  }: TypedOptionType<string>,
  wardName: string
) => {
  // Note: see wardBedOptionsTransform to add additional fields here
  const defaultResult = [
    {
      key: 'Ward',
      value: wardName,
    },
    {
      key: 'D.O.B',
      value: dateOfBirth,
    },
    {
      key: 'Bed',
      value: label,
    },
    {
      key: 'Patient',
      value: patientName,
    },
    {
      key: 'Isolation',
      value: isolation,
    },
    {
      key: 'Speciality',
      value: speciality,
    },
    {
      key: 'Gender',
      value: getGenderFromAbbrev(gender),
    },
    {
      key: 'Outlier',
      value: outlier,
    },
  ];

  const genderResult = insert(
    7,
    {
      key: 'Further pref',
      value: isEmpty(furtherPref) ? EMPTY_VALUE : furtherPref,
    },
    insert(
      5,
      {
        key: 'Ward pref',
        value:
          PatientWardPreferencesMap[wardPref as PatientWardPreferencesType],
      },
      defaultResult
    )
  );

  const patiendIdentifierResult = insert(
    4,
    {
      key: 'Hospital No',
      value: patientIdentifier,
    },
    defaultResult
  );

  const genderWithPatientIdentifierResult = insert(
    7,
    {
      key: 'Further pref',
      value: isEmpty(furtherPref) ? EMPTY_VALUE : furtherPref,
    },
    insert(
      5,
      {
        key: 'Ward pref',
        value:
          PatientWardPreferencesMap[wardPref as PatientWardPreferencesType],
      },
      insert(
        4,
        {
          key: 'Hospital No',
          value: patientIdentifier,
        },
        defaultResult
      )
    )
  );

  if (requireGender(gender)) {
    return patientIdentifier ? genderWithPatientIdentifierResult : genderResult;
  }

  return patientIdentifier ? patiendIdentifierResult : defaultResult;
};
