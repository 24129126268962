import type { BedDischargeFormData } from 'components/BedDischarge/types';
import type { WardBed, WardDischargeInput } from 'services';
import { isEmpty, isEmptyArray } from 'validation/rules';
import { omitGraphQLFields } from 'mappings/mapping.utils';
import { getDateAsString, getDateTime, setFormDatesToUTC } from '../date.utils';
import {
  isolationTypeBuilder,
  isolationTypeOtherBuilder,
  pharmacyBuilder,
} from './builders/bedDischarge';

export const bedDischargeFormMapping = (
  currentData: WardBed,
  data?: BedDischargeFormData,
  decisionMakerLastVisitedDateTime?: string | null,
  dischargeToAssessDateTime?: string | null
) =>
  ({
    ...currentData,
    attributes: {
      ...currentData.attributes,
      bedCleaningMethod: isEmpty(data?.bedCleaningMethod)
        ? currentData.attributes.bedCleaningMethod
        : data?.bedCleaningMethod?.value,
      bedStatus: isEmpty(data?.bedCleaningMethod)
        ? currentData.attributes.bedStatus
        : 'CLEANING',
    },
    wardPatient: {
      ...currentData.wardPatient,
      wardDischarge: {
        ...currentData.wardPatient?.wardDischarge,
        id: currentData.wardPatient?.wardDischarge?.id,
        attributes: {
          ...currentData.wardPatient?.wardDischarge?.attributes,
          dischargeStatus: isEmpty(data?.dischargeStatus)
            ? currentData.wardPatient?.wardDischarge?.attributes.dischargeStatus
            : data!.dischargeStatus?.value,
          dischargeExpectedDateTime: isEmpty(data?.dischargeExpectedDate)
            ? currentData.wardPatient?.wardDischarge?.attributes
                ?.dischargeExpectedDateTime
            : getDateTime(
                data!.dischargeExpectedDate as Date,
                data?.dischargeExpectedTime
              ),
          client: {
            dischargeExpectedDate: isEmpty(data?.dischargeExpectedDate)
              ? null
              : getDateAsString(data!.dischargeExpectedDate as Date),
            dischargeExpectedTime: isEmpty(data?.dischargeExpectedTime)
              ? null
              : data!.dischargeExpectedTime,
            readyForDischargeDate: isEmpty(data?.readyForDischargeDate)
              ? null
              : getDateAsString(data!.readyForDischargeDate as Date),
            readyForDischargeTime: isEmpty(data?.readyForDischargeTime)
              ? null
              : data!.readyForDischargeTime,
            clinicallyReadyForDischargeDate: isEmpty(
              data?.clinicallyReadyForDischargeDate
            )
              ? null
              : getDateAsString(data!.clinicallyReadyForDischargeDate as Date),
            clinicallyReadyForDischargeTime: isEmpty(
              data?.clinicallyReadyForDischargeTime
            )
              ? null
              : data!.clinicallyReadyForDischargeTime,
            dischargeActualDate: isEmpty(data?.dischargeActualDate)
              ? null
              : getDateAsString(data!.dischargeActualDate as Date),
            dischargeActualTime: isEmpty(data?.dischargeActualTime)
              ? null
              : data!.dischargeActualTime,
          },
          dischargeActualDateTime: isEmpty(data?.dischargeActualDate)
            ? currentData.wardPatient?.wardDischarge?.attributes
                ?.dischargeActualDateTime
            : getDateTime(
                data!.dischargeActualDate as Date,
                data?.dischargeActualTime
              ),
          decisionMakerYN: isEmpty(data?.decisionMakerYN)
            ? 'NONE'
            : data!.decisionMakerYN,
          requiresIsolationYN: isEmpty(data?.requiresIsolationYN)
            ? 'NONE'
            : data!.requiresIsolationYN,
          isolationType: isolationTypeBuilder(currentData, data),
          isolationTypeOther: isolationTypeOtherBuilder(currentData, data),
          toTakeOutStatus: isEmpty(data?.toTakeOutStatus)
            ? currentData.wardPatient?.wardDischarge?.attributes
                .toTakeOutStatus ?? 'NONE'
            : data?.toTakeOutStatus,
          pharmacy: pharmacyBuilder(currentData, data),
          carePackageStatus: isEmpty(data?.carePackageStatus)
            ? currentData.wardPatient?.wardDischarge?.attributes
                .carePackageStatus ?? 'NONE'
            : data?.carePackageStatus,
          porterStatus: isEmpty(data?.porterStatus)
            ? currentData.wardPatient?.wardDischarge?.attributes.porterStatus ??
              'NONE'
            : data?.porterStatus,
          dischargeStatusFailedReasons: isEmptyArray(
            data?.failedDischargeStatusReasons
          )
            ? null
            : data?.failedDischargeStatusReasons?.map((item) => item.value),
          dischargeStatusFailedReasonOther: isEmpty(
            data?.failedDischargeStatusReasonOther
          )
            ? null
            : data?.failedDischargeStatusReasonOther,
          socialCareYN: isEmpty(data?.socialCareYN)
            ? currentData.wardPatient?.wardDischarge?.attributes.socialCareYN ??
              'NONE'
            : data?.socialCareYN,
          waitingReasonsForPotentialDischarge: isEmptyArray(
            data?.waitingReasonsForPotentialDischarge
          )
            ? null
            : data!.waitingReasonsForPotentialDischarge?.map(
                (item) => item.value
              ),
          waitingReasonOther: isEmpty(data?.waitingReasonOther)
            ? null
            : data?.waitingReasonOther,
          waitingReasonsForClinicallyReadyForDischarge: isEmptyArray(
            data?.waitingReasonsForClinicallyReadyForDischarge
          )
            ? null
            : data!.waitingReasonsForClinicallyReadyForDischarge!.map(
                (item) => item.value
              ),
          requireDischargeToAssess: isEmpty(data?.requireDischargeToAssess)
            ? currentData.wardPatient?.wardDischarge?.attributes
                .requireDischargeToAssess ?? null
            : data?.requireDischargeToAssess,
          dischargeAssessmentOutcome: isEmpty(data?.dischargeAssessmentOutcome)
            ? null
            : data!.dischargeAssessmentOutcome?.value,
          readyForDischargeDateTime: isEmpty(data?.readyForDischargeDate)
            ? null
            : getDateTime(
                data!.readyForDischargeDate as Date,
                data?.readyForDischargeTime
              ),
          clinicallyReadyForDischargeDateTime: isEmpty(
            data?.clinicallyReadyForDischargeDate
          )
            ? null
            : getDateTime(
                data!.clinicallyReadyForDischargeDate as Date,
                data?.clinicallyReadyForDischargeTime
              ),
          decisionMakerLastVisitedDateTime,
          dischargeToAssessDateTime,
          bedCleaningStatus: isEmpty(data?.bedCleaningStatus)
            ? currentData.wardPatient?.wardDischarge?.attributes
                ?.bedCleaningStatus
            : data?.bedCleaningStatus,
        },
      },
    },
  } as WardBed);

export const bedDischargeSubmissionMap = (
  mappedWardBedData: WardBed,
  wardId: string,
  careSettingId: string,
  needsBedTransferConfirmation?: boolean
) => {
  const wardPatientId = mappedWardBedData.wardPatient!.id;
  const wardDischargeId = mappedWardBedData.wardPatient!.wardDischarge!.id;
  const { wardGroupId } = mappedWardBedData.attributes;
  const version =
    mappedWardBedData.wardPatient?.wardDischarge?.attributes.audit?.version;
  const bedTransferConfirmed = needsBedTransferConfirmation;
  return {
    ...omitGraphQLFields(
      ['audit'],
      setFormDatesToUTC(
        mappedWardBedData.wardPatient!.wardDischarge!.attributes,
        [
          'dischargeActualDateTime',
          'dischargeExpectedDateTime',
          'readyForDischargeDateTime',
          'clinicallyReadyForDischargeDateTime',
        ]
      )
    ),
    wardId,
    careSettingId,
    wardPatientId,
    wardDischargeId,
    wardGroupId,
    version,
    bedTransferConfirmed,
    bedCleaningMethod: mappedWardBedData.attributes.bedCleaningMethod,
  } as WardDischargeInput;
};
