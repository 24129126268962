import type { Comparator, ExtendedWardBedSort } from './types';

const extractValue = (string: string, pattern: RegExp = /[0-9]+/) => {
  const match = string.toLowerCase().match(pattern);
  return match ? match[0] : null;
};

const alphaSort: Comparator<string> = (curr, next) => {
  const pattern = /^\d*[a-z]*|[a-z]+/;
  const currMatch = extractValue(curr, pattern);
  const nextMatch = extractValue(next, pattern);
  if (currMatch !== null && nextMatch !== null) {
    return currMatch.localeCompare(nextMatch);
  }
  return currMatch === null && nextMatch !== null ? 1 : -1;
};

export const alphaNumericComparator: Comparator<string> = (curr, next) => {
  const sortResult = alphaSort(curr, next);
  if (sortResult !== 0) {
    return sortResult;
  }
  const currMatch = extractValue(curr);
  const nextMatch = extractValue(next);
  if (currMatch === null) {
    return -1;
  }
  if (nextMatch === null) {
    return 1;
  }
  return +currMatch - +nextMatch;
};

export const wardBedSortComparator: Comparator<ExtendedWardBedSort> = (
  curr,
  next
) => {
  const nameCompare = curr.sortOrder - next.sortOrder;
  return nameCompare !== 0
    ? nameCompare
    : alphaNumericComparator(curr.sortName, next.sortName);
};
