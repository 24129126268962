import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';

import type {
  BedStatusType,
  DischargeStatusType,
  Maybe,
  WardBed,
} from 'services';

export const notEmpty = (arg?: Maybe<string>) => arg != null && arg !== '';

export const isEmpty = (
  arg?: Maybe<string | TypedOptionType<string | number> | Date>
) => arg == null || arg === '';

export const isEmptyArray = <T>(arg?: Maybe<T[]>) =>
  arg == null || arg.length === 0;

export const isEnumValue = (value: string, arg?: string) =>
  arg != null && arg.toUpperCase() === value;

export const isYes = (arg?: string) => isEnumValue('YES', arg);
export const isNo = (arg?: string) => isEnumValue('NO', arg);

export const isOtherSelected = (arg: Maybe<TypedOptionType<string>>) =>
  arg != null && arg.value === 'OTHER';

export const isOtherSelectedMulti = (arg: TypedOptionType<string>[]) =>
  arg != null && arg.some((item) => item.value === 'OTHER');

export const matchesOption = <T extends string | number>(
  arg: Maybe<TypedOptionType<T>>,
  option: T
) => arg != null && arg.value === option;

export const matchesEnumValue = (value: string, arg?: string) =>
  arg != null && arg === value;

export const isValidOption = (
  arg: TypedOptionType<string> | string | TypedOptionType<string>[],
  value: string
) => {
  if (!arg) return false;
  if (value.includes('|')) {
    const args = value.split('|');
    if (typeof arg === 'string') return args.includes(arg);
    if (Array.isArray(arg))
      return arg.every((item) => args.includes(item.value));
    return args.includes(arg.value as string);
  }
  if (typeof arg === 'string') return arg === value;
  if (Array.isArray(arg)) return arg.some((item) => item.value === value);
  return arg.value === value;
};

/** WardBed rules */
export const bedAllocationDisabled = (
  { attributes }: WardBed,
  hasBedManageAccess: boolean
) =>
  (!hasBedManageAccess && attributes.bedStatus === 'CLOSED') ||
  attributes.bedStatus === 'ALLOCATED' ||
  attributes.bedStatus === 'PREALLOCATED';

export const bedDischargeDisabled = ({ attributes }: WardBed) =>
  attributes.bedStatus !== 'ALLOCATED';

export const bedAllocatedWithNoDischarge = (
  bedStatus?: BedStatusType,
  dischargeStatus?: DischargeStatusType
) => dischargeStatus == null && bedStatus === 'ALLOCATED';
