import { getPatientName } from 'mappings/mapping.utils';
import type { CareSettingPatient, WardBed } from 'services';

export const duplicatePatientCheck = (name: string, wardBeds: WardBed[]) =>
  wardBeds.some((bed) => {
    const patientName = getPatientName(bed.wardPatient?.careSettingPatient);
    return patientName.toLowerCase().trim() === name.toLowerCase();
  });

export const wardHasMatchingPatientCheck = (
  currentWardBeds: WardBed[],
  patient: CareSettingPatient
) => {
  if (patient.person) {
    const patientName = getPatientName(patient);

    return duplicatePatientCheck(patientName, currentWardBeds);
  }

  return false;
};
