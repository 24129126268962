import {
  Input,
  MultipleChoiceButton,
  Wrapper,
} from '@bt-healthcare/ui-toolkit';

import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { yesNoOptions, isolationOptions } from 'components/Form/formOptions';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import type { ChangeEvent, SyntheticEvent } from 'react';
import { isYes, isOtherSelected } from 'validation/rules';
import type { BedDischargeFormFieldsProps } from '../types';

export const Isolation = ({
  register,
  control,
  setValue,
  errors,
}: BedDischargeFormFieldsProps) => {
  const handleIsolationToggle = (event: SyntheticEvent) => {
    const { value } = (event as ChangeEvent<HTMLInputElement>).target;
    if (value === 'NO') {
      setValue?.('requiresIsolationYN', 'NO');
      setValue?.('isolationType', { label: '', value: 'NONE' });
    }

    return null;
  };

  return (
    <>
      <Wrapper onChange={(event) => handleIsolationToggle(event)}>
        <MultipleChoiceButton
          label="Does the patient require isolation?"
          options={yesNoOptions}
          {...register('requiresIsolationYN')}
        />
      </Wrapper>
      <ConditionalFieldRenderer
        control={control}
        fieldName="requiresIsolationYN"
        condition={isYes}
      >
        <RHFDropDown
          label="Reason for isolation"
          fieldName="isolationType"
          control={control}
          options={isolationOptions}
          markAsRequired
          errorText={
            errors?.isolationType?.message
              ? String(errors.isolationType.message)
              : undefined
          }
        />
        <ConditionalFieldRenderer
          control={control}
          fieldName="isolationType"
          condition={isOtherSelected}
        >
          <Input
            id="isolationTypeOther"
            label="Other reason for isolation"
            {...register('isolationTypeOther')}
            markAsRequired
          />
        </ConditionalFieldRenderer>
      </ConditionalFieldRenderer>
    </>
  );
};
