import { bedAllocationRagStatusSortMap } from 'mappings/ragStatus/bedAllocation';
import { bedDischargeRagStatusSortMap } from 'mappings/ragStatus/bedDischarge';
import { StyledRagStatus } from './styles';
import type { RagStatusProps } from './types';

export const RagStatus = ({
  dischargeStatus,
  bedStatus,
  ragType = 'DischargeStatus',
}: RagStatusProps) => {
  const sortFn =
    ragType === 'BedStatus'
      ? bedAllocationRagStatusSortMap
      : bedDischargeRagStatusSortMap;
  const testIdentifier = `rag-status-${sortFn(dischargeStatus, bedStatus)}`;

  return (
    <StyledRagStatus
      dischargeStatus={dischargeStatus}
      bedStatus={bedStatus}
      ragType={ragType}
      data-testid={testIdentifier}
    />
  );
};
