import { descend, sortWith, ascend, splitEvery } from 'ramda';

import type {
  GetWardGroupStatisticsQuery,
  GetWardStatisticsQuery,
} from 'services';

export const mapWardStatisticsToWardGroups = (
  data: GetWardGroupStatisticsQuery
) => {
  if (!data?.wardGroupStatistics) return [];
  const result = data.wardGroupStatistics.map((item) => ({
    id: item.id,
    name: item.attributes.name,
    capacity: item.attributes.capacity,
    wardGroupId: item.attributes.wardGroupId,
    statistics: [...item.attributes.statistics],
  }));
  return splitEvery(2, result);
};

export const mapWardStatisticsToWards = (data: GetWardStatisticsQuery) => {
  if (!data?.wardStatistics) return [];
  const wardStatistics = data.wardStatistics.map((item) => ({
    id: item.attributes.wardId,
    name: item.attributes.name,
    active: item.attributes.isActiveRecord ?? true,
    capacity: item.attributes.capacity,
    wardGroupId: item.attributes.wardGroupId,
    statistics: [...item.attributes.statistics],
  }));

  const sortedWards = sortWith(
    [descend((ward) => ward.active), ascend((ward) => ward.name)],
    wardStatistics
  );

  return sortedWards;
};
