import type { BedTransferFormData } from 'components/BedTransfer/types';
import { getDate } from 'mappings/date.utils';
import { BedCleaningStatusMap, PorteringMap } from 'mappings/enums';
import type { BedCleaningStatus, PorterStatusType } from 'services';
import type { DictionaryList } from 'types';

export const bedTransferConfirmationMapping = (data: BedTransferFormData) => {
  const {
    interWardTransferReason,
    intraWardTransferReason,
    otherTransferReason,
    arrivalToWardDate,
    arrivalToWardTime,
    specialty,
    porterStatus,
    bedCleaningStatus,
    bedCleaningMethod,
  } = data;

  const getTransferReason = (reason: string, otherReason: string) => {
    if (reason === 'Other') return otherReason;

    return reason;
  };

  let bedCleaningParams: DictionaryList<string> = [];
  const bedCleaning = [
    {
      key: 'Bed cleaning',
      value: BedCleaningStatusMap[bedCleaningStatus as BedCleaningStatus],
    },
  ];

  if (bedCleaningMethod != null) {
    bedCleaningParams = [
      ...bedCleaning,
      { key: 'Type of clean', value: bedCleaningMethod.label },
    ];
  } else {
    bedCleaningParams = [...bedCleaning];
  }

  if (intraWardTransferReason)
    return [
      {
        key: 'Reason',
        value: getTransferReason(
          intraWardTransferReason.label,
          otherTransferReason
        ),
      },
      ...bedCleaningParams,
    ];

  const defaultParams = [
    {
      key: 'Est. date of arrival to ward',
      value: getDate(arrivalToWardDate as string),
    },
    {
      key: 'Est. time of arrival to ward',
      value: arrivalToWardTime!,
    },
    {
      key: 'Porter',
      value: PorteringMap[porterStatus! as PorterStatusType],
    },
    ...bedCleaningParams,
  ];

  if (specialty != null) {
    return [
      {
        key: 'Reason',
        value: getTransferReason(
          interWardTransferReason!.label,
          otherTransferReason
        ),
      },
      { key: 'Patient new speciality', value: specialty!.label },
      ...defaultParams,
    ];
  }
  return [
    {
      key: 'Reason',
      value: getTransferReason(
        interWardTransferReason!.label,
        otherTransferReason
      ),
    },
    ...defaultParams,
  ];
};
