import { spacing, fontSizes, device } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const AdditionalDetailsWrapper = styled.div`
  display: grid;
  gap: ${spacing.s4};
  font-size: ${fontSizes.sm};

  @media ${device.desktop} {
    grid-template-columns: repeat(2, 1fr);
    font-size: ${fontSizes.base};
    gap: ${spacing.s8};
  }
`;

export const MultipleButtonWrapper = styled.div`
  & > div {
    margin-bottom: ${spacing.s4};

    &:last-child {
      margin-bottom: ${spacing.none};
    }
  }
`;
