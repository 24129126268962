import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${spacing.s2};
  height: fit-content;

  svg {
    width: 14px;
    height: 12px;

    @media ${device.desktop} {
      width: 20px;
      height: 18px;
    }
  }

  span {
    color: ${colors.grey.grey08};
    font-size: ${fontSizes.xs};
    margin-left: ${spacing.s2};

    @media ${device.desktop} {
      font-size: ${fontSizes.base};
    }
  }
`;
