import {
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  Stack,
  Paragraph,
} from '@bt-healthcare/ui-toolkit';

import { ConfirmationIllustration } from 'assets';
import type { BedTransferConfirmationModalProps } from '../types';
import { IconWrapper, SubHeader } from '../styles';

export const BedTransferConfirmationModal = ({
  handleViewTransfers,
  handleNewTransfer,
  handleClose,
  modalOpen,
  title,
  strapLine,
}: BedTransferConfirmationModalProps) => (
  <Modal
    onClose={handleClose}
    isModalOpen={modalOpen}
    id="bed-transfer"
    inPortal
  >
    <ModalBody>
      <Stack space="s1" id="confirmation" alignItems="center">
        <IconWrapper>
          <ConfirmationIllustration />
        </IconWrapper>
        <SubHeader>{title}</SubHeader>
        <Paragraph textAlign="center">{strapLine}</Paragraph>
      </Stack>
    </ModalBody>
    <ModalFooter>
      <Button id="view-bed-transfers" onClick={handleViewTransfers}>
        View bed transfers
      </Button>
      <Button
        id="new-bed-transfer"
        onClick={handleNewTransfer}
        variant="tertiary"
      >
        Start new bed transfer
      </Button>
    </ModalFooter>
  </Modal>
);
