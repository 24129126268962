import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const SearchWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${spacing.s6} 0;

  div:first-child {
    flex-grow: 1;
    width: inherit;
    margin-right: ${spacing.s5};
  }

  @media ${device.tablet} {
    div:first-child {
      max-width: calc(50% - 12px);
    }
  }

  @media ${device.desktop} {
    margin: ${spacing.s8} 0;
  }

  @media ${device.largeDesktop} {
    div:first-child {
      max-width: 504px;
    }
  }
`;

// TODO: Form styles to be depreciated - replaced by FormLayout component
export const Subheading = styled.h2`
  display: none;

  @media ${device.desktop} {
    display: block;
    font-size: ${fontSizes.h4};
    line-height: 30px;
    font-weight: 500;
    margin-right: ${spacing.s6};
    color: ${colors.primaryIndigo.indigo08};
  }
`;

export const FormWrapper = styled.div`
  margin-bottom: calc(${spacing.s6} + ${spacing.s6} + ${spacing.s12});

  @media ${device.desktop} {
    max-width: 900px;
  }
`;

export const FormFieldLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s6};
  margin: ${spacing.s6} ${spacing.none};

  @media ${device.desktop} {
    gap: ${spacing.s8};
    margin: ${spacing.s8} ${spacing.none};
    &:first-child {
      margin: ${spacing.none};
    }
  }
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  @media ${device.desktop} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
