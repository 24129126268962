import { sortWith, ascend, prop } from 'ramda';
import type { SpacingType, TooltipDirection } from '@bt-healthcare/ui-toolkit';

import { EMPTY_VALUE } from 'App.constants';
import type {
  BedStatusType,
  CarePackageStatusType,
  DischargeStatusFailed,
  DischargeStatusType,
  GenderIdentifiedAs,
  GenderType,
  IsolationType,
  OriginType,
  PorterStatusType,
  PatientWardPreferencesType,
  SpecialtyType,
  TtoStatusType,
  YesNoType,
  IntraWardTransferReason,
  BedTransferStatus,
  InterWardTransferReason,
  WaitingForDischarge,
  AwaitingFromDischargeAssess,
  PharmacyStatusType,
  WaitingForPotentialDischarge,
  BedCleaningStatus,
  BedCleaningMethod,
  ReasonForBedClosure,
} from 'services';
import type { DisabledMultiChoiceButtonType, EnumMapType } from 'types';

const EmptyMap = {
  NONE: EMPTY_VALUE,
};

export const getOptionsForEnum = <T extends {}>(
  value: T,
  ignore?: string[],
  sort = false,
  disabled?: DisabledMultiChoiceButtonType
) => {
  const entries = Object.entries(value);
  const options = ignore
    ? entries.filter((item) => !ignore.includes(item[0]))
    : entries;
  const result = options
    .filter((item) => item[0] !== 'NONE')
    .map((item) => ({
      label: item[1] as string,
      value: item[0],
      ...(item[0] === disabled?.field && {
        disabled: true,
        tooltip: disabled.toolTip,
        tooltipDirection: 'bottom-end' as TooltipDirection,
        tooltipOffset: 's1' as SpacingType,
      }),
    }));

  return sort ? sortWith([ascend(prop('label'))], result) : result;
};

export const DischargeStatusMap = {
  CONFIRMED: 'Confirmed',
  POTENTIAL: 'Potential',
  DELAYED: 'Delayed',
  COMPLETED: 'Completed',
  NOT_FIT: 'Not fit',
  CLINICALLY_READY: 'Clinically ready',
  ...EmptyMap,
} as EnumMapType<DischargeStatusType>;

export const BedStatusMap = {
  UNALLOCATED: 'Unallocated',
  ALLOCATED: 'Allocated',
  CLOSED: 'Closed',
  PREALLOCATED: 'Preallocated',
  CLEANING: 'In cleaning',
  ...EmptyMap,
} as EnumMapType<BedStatusType>;

export const IntraWardTransferReasonMap = {
  NO_LONGER_REQUIRE_ISOLATION: 'No longer requires isolation',
  REQUIRES_ISOLATION: 'Requires isolation',
  OTHER: 'Other',
  ...EmptyMap,
} as EnumMapType<IntraWardTransferReason>;

export const YesNoMap = {
  YES: 'Yes',
  NO: 'No',
  ...EmptyMap,
} as EnumMapType<YesNoType>;

export const BedTransferStatusMap = {
  DELAYED: 'Delayed',
  CANCELLED: 'Cancelled',
  IN_PROGRESS: 'In progress',
  COMPLETE: 'Complete',
  ...EmptyMap,
} as EnumMapType<BedTransferStatus>;

export const InterWardTransferReasonMap = {
  OUTLIER_TRANSFER: 'Outlier',
  PATIENT_REQUEST: 'Patient request',
  PATIENT_CARE_NEEDS_CHANGED: 'Patient care needs changed',
  OTHER: 'Other',
  ...EmptyMap,
} as EnumMapType<InterWardTransferReason>;

export const TTOStatusMap = {
  COMPLETE: 'Complete',
  INCOMPLETE: 'Incomplete',
  ...EmptyMap,
} as EnumMapType<TtoStatusType>;

export const PharmacyStatusMap = {
  ORDERED: 'Ordered',
  RECEIVED: 'Received',
  NOT_REQUIRED: 'Not required',
  DELAYED: 'Delayed',
  ...EmptyMap,
} as EnumMapType<PharmacyStatusType>;

export const GenderMap = {
  MALE: 'Male',
  FEMALE: 'Female',
  ...EmptyMap,
} as EnumMapType<GenderType>;

export const GenderIdentifiedAsMap = {
  MALE: 'Male',
  FEMALE: 'Female',
  NON_BINARY: 'Non-binary',
  PREFER_NONE: 'Prefer not to say',
  ...EmptyMap,
} as EnumMapType<GenderIdentifiedAs>;

export const GenderIdentifiedAsAbbrevMap = {
  MALE: 'M',
  FEMALE: 'F',
  NON_BINARY: 'NB',
  PREFER_NONE: EMPTY_VALUE,
} as EnumMapType<GenderIdentifiedAs>;

export const PatientWardPreferencesMap = {
  FEMALE_WARD: 'Female ward',
  MALE_WARD: 'Male ward',
  GENDER_NEUTRAL: 'Gender neutral',
  ...EmptyMap,
} as EnumMapType<PatientWardPreferencesType>;

export const CarePackageMap = {
  INCOMPLETE: 'Incomplete',
  COMPLETE: 'Complete',
  DELAYED: 'Delayed',
  NOT_REQUIRED: 'Not required',
  ...EmptyMap,
} as EnumMapType<CarePackageStatusType>;

export const BedCleaningStatusMap = {
  NOT_REQUIRED: 'Not required',
  REQUIRED: 'Required',
} as EnumMapType<BedCleaningStatus>;

export const BedCleaningMethodMap = {
  FOG_CLEAN: 'Fog clean',
  INFECTION_CONTROL_CLEAN: 'Infection control clean',
  STANDARD_CLEAN: 'Standard clean',
} as EnumMapType<BedCleaningMethod>;

export const PorteringMap = {
  REQUESTED: 'Requested',
  NOT_REQUIRED: 'Not required',
  ...EmptyMap,
} as EnumMapType<PorterStatusType>;

export const IsolationMap = {
  COVID_19: 'COVID-19',
  COMMON_COLD: 'Common cold',
  DV: 'D&V',
  MRSA: 'MRSA',
  PATIENT_AGGRESSIVE: 'Patient aggressive',
  PATIENT_CONFUSED: 'Patient confused',
  OTHER: 'Other',
  ...EmptyMap,
} as EnumMapType<IsolationType>;

export const FailedDischargeStatusMap = {
  DELAYED_TEST: 'Delayed test results',
  DELAYED_PHARMACY: 'Delayed Pharmacy',
  DELAYED_PORTERING: 'Delayed Portering',
  NO_CARE_PACKAGE: 'Care package not in place',
  DELAYED_TRANSFER: 'Delayed transfer of care',
  PATIENT_CHANGE: 'Patient circumstances have changed',
  OTHER: 'Other',
} as EnumMapType<DischargeStatusFailed>;

export const WaitingForPotentialDischargeStatusMap = {
  CLINICAL_SIGN_OFF: 'Clinical sign off',
  FURTHER_INVESTIGATIONS: 'Further investigations',
  RESULTS_OF_INVESTIGATIONS: 'Results of investigations',
  BLOOD_TEST_RESULTS: 'Blood test results',
  MEDICAL_REVIEWS: 'Medical reviews',
  X_RAYS: 'X-rays',
  SURGICAL_REVIEW: 'Surgical review',
  PATIENT_TRANSPORT: 'Patient transport',
  CT_SCAN: 'CT scan',
  MRI_SCAN: 'MRI scan',
  SPECIALITY_NURSE_REVIEW: 'Speciality nurse review',
  VIRTUAL_WARD_REFERRAL: 'Virtual Ward referral',
  OTHER: 'Other',
} as EnumMapType<WaitingForPotentialDischarge>;

export const WaitingForDischargeStatusMap = {
  SOCIAL_CARE_INPUT: 'Social care input',
  OCCUPATIONAL_THERAPY_SIGN_OFF: 'Occupational therapy sign off',
  PHYSIOTHERAPY_SIGN_OFF: 'Physiotherapy sign off',
  RESULTS_OF_INVESTIGATIONS: 'Results of investigations',
  FINAL_ASSESSMENT: 'Final assessment',
  PHARMACY_ITEMS: 'Pharmacy items',
  ...EmptyMap,
} as EnumMapType<WaitingForDischarge>;

export const AwaitingDischargeAssessMap = {
  ASSESSMENT_TO_BE_COMPLETED: 'Assessment to be completed',
  SIMPLE_DISCHARGE_PATHWAY_0: 'Simple discharge - Pathway 0',
  CARE_PACKAGE_AT_HOME_PATHWAY_1: 'Care package at home - Pathway 1',
  EQUIPMENT_AT_HOME_PATHWAY_1: 'Equipment at home - Pathway 1',
  END_OF_LIFE_PATHWAY_1: 'End of life - Pathway 1',
  INTERMEDIATE_CARE_PATHWAY_2: 'Intermediate care - Pathway 2',
  COMPLEX_CARE_PLACEMENT_PATHWAY_3: 'Complex care placement - Pathway 3',
  ...EmptyMap,
} as EnumMapType<AwaitingFromDischargeAssess>;

export const DischargePathwayMap = {
  SIMPLE_DISCHARGE_PATHWAY_0: 'Simple discharge - Pathway 0',
  CARE_PACKAGE_AT_HOME_PATHWAY_1: 'Care package at home - Pathway 1',
  EQUIPMENT_AT_HOME_PATHWAY_1: 'Equipment at home - Pathway 1',
  END_OF_LIFE_PATHWAY_1: 'End of life - Pathway 1',
  INTERMEDIATE_CARE_PATHWAY_2: 'Intermediate care - Pathway 2',
  COMPLEX_CARE_PLACEMENT_PATHWAY_3: 'Complex care placement - Pathway 3',
  ...EmptyMap,
} as EnumMapType<AwaitingFromDischargeAssess>;

export const DischargePathwayReasonMap = {
  SIMPLE_DISCHARGE_PATHWAY_0: 'Simple discharge - Pathway 0',
  CARE_PACKAGE_AT_HOME_PATHWAY_1: 'Care package at home - Pathway 1',
  EQUIPMENT_AT_HOME_PATHWAY_1: 'Equipment at home - Pathway 1',
  END_OF_LIFE_PATHWAY_1: 'End of life - Pathway 1',
  INTERMEDIATE_CARE_PATHWAY_2: 'Intermediate care - Pathway 2',
  COMPLEX_CARE_PLACEMENT_PATHWAY_3: 'Complex care placement - Pathway 3',
  PATIENT_DEATH: 'Patient death',
  AGAINST_MEDICAL_ADVICE: 'Against medical advice',
  ...EmptyMap,
} as EnumMapType<AwaitingFromDischargeAssess>;

export const SpecialtyMap = {
  CARDIOLOGY: 'Cardiology',
  ENDOC_DIABETES: 'Endocrinology and diabetes',
  EAR_NOSE_AND_THROAT: 'ENT',
  GASTRO: 'Gastroenterology',
  GEN_INT_MEDICINE: 'General internal medicine',
  GEN_SURGERY: 'General surgery',
  GENI_MEDICINE: 'Genitourinary medicine',
  GERI_MEDICINE: 'Geriatric medicine',
  MAX_FAX: 'Max Fax',
  NEUROLOGY: 'Neurology',
  ORTHO: 'Orthopaedics',
  PLASTIC_SURGERY: 'Plastic surgery',
  REN_MEDICINE: 'Renal medicine',
  RESP_MEDICINE: 'Respiratory medicine',
  RHREU: 'Rhreumatology',
  STROKE_MEDICINE: 'Stroke medicine',
  UROLOGY: 'Urology',
  VASC_SURGERY: 'Vascular surgery',
  ...EmptyMap,
} as EnumMapType<SpecialtyType>;

export const OriginMap = {
  A_E: 'A & E',
  MEDICAL_ASS_UNIT: 'Medical assessment unit',
  OUTPATIENTS: 'Outpatients',
  SURGICAL_ASS_UNIT: 'Surgical assessment unit',
  ...EmptyMap,
} as EnumMapType<OriginType>;

export const BedStatusClosedMap = {
  ESTATE_WORKS: 'Estate works',
  PATIENT_DEATH: 'Patient death',
  RING_FENCED: 'Ring fenced',
  BROKEN: 'Broken bed',
  STAFFING: 'Staffing',
  OTHER: 'Other',
  ...EmptyMap,
} as EnumMapType<ReasonForBedClosure>;
