import { Button, Loader } from '@bt-healthcare/ui-toolkit';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { BedTransferConfirmationModal } from 'components/Modal/BedTransferConfirmationModal';
import { bedWardTransferSubmissionMapping } from 'mappings/forms/bedTransfers';
import {
  GetWardBedTransfersDocument,
  useCreateWardBedTransferMutation,
} from 'services';
import { useStore } from 'store';
import { BedTransferConfirmation } from 'components/BedTransfer/BedTransferConfirmation';
import { PageNotification } from 'components/PageNotification';
import { ROUTES } from 'App.constants';
import {
  ConfirmationWrapper,
  Headline,
  Strapline,
  FormFooterWrapper,
  FormFooter,
} from './styles';

export const BedTransferFormConfirmation = () => {
  const [
    formData,
    resetFormData,
    resetBedTransfer,
    { careSettingId },
    errorMessage,
  ] = useStore(
    (state) => [
      state.bedTransferFormData,
      state.resetBedTransferFormData,
      state.resetBedTransfer,
      state.appConfig,
      state.errorMessage,
    ],
    shallow
  );

  const [createWardBedTransfer, { loading, error }] =
    useCreateWardBedTransferMutation();

  const {
    intraWardTransferReason,
    interWardTransferReason,
    otherTransferReason,
    fromWard,
    toWard,
    toBed,
    fromBed,
    porterStatus,
    arrivalToWardDate,
    arrivalToWardTime,
    specialty,
    bedCleaningMethod,
    bedCleaningStatus,
  } = formData;

  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleViewTransfers = () => {
    setModalOpen(false);
    navigate(ROUTES.BED_TRANSFER_HOME);
  };

  const handleCancel = () => {
    resetFormData();
    resetBedTransfer();
    navigate(ROUTES.BED_TRANSFER_MANAGE);
  };

  const handleClose = () => handleViewTransfers();

  const handleFormSubmission = () => {
    const input = bedWardTransferSubmissionMapping({
      intraWardTransferReason,
      interWardTransferReason,
      otherTransferReason,
      fromWardId: fromWard!.value,
      fromBed,
      toWardId: toWard?.value ?? fromWard!.value,
      toBed,
      porterStatus,
      arrivalToWardDate,
      arrivalToWardTime,
      specialty,
      careSettingId,
      bedCleaningStatus,
      bedCleaningMethod,
    });
    createWardBedTransfer({
      variables: {
        input,
      },
      onCompleted: () => setModalOpen(true),
      refetchQueries: () => [
        {
          query: GetWardBedTransfersDocument,
          variables: { careSettingId },
        },
      ],
    });
  };

  const handleNewTransfer = () => {
    setModalOpen(false);
    resetFormData();
    resetBedTransfer();
    navigate(ROUTES.BED_TRANSFER_MANAGE);
  };

  if (loading) return <Loader />;

  return (
    <ConfirmationWrapper>
      <Headline>Confirm bed transfer</Headline>
      {error && (
        <PageNotification message={errorMessage('creating a bed transfer')} />
      )}
      <Strapline>
        Please check the information below, once you&apos;re happy with it,
        press confirm to complete the transfer.
      </Strapline>
      <BedTransferConfirmation />
      <FormFooterWrapper id="sticky-footer">
        <FormFooter>
          <Button
            aria-label="Confirm bed transfer"
            id="confirm-bed-transfer"
            onClick={() => handleFormSubmission()}
          >
            Confirm
          </Button>

          <Button
            aria-label="Cancel bed transfer"
            id="cancel-bed-transfer"
            onClick={handleCancel}
            variant="tertiary"
          >
            Cancel
          </Button>
        </FormFooter>
      </FormFooterWrapper>

      {modalOpen && !loading && !error && (
        <BedTransferConfirmationModal
          modalOpen={modalOpen}
          handleNewTransfer={handleNewTransfer}
          handleViewTransfers={handleViewTransfers}
          handleClose={handleClose}
          title="Bed transfer confirmed"
          strapLine="Good news, your transfer was successful and will be completed once patient arrival to ward is confirmed."
        />
      )}
    </ConfirmationWrapper>
  );
};
