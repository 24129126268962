import type { UseFormResetField } from 'react-hook-form';

import type { BedDischargeFormData } from 'components/BedDischarge/types';
import type { WardBed } from 'services';
import { convertDateStringForFormInput } from 'mappings/date.utils';
import { getPotentialDischargeWaitingReasons } from '../form.utils';

export const resetPotentialFormFields = (
  resetField: UseFormResetField<BedDischargeFormData>,
  isCurrent: boolean,
  wardBed: WardBed
) => {
  resetField('toTakeOutStatus', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes.toTakeOutStatus ?? ''
      : '',
  });
  resetField('carePackageStatus', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes.carePackageStatus ?? ''
      : '',
  });
  resetField('pharmacy', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes.pharmacy ?? ''
      : '',
  });

  resetField('dischargeExpectedDate', {
    defaultValue: isCurrent
      ? convertDateStringForFormInput(
          wardBed.wardPatient?.wardDischarge?.attributes.client
            ?.dischargeExpectedDate
        )
      : '',
  });
  resetField('dischargeExpectedTime', {
    defaultValue: isCurrent
      ? wardBed.wardPatient?.wardDischarge?.attributes.client
          ?.dischargeExpectedTime
      : '',
  });

  resetField('waitingReasonsForPotentialDischarge', {
    defaultValue: isCurrent
      ? getPotentialDischargeWaitingReasons(wardBed)
      : null,
  });
};
