import type { UseFormResetField } from 'react-hook-form';

import type { BedAllocationFormData } from 'components/BedAllocation/types';

export const resetAllocatedFormFields = (
  resetField: UseFormResetField<BedAllocationFormData>
) => {
  resetField('bedCleaningMethod', { defaultValue: null });
  resetField('patient', { defaultValue: {} });
  resetField('patientName', { defaultValue: '' });
  resetField('arrivalDate', { defaultValue: '' });
  resetField('arrivalTime', { defaultValue: '' });
  resetField('outlierYN', { defaultValue: '' });
  resetField('porterStatus', { defaultValue: '' });
};
