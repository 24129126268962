import { Outlet } from 'react-router-dom';

import { useAuthUser } from 'auth/useAuthUser';
import { unauthorisedRedirect } from './routes.utils';

export const ProtectedRoute = () => {
  const { auth } = useAuthUser();
  if (!auth.isAuthorised.module || !auth.isAuthorised.app) {
    unauthorisedRedirect(auth.isAuthorised.app);
  }
  return <Outlet />;
};
