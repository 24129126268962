import type { UseFormResetField } from 'react-hook-form';

import type { BedAllocationFormData } from 'components/BedAllocation/types';

export const resetUnallocatedFormFields = (
  resetField: UseFormResetField<BedAllocationFormData>
) => {
  resetField('bedCleaningMethod', { defaultValue: null });
  resetField('bedStatusDate', { defaultValue: '' });
  resetField('bedStatusTime', { defaultValue: '' });
};
