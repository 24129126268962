import { bedCleaningMethodOptions } from 'components/Form/formOptions';

import type { WardBed } from 'services';

export const getBedCleaningMethod = (wardBed: WardBed) => {
  const cleaningMethod = wardBed.attributes.bedCleaningMethod;

  if (cleaningMethod)
    return bedCleaningMethodOptions.find(
      (item) => item.value === cleaningMethod
    );

  return null;
};
