import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import {
  colors,
  Loader,
  MultipleChoiceButton,
  Input,
} from '@bt-healthcare/ui-toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';

import type { InterWardTransferReason, WardBed } from 'services';
import { useGetWardBedOptionsLazyQuery } from 'services';
import { useStore } from 'store';
import { wardBedOptionsTransform } from 'transforms/wardBedOptionTransform';
import { matchesOption, notEmpty } from 'validation/rules';
import { FormFieldLayout } from 'pages/styles';
import { RHFDatePicker } from 'components/Form/RHFDatePicker';
import { FETCH_POLICY } from 'App.constants';
import { duplicatePatientCheck } from 'transforms/hasMatchingPatient.utils';
import { MatchingPatientModal } from 'components/Modal/MatchingPatientModal';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { FormFooter } from 'components/Form/FormFooter';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import { BedCleaningFormFields } from 'components/BedCleaning/BedCleaningFormFields';
import { DataListCard } from '../Card/DataListCard/DataListCard';
import {
  interWardTransferReasonOptions,
  porterOptions,
  specialtyOptions,
} from '../Form/formOptions';
import { RHFButtonDropDown } from '../Form/RHFButtonDropDown';
import { BedTransferFormatOptionLabel } from './BedTransferFormatOptionLabel';
import type { BedTransferFormData, BedTransferFormFieldsProps } from './types';
import { bedInterTransferSchema } from './validationSchema';
import { MultipleButtonWrapper } from './styles';
import {
  bedFromTransferCardColumns,
  bedFromTransferCardItemGap,
} from './config';

export const BedInterTransferFormFields = ({
  wardOptions,
  onSubmit,
  onWardBedFromChange,
  onWardBedToChange,
  activeBedTransfers,
}: BedTransferFormFieldsProps) => {
  const [wardToBedOptions, setWardToBedOptions] = useState(
    [] as TypedOptionType<string>[]
  );

  const [wardFromBedOptions, setWardFromBedOptions] = useState(
    [] as TypedOptionType<string>[]
  );

  const [wardToOptions, setWardToOptions] = useState(
    [] as TypedOptionType<string>[]
  );

  const [matchingPatientAlert, setMatchingPatientAlert] = useState(false);

  const [
    setFormData,
    bedFromTransfer,
    bedToTransfer,
    setBedFromTransfer,
    setBedToTransfer,
    resetBedTransfer,
  ] = useStore(
    (state) => [
      state.setBedTransferForm,
      state.bedFromTransfer,
      state.bedToTransfer,
      state.setBedFromTransfer,
      state.setBedToTransfer,
      state.resetBedTransfer,
    ],
    shallow
  );
  const defaultValues: Partial<BedTransferFormData> = {
    fromWard: null,
    fromBed: null,
    toBed: null,
    toWard: null,
    porterStatus: '',
    specialty: null,
    interWardTransferReason: null,
    arrivalToWardTime: '',
    bedCleaningMethod: null,
    bedCleaningStatus: '',
  };

  const {
    control,
    register,
    getValues,
    handleSubmit,
    resetField,
    formState: { isValid },
  } = useForm<BedTransferFormData>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(bedInterTransferSchema),
  });

  const [getWardBedsByWardBedId, { loading: wardBedOptionsLoading }] =
    useGetWardBedOptionsLazyQuery();

  const isLoading = wardBedOptionsLoading;

  const handleClick = () => {
    setFormData({
      ...getValues(),
      arrivalToWardDate: (getValues().arrivalToWardDate as Date).toISOString(),
    });
    handleSubmit(onSubmit)();
  };

  const handleFromWardChange = ({ label, value }: TypedOptionType<string>) => {
    resetField('toBed');
    resetField('toWard');
    resetField('fromBed');
    resetField('interWardTransferReason');
    resetBedTransfer();
    setWardToBedOptions([]);
    getWardBedsByWardBedId({
      variables: { wardId: value },
      fetchPolicy: FETCH_POLICY,
      onCompleted: (response) => {
        const allocatedWardBeds = wardBedOptionsTransform(
          response,
          activeBedTransfers
        );
        setBedFromTransfer([
          {
            key: 'Ward',
            value: label,
          },
        ]);
        setWardFromBedOptions(allocatedWardBeds);
        const wardToOptionsFilter = wardOptions.toWardOptions.filter(
          (item) => item.value !== value
        );
        setWardToOptions(wardToOptionsFilter);
      },
    });
  };

  const handleToWardChange = ({ value, label }: TypedOptionType<string>) => {
    resetField('interWardTransferReason');
    resetField('arrivalToWardDate');
    resetField('porterStatus');
    resetField('toBed');
    resetField('arrivalToWardTime');
    resetField('specialty');
    getWardBedsByWardBedId({
      variables: { wardId: value },
      fetchPolicy: FETCH_POLICY,
      onCompleted: (response) => {
        setBedToTransfer([
          {
            key: 'Ward',
            value: label,
          },
        ]);
        const unallocatedWardBeds = wardBedOptionsTransform(
          response,
          activeBedTransfers,
          'UNALLOCATED'
        );
        setWardToBedOptions(unallocatedWardBeds);
        const fromBedName = bedFromTransfer.find(
          (item) => item.key === 'Patient'
        );
        const isMatchingPatient = duplicatePatientCheck(
          fromBedName?.value as string,
          response.wardBeds as WardBed[]
        );
        if (isMatchingPatient) setMatchingPatientAlert(true);
      },
    });
  };

  if (isLoading) return <Loader />;
  return (
    <form>
      <FormFieldLayout id="bedTransferFormFields">
        <MultipleButtonWrapper>
          <RHFButtonDropDown
            label="From"
            fieldName="fromWard"
            control={control}
            options={wardOptions.fromWardOptions}
            defaultValue={null}
            buttonLabel="Ward"
            handleChange={handleFromWardChange}
          />
          <RHFButtonDropDown
            fieldName="fromBed"
            control={control}
            options={wardFromBedOptions}
            buttonLabel="Bed"
            formatOptionLabel={BedTransferFormatOptionLabel}
            handleChange={onWardBedFromChange}
            disabled={wardFromBedOptions.length === 0}
          />
        </MultipleButtonWrapper>
        {bedFromTransfer?.length !== 0 && (
          <DataListCard
            columns={bedFromTransferCardColumns}
            itemGap={bedFromTransferCardItemGap}
            background={colors.primaryIndigo.indigo01}
            data={bedFromTransfer!}
            cardType="primaryBedTransfer"
          />
        )}
        <MultipleButtonWrapper>
          <RHFButtonDropDown
            label="To"
            fieldName="toWard"
            control={control}
            options={wardToOptions!}
            defaultValue={null}
            buttonLabel="Ward"
            variant="secondary"
            handleChange={handleToWardChange}
            disabled={wardFromBedOptions.length === 0}
          />
          <RHFButtonDropDown
            fieldName="toBed"
            control={control}
            options={wardToBedOptions}
            buttonLabel="Bed"
            formatOptionLabel={BedTransferFormatOptionLabel}
            variant="secondary"
            handleChange={onWardBedToChange}
            disabled={wardToBedOptions.length === 0}
          />
        </MultipleButtonWrapper>
        {bedToTransfer.length !== 0 && (
          <DataListCard
            columns="2"
            background={colors.secondaryBlue.blue01}
            data={bedToTransfer}
            cardType="secondaryBedTransfer"
          />
        )}
        <ConditionalFieldRenderer
          control={control}
          fieldName="toBed"
          condition={notEmpty}
        >
          <RHFDropDown
            label="Reason for transfer"
            fieldName="interWardTransferReason"
            control={control}
            options={interWardTransferReasonOptions}
          />
          <ConditionalFieldRenderer
            control={control}
            fieldName="interWardTransferReason"
            condition={(arg) =>
              matchesOption<InterWardTransferReason>(
                arg,
                'PATIENT_CARE_NEEDS_CHANGED'
              )
            }
          >
            <RHFDropDown
              label="Patient new speciality"
              fieldName="specialty"
              control={control}
              options={specialtyOptions}
            />
          </ConditionalFieldRenderer>
          <ConditionalFieldRenderer
            control={control}
            fieldName="interWardTransferReason"
            condition={(arg) =>
              matchesOption<InterWardTransferReason>(arg, 'OTHER')
            }
          >
            <Input
              id="otherTransferReason"
              label="Other reason"
              {...register('otherTransferReason')}
            />
          </ConditionalFieldRenderer>
          <RHFDatePicker
            control={control}
            fieldName="arrivalToWardDate"
            timeFieldName="arrivalToWardTime"
            label="Est. date &amp; time of arrival to ward"
            minDate={new Date()}
          />
          <MultipleChoiceButton
            label="Porter"
            options={porterOptions}
            size="lg"
            {...register('porterStatus')}
          />
          <BedCleaningFormFields
            resetField={resetField}
            register={register}
            control={control}
            journey="transfer"
          />
        </ConditionalFieldRenderer>

        <FormFooter id="continue" handleClick={handleClick} isValid={isValid} />
      </FormFieldLayout>
      <MatchingPatientModal
        modalOpen={matchingPatientAlert}
        setModalOpen={setMatchingPatientAlert}
        onCancel={() => resetField('toWard')}
      />
    </form>
  );
};
