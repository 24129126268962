import { colors } from '@bt-healthcare/ui-toolkit';

import type { DischargeStatusType, BedStatusType } from 'services';

export const bedAllocationRagStatusColorMap = (
  dischargeStatus: DischargeStatusType,
  bedStatus: BedStatusType,
  theme: any
) => {
  const map = {
    UNALLOCATED: theme.rag.green,
    ALLOCATED:
      dischargeStatus === 'CONFIRMED' ? theme.rag.amber : colors.grey.grey08,
    CLOSED: theme.rag.red,
    PREALLOCATED: colors.secondaryBlue.blue06,
    CLEANING: theme.rag.purple,
    NONE: 'transparent',
  };
  return map[bedStatus];
};

export const bedAllocationRagStatusSortMap = (
  dischargeStatus: DischargeStatusType,
  bedStatus: BedStatusType
) => {
  const dischargeMap = {
    CONFIRMED: 4,
    DELAYED: 6,
    POTENTIAL: 7,
    CLINICALLY_READY: 8,
    NOT_FIT: 9,
    COMPLETED: 10, // This state should never exist - added to map to prevent errors
    NONE: 11, // This state should never exist - added to map to prevent errors
  };

  const map = {
    PREALLOCATED: 1,
    UNALLOCATED: 2,
    CLEANING: 3,
    ALLOCATED: dischargeMap[dischargeStatus],
    CLOSED: 5,
    NONE: 11,
  };
  return map[bedStatus];
};
