import type { MenuBarOptions } from '@bt-healthcare/ui-toolkit';
import type { NamedSet } from 'zustand/middleware';

import type { AppConfig, MenuItemsType } from 'types';
import type { AppState, WardHeader } from './types';

export const uiSlice = (set: NamedSet<AppState>) => ({
  menuItems: [] as MenuBarOptions[],
  menuHeader: 'Bed Optimiser',
  appConfig: {} as AppConfig,
  wardHeader: {} as WardHeader,
  hasFlag: false,
  setMenuItems: (menuItems: MenuBarOptions[]) =>
    set({ menuItems }, false, 'ui/setMenuItems'),
  setWardHeader: (wardName: string, capacity: number) =>
    set({ wardHeader: { capacity, wardName } }, false, 'ui/setWardHeader'),
  setMenuItem: (label: MenuItemsType) =>
    set(
      (state) => ({
        menuItems: state.menuItems.map((item) => ({
          ...item,
          active: item.label === label,
        })),
      }),
      false,
      'ui/setMenuItem'
    ),
  resetMenuItems: () =>
    set(
      (state) => ({
        menuItems: state.menuItems.map((item) => ({
          ...item,
          active: false,
        })),
      }),
      false,
      'ui/resetMenuItem'
    ),
  setMenuHeader: (menuHeader: string) =>
    set({ menuHeader }, false, 'ui/setMenuHeader'),
  setAppConfig: (appConfig: AppConfig) =>
    set({ appConfig }, false, 'ui/setAppConfig'),
  setHasFlag: (hasFlag: boolean) => set({ hasFlag }, false, 'ui/setHasFlag'),
});
