import {
  Col,
  Grid,
  Row,
  MenuCard,
  spacing,
  Wrapper,
} from '@bt-healthcare/ui-toolkit';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { PageHeader } from 'components/PageHeader';
import { useStore } from 'store';
import { useTracking } from 'hooks/useTracking';
import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';
import { getStatusOptions } from './StatusOptions';

export const WardOptions = () => {
  const wardId = useRedirectAndReturnState('wardId');
  const { trackPage } = useTracking();
  const [setMenuHeader, wardHeader] = useStore(
    (state) => [state.setMenuHeader, state.wardHeader],
    shallow
  );
  useEffect(() => {
    setMenuHeader(wardHeader!.wardName);
  }, [wardHeader]);

  useEffect(() => {
    trackPage(
      `${wardHeader ? wardHeader.wardName : 'ward'} - Allocate Bed or Discharge`
    );
  }, []);

  return (
    <Wrapper style={{ marginTop: spacing.s6 }}>
      {wardHeader && (
        <PageHeader
          title={wardHeader.wardName}
          capacity={wardHeader.capacity}
        />
      )}
      <Grid>
        <Row>
          {getStatusOptions(wardId!).map((item) => (
            <Col xs={6} key={item.id}>
              <MenuCard {...item} />
            </Col>
          ))}
        </Row>
      </Grid>
    </Wrapper>
  );
};
