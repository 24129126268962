import { useState } from 'react';

import { FETCH_POLICY, POLL_INTERVAL } from 'App.constants';
import { useGetPatientsQuery } from 'services';
import { sortByDecisionToAdmitWaitTime } from 'sorting/patients';
import type { CareSettingPatientQuery } from 'models';
import { admissionMatchingPatientTransform } from 'transforms/admissionMatchingPatientTransform';

export const usePatientSort = (careSettingId: string) => {
  const [sortedPatients, setSortedPatients] = useState<CareSettingPatientQuery>(
    []
  );
  const { loading, error } = useGetPatientsQuery({
    variables: {
      careSettingId,
      unallocated: true,
    },
    pollInterval: POLL_INTERVAL,
    fetchPolicy: FETCH_POLICY,
    onCompleted: (data) => {
      const sortedData = sortByDecisionToAdmitWaitTime(
        data.careSettingPatientsByCareSetting!
      );
      const matchPatientData = admissionMatchingPatientTransform(sortedData);
      setSortedPatients(matchPatientData);
    },
  });

  return { sortedPatients, loading, error };
};
