export const BedStatusIcon = () => (
  <svg
    className="icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 6.66667V13.3333L16.3333 16.6933L17.36 14.9867L12.6667 12.2V6.66667H10.6667ZM24 9.33333V0L20.48 3.52C18.32 1.34667 15.32 0 12 0C5.37333 0 0 5.37333 0 12C0 18.6267 5.37333 24 12 24C18.6267 24 24 18.6267 24 12H21.3333C21.3333 17.1467 17.1467 21.3333 12 21.3333C6.85333 21.3333 2.66667 17.1467 2.66667 12C2.66667 6.85333 6.85333 2.66667 12 2.66667C14.5733 2.66667 16.9067 3.72 18.6 5.4L14.6667 9.33333H24Z"
      fill="white"
    />
  </svg>
);
