import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const RHFDatePickerFieldset = styled.fieldset`
  border: none;
  display: flex;
  gap: ${spacing.s2};

  > div {
    width: 50%;
  }

  @media ${device.desktop} {
    gap: ${spacing.s4};
  }
`;

export const RHFDatePickerLegend = styled.legend`
  color: ${colors.grey.grey10};
  margin: ${spacing.s2} 0;
`;

export const HeadlineWrapper = styled.div`
  font-size: ${fontSizes.h4};
  margin-bottom: ${spacing.s2};
  text-align: center;

  @media ${device.desktop} {
    font-size: ${fontSizes.h3};
    margin-bottom: ${spacing.s6};
  }
`;

export const TextWrapper = styled.div`
  font-size: ${fontSizes.base};
  text-align: center;
  margin-bottom: ${spacing.s6};

  @media ${device.desktop} {
    margin-bottom: ${spacing.s8};
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing.s6};

  @media ${device.desktop} {
    flex-direction: row-reverse;
    gap: ${spacing.l4};
  }
`;

export const IconWrapper = styled.div`
  svg {
    max-width: 100%;
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 450px;
`;

export const ViewWardContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  margin: auto;

  & > button {
    margin-bottom: ${spacing.s6};
  }

  @media ${device.desktop} {
    flex-direction: column-reverse;

    & > div {
      margin-bottom: ${spacing.s8};
    }

    & > button {
      margin-bottom: ${spacing.none};
    }
  }
`;

export const CardWrapper = styled.div`
  width: 90%;
  margin: auto;

  @media ${device.desktop} {
    width: 100%;
  }
`;

export const FormFooterWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.base.light};
  padding: ${spacing.s4};

  @media ${device.desktop} {
    padding: ${spacing.s8} ${spacing.s10};
    box-shadow: 0px 4px 30px 1px rgba(112, 144, 176, 0.15);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};
  max-width: 1560px;
  margin: auto;

  @media ${device.desktop} {
    display: grid;
    grid-template-columns: 450px;

    #form-layout-tertiary-button {
      text-align: left;
    }
  }
`;
