import { Wrapper } from '@bt-healthcare/ui-toolkit';

import { GenderOptionItem } from './styles';
import type { GenderOptionProps } from './types';

export const GenderOption = ({ gender }: GenderOptionProps) => (
  <Wrapper data-testid="gender-option" as="span">
    <GenderOptionItem active={gender === 'FEMALE'}>F</GenderOptionItem>
    <GenderOptionItem active>|</GenderOptionItem>
    <GenderOptionItem active={gender === 'MALE'}>M</GenderOptionItem>
  </Wrapper>
);
