import type { BedDischargeFormData } from 'components/BedDischarge/types';
import type { WardBed } from 'services';
import { convertDateStringForFormInput } from 'mappings/date.utils';
import { getPotentialDischargeWaitingReasons } from '../form.utils';

export const getPotentialDefaultValues = (
  defaultFields: Partial<BedDischargeFormData>,
  wardBed: WardBed
): Partial<BedDischargeFormData> => ({
  ...defaultFields,
  dischargeExpectedDate: convertDateStringForFormInput(
    wardBed.wardPatient?.wardDischarge?.attributes.client?.dischargeExpectedDate
  ),
  dischargeExpectedTime:
    wardBed.wardPatient?.wardDischarge?.attributes.client
      ?.dischargeExpectedTime ?? '',
  toTakeOutStatus:
    wardBed.wardPatient?.wardDischarge?.attributes.toTakeOutStatus ?? '',
  pharmacy:
    wardBed.wardPatient?.wardDischarge?.attributes.toTakeOutStatus ===
    'COMPLETE'
      ? wardBed.wardPatient?.wardDischarge?.attributes.pharmacy ?? ''
      : '',
  carePackageStatus:
    wardBed.wardPatient?.wardDischarge?.attributes.carePackageStatus ?? '',
  waitingReasonsForPotentialDischarge:
    getPotentialDischargeWaitingReasons(wardBed),
  waitingReasonOther: getPotentialDischargeWaitingReasons(wardBed)?.some(
    (reason) => reason?.value === 'OTHER'
  )
    ? wardBed.wardPatient?.wardDischarge?.attributes.waitingReasonOther ?? ''
    : '',
});
