import type { UseFormResetField } from 'react-hook-form';

import type { BedDischargeFormData } from 'components/BedDischarge/types';
import type { WardBed, DischargeStatusType } from 'services';
import { resetClinicallyReadyFormFields } from './clinicallyReady.utils';
import { resetCompletedFormFields } from './completed.utils';
import { resetConfirmedFormFields } from './confirmed.utils';
import { resetDelayedFormFields } from './delayed.utils';
import { resetNotFitFormFields } from './notFit.utils';
import { resetPotentialFormFields } from './potential.utils';

export const setFormValues = (
  isCurrent: boolean,
  resetField: UseFormResetField<BedDischargeFormData>,
  wardBed: WardBed,
  value: DischargeStatusType
) => {
  switch (value) {
    case 'CLINICALLY_READY':
      resetClinicallyReadyFormFields(resetField, isCurrent, wardBed);
      break;
    case 'CONFIRMED':
      resetConfirmedFormFields(resetField, isCurrent, wardBed);
      break;
    case 'DELAYED':
      resetDelayedFormFields(resetField, isCurrent, wardBed);
      break;
    case 'NOT_FIT':
      resetNotFitFormFields(resetField, isCurrent, wardBed);
      break;
    case 'POTENTIAL':
      resetPotentialFormFields(resetField, isCurrent, wardBed);
      break;
    default:
      resetCompletedFormFields(resetField);
      break;
  }
};
