import { EMPTY_VALUE } from 'App.constants';
import type { Maybe } from 'graphql/jsutils/Maybe';
import type { EnumMapType } from 'types';

export const getEnumMapOption = <T extends string>(
  map: EnumMapType<T>,
  option?: Maybe<T>,
  otherOption?: Maybe<string>
) => {
  if (option) {
    if (option === 'OTHER') return otherOption ?? EMPTY_VALUE;
    if (option === 'NONE') return EMPTY_VALUE;
    return map[option as T];
  }
  return EMPTY_VALUE;
};

export const getEnumMapOptions = <T extends string>(
  map: EnumMapType<T>,
  options?: Maybe<Maybe<T>[]>,
  otherOption?: Maybe<string>
) => {
  if (options) {
    return options
      .map((option) => getEnumMapOption(map, option, otherOption))
      .join(', ');
  }
  return EMPTY_VALUE;
};
