import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { Loader, FormLayout } from '@bt-healthcare/ui-toolkit';
import { useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'ramda';

import { useStore } from 'store';
import { BedDischargeFormFields } from 'components/BedDischarge/BedDischargeFormFields';
import type { BedDischargeFormData } from 'components/BedDischarge/types';
import { dischargeStatusSchema } from 'components/BedDischarge/validationSchema';
import { BedConfirmationModal } from 'components/Modal';
import {
  bedDischargeSubmissionMap,
  bedDischargeFormMapping,
} from 'mappings/forms/bedDischarge';
import { PatientHeader } from 'components/PatientHeader';
import type { DischargeStatusType } from 'services';
import { createUserError } from 'errors/userErrors';
import {
  GetWardBedsDocument,
  useCreateWardDischargeMutation,
  useUpdateWardDischargeMutation,
} from 'services';
import { PageHeader } from 'components/PageHeader';
import { PageNotification } from 'components/PageNotification';
import { useTracking } from 'hooks/useTracking';
import { ROUTES } from 'App.constants';
import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';
import { defaultValues } from './formDefaultValues';
import { setFormValues } from './formResetValues';

export const BedDischargeForm = () => {
  const wardId = useRedirectAndReturnState('wardId');
  const { trackPage } = useTracking();
  const [
    wardHeader,
    wardBed,
    formData,
    resetFormData,
    setFormData,
    errorMessage,
    errors,
    { careSettingId },
    hasFlag,
  ] = useStore(
    (state) => [
      state.wardHeader,
      state.bed,
      state.bedDischargeFormData,
      state.resetBedDischargeFormData,
      state.setBedDischargeForm,
      state.errorMessage,
      state.errors,
      state.appConfig,
      state.hasFlag,
    ],
    shallow
  );
  const [updateWardDischarge, { loading: updateLoading, error: updateError }] =
    useUpdateWardDischargeMutation();
  const [createWardDischarge, { loading: createLoading, error: createError }] =
    useCreateWardDischargeMutation();

  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    trackPage(
      `${wardHeader ? wardHeader.wardName : 'ward'} - Discharge Management Form`
    );
  }, []);

  const hasError = createError || updateError || errors;
  const loading = createLoading || updateLoading;

  const previousDMDateTime =
    wardBed.wardPatient?.wardDischarge?.attributes
      .decisionMakerLastVisitedDateTime;
  const previousD2ADateTime =
    wardBed.wardPatient?.wardDischarge?.attributes.dischargeToAssessDateTime;

  const [decisionMakerLastDateTime, setDecisionMakerLastDateTime] = useState<
    null | string
  >(previousDMDateTime || null);
  const [dischargeToAssessDateTime, setDischargeToAssessDateTime] = useState<
    null | string
  >(previousD2ADateTime || null);

  const needsBedTransferConfirmation =
    wardBed?.wardBedTransfers != null &&
    wardBed.wardBedTransfers.length > 0 &&
    wardBed.wardBedTransfers.every(
      (item) =>
        item!.attributes.bedTransferStatus !== 'COMPLETE' &&
        item!.attributes.bedTransferStatus !== 'NONE'
    );

  const mappedDischargeData = bedDischargeFormMapping(
    wardBed,
    formData,
    decisionMakerLastDateTime,
    dischargeToAssessDateTime
  );

  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    resetField,
    trigger,
    formState: { isValid, errors: formErrors },
  } = useForm<BedDischargeFormData>({
    defaultValues: defaultValues(wardBed),
    mode: 'onChange',
    resolver: yupResolver(dischargeStatusSchema),
  });

  useEffect(() => {
    // Re-trigger validation for Date and time fields which require a dependence check
    const fieldsToTrigger: (keyof Partial<BedDischargeFormData>)[] = [
      'clinicallyReadyForDischargeDate',
      'clinicallyReadyForDischargeTime',
      'dischargeActualDate',
      'dischargeActualTime',
    ];
    fieldsToTrigger.forEach((item) => trigger(item));
  }, [isValid]);

  const onSubmit = () => {
    const options = {
      variables: {
        input: bedDischargeSubmissionMap(
          mappedDischargeData,
          wardId!,
          careSettingId,
          needsBedTransferConfirmation
        ),
      },
      refetchQueries: [{ query: GetWardBedsDocument, variables: { wardId } }],
      onCompleted: () =>
        navigate(ROUTES.WARDS_BED_DISCHARGE_CONFIRM, {
          state: { wardId },
        }),
    };
    if (mappedDischargeData.wardPatient?.wardDischarge?.id) {
      updateWardDischarge(options);
    } else {
      createWardDischarge(options);
    }
  };

  const handleStatusChange = ({ value }: TypedOptionType<string>) => {
    const dischargeStatus =
      wardBed.wardPatient?.wardDischarge?.attributes.dischargeStatus;
    const prevDischargeStatus = formData.dischargeStatus;
    const isCurrent = dischargeStatus === value;
    // Reset previous values if set as form data
    if (prevDischargeStatus) {
      setFormValues(
        false,
        resetField,
        wardBed,
        prevDischargeStatus.value as DischargeStatusType
      );
    }
    setFormValues(isCurrent, resetField, wardBed, value as DischargeStatusType);
  };

  const handleClose = () => {
    setModalOpen(false);
    resetFormData();
  };
  const handleClick = () => {
    handleSubmit(onSubmit)();
    setModalOpen(false);
  };

  if (loading) return <Loader />;

  return (
    <>
      <FormLayout
        id="statusForm"
        header={
          <PageHeader
            title={wardHeader.wardName}
            capacity={wardHeader.capacity}
          />
        }
        primaryButtonClick={() => {
          const data = getValues();
          setFormData(data);
          if (hasFlag)
            navigate(ROUTES.WARDS_MANAGE_BED_DISCHARGE_CONFIRM, {
              state: { wardId },
            });
          setModalOpen(true);
        }}
        primaryButtonDisabled={!isValid}
      >
        <PatientHeader
          name={wardBed.attributes.wardBedReference}
          person={wardBed.wardPatient?.careSettingPatient?.person}
          gender={wardBed.attributes.bedGender}
          dischargeStatus={
            wardBed.wardPatient?.wardDischarge?.attributes?.dischargeStatus
          }
        />
        {!isEmpty(hasError) && (
          <PageNotification
            message={createUserError(
              errors,
              errorMessage('updating the discharge status')
            )}
          />
        )}
        <BedDischargeFormFields
          register={register}
          control={control}
          currentDischargeStatus={
            wardBed.wardPatient?.wardDischarge?.attributes.dischargeStatus
          }
          previousDMDateTime={previousDMDateTime}
          decisionMakerLastDateTime={decisionMakerLastDateTime}
          setDecisionMakerLastDateTime={setDecisionMakerLastDateTime}
          previousD2ADateTime={previousD2ADateTime}
          dischargeToAssessDateTime={dischargeToAssessDateTime}
          setDischargeToAssessDateTime={setDischargeToAssessDateTime}
          setValue={setValue}
          handleStatusChange={handleStatusChange}
          resetField={resetField}
          errors={formErrors}
        />
      </FormLayout>
      <BedConfirmationModal
        wardName={wardHeader.wardName}
        data={mappedDischargeData}
        modalOpen={modalOpen}
        handleClose={handleClose}
        handleClick={handleClick}
        title="Confirm discharge status"
        strapLine="Please confirm you are about to update the discharge status of this
        bed."
      />
    </>
  );
};
