import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import type { BedDischargeFormData } from 'components/BedDischarge/types';

import {
  isolationOptions,
  dischargeStatusOptions,
  dischargePathwayOptions,
  awaitingDischargeAssessOptions,
  waitingForPotentialDischargeOptions,
  waitingForDischargeOptions,
  failedDischargeOptions,
} from 'components/Form/formOptions';

import { getCurrentUTCDateTime } from 'mappings/date.utils';
import type {
  WardBed,
  DischargeStatusType,
  YesNoType,
  WaitingForDischarge,
  DischargeStatusFailed,
  WaitingForPotentialDischarge,
} from 'services';

export const getRequiresIsolation = (wardBed: WardBed) => {
  const patientRequiresIsolation =
    wardBed.wardPatient?.careSettingPatient?.attributes?.requiresIsolationYN;

  if (wardBed.wardPatient?.wardDischarge) {
    const wardDischargeRequiresIsolation =
      wardBed.wardPatient.wardDischarge.attributes.requiresIsolationYN;
    return wardDischargeRequiresIsolation ?? '';
  }
  return patientRequiresIsolation ?? '';
};

export const getOption = (options: TypedOptionType<any>[], item: any) =>
  options.find((option) => option.value === item) ?? undefined;

export const getIsolationType = (wardBed: WardBed) => {
  const patientIsolationType = getOption(
    isolationOptions,
    wardBed.wardPatient?.careSettingPatient?.attributes?.isolationType
  );

  if (wardBed.wardPatient?.wardDischarge) {
    const wardDischargeIsolationType = getOption(
      isolationOptions,
      wardBed.wardPatient?.wardDischarge?.attributes?.isolationType
    );

    return wardDischargeIsolationType;
  }

  return patientIsolationType;
};

export const getIsolationTypeOther = (wardBed: WardBed) => {
  const patientIsolationTypeOther =
    wardBed.wardPatient?.careSettingPatient?.attributes?.isolationTypeOther;

  if (wardBed.wardPatient?.wardDischarge) {
    const wardDischargeIsolationType =
      wardBed.wardPatient?.wardDischarge?.attributes?.isolationTypeOther;

    return wardDischargeIsolationType;
  }

  return patientIsolationTypeOther;
};

export const getDischargeStatus = (wardBed: WardBed) => {
  const dischargeStatusType = getOption(
    dischargeStatusOptions,
    wardBed.wardPatient?.wardDischarge?.attributes.dischargeStatus
  );
  return dischargeStatusType;
};

export const getDischargeAssessmentOutcome = (
  wardBed: WardBed,
  dischargeStatus?: DischargeStatusType
) =>
  getOption(
    dischargeStatus === 'CONFIRMED'
      ? dischargePathwayOptions
      : awaitingDischargeAssessOptions,
    wardBed.wardPatient?.wardDischarge?.attributes.dischargeAssessmentOutcome
  );

export const getPotentialDischargeWaitingReasons = (wardBed: WardBed) => {
  const waitingReasons =
    wardBed.wardPatient?.wardDischarge?.attributes
      .waitingReasonsForPotentialDischarge;
  if (waitingReasons) {
    return waitingForPotentialDischargeOptions.filter((item) =>
      waitingReasons.includes(item.value as WaitingForPotentialDischarge)
    );
  }
  return null;
};

export const getClinicallyReadyWaitingReasons = (wardBed: WardBed) => {
  const waitingReasons =
    wardBed.wardPatient?.wardDischarge?.attributes
      .waitingReasonsForClinicallyReadyForDischarge;
  if (waitingReasons) {
    return waitingForDischargeOptions.filter((item) =>
      waitingReasons.includes(item.value as WaitingForDischarge)
    );
  }
  return null;
};

export const getFailedDischargeStatusReasons = (wardBed: WardBed) => {
  const patientsDelayedReasons =
    wardBed.wardPatient?.wardDischarge?.attributes.dischargeStatusFailedReasons;
  if (patientsDelayedReasons) {
    return failedDischargeOptions.filter((item) =>
      patientsDelayedReasons.includes(item.value as DischargeStatusFailed)
    );
  }
  return null;
};

export const initialValues: Partial<BedDischargeFormData> = {
  toTakeOutStatus: '',
  carePackageStatus: '',
  porterStatus: '',
  pharmacy: '',
  socialCareYN: '',
  requireDischargeToAssess: '',
  failedDischargeStatusReasonOther: '',
  waitingReasonOther: '',
  bedCleaningStatus: '',
  readyForDischargeDate: '',
  readyForDischargeTime: '',
  clinicallyReadyForDischargeDate: '',
  clinicallyReadyForDischargeTime: '',
  requiresIsolationYN: '',
  isolationType: null,
  isolationTypeOther: '',
  decisionMakerYN: '',
  dischargeExpectedDate: '',
  dischargeExpectedTime: '',
  dischargeAssessmentOutcome: null,
  dischargeActualDate: '',
  dischargeActualTime: '',
};

export const getPreviousDateTime = (
  value: YesNoType,
  previousDateTime?: string | null,
  isD2ADateTime?: boolean
) => {
  const currentUTCDateTime = getCurrentUTCDateTime();

  if (value === 'YES' && previousDateTime && isD2ADateTime)
    return previousDateTime;
  if (value === 'YES') return currentUTCDateTime;
  if (value === 'NO' && previousDateTime && !isD2ADateTime)
    return previousDateTime;

  return null;
};
