import type { UseFormResetField } from 'react-hook-form';

import type { BedAllocationFormData } from 'components/BedAllocation/types';
import type { WardBed, BedStatusType } from 'services';
import { resetAllocatedFormFields } from './allocated.utils';
import { resetInCleaningFormFields } from './inCleaning.utils';
import { resetClosedFormFields } from './closed.utils';
import { resetUnallocatedFormFields } from './unallocated.utils';

export const setFormValues = (
  isCurrent: boolean,
  resetField: UseFormResetField<BedAllocationFormData>,
  wardBed: WardBed,
  value: BedStatusType
) => {
  switch (value) {
    case 'ALLOCATED':
      resetAllocatedFormFields(resetField);
      break;
    case 'CLEANING':
      resetInCleaningFormFields(resetField, isCurrent, wardBed);
      break;
    case 'CLOSED':
      resetClosedFormFields(resetField);
      break;
    default:
      resetUnallocatedFormFields(resetField);
  }
};
