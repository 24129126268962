import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ROUTES } from 'App.constants';

type StateKeys = 'wardId';

export const useRedirectAndReturnState = (stateKey: StateKeys) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state && location.state[stateKey];

  useEffect(() => {
    if (!state) {
      navigate(ROUTES.HOME);
    }
  }, [state, navigate]);

  return state;
};
