import { colors, Text, toHyphenCase } from '@bt-healthcare/ui-toolkit';
import { TOOLTIP_MESSAGES } from 'App.constants';

import { BedCount } from 'components/BedCount';
import { getWardStatistic } from 'mappings/mapping.utils';
import type { WardStatisticsView } from 'models';

export const headerData: any = [
  { id: '1', label: { desktop: 'Ward name', mobile: 'Ward' } },
  { id: '2', label: '', hide: 'mobile' },
  { id: '3', label: { desktop: 'Patients', mobile: 'PATs' }, align: 'center' },
  {
    id: '4',
    label: { desktop: 'Beds avail.', mobile: 'Beds Av.' },
    align: 'center',
  },
  {
    id: '5',
    label: { desktop: 'Confirmed D. today', mobile: 'CON Dis.' },
    align: 'center',
  },
  {
    id: '6',
    label: { desktop: 'Potential D. today', mobile: 'POT Dis.' },
    align: 'center',
  },
  {
    id: '7',
    label: { desktop: 'Delayed D. today', mobile: 'DEL Dis.' },
    align: 'center',
  },
];

export const rowData: any = (data: WardStatisticsView[]) =>
  data.map((rowItem) => ({
    id: rowItem.id,
    active: rowItem.active,
    tooltip: !rowItem.active
      ? {
          content: TOOLTIP_MESSAGES.WARD_TRIAL_SCOPE,
          id: `tooltip-${toHyphenCase(rowItem.name)}`,
          triggerOnClick: true,
          direction: 'top-start',
        }
      : undefined,
    items: [
      {
        id: '1',
        item: (
          <Text fontWeight={500} color={colors.primaryIndigo.indigo08}>
            {rowItem.name}
          </Text>
        ),
        dataValue: {
          key: 'wardName',
          value: rowItem.name,
        },
      },
      {
        id: '2',
        item: <BedCount capacity={rowItem.capacity} />,
        hide: 'mobile',
        dataValue: {
          key: 'capacity',
          value: rowItem.capacity,
        },
      },
      {
        id: '3',
        item: getWardStatistic(rowItem.statistics, 'Patients'),
        align: 'center',
      },
      {
        id: '4',
        item: getWardStatistic(rowItem.statistics, 'Beds available'),
        align: 'center',
      },
      {
        id: '5',
        item: getWardStatistic(rowItem.statistics, 'Confirmed'),
        align: 'center',
      },
      {
        id: '6',
        item: getWardStatistic(rowItem.statistics, 'Potential'),
        align: 'center',
      },
      {
        id: '7',
        item: getWardStatistic(rowItem.statistics, 'Delayed'),
        align: 'center',
      },
    ],
  }));
