import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@bt-healthcare/ui-toolkit';

import { patientSelectionMapping } from 'mappings/datalists/patient/patientSelection';
import { useStore } from 'store';
import { DataListCard } from 'components/Card/DataListCard/DataListCard';
import { MatchingPatientAlert } from 'components/MatchingPatientAlert';
import type { ExtendedCareSettingPatient } from 'types';
import type { ModalProps } from '../types';
import { ModalHeadlineWrapper, ModalHeadline } from '../styles';

export const PatientSelectionModal = ({
  data,
  handleClose,
  handleClick,
  modalOpen,
}: ModalProps<ExtendedCareSettingPatient>) => {
  const patientName = `${
    data!.person.attributes.personalInformation.firstName
  } ${data.person.attributes.personalInformation.surname}`;

  const menuitems = useStore((state) => state.menuItems);
  const isDisabled = !!menuitems.find(
    (item) => item.label === 'Admissions' && item.active
  );
  return (
    <Modal
      onClose={handleClose}
      isModalOpen={modalOpen}
      id="patient-selection"
      inPortal
    >
      <ModalHeader>
        <ModalHeadlineWrapper>
          <ModalHeadline hasRagStatus={false}>{patientName}</ModalHeadline>
        </ModalHeadlineWrapper>
      </ModalHeader>
      <ModalBody>
        <DataListCard data={patientSelectionMapping(data)} />
        <MatchingPatientAlert
          data={data}
          note="There is another patient with the same name in this list."
        />
      </ModalBody>
      <ModalFooter flex>
        <Button
          aria-label="Cancel modal"
          id="cancel"
          onClick={handleClose}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          aria-label={`Select patient ${patientName}`}
          id="select"
          onClick={handleClick}
          disabled={isDisabled}
        >
          Select
        </Button>
      </ModalFooter>
    </Modal>
  );
};
