import * as yup from 'yup';

import {
  isRequiredWhenYes,
  isRequiredWhenSelection,
  isRequiredDropDownListOption,
  isRequiredWhenValueIsEnum,
  isRequiredTime,
  isRequiredNullable,
  isRequiredSelectionWithDependentField,
  DATE_REQUIRED_TEXT,
} from 'validation/schema';
import type { FormKey } from '../Form/types';
import type { BedDischargeFormData } from './types';

const dischargeFormRules = {
  isolationType: isRequiredWhenYes({
    field: 'requiresIsolationYN',
    fn: () => isRequiredDropDownListOption(),
    yupSchema: yup.object(),
  }),
  isolationTypeOther: isRequiredWhenSelection({
    field: 'isolationType',
    value: 'OTHER',
  }),
  dischargeStatus: isRequiredDropDownListOption(),
  dischargeExpectedDate: isRequiredWhenSelection({
    field: 'dischargeStatus',
    value: 'POTENTIAL|NOT_FIT|CLINICALLY_READY',
    fn: () => isRequiredNullable(yup.string(), DATE_REQUIRED_TEXT),
  }),
  dischargeExpectedTime: isRequiredWhenValueIsEnum({
    field: 'dischargeStatus',
    value: 'POTENTIAL|NOT_FIT|CLINICALLY_READY',
    fn: () => isRequiredTime(),
  }),
  dischargeActualDate: isRequiredSelectionWithDependentField({
    optionField: 'dischargeStatus',
    dependentField: 'dischargeActualTime',
    optionMatchValue: 'COMPLETED',
    fn: () => isRequiredNullable(yup.string(), DATE_REQUIRED_TEXT),
  }),
  dischargeActualTime: isRequiredSelectionWithDependentField({
    optionField: 'dischargeStatus',
    dependentField: 'dischargeActualDate',
    optionMatchValue: 'COMPLETED',
    fn: isRequiredTime,
  }),
  dischargeAssessmentOutcome: isRequiredWhenYes({
    field: 'requireDischargeToAssess',
    fn: () => isRequiredDropDownListOption(),
    yupSchema: yup.object(),
  }),
  readyForDischargeDate: isRequiredWhenSelection({
    field: 'dischargeStatus',
    value: 'CONFIRMED',
    fn: () => isRequiredNullable(yup.string(), DATE_REQUIRED_TEXT),
  }),
  readyForDischargeTime: isRequiredWhenValueIsEnum({
    field: 'dischargeStatus',
    value: 'CONFIRMED',
    fn: () => isRequiredTime(),
  }),
  clinicallyReadyForDischargeDate: isRequiredSelectionWithDependentField({
    optionField: 'dischargeStatus',
    dependentField: 'clinicallyReadyForDischargeTime',
    optionMatchValue: 'CLINICALLY_READY',
    fn: () => isRequiredNullable(yup.string(), DATE_REQUIRED_TEXT),
  }),
  clinicallyReadyForDischargeTime: isRequiredSelectionWithDependentField({
    optionField: 'dischargeStatus',
    dependentField: 'clinicallyReadyForDischargeDate',
    optionMatchValue: 'CLINICALLY_READY',
    fn: isRequiredTime,
  }),
  failedDischargeStatusReasonOther: isRequiredWhenSelection({
    field: 'failedDischargeStatusReasons',
    value: 'OTHER',
  }),
  waitingReasonOther: isRequiredWhenSelection({
    field: 'waitingReasonsForPotentialDischarge',
    value: 'OTHER',
  }),
  bedCleaningStatus: isRequiredWhenSelection({
    field: 'dischargeStatus',
    value: 'COMPLETED',
  }),
  bedCleaningMethod: isRequiredWhenSelection({
    field: 'bedCleaningStatus',
    value: 'REQUIRED',
    fn: () => isRequiredDropDownListOption(),
    yupSchema: yup.object(),
  }),
} as { [key in FormKey<BedDischargeFormData>]: any };

export const dischargeStatusSchema = yup.object().shape(dischargeFormRules, [
  ['dischargeActualDate', 'dischargeActualTime'],
  ['clinicallyReadyForDischargeDate', 'clinicallyReadyForDischargeTime'],
]);
