import { Input, MultipleChoiceButton } from '@bt-healthcare/ui-toolkit';

import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { yesNoOptions, isolationOptions } from 'components/Form/formOptions';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import { isYes, isOtherSelected } from 'validation/rules';
import type { PatientAdmissionFormFieldsProps } from '../types';

export const Isolation = ({
  register,
  control,
  resetField,
  markAsRequired = false,
}: PatientAdmissionFormFieldsProps) => (
  <>
    <MultipleChoiceButton
      label="Does the patient require isolation?"
      options={yesNoOptions}
      {...register('requiresIsolationYN', {
        onChange: () => {
          resetField!('isolationType', { defaultValue: null });
          resetField!('isolationTypeOther', { defaultValue: '' });
        },
      })}
      markAsRequired={markAsRequired}
    />
    <ConditionalFieldRenderer
      control={control}
      fieldName="requiresIsolationYN"
      condition={(arg) => (arg ? isYes(arg) : false)}
    >
      <RHFDropDown
        label="Reason for isolation"
        fieldName="isolationType"
        control={control}
        options={isolationOptions}
        markAsRequired={markAsRequired}
      />
      <ConditionalFieldRenderer
        control={control}
        fieldName="isolationType"
        condition={isOtherSelected}
      >
        <Input
          id="isolationTypeOther"
          label="Other reason for isolation"
          {...register('isolationTypeOther')}
          markAsRequired={markAsRequired}
        />
      </ConditionalFieldRenderer>
    </ConditionalFieldRenderer>
  </>
);
