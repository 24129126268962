import { sortWith, descend, prop, compose, map, omit } from 'ramda';

import type { CareSettingPatientQuery } from 'models';

export const sortByDecisionToAdmitWaitTime = (
  data: CareSettingPatientQuery
) => {
  if (data) {
    const result = data.map((item) => ({
      ...item,
      sortKey: item.attributes?.decisionToAdmitWaitTime ?? '0',
    }));
    const sortedResult = sortWith([descend(prop('sortKey'))], result);
    const omitKeys = compose(map, omit);
    return omitKeys(['sortKey'])(sortedResult) as CareSettingPatientQuery;
  }
  return data;
};
