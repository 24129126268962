import { useAnalytics } from '@bt-healthcare/ui-toolkit';
import { useStore } from '../store';
import { appName, basePageName } from '../App.constants';

export const useTracking = () => {
  const { trackPage: track } = useAnalytics();
  const {
    appConfig: { organisationName },
  } = useStore.getState();

  const trackPage = (pageName: string) => {
    const trackingProperties = {
      appName,
      careSetting: organisationName,
    };
    track(
      `${pageName}${basePageName}`,
      window.location.href,
      trackingProperties
    );
  };

  return {
    trackPage,
  };
};
