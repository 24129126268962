import { colors, Text, Tooltip, BTHealthIcon } from '@bt-healthcare/ui-toolkit';
import { EMPTY_VALUE, TOOLTIP_MESSAGES } from 'App.constants';

import { getDate, getHoursFromMins } from 'mappings/date.utils';
import {
  GenderIdentifiedAsAbbrevMap,
  OriginMap,
  SpecialtyMap,
  YesNoMap,
} from 'mappings/enums';
import {
  getPatientName,
  getPersonIsolationReason,
} from 'mappings/mapping.utils';
import type { ExtendedCareSettingPatient } from 'types';

export const headerData: any = [
  { id: '1', label: 'Patient' },
  { id: '2', label: '', hide: 'mobile' },
  { id: '3', label: { mobile: 'Wait(h)', desktop: 'Wait time (h)' } },
  { id: '4', label: 'Gender' },
  { id: '5', label: 'D.O.B', hide: 'mobile' },
  {
    id: '6',
    label: 'Origin',
    hide: 'mobile',
  },
  {
    id: '7',
    label: '',
    hide: 'mobile',
  },
  { id: '8', label: 'DTA Date', hide: 'mobile' },
  { id: '9', label: 'DTA Time', hide: 'mobile' },
  {
    id: '10',
    label: 'Speciality',
  },
  { id: '11', label: 'Isolation', hide: 'mobile' },
  { id: '12', label: 'Social Care', hide: 'mobile' },
];

export const rowData: any = (data: ExtendedCareSettingPatient[]) =>
  data.map((rowItem) => ({
    id: rowItem.id,
    items: [
      {
        id: '1',
        item: (
          <Text fontWeight={500} color={colors.primaryIndigo.indigo08}>
            {getPatientName(rowItem)}
          </Text>
        ),
      },
      {
        id: '2',
        item: rowItem.hasMatchingPatientName ? (
          <Tooltip
            content={TOOLTIP_MESSAGES.SAME_NAME_PATIENT}
            id={`tooltip-${rowItem.id}`}
          >
            <BTHealthIcon
              icon="People"
              size={20}
              color={colors.grey.grey08}
              title=""
            />
          </Tooltip>
        ) : (
          ''
        ),
        align: 'center',
        hide: 'mobile',
        overflow: false,
      },
      {
        id: '3',
        item: getHoursFromMins(rowItem.attributes.decisionToAdmitWaitTime),
      },
      {
        id: '4',
        item: GenderIdentifiedAsAbbrevMap[
          rowItem.person.attributes.personalInformation.genderIdentifiedAs
        ],
      },
      {
        id: '5',
        item: getDate(
          rowItem.person.attributes.personalInformation.dateOfBirth,
          'dd/MM/yyyy'
        ),
        hide: 'mobile',
      },
      {
        id: '6',
        item: OriginMap[rowItem.attributes.origin ?? 'NONE'],
        hide: 'mobile',
        overflow: false,
      },
      {
        id: '7',
        item: '',
        hide: 'mobile',
      },
      {
        id: '8',
        item: rowItem.attributes.client?.decisionToAdmitDate ?? EMPTY_VALUE,
        hide: 'mobile',
      },
      {
        id: '9',
        item: rowItem.attributes.client?.decisionToAdmitTime ?? EMPTY_VALUE,
        hide: 'mobile',
      },
      {
        id: '10',
        item: SpecialtyMap[rowItem.attributes.specialty ?? 'NONE'],
      },
      {
        id: '11',
        hide: 'mobile',
        item: getPersonIsolationReason(rowItem.attributes),
      },
      {
        id: '12',
        hide: 'mobile',
        item: YesNoMap[rowItem.attributes.socialCareYN ?? 'NONE'],
      },
    ],
  }));
